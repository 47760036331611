// @ts-nocheck
/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Orion API
 * OpenAPI spec version: 0.1.0
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import loginForAccessTokenAuthTokenPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as LoginForAccessTokenAuthTokenPostErrorType } from '../services/mutator/custom-instance';
import loginForAccessTokenByCodeAuthAuthorizationCodePostMutator from '../services/mutator/custom-instance';
import type { ErrorType as LoginForAccessTokenByCodeAuthAuthorizationCodePostErrorType } from '../services/mutator/custom-instance';
import changePasswordAuthUserPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as ChangePasswordAuthUserPatchErrorType } from '../services/mutator/custom-instance';
import messageWechatMessagePostMutator from '../services/mutator/custom-instance';
import type { ErrorType as MessageWechatMessagePostErrorType } from '../services/mutator/custom-instance';
import sentCallbackWechatSentResultPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as SentCallbackWechatSentResultPostErrorType } from '../services/mutator/custom-instance';
import weworkCallbackVerifyWechatWeworkCallbackGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as WeworkCallbackVerifyWechatWeworkCallbackGetErrorType } from '../services/mutator/custom-instance';
import weworkCallbackWechatWeworkCallbackPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as WeworkCallbackWechatWeworkCallbackPostErrorType } from '../services/mutator/custom-instance';
import wechatAuthWechatAuthPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as WechatAuthWechatAuthPostErrorType } from '../services/mutator/custom-instance';
import wechatAuthTestWechatAuthTestPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as WechatAuthTestWechatAuthTestPostErrorType } from '../services/mutator/custom-instance';
import wechatMpAuthWechatMpAuthPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as WechatMpAuthWechatMpAuthPostErrorType } from '../services/mutator/custom-instance';
import xfyunRaasrCallbackHookXfyunRaasrGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as XfyunRaasrCallbackHookXfyunRaasrGetErrorType } from '../services/mutator/custom-instance';
import googleCalendarChannelCallbackHookGoogleCalendarChannelPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as GoogleCalendarChannelCallbackHookGoogleCalendarChannelPostErrorType } from '../services/mutator/custom-instance';
import stripeWebhookHookStripeWebhookPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as StripeWebhookHookStripeWebhookPostErrorType } from '../services/mutator/custom-instance';
import callbackLineCallbackPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CallbackLineCallbackPostErrorType } from '../services/mutator/custom-instance';
import loginWithIdTokenLineLoginWithIdTokenPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as LoginWithIdTokenLineLoginWithIdTokenPostErrorType } from '../services/mutator/custom-instance';
import callbackWhatsappCallbackPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CallbackWhatsappCallbackPostErrorType } from '../services/mutator/custom-instance';
import callbackWhatsappTwilioCallbackPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CallbackWhatsappTwilioCallbackPostErrorType } from '../services/mutator/custom-instance';
import callbackImessageCallbackLivechatPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CallbackImessageCallbackLivechatPostErrorType } from '../services/mutator/custom-instance';
import larkOpsCommandOpsLarkHookPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as LarkOpsCommandOpsLarkHookPostErrorType } from '../services/mutator/custom-instance';
import authTelegramAuthPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as AuthTelegramAuthPostErrorType } from '../services/mutator/custom-instance';
import callbackTelegramCallbackTokenPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CallbackTelegramCallbackTokenPostErrorType } from '../services/mutator/custom-instance';
import oauthLoginOauthLoginProviderNameGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as OauthLoginOauthLoginProviderNameGetErrorType } from '../services/mutator/custom-instance';
import oauthCallbackOauthCallbackProviderNameGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as OauthCallbackOauthCallbackProviderNameGetErrorType } from '../services/mutator/custom-instance';
import getSharedEventActionGuestCalendarSharedEventsShareKeyGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetSharedEventActionGuestCalendarSharedEventsShareKeyGetErrorType } from '../services/mutator/custom-instance';
import downloadExportedIcsGuestCalendarExportDownloadExportKeyGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetErrorType } from '../services/mutator/custom-instance';
import userDeactivateReasonGuestDeactivateReasonPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as UserDeactivateReasonGuestDeactivateReasonPostErrorType } from '../services/mutator/custom-instance';
import callbackCallAliyunCallbackPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CallbackCallAliyunCallbackPostErrorType } from '../services/mutator/custom-instance';
import statusCallbackCallTwilioStatusCallbackPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as StatusCallbackCallTwilioStatusCallbackPostErrorType } from '../services/mutator/custom-instance';
import whatsappIncomingCallTwilioWhatsappIncomingPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as WhatsappIncomingCallTwilioWhatsappIncomingPostErrorType } from '../services/mutator/custom-instance';
import whatsappStatusCallTwilioWhatsappStatusPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as WhatsappStatusCallTwilioWhatsappStatusPostErrorType } from '../services/mutator/custom-instance';
import webTemplateApiWebI18nTemplateKeyGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as WebTemplateApiWebI18nTemplateKeyGetErrorType } from '../services/mutator/custom-instance';
import getUserTrafficDataUserTrafficGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetUserTrafficDataUserTrafficGetErrorType } from '../services/mutator/custom-instance';
import getCardContentFeedOpenV2FeedRidGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetCardContentFeedOpenV2FeedRidGetErrorType } from '../services/mutator/custom-instance';
import listFeedsFeedGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListFeedsFeedGetErrorType } from '../services/mutator/custom-instance';
import addFeedFeedPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as AddFeedFeedPostErrorType } from '../services/mutator/custom-instance';
import getFeedFeedUidGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetFeedFeedUidGetErrorType } from '../services/mutator/custom-instance';
import updateFeedFeedUidPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateFeedFeedUidPatchErrorType } from '../services/mutator/custom-instance';
import unsubFeedFeedUidDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as UnsubFeedFeedUidDeleteErrorType } from '../services/mutator/custom-instance';
import listFeedRecordsFeedUidHistoryGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListFeedRecordsFeedUidHistoryGetErrorType } from '../services/mutator/custom-instance';
import giveFeedFeedbackFeedUidFeedbackPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as GiveFeedFeedbackFeedUidFeedbackPostErrorType } from '../services/mutator/custom-instance';
import giveRecordLikeFeedRecordUidLikePostMutator from '../services/mutator/custom-instance';
import type { ErrorType as GiveRecordLikeFeedRecordUidLikePostErrorType } from '../services/mutator/custom-instance';
import cancelRecordDislikeFeedRecordUidLikeDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as CancelRecordDislikeFeedRecordUidLikeDeleteErrorType } from '../services/mutator/custom-instance';
import giveRecordDislikeFeedRecordUidDislikePostMutator from '../services/mutator/custom-instance';
import type { ErrorType as GiveRecordDislikeFeedRecordUidDislikePostErrorType } from '../services/mutator/custom-instance';
import listSubscribedChannelsFeedV2ChannelGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListSubscribedChannelsFeedV2ChannelGetErrorType } from '../services/mutator/custom-instance';
import subscribeNewChannelFeedV2ChannelPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as SubscribeNewChannelFeedV2ChannelPostErrorType } from '../services/mutator/custom-instance';
import getSubscribedChannelDetailFeedV2ChannelCidGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetSubscribedChannelDetailFeedV2ChannelCidGetErrorType } from '../services/mutator/custom-instance';
import unsubChannelFeedV2ChannelCidDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as UnsubChannelFeedV2ChannelCidDeleteErrorType } from '../services/mutator/custom-instance';
import switchSubscribedChannelFeedV2ChannelCidSwitchPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchErrorType } from '../services/mutator/custom-instance';
import addSubscriptionScheduleFeedV2ChannelCidTimePostMutator from '../services/mutator/custom-instance';
import type { ErrorType as AddSubscriptionScheduleFeedV2ChannelCidTimePostErrorType } from '../services/mutator/custom-instance';
import updateSubscriptionScheduleFeedV2ChannelCidTimePutMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateSubscriptionScheduleFeedV2ChannelCidTimePutErrorType } from '../services/mutator/custom-instance';
import deleteSubscriptionScheduleFeedV2ChannelCidTimeDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeleteErrorType } from '../services/mutator/custom-instance';
import listSubscribedChannelFeedsFeedV2FeedsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListSubscribedChannelFeedsFeedV2FeedsGetErrorType } from '../services/mutator/custom-instance';
import listTodayWeatherFeedsFeedV2TodayWeatherGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListTodayWeatherFeedsFeedV2TodayWeatherGetErrorType } from '../services/mutator/custom-instance';
import listTodayNewsFeedsFeedV2TodayNewsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListTodayNewsFeedsFeedV2TodayNewsGetErrorType } from '../services/mutator/custom-instance';
import readTodayNewsFeedV2TodayReadGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ReadTodayNewsFeedV2TodayReadGetErrorType } from '../services/mutator/custom-instance';
import getFeedLikeFeedV2RecordRidLikeGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetFeedLikeFeedV2RecordRidLikeGetErrorType } from '../services/mutator/custom-instance';
import likeFeedFeedV2RecordRidLikePostMutator from '../services/mutator/custom-instance';
import type { ErrorType as LikeFeedFeedV2RecordRidLikePostErrorType } from '../services/mutator/custom-instance';
import cancelFeedReactionFeedV2RecordRidLikeDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as CancelFeedReactionFeedV2RecordRidLikeDeleteErrorType } from '../services/mutator/custom-instance';
import dislikeFeedFeedV2RecordRidDislikePostMutator from '../services/mutator/custom-instance';
import type { ErrorType as DislikeFeedFeedV2RecordRidDislikePostErrorType } from '../services/mutator/custom-instance';
import queryRecomChannelsFeedV2RecomGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as QueryRecomChannelsFeedV2RecomGetErrorType } from '../services/mutator/custom-instance';
import joinRecomChannelsFeedV2RecomRidJoinPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as JoinRecomChannelsFeedV2RecomRidJoinPostErrorType } from '../services/mutator/custom-instance';
import listUserCalendarEventsUserCalendarEventsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListUserCalendarEventsUserCalendarEventsGetErrorType } from '../services/mutator/custom-instance';
import getUserCalendarEventUserCalendarEventsEventUuidGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetUserCalendarEventUserCalendarEventsEventUuidGetErrorType } from '../services/mutator/custom-instance';
import deleteUserCalendarEventUserCalendarEventsEventUuidDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteErrorType } from '../services/mutator/custom-instance';
import patchUserCalendarEventUserCalendarEventsEventUuidPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as PatchUserCalendarEventUserCalendarEventsEventUuidPatchErrorType } from '../services/mutator/custom-instance';
import createSharedEventActionUserCalendarSharedEventsPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateSharedEventActionUserCalendarSharedEventsPostErrorType } from '../services/mutator/custom-instance';
import getSharedEventActionUserCalendarSharedEventsShareKeyGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetSharedEventActionUserCalendarSharedEventsShareKeyGetErrorType } from '../services/mutator/custom-instance';
import joinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostErrorType } from '../services/mutator/custom-instance';
import deactivateUserUserDeactivatePostMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeactivateUserUserDeactivatePostErrorType } from '../services/mutator/custom-instance';
import giveFeedbackUserFeedbackPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as GiveFeedbackUserFeedbackPostErrorType } from '../services/mutator/custom-instance';
import getUserInfoUserInfoGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetUserInfoUserInfoGetErrorType } from '../services/mutator/custom-instance';
import updateUserSettingsUserSettingsPutMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateUserSettingsUserSettingsPutErrorType } from '../services/mutator/custom-instance';
import resetSelfHostedPasswordUserAccountsSelfHostedPasswordPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as ResetSelfHostedPasswordUserAccountsSelfHostedPasswordPostErrorType } from '../services/mutator/custom-instance';
import exportSelfHostedCalendarUserAccountsSelfHostedExportGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ExportSelfHostedCalendarUserAccountsSelfHostedExportGetErrorType } from '../services/mutator/custom-instance';
import addIcloudAccountUserAccountsIcloudPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as AddIcloudAccountUserAccountsIcloudPostErrorType } from '../services/mutator/custom-instance';
import bindGoogleAccountUserAccountsGoogleGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as BindGoogleAccountUserAccountsGoogleGetErrorType } from '../services/mutator/custom-instance';
import unbindCalendarAccountUserAccountsProviderDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as UnbindCalendarAccountUserAccountsProviderDeleteErrorType } from '../services/mutator/custom-instance';
import listCalendarsUserCalendarsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListCalendarsUserCalendarsGetErrorType } from '../services/mutator/custom-instance';
import setDefaultCalendarUserCalendarsDefaultPutMutator from '../services/mutator/custom-instance';
import type { ErrorType as SetDefaultCalendarUserCalendarsDefaultPutErrorType } from '../services/mutator/custom-instance';
import createCustomerSessionActionUserPaymentsCustomerSessionPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateCustomerSessionActionUserPaymentsCustomerSessionPostErrorType } from '../services/mutator/custom-instance';
import createBillingPortalSessionActionUserPaymentsBillingPortalSessionPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateBillingPortalSessionActionUserPaymentsBillingPortalSessionPostErrorType } from '../services/mutator/custom-instance';
import listUserAirnoteTablesUserAirnoteTablesGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListUserAirnoteTablesUserAirnoteTablesGetErrorType } from '../services/mutator/custom-instance';
import listUserAirnoteTableRowsUserAirnoteTablesTableNameGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetErrorType } from '../services/mutator/custom-instance';
import listUserAirnoteActivityUserAirnoteActivityGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListUserAirnoteActivityUserAirnoteActivityGetErrorType } from '../services/mutator/custom-instance';
import setDefaultCalendarV2UserV2CalendarsDefaultPutMutator from '../services/mutator/custom-instance';
import type { ErrorType as SetDefaultCalendarV2UserV2CalendarsDefaultPutErrorType } from '../services/mutator/custom-instance';
import getCodeUserAuthCodePostMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetCodeUserAuthCodePostErrorType } from '../services/mutator/custom-instance';
import getTokenUserAuthTokenGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetTokenUserAuthTokenGetErrorType } from '../services/mutator/custom-instance';
import downloadFileExperimentalSandboxFilesFilePathGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as DownloadFileExperimentalSandboxFilesFilePathGetErrorType } from '../services/mutator/custom-instance';
import sqliteViewerExperimentalSandboxSqliteViewerGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as SqliteViewerExperimentalSandboxSqliteViewerGetErrorType } from '../services/mutator/custom-instance';
import listUserCalendarEventsExperimentalClockCalendarNextEventsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListUserCalendarEventsExperimentalClockCalendarNextEventsGetErrorType } from '../services/mutator/custom-instance';
import completeExperimentalOpenaiChatCompletionsPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CompleteExperimentalOpenaiChatCompletionsPostErrorType } from '../services/mutator/custom-instance';
import listLabelsAdminAgentsUuidLabelsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListLabelsAdminAgentsUuidLabelsGetErrorType } from '../services/mutator/custom-instance';
import updateAdminAgentsUuidLabelsPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateAdminAgentsUuidLabelsPatchErrorType } from '../services/mutator/custom-instance';
import addLabelsAdminAgentsUuidLabelsPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as AddLabelsAdminAgentsUuidLabelsPostErrorType } from '../services/mutator/custom-instance';
import deleteLabelAdminAgentsUuidLabelsLabelIdDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteErrorType } from '../services/mutator/custom-instance';
import getModelsAdminAgentsModelsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetModelsAdminAgentsModelsGetErrorType } from '../services/mutator/custom-instance';
import listAgentsAdminAgentsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListAgentsAdminAgentsGetErrorType } from '../services/mutator/custom-instance';
import getAgentOptionsAdminAgentsOptionsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetAgentOptionsAdminAgentsOptionsGetErrorType } from '../services/mutator/custom-instance';
import getAgentAdminAgentsUuidGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetAgentAdminAgentsUuidGetErrorType } from '../services/mutator/custom-instance';
import deleteAgentAdminAgentsUuidDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteAgentAdminAgentsUuidDeleteErrorType } from '../services/mutator/custom-instance';
import saveAgentAdminAgentsUuidPutMutator from '../services/mutator/custom-instance';
import type { ErrorType as SaveAgentAdminAgentsUuidPutErrorType } from '../services/mutator/custom-instance';
import patchAgentAdminAgentsUuidVersionsVersionPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as PatchAgentAdminAgentsUuidVersionsVersionPatchErrorType } from '../services/mutator/custom-instance';
import getVersionedAgentAdminAgentsUuidVersionsVersionGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetVersionedAgentAdminAgentsUuidVersionsVersionGetErrorType } from '../services/mutator/custom-instance';
import deleteVersionAdminAgentsUuidVersionsVersionDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteVersionAdminAgentsUuidVersionsVersionDeleteErrorType } from '../services/mutator/custom-instance';
import getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetErrorType } from '../services/mutator/custom-instance';
import getAgentVersionsAdminAgentsUuidVersionsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetAgentVersionsAdminAgentsUuidVersionsGetErrorType } from '../services/mutator/custom-instance';
import deployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostErrorType } from '../services/mutator/custom-instance';
import getPromptParametersAdminAgentsUuidPromptParamsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetPromptParametersAdminAgentsUuidPromptParamsGetErrorType } from '../services/mutator/custom-instance';
import createDraftAgentAdminAgentsPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateDraftAgentAdminAgentsPostErrorType } from '../services/mutator/custom-instance';
import listLabelsAdminWorkflowsUuidLabelsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListLabelsAdminWorkflowsUuidLabelsGetErrorType } from '../services/mutator/custom-instance';
import updateAdminWorkflowsUuidLabelsPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateAdminWorkflowsUuidLabelsPatchErrorType } from '../services/mutator/custom-instance';
import addLabelsAdminWorkflowsUuidLabelsPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as AddLabelsAdminWorkflowsUuidLabelsPostErrorType } from '../services/mutator/custom-instance';
import deleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteErrorType } from '../services/mutator/custom-instance';
import listWorkflowAdminWorkflowsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListWorkflowAdminWorkflowsGetErrorType } from '../services/mutator/custom-instance';
import getWorkflowAdminWorkflowsUuidGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetWorkflowAdminWorkflowsUuidGetErrorType } from '../services/mutator/custom-instance';
import saveWorkflowAdminWorkflowsUuidPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as SaveWorkflowAdminWorkflowsUuidPatchErrorType } from '../services/mutator/custom-instance';
import deleteWorkflowApiAdminWorkflowsUuidDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteWorkflowApiAdminWorkflowsUuidDeleteErrorType } from '../services/mutator/custom-instance';
import updateWorkflowApiAdminWorkflowsUuidPutMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateWorkflowApiAdminWorkflowsUuidPutErrorType } from '../services/mutator/custom-instance';
import createNewWorkflowAdminWorkflowsPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateNewWorkflowAdminWorkflowsPostErrorType } from '../services/mutator/custom-instance';
import getWorkflowVersionsAdminWorkflowsUuidVersionsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetWorkflowVersionsAdminWorkflowsUuidVersionsGetErrorType } from '../services/mutator/custom-instance';
import getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetErrorType } from '../services/mutator/custom-instance';
import deleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeleteErrorType } from '../services/mutator/custom-instance';
import deployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostErrorType } from '../services/mutator/custom-instance';
import listLabelsAdminEvaluationTemplatesIdLabelsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListLabelsAdminEvaluationTemplatesIdLabelsGetErrorType } from '../services/mutator/custom-instance';
import updateAdminEvaluationTemplatesIdLabelsPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateAdminEvaluationTemplatesIdLabelsPatchErrorType } from '../services/mutator/custom-instance';
import addLabelsAdminEvaluationTemplatesIdLabelsPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as AddLabelsAdminEvaluationTemplatesIdLabelsPostErrorType } from '../services/mutator/custom-instance';
import deleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteErrorType } from '../services/mutator/custom-instance';
import listEvaluationTemplatesAdminEvaluationTemplatesGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListEvaluationTemplatesAdminEvaluationTemplatesGetErrorType } from '../services/mutator/custom-instance';
import createTemplateAdminEvaluationTemplatesPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateTemplateAdminEvaluationTemplatesPostErrorType } from '../services/mutator/custom-instance';
import getEvaluationTemplateAdminEvaluationTemplatesIdGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetEvaluationTemplateAdminEvaluationTemplatesIdGetErrorType } from '../services/mutator/custom-instance';
import updateTemplateAdminEvaluationTemplatesIdPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateTemplateAdminEvaluationTemplatesIdPatchErrorType } from '../services/mutator/custom-instance';
import deleteTemplateAdminEvaluationTemplatesIdDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteTemplateAdminEvaluationTemplatesIdDeleteErrorType } from '../services/mutator/custom-instance';
import listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetErrorType } from '../services/mutator/custom-instance';
import createTemplateBatchAdminEvaluationTemplatesBatchPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateTemplateBatchAdminEvaluationTemplatesBatchPostErrorType } from '../services/mutator/custom-instance';
import completeTemplateAdminEvaluationTemplatesIdCompletionPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CompleteTemplateAdminEvaluationTemplatesIdCompletionPostErrorType } from '../services/mutator/custom-instance';
import parserTemplateCaseAdminEvaluationTemplatesCaseParserPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as ParserTemplateCaseAdminEvaluationTemplatesCaseParserPostErrorType } from '../services/mutator/custom-instance';
import createTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPostErrorType } from '../services/mutator/custom-instance';
import createTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPostErrorType } from '../services/mutator/custom-instance';
import listEvaluationPlansAdminEvaluationPlansGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListEvaluationPlansAdminEvaluationPlansGetErrorType } from '../services/mutator/custom-instance';
import createEvaluationAdminEvaluationPlansPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateEvaluationAdminEvaluationPlansPostErrorType } from '../services/mutator/custom-instance';
import getEvaluationPlanAdminEvaluationPlansIdGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetEvaluationPlanAdminEvaluationPlansIdGetErrorType } from '../services/mutator/custom-instance';
import startEvaluationAdminEvaluationPlansIdRunPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as StartEvaluationAdminEvaluationPlansIdRunPostErrorType } from '../services/mutator/custom-instance';
import getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetErrorType } from '../services/mutator/custom-instance';
import listEvaluationTasksAdminEvaluationPlansIdTasksGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListEvaluationTasksAdminEvaluationPlansIdTasksGetErrorType } from '../services/mutator/custom-instance';
import createEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostErrorType } from '../services/mutator/custom-instance';
import batchCreateEvaluationAdminEvaluationPlansBatchPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as BatchCreateEvaluationAdminEvaluationPlansBatchPostErrorType } from '../services/mutator/custom-instance';
import createTemplateEvaluationAdminEvaluationPlansTemplateBatchPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateTemplateEvaluationAdminEvaluationPlansTemplateBatchPostErrorType } from '../services/mutator/custom-instance';
import getEvaluationTaskAdminEvaluationTaskIdGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetEvaluationTaskAdminEvaluationTaskIdGetErrorType } from '../services/mutator/custom-instance';
import queryAgentTracesAdminTraceByAgentUuidGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as QueryAgentTracesAdminTraceByAgentUuidGetErrorType } from '../services/mutator/custom-instance';
import getExecutionContextAdminTraceTypeIdGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetExecutionContextAdminTraceTypeIdGetErrorType } from '../services/mutator/custom-instance';
import listLabelsAdminTemplatesIdLabelsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListLabelsAdminTemplatesIdLabelsGetErrorType } from '../services/mutator/custom-instance';
import updateAdminTemplatesIdLabelsPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateAdminTemplatesIdLabelsPatchErrorType } from '../services/mutator/custom-instance';
import addLabelsAdminTemplatesIdLabelsPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as AddLabelsAdminTemplatesIdLabelsPostErrorType } from '../services/mutator/custom-instance';
import deleteLabelAdminTemplatesIdLabelsLabelIdDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteErrorType } from '../services/mutator/custom-instance';
import queryTemplatesApiAdminTemplatesGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as QueryTemplatesApiAdminTemplatesGetErrorType } from '../services/mutator/custom-instance';
import queryTemplateKeysApiAdminTemplatesKeysGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as QueryTemplateKeysApiAdminTemplatesKeysGetErrorType } from '../services/mutator/custom-instance';
import getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetErrorType } from '../services/mutator/custom-instance';
import getTemplatesApiAdminTemplatesKeysTemplateKeyGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetTemplatesApiAdminTemplatesKeysTemplateKeyGetErrorType } from '../services/mutator/custom-instance';
import getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetErrorType } from '../services/mutator/custom-instance';
import updateTemplateApiAdminTemplatesIdPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateTemplateApiAdminTemplatesIdPatchErrorType } from '../services/mutator/custom-instance';
import deleteTemplateApiAdminTemplatesIdDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteTemplateApiAdminTemplatesIdDeleteErrorType } from '../services/mutator/custom-instance';
import addTemplateApiAdminTemplatesPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as AddTemplateApiAdminTemplatesPostErrorType } from '../services/mutator/custom-instance';
import addDraftTemplateApiAdminTemplatesDraftPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as AddDraftTemplateApiAdminTemplatesDraftPostErrorType } from '../services/mutator/custom-instance';
import getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetErrorType } from '../services/mutator/custom-instance';
import deployTemplateApiAdminTemplatesDraftIdPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeployTemplateApiAdminTemplatesDraftIdPostErrorType } from '../services/mutator/custom-instance';
import batchDeployTemplateApiAdminTemplatesDeployPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as BatchDeployTemplateApiAdminTemplatesDeployPostErrorType } from '../services/mutator/custom-instance';
import translateTemplateApiAdminTemplatesTranslatePostMutator from '../services/mutator/custom-instance';
import type { ErrorType as TranslateTemplateApiAdminTemplatesTranslatePostErrorType } from '../services/mutator/custom-instance';
import showTranslateTemplateResultApiAdminTemplatesTranslateGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ShowTranslateTemplateResultApiAdminTemplatesTranslateGetErrorType } from '../services/mutator/custom-instance';
import showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetErrorType } from '../services/mutator/custom-instance';
import listLabelsAdminLabelsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListLabelsAdminLabelsGetErrorType } from '../services/mutator/custom-instance';
import updateTemplateApiAdminLabelsIdPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateTemplateApiAdminLabelsIdPatchErrorType } from '../services/mutator/custom-instance';
import deleteTemplateApiAdminLabelsIdDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteTemplateApiAdminLabelsIdDeleteErrorType } from '../services/mutator/custom-instance';
import createLabelAdminLabelsPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateLabelAdminLabelsPostErrorType } from '../services/mutator/custom-instance';
import getCurrentDashUserInfoAdminUserGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetCurrentDashUserInfoAdminUserGetErrorType } from '../services/mutator/custom-instance';
import getExecelAdminFinetuneGetExcelPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetExecelAdminFinetuneGetExcelPostErrorType } from '../services/mutator/custom-instance';
import getFileAdminFinetuneGetOpenaiFileFileIdGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetFileAdminFinetuneGetOpenaiFileFileIdGetErrorType } from '../services/mutator/custom-instance';
import uploadExcelAdminFinetuneUploadExcelPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as UploadExcelAdminFinetuneUploadExcelPostErrorType } from '../services/mutator/custom-instance';
import listFinetuneJobAdminFinetuneTuneJobGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListFinetuneJobAdminFinetuneTuneJobGetErrorType } from '../services/mutator/custom-instance';
import createFinetuneJobAdminFinetuneTuneJobPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateFinetuneJobAdminFinetuneTuneJobPostErrorType } from '../services/mutator/custom-instance';
import listFinetuneModelsAdminFinetuneModelGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListFinetuneModelsAdminFinetuneModelGetErrorType } from '../services/mutator/custom-instance';
import listFinetuneFilesAdminFinetuneTuneFileGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListFinetuneFilesAdminFinetuneTuneFileGetErrorType } from '../services/mutator/custom-instance';
import enableTuneModelAdminFinetuneEnablePostMutator from '../services/mutator/custom-instance';
import type { ErrorType as EnableTuneModelAdminFinetuneEnablePostErrorType } from '../services/mutator/custom-instance';
import getUserProfileExecelAdminChoreGetUserProfileExecelPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetUserProfileExecelAdminChoreGetUserProfileExecelPostErrorType } from '../services/mutator/custom-instance';
import listLabelsAdminAnnotationIdLabelsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListLabelsAdminAnnotationIdLabelsGetErrorType } from '../services/mutator/custom-instance';
import updateAdminAnnotationIdLabelsPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateAdminAnnotationIdLabelsPatchErrorType } from '../services/mutator/custom-instance';
import addLabelsAdminAnnotationIdLabelsPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as AddLabelsAdminAnnotationIdLabelsPostErrorType } from '../services/mutator/custom-instance';
import deleteLabelAdminAnnotationIdLabelsLabelIdDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteErrorType } from '../services/mutator/custom-instance';
import listAnnotationsAdminAnnotationGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListAnnotationsAdminAnnotationGetErrorType } from '../services/mutator/custom-instance';
import createAdminAnnotationPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateAdminAnnotationPostErrorType } from '../services/mutator/custom-instance';
import getAdminAnnotationIdGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetAdminAnnotationIdGetErrorType } from '../services/mutator/custom-instance';
import removeAdminAnnotationIdDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as RemoveAdminAnnotationIdDeleteErrorType } from '../services/mutator/custom-instance';
import getUsersAdminUsersGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetUsersAdminUsersGetErrorType } from '../services/mutator/custom-instance';
import getUserAdminUsersUserIdGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetUserAdminUsersUserIdGetErrorType } from '../services/mutator/custom-instance';
import updateUserActionAdminUsersUserIdPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateUserActionAdminUsersUserIdPatchErrorType } from '../services/mutator/custom-instance';
import getUserCalendarEventsAdminUsersUserIdCalendarEventsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetErrorType } from '../services/mutator/custom-instance';
import getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetErrorType } from '../services/mutator/custom-instance';
import getUserNotesAdminUsersUserIdNotesGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetUserNotesAdminUsersUserIdNotesGetErrorType } from '../services/mutator/custom-instance';
import createUserNoteAdminUsersUserIdNotesPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateUserNoteAdminUsersUserIdNotesPostErrorType } from '../services/mutator/custom-instance';
import updateUserNoteAdminUsersUserIdNotesNoteIdPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchErrorType } from '../services/mutator/custom-instance';
import deleteUserNoteAdminUsersUserIdNotesNoteIdDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteUserNoteAdminUsersUserIdNotesNoteIdDeleteErrorType } from '../services/mutator/custom-instance';
import getChatHistoryByUserAdminUsersUserIdChatHistoryGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetChatHistoryByUserAdminUsersUserIdChatHistoryGetErrorType } from '../services/mutator/custom-instance';
import getChatStatsByUserAdminUsersUserIdChatStatsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetChatStatsByUserAdminUsersUserIdChatStatsGetErrorType } from '../services/mutator/custom-instance';
import getUserEventsAdminUsersUserIdEventsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetUserEventsAdminUsersUserIdEventsGetErrorType } from '../services/mutator/custom-instance';
import cancelChatAdminChatCancelPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CancelChatAdminChatCancelPostErrorType } from '../services/mutator/custom-instance';
import chatWithBotAdminChatPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as ChatWithBotAdminChatPostErrorType } from '../services/mutator/custom-instance';
import deleteChatMessagesAdminChatHistoryDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteChatMessagesAdminChatHistoryDeleteErrorType } from '../services/mutator/custom-instance';
import getChatHistoryAdminChatHistoryGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetChatHistoryAdminChatHistoryGetErrorType } from '../services/mutator/custom-instance';
import dryRunAgentAdminAgentsRunPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as DryRunAgentAdminAgentsRunPostErrorType } from '../services/mutator/custom-instance';
import dumpFullWorkflowConfigAdminWorkflowsDumpGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as DumpFullWorkflowConfigAdminWorkflowsDumpGetErrorType } from '../services/mutator/custom-instance';
import dryRunWorkflowAdminWorkflowsRunPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as DryRunWorkflowAdminWorkflowsRunPostErrorType } from '../services/mutator/custom-instance';
import listLabelsAdminMessagesIdLabelsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListLabelsAdminMessagesIdLabelsGetErrorType } from '../services/mutator/custom-instance';
import updateAdminMessagesIdLabelsPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateAdminMessagesIdLabelsPatchErrorType } from '../services/mutator/custom-instance';
import addLabelsAdminMessagesIdLabelsPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as AddLabelsAdminMessagesIdLabelsPostErrorType } from '../services/mutator/custom-instance';
import deleteLabelAdminMessagesIdLabelsLabelIdDeleteMutator from '../services/mutator/custom-instance';
import type { ErrorType as DeleteLabelAdminMessagesIdLabelsLabelIdDeleteErrorType } from '../services/mutator/custom-instance';
import getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetErrorType } from '../services/mutator/custom-instance';
import getChatGroupsAdminGroupsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetChatGroupsAdminGroupsGetErrorType } from '../services/mutator/custom-instance';
import getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetErrorType } from '../services/mutator/custom-instance';
import getChatStatsByGroupAdminGroupsGroupIdChatStatsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetErrorType } from '../services/mutator/custom-instance';
import getGroupEventsAdminGroupsGroupIdEventsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetGroupEventsAdminGroupsGroupIdEventsGetErrorType } from '../services/mutator/custom-instance';
import getSessionsAdminSessionsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetSessionsAdminSessionsGetErrorType } from '../services/mutator/custom-instance';
import getSessionsByUserIdAdminSessionsUserIdGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetSessionsByUserIdAdminSessionsUserIdGetErrorType } from '../services/mutator/custom-instance';
import getBotsAdminBotsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetBotsAdminBotsGetErrorType } from '../services/mutator/custom-instance';
import listContactWayActionAdminWechatContactWaysGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListContactWayActionAdminWechatContactWaysGetErrorType } from '../services/mutator/custom-instance';
import createContactWayActionAdminWechatContactWaysPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateContactWayActionAdminWechatContactWaysPostErrorType } from '../services/mutator/custom-instance';
import patchContactWayActionAdminWechatContactWaysWayIdPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as PatchContactWayActionAdminWechatContactWaysWayIdPatchErrorType } from '../services/mutator/custom-instance';
import listAllAdminUserTrafficGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as ListAllAdminUserTrafficGetErrorType } from '../services/mutator/custom-instance';
import createAdminUserTrafficPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as CreateAdminUserTrafficPostErrorType } from '../services/mutator/custom-instance';
import updateAdminUserTrafficIdPatchMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateAdminUserTrafficIdPatchErrorType } from '../services/mutator/custom-instance';
import getDashboardUrlAdminMetabaseDashboardPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetDashboardUrlAdminMetabaseDashboardPostErrorType } from '../services/mutator/custom-instance';
import getMockDataAdminCalendarMockDataGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as GetMockDataAdminCalendarMockDataGetErrorType } from '../services/mutator/custom-instance';
import executeWorkflowApiWorkflowsWorkflowUuidPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as ExecuteWorkflowApiWorkflowsWorkflowUuidPostErrorType } from '../services/mutator/custom-instance';
import executeAgentApiAgentsAgentIdPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as ExecuteAgentApiAgentsAgentIdPostErrorType } from '../services/mutator/custom-instance';
import runAdminPlaygroundRunPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as RunAdminPlaygroundRunPostErrorType } from '../services/mutator/custom-instance';
import executeToolAdminPlaygroundExecuteToolPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as ExecuteToolAdminPlaygroundExecuteToolPostErrorType } from '../services/mutator/custom-instance';
import shareAdminPlaygroundSharePostMutator from '../services/mutator/custom-instance';
import type { ErrorType as ShareAdminPlaygroundSharePostErrorType } from '../services/mutator/custom-instance';
import updateAdminPlaygroundUpdateUuidPostMutator from '../services/mutator/custom-instance';
import type { ErrorType as UpdateAdminPlaygroundUpdateUuidPostErrorType } from '../services/mutator/custom-instance';
import presetsAdminPlaygroundPresetsGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as PresetsAdminPlaygroundPresetsGetErrorType } from '../services/mutator/custom-instance';
import presetAdminPlaygroundPresetsPresetUuidGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as PresetAdminPlaygroundPresetsPresetUuidGetErrorType } from '../services/mutator/custom-instance';
import pingGetMutator from '../services/mutator/custom-instance';
import type { ErrorType as PingGetErrorType } from '../services/mutator/custom-instance';
export type PingGetParams = {
code?: number;
};

export type PresetAdminPlaygroundPresetsPresetUuidGetPathParameters = {
 uuid: string,
 }
export type UpdateAdminPlaygroundUpdateUuidPostPathParameters = {
 uuid: string,
 }
export type RunAdminPlaygroundRunPost200 = { [key: string]: unknown };

export type ExecuteAgentApiAgentsAgentIdPostPathParameters = {
 agentId: string,
 }
export type ExecuteWorkflowApiWorkflowsWorkflowUuidPostPathParameters = {
 workflowUuid: string,
 }
export type UpdateAdminUserTrafficIdPatchPathParameters = {
 id: number,
 }
export type PatchContactWayActionAdminWechatContactWaysWayIdPatchPathParameters = {
 wayId: number,
 }
export type GetBotsAdminBotsGetParams = {
source?: Source;
bot_type?: BotType;
};

export type GetSessionsByUserIdAdminSessionsUserIdGetPathParameters = {
 userId: number,
 }
export type GetSessionsAdminSessionsGetParams = {
keyword?: string;
page?: number;
page_size?: number;
bound_source?: Source;
languages?: string[] | null;
timezones?: string[] | null;
bot_id?: number;
session_type?: SessionType;
filter_sub_group?: boolean;
};

export type GetGroupEventsAdminGroupsGroupIdEventsGetParams = {
page?: number;
page_size?: number;
not_before?: string | null;
not_after?: string | null;
};

export type GetGroupEventsAdminGroupsGroupIdEventsGetPathParameters = {
 groupId: number,
 }
export type GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams = {
from_date: string;
to_date: string;
};

export type GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetPathParameters = {
 groupId: number,
 }
export type GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams = {
page?: number;
page_size?: number;
from_date?: string | null;
to_date?: string | null;
};

export type GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetPathParameters = {
 groupId: number,
 }
export type GetChatGroupsAdminGroupsGetParams = {
keyword?: string;
id?: string;
source?: Source;
page?: number;
page_size?: number;
filter_sub_group?: boolean;
};

export type GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams = {
page?: number;
page_size?: number;
desc?: boolean;
};

export type GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetPathParameters = {
 messageId: number,
 }
export type DeleteLabelAdminMessagesIdLabelsLabelIdDeleteParams = {
uuid?: string | null;
};

export type DeleteLabelAdminMessagesIdLabelsLabelIdDeletePathParameters = {
 id: number | null,
    labelId: number,
 }
export type AddLabelsAdminMessagesIdLabelsPostParams = {
uuid?: string | null;
};

export type AddLabelsAdminMessagesIdLabelsPostPathParameters = {
 id: number | null,
 }
export type UpdateAdminMessagesIdLabelsPatchParams = {
uuid?: string | null;
};

export type UpdateAdminMessagesIdLabelsPatchPathParameters = {
 id: number | null,
 }
export type ListLabelsAdminMessagesIdLabelsGetParams = {
uuid?: string | null;
};

export type ListLabelsAdminMessagesIdLabelsGetPathParameters = {
 id: number | null,
 }
export type GetChatHistoryAdminChatHistoryGetParams = {
source?: Source;
page?: number;
page_size?: number;
};

export type GetUserEventsAdminUsersUserIdEventsGetParams = {
page?: number;
page_size?: number;
not_before?: string | null;
not_after?: string | null;
};

export type GetUserEventsAdminUsersUserIdEventsGetPathParameters = {
 userId: number,
 }
export type GetChatStatsByUserAdminUsersUserIdChatStatsGetParams = {
from_date: string;
to_date: string;
session_ref_id?: number;
};

export type GetChatStatsByUserAdminUsersUserIdChatStatsGetPathParameters = {
 userId: number,
 }
export type GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams = {
page?: number;
page_size?: number;
from_date?: string | null;
to_date?: string | null;
session_ref_id?: number;
};

export type GetChatHistoryByUserAdminUsersUserIdChatHistoryGetPathParameters = {
 userId: number,
 }
export type DeleteUserNoteAdminUsersUserIdNotesNoteIdDeletePathParameters = {
 userId: number,
    noteId: number,
 }
export type UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchPathParameters = {
 userId: number,
    noteId: number,
 }
export type CreateUserNoteAdminUsersUserIdNotesPostPathParameters = {
 userId: number,
 }
export type GetUserNotesAdminUsersUserIdNotesGetPathParameters = {
 userId: number,
 }
export type GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetPathParameters = {
 userId: number,
    eventUuid: string,
 }
export type GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetPathParameters = {
 userId: number,
 }
export type UpdateUserActionAdminUsersUserIdPatchPathParameters = {
 userId: number,
 }
export type GetUserAdminUsersUserIdGetPathParameters = {
 userId: number,
 }
export type GetUsersAdminUsersGetParams = {
keyword?: string;
page?: number;
page_size?: number;
bound_source?: Source;
languages?: string[] | null;
timezones?: string[] | null;
bot_id?: number;
};

export type RemoveAdminAnnotationIdDeletePathParameters = {
 id: number,
 }
export type GetAdminAnnotationIdGetPathParameters = {
 id: number,
 }
export type ListAnnotationsAdminAnnotationGetParams = {
page?: number;
page_size?: number;
label_ids?: number[] | null;
from_date?: string | null;
to_date?: string | null;
created_by_user_ids?: number[] | null;
combination?: string;
};

export type DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteParams = {
uuid?: string | null;
};

export type DeleteLabelAdminAnnotationIdLabelsLabelIdDeletePathParameters = {
 id: number | null,
    labelId: number,
 }
export type AddLabelsAdminAnnotationIdLabelsPostParams = {
uuid?: string | null;
};

export type AddLabelsAdminAnnotationIdLabelsPostPathParameters = {
 id: number | null,
 }
export type UpdateAdminAnnotationIdLabelsPatchParams = {
uuid?: string | null;
};

export type UpdateAdminAnnotationIdLabelsPatchPathParameters = {
 id: number | null,
 }
export type ListLabelsAdminAnnotationIdLabelsGetParams = {
uuid?: string | null;
};

export type ListLabelsAdminAnnotationIdLabelsGetPathParameters = {
 id: number | null,
 }
export type GetFileAdminFinetuneGetOpenaiFileFileIdGetPathParameters = {
 fileId: string,
 }
export type DeleteTemplateApiAdminLabelsIdDeletePathParameters = {
 id: number,
 }
export type UpdateTemplateApiAdminLabelsIdPatchPathParameters = {
 id: number,
 }
export type ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams = {
batch_id?: string | null;
};

export type ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams = {
batch_id?: string | null;
};

export type DeployTemplateApiAdminTemplatesDraftIdPostPathParameters = {
 id: number,
 }
export type GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams = {
source?: Source | null;
language?: string | null;
page?: number;
page_size?: number;
with_history_prod_version?: boolean | null;
};

export type GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetPathParameters = {
 templateKey: string,
 }
export type DeleteTemplateApiAdminTemplatesIdDeletePathParameters = {
 id: number,
 }
export type UpdateTemplateApiAdminTemplatesIdPatchPathParameters = {
 id: number,
 }
export type GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams = {
source: Source;
language: string;
preprocess?: boolean;
};

export type GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetPathParameters = {
 templateKey: string,
 }
export type GetTemplatesApiAdminTemplatesKeysTemplateKeyGetPathParameters = {
 templateKey: string,
 }
export type GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetPathParameters = {
 templateKey: string,
 }
export type QueryTemplateKeysApiAdminTemplatesKeysGetParams = {
page?: number;
page_size?: number;
label_ids?: number[] | null;
keyword?: string | null;
};

export type DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteParams = {
uuid?: string | null;
};

export type DeleteLabelAdminTemplatesIdLabelsLabelIdDeletePathParameters = {
 id: number | null,
    labelId: number,
 }
export type AddLabelsAdminTemplatesIdLabelsPostParams = {
uuid?: string | null;
};

export type AddLabelsAdminTemplatesIdLabelsPostPathParameters = {
 id: number | null,
 }
export type UpdateAdminTemplatesIdLabelsPatchParams = {
uuid?: string | null;
};

export type UpdateAdminTemplatesIdLabelsPatchPathParameters = {
 id: number | null,
 }
export type ListLabelsAdminTemplatesIdLabelsGetParams = {
uuid?: string | null;
};

export type ListLabelsAdminTemplatesIdLabelsGetPathParameters = {
 id: number | null,
 }
export type GetExecutionContextAdminTraceTypeIdGetPathParameters = {
 type: string,
    id: number,
 }
export type QueryAgentTracesAdminTraceByAgentUuidGetParams = {
page?: number;
page_size?: number;
version?: number | null;
related_types?: string;
};

export type QueryAgentTracesAdminTraceByAgentUuidGetPathParameters = {
 uuid: string,
 }
export type GetEvaluationTaskAdminEvaluationTaskIdGetPathParameters = {
 id: number,
 }
export type CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostPathParameters = {
 messageId: number,
 }
export type ListEvaluationTasksAdminEvaluationPlansIdTasksGetPathParameters = {
 id: number,
 }
export type GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams = {
page?: number;
page_size?: number;
};

export type GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetPathParameters = {
 planType: string,
    uuid: string,
    version: number,
 }
export type StartEvaluationAdminEvaluationPlansIdRunPostPathParameters = {
 id: number,
 }
export type GetEvaluationPlanAdminEvaluationPlansIdGetPathParameters = {
 id: number,
 }
export type ListEvaluationPlansAdminEvaluationPlansGetParams = {
page?: number;
page_size?: number;
plan_type?: string | null;
workflow?: string | null;
workflow_version?: number | null;
};

export type CompleteTemplateAdminEvaluationTemplatesIdCompletionPostPathParameters = {
 id: number,
 }
export type ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetPathParameters = {
 id: number,
 }
export type DeleteTemplateAdminEvaluationTemplatesIdDeletePathParameters = {
 id: number,
 }
export type UpdateTemplateAdminEvaluationTemplatesIdPatchPathParameters = {
 id: number,
 }
export type GetEvaluationTemplateAdminEvaluationTemplatesIdGetPathParameters = {
 id: number,
 }
export type ListEvaluationTemplatesAdminEvaluationTemplatesGetParams = {
page?: number;
page_size?: number;
label_ids?: number[] | null;
keyword?: string | null;
combination?: string;
};

export type DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteParams = {
uuid?: string | null;
};

export type DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeletePathParameters = {
 id: number | null,
    labelId: number,
 }
export type AddLabelsAdminEvaluationTemplatesIdLabelsPostParams = {
uuid?: string | null;
};

export type AddLabelsAdminEvaluationTemplatesIdLabelsPostPathParameters = {
 id: number | null,
 }
export type UpdateAdminEvaluationTemplatesIdLabelsPatchParams = {
uuid?: string | null;
};

export type UpdateAdminEvaluationTemplatesIdLabelsPatchPathParameters = {
 id: number | null,
 }
export type ListLabelsAdminEvaluationTemplatesIdLabelsGetParams = {
uuid?: string | null;
};

export type ListLabelsAdminEvaluationTemplatesIdLabelsGetPathParameters = {
 id: number | null,
 }
export type DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostPathParameters = {
 uuid: string,
    version: number,
 }
export type DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeletePathParameters = {
 uuid: string,
    version: number,
 }
export type GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetPathParameters = {
 uuid: string,
    version: number,
 }
export type GetWorkflowVersionsAdminWorkflowsUuidVersionsGetPathParameters = {
 uuid: string,
 }
export type UpdateWorkflowApiAdminWorkflowsUuidPutPathParameters = {
 uuid: string,
 }
export type DeleteWorkflowApiAdminWorkflowsUuidDeletePathParameters = {
 uuid: string,
 }
export type SaveWorkflowAdminWorkflowsUuidPatchPathParameters = {
 uuid: string,
 }
export type GetWorkflowAdminWorkflowsUuidGetPathParameters = {
 uuid: string,
 }
export type DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteParams = {
id?: number | null;
};

export type DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeletePathParameters = {
 uuid: string | null,
    labelId: number,
 }
export type AddLabelsAdminWorkflowsUuidLabelsPostParams = {
id?: number | null;
};

export type AddLabelsAdminWorkflowsUuidLabelsPostPathParameters = {
 uuid: string | null,
 }
export type UpdateAdminWorkflowsUuidLabelsPatchParams = {
id?: number | null;
};

export type UpdateAdminWorkflowsUuidLabelsPatchPathParameters = {
 uuid: string | null,
 }
export type ListLabelsAdminWorkflowsUuidLabelsGetParams = {
id?: number | null;
};

export type ListLabelsAdminWorkflowsUuidLabelsGetPathParameters = {
 uuid: string | null,
 }
export type GetPromptParametersAdminAgentsUuidPromptParamsGet200 = { [key: string]: unknown };

export type GetPromptParametersAdminAgentsUuidPromptParamsGetPathParameters = {
 uuid: string,
 }
export type DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostPathParameters = {
 uuid: string,
    version: number,
 }
export type GetAgentVersionsAdminAgentsUuidVersionsGetPathParameters = {
 uuid: string,
 }
export type GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetPathParameters = {
 uuid: string,
    version: number,
 }
export type DeleteVersionAdminAgentsUuidVersionsVersionDeletePathParameters = {
 uuid: string,
    version: number,
 }
export type GetVersionedAgentAdminAgentsUuidVersionsVersionGetPathParameters = {
 uuid: string,
    version: number,
 }
export type PatchAgentAdminAgentsUuidVersionsVersionPatchPathParameters = {
 uuid: string,
    version: number,
 }
export type SaveAgentAdminAgentsUuidPutPathParameters = {
 uuid: string,
 }
export type DeleteAgentAdminAgentsUuidDeletePathParameters = {
 uuid: string,
 }
export type GetAgentAdminAgentsUuidGetPathParameters = {
 uuid: string,
 }
export type DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteParams = {
id?: number | null;
};

export type DeleteLabelAdminAgentsUuidLabelsLabelIdDeletePathParameters = {
 uuid: string | null,
    labelId: number,
 }
export type AddLabelsAdminAgentsUuidLabelsPostParams = {
id?: number | null;
};

export type AddLabelsAdminAgentsUuidLabelsPostPathParameters = {
 uuid: string | null,
 }
export type UpdateAdminAgentsUuidLabelsPatchParams = {
id?: number | null;
};

export type UpdateAdminAgentsUuidLabelsPatchPathParameters = {
 uuid: string | null,
 }
export type ListLabelsAdminAgentsUuidLabelsGetParams = {
id?: number | null;
};

export type ListLabelsAdminAgentsUuidLabelsGetPathParameters = {
 uuid: string | null,
 }
export type ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams = {
/**
 * code
 */
source?: Source;
limit?: number;
};

export type DownloadFileExperimentalSandboxFilesFilePathGetParams = {
'user-id'?: number | null;
};

export type DownloadFileExperimentalSandboxFilesFilePathGetPathParameters = {
 filePath: string,
 }
export type GetTokenUserAuthTokenGetParams = {
code: string;
};

export type ListUserAirnoteActivityUserAirnoteActivityGetParams = {
limit?: number;
};

export type ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams = {
limit?: number;
offset?: number;
};

export type ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetPathParameters = {
 tableName: string,
 }
export type UnbindCalendarAccountUserAccountsProviderDeletePathParameters = {
 provider: CalendarProviderName,
 }
export type BindGoogleAccountUserAccountsGoogleGetParams = {
/**
 * code
 */
source?: Source;
/**
 * callback url
 */
callback_url?: string | null;
};

export type JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostPathParameters = {
 shareKey: string,
 }
export type GetSharedEventActionUserCalendarSharedEventsShareKeyGetPathParameters = {
 shareKey: string,
 }
export type PatchUserCalendarEventUserCalendarEventsEventUuidPatchParams = {
/**
 * Recurrence ID
 */
recurrence_id?: string | string | null;
/**
 * Include future events
 */
include_future?: boolean;
};

export type PatchUserCalendarEventUserCalendarEventsEventUuidPatchPathParameters = {
 eventUuid: string,
 }
export type DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteParams = {
/**
 * Recurrence ID
 */
recurrence_id?: string | string | null;
/**
 * Include future events
 */
include_future?: boolean;
};

export type DeleteUserCalendarEventUserCalendarEventsEventUuidDeletePathParameters = {
 eventUuid: string,
 }
export type GetUserCalendarEventUserCalendarEventsEventUuidGetParams = {
/**
 * code
 */
source?: Source;
/**
 * Recurrence ID
 */
recurrence_id?: string | null;
};

export type GetUserCalendarEventUserCalendarEventsEventUuidGetPathParameters = {
 eventUuid: string,
 }
export type ListUserCalendarEventsUserCalendarEventsGetParams = {
from_date: string;
to_date: string;
/**
 * code
 */
source?: Source;
};

export type JoinRecomChannelsFeedV2RecomRidJoinPostPathParameters = {
 rid: string,
 }
export type QueryRecomChannelsFeedV2RecomGetParams = {
page?: number;
page_size?: number;
};

export type DislikeFeedFeedV2RecordRidDislikePostPathParameters = {
 rid: string,
 }
export type CancelFeedReactionFeedV2RecordRidLikeDeletePathParameters = {
 rid: string,
 }
export type LikeFeedFeedV2RecordRidLikePostPathParameters = {
 rid: string,
 }
export type GetFeedLikeFeedV2RecordRidLikeGetPathParameters = {
 rid: string,
 }
export type ListSubscribedChannelFeedsFeedV2FeedsGetParams = {
page?: number;
page_size?: number;
cid?: string;
date?: number;
};

export type DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeletePathParameters = {
 cid: string,
 }
export type UpdateSubscriptionScheduleFeedV2ChannelCidTimePutPathParameters = {
 cid: string,
 }
export type AddSubscriptionScheduleFeedV2ChannelCidTimePostPathParameters = {
 cid: string,
 }
export type SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchPathParameters = {
 cid: string,
 }
export type UnsubChannelFeedV2ChannelCidDeletePathParameters = {
 cid: string,
 }
export type GetSubscribedChannelDetailFeedV2ChannelCidGetPathParameters = {
 cid: string,
 }
export type GiveRecordDislikeFeedRecordUidDislikePostPathParameters = {
 uid: string,
 }
export type CancelRecordDislikeFeedRecordUidLikeDeletePathParameters = {
 uid: string,
 }
export type GiveRecordLikeFeedRecordUidLikePostPathParameters = {
 uid: string,
 }
export type GiveFeedFeedbackFeedUidFeedbackPostPathParameters = {
 uid: string,
 }
export type ListFeedRecordsFeedUidHistoryGetParams = {
page?: number;
page_size?: number;
};

export type ListFeedRecordsFeedUidHistoryGetPathParameters = {
 uid: string,
 }
export type UnsubFeedFeedUidDeletePathParameters = {
 uid: string,
 }
export type UpdateFeedFeedUidPatchPathParameters = {
 uid: string,
 }
export type GetFeedFeedUidGetPathParameters = {
 uid: string,
 }
export type GetCardContentFeedOpenV2FeedRidGetPathParameters = {
 rid: string,
 }
export type GetUserTrafficDataUserTrafficGetParams = {
medium?: string;
source: string;
campaign?: string;
};

export type WebTemplateApiWebI18nTemplateKeyGetParams = {
language?: string;
};

export type WebTemplateApiWebI18nTemplateKeyGetPathParameters = {
 templateKey: string,
 }
export type DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetPathParameters = {
 exportKey: string,
 }
export type GetSharedEventActionGuestCalendarSharedEventsShareKeyGetPathParameters = {
 shareKey: string,
 }
export type OauthCallbackOauthCallbackProviderNameGetParams = {
state: string;
error?: string;
};

export type OauthCallbackOauthCallbackProviderNameGetPathParameters = {
 providerName: OauthProviderName,
 }
export type OauthLoginOauthLoginProviderNameGetParams = {
state: string;
source: string;
};

export type OauthLoginOauthLoginProviderNameGetPathParameters = {
 providerName: OauthProviderName,
 }
export type CallbackTelegramCallbackTokenPostPathParameters = {
 token: string,
 }
export type XfyunRaasrCallbackHookXfyunRaasrGetParams = {
orderId: string;
status: XfYunRaasrStatus;
};

export type WeworkCallbackWechatWeworkCallbackPostParams = {
msg_signature: string;
timestamp: string;
nonce: string;
};

export type WeworkCallbackVerifyWechatWeworkCallbackGetParams = {
msg_signature: string;
timestamp: string;
nonce: string;
echostr: string;
};

export type LoginForAccessTokenByCodeAuthAuthorizationCodePostParams = {
/**
 * code
 */
code?: string;
};

export type LoginForAccessTokenAuthTokenPostParams = {
cookie?: boolean;
};

export type OrionServerPlaygroundOpenaiRequestToolsItem = { [key: string]: unknown };

export type OrionServerPlaygroundOpenaiRequestResponseFormat = { [key: string]: unknown };

export type OrionServerPlaygroundOpenaiRequestMessagesItem = { [key: string]: unknown };

export type OrionServerPlaygroundOpenaiRequestLogitBias = { [key: string]: unknown };

export type OrionServerPlaygroundOpenaiRequestFunctionsItem = { [key: string]: unknown };

export interface OrionServerPlaygroundOpenaiRequest {
  function_call?: OrionServerPlaygroundOpenaiRequestFunctionCall;
  functions?: OrionServerPlaygroundOpenaiRequestFunctionsItem[];
  logit_bias?: OrionServerPlaygroundOpenaiRequestLogitBias;
  logprobs?: boolean;
  max_tokens?: number;
  messages: OrionServerPlaygroundOpenaiRequestMessagesItem[];
  model: string;
  n?: number;
  parallel_tool_calls?: boolean;
  response_format?: OrionServerPlaygroundOpenaiRequestResponseFormat;
  seed?: number;
  stop?: string[];
  stream?: boolean;
  temperature?: number;
  tool_choice?: unknown;
  tools?: OrionServerPlaygroundOpenaiRequestToolsItem[];
  top_logprobs?: number;
}

export type OrionServerPlaygroundOpenaiRequestFunctionCallAnyOf = { [key: string]: unknown };

export type OrionServerPlaygroundOpenaiRequestFunctionCall = OrionServerPlaygroundOpenaiRequestFunctionCallAnyOf | string;

export type OrionDashWorkflowCommonWorkflowInfoPositions = {[key: string]: Position};

export interface OrionDashWorkflowCommonWorkflowInfo {
  author: DashUserInfo;
  classification?: string;
  config: string;
  created_at?: number;
  description?: string;
  name: string;
  note?: string;
  parent_version?: number;
  positions?: OrionDashWorkflowCommonWorkflowInfoPositions;
  status: string;
  updated_at?: number;
  uuid: string;
  version: number;
}

export interface OrionDashFinetuneAppGetExcelPayload {
  eval_batch_ids?: string[];
}

export interface OrionDashChoreAppGetExcelPayload {
  eval_batch_ids: string[];
}

export type OrionBizAppExperimentalOpenaiOpenaiRequestMessagesItem = ChatCompletionSystemMessageParam | ChatCompletionUserMessageParam | ChatCompletionAssistantMessageParam | ChatCompletionToolMessageParam | ChatCompletionFunctionMessageParam;

export interface OrionBizAppExperimentalOpenaiOpenaiRequest {
  messages: OrionBizAppExperimentalOpenaiOpenaiRequestMessagesItem[];
  model: string;
  stream?: boolean;
}

export type OrionAlgLlmCommonLLMOptionLogitBias = {[key: string]: number};

export interface OrionAlgLlmCommonLLMOption {
  input_token_limit?: number;
  logit_bias?: OrionAlgLlmCommonLLMOptionLogitBias;
  n?: number;
  output_token_limit?: number;
  parallel_tool_calls?: boolean;
  stop?: string[];
  stream?: boolean;
  temperature?: number;
}

export type OrionAlgCommonLLMOptionLogitBias = {[key: string]: number};

export interface OrionAlgCommonLLMOption {
  input_token_limit?: number;
  logit_bias?: OrionAlgCommonLLMOptionLogitBias;
  max_token?: number;
  model?: string;
  n?: number;
  parallel_tool_calls?: boolean;
  stop?: string[];
  stream?: boolean;
  temperature?: number;
}

export type OrionAgiCommonWorkflowInfoParams = { [key: string]: unknown };

export type OrionAgiCommonWorkflowInfoMessagesDict = {[key: string]: MessageOutput[]};

export interface OrionAgiCommonWorkflowInfo {
  config?: string;
  messages_dict?: OrionAgiCommonWorkflowInfoMessagesDict;
  params?: OrionAgiCommonWorkflowInfoParams;
  should_extend_result?: boolean;
  start_uuids?: string[];
  status?: string;
  uuid?: string;
  version?: number;
}

export enum XfYunRaasrStatus {
  NUMBER_1= 1,
  NUMBER_MINUS_1= -1,

}
export type WorkflowTraceTotalServicePrice = {[key: string]: number};

export type WorkflowTraceTotalServiceCalls = {[key: string]: number};

export interface WorkflowTrace {
  actions?: Trace[];
  actor_traces?: ActorExecutionTrace[];
  context_info?: ActorContextInfo;
  duration?: number;
  ended_at?: number;
  name?: string;
  related_id?: number;
  result?: string;
  started_at?: number;
  status?: string;
  total_llm_calls?: number;
  total_llm_reactions?: number;
  total_price?: number;
  total_service_calls?: WorkflowTraceTotalServiceCalls;
  total_service_price?: WorkflowTraceTotalServicePrice;
  total_tokens?: number;
  uuid: string;
  version?: number;
  workflow_traces?: Trace[];
}

export interface WorkflowSummary {
  author: DashUserInfo;
  classification?: string;
  created_at?: number;
  description?: string;
  name: string;
  note?: string;
  parent_version?: number;
  status: string;
  updated_at?: number;
  uuid: string;
  version: number;
}

export interface WorkflowFullRequest {
  config: string;
  is_evaluation?: boolean;
}

export type WorkflowFlowDebugRequestStartParams = { [key: string]: unknown };

export type WorkflowFlowDebugRequestMockParams = { [key: string]: unknown };

export interface WorkflowFlowDebugRequest {
  config: string;
  debug_end_actor_uuid?: string;
  is_debug_mode?: boolean;
  is_evaluation?: boolean;
  messages?: MessageInput[];
  mock_calendar?: boolean;
  mock_params?: WorkflowFlowDebugRequestMockParams;
  mock_tools?: boolean;
  run_single?: boolean;
  save_context?: boolean;
  start_params?: WorkflowFlowDebugRequestStartParams;
  start_uuids?: string[];
  user_input: string;
}

export interface WorkflowDeployPayload {
  note: string;
}

export type WorkflowDebugResultDebugData = { [key: string]: unknown };

export interface WorkflowDebugResult {
  context: Context;
  debug_data?: WorkflowDebugResultDebugData;
  related_id?: number;
  related_type?: string;
  result: ActorExecutionResult;
}

export type WorkflowConfigPayloadPositions = {[key: string]: Position};

export interface WorkflowConfigPayload {
  classification?: string;
  config?: string;
  description?: string;
  name?: string;
  need_notify?: boolean;
  positions?: WorkflowConfigPayloadPositions;
}

export interface WorkflowApiResponse {
  context: Context;
  meta: MessageMeta;
  response: ActorExecutionResult;
}

export type WorkflowApiRequestParams = {[key: string]: string};

export type WorkflowApiRequestMessagesDict = {[key: string]: MessageInput[]};

export interface WorkflowApiRequest {
  agent_status_filter?: AgentStatus[];
  messages?: MessageInput[];
  messages_dict?: WorkflowApiRequestMessagesDict;
  params: WorkflowApiRequestParams;
  start_uuids?: string[];
  user_id: number;
  workflow_status_filter?: ActorStatus[];
  workflow_version?: number;
}

export interface WhatsappConversation {
  authentication_expire_time?: string;
  marketing_expire_time?: string;
  service_expire_time?: string;
  utility_expire_time?: string;
}

export interface WeatherFeedV2 {
  channel_name: string;
  channel_type: ChannelType;
  cid: string;
  condition_code: string;
  condition_emoji: string;
  temperature_max: string;
  temperature_min: string;
}

export type VoiceReportVoiceType = string | null;

export type VoiceReportTollType = string | null;

export type VoiceReportStartTime = string | null;

export type VoiceReportRingTime = string | null;

export type VoiceReportOriginateTime = string | null;

export type VoiceReportHangupDirection = string | null;

export type VoiceReportEndTime = string | null;

export type VoiceReportDuration = number | null;

export type VoiceReportBStartTime = string | null;

export type VoiceReportBRingTime = string | null;

export type VoiceReportBOriginateTime = string | null;

export type VoiceReportBEndTime = string | null;

export type VoiceReportBDuration = number | null;

export interface VoiceReport {
  b_duration: VoiceReportBDuration;
  b_end_time: VoiceReportBEndTime;
  b_originate_time: VoiceReportBOriginateTime;
  b_ring_time: VoiceReportBRingTime;
  b_start_time: VoiceReportBStartTime;
  call_id: string;
  callee: string;
  caller: string;
  duration: VoiceReportDuration;
  end_time: VoiceReportEndTime;
  hangup_direction: VoiceReportHangupDirection;
  originate_time: VoiceReportOriginateTime;
  out_id: string;
  ring_time: VoiceReportRingTime;
  start_time: VoiceReportStartTime;
  status_code: string;
  status_msg: string;
  toll_type: VoiceReportTollType;
  voice_type: VoiceReportVoiceType;
}

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

/**
 * An enumeration.
 */
export enum UserType {
  user= 'user',
  group= 'group',
  bot= 'bot',

}
export interface UserTrafficUpdate {
  campaign?: string;
  emoji: string;
  medium?: string;
  source?: string;
}

export interface UserTrafficPayload {
  campaign: string;
  emoji: string;
  medium: string;
  source: string;
}

export interface UserTrafficData {
  campaign: string;
  created_at: number;
  created_by: number;
  emoji: string;
  id: number;
  medium: string;
  source: string;
  updated_at: number;
  uuid: string;
}

export interface UserStatesOutput {
  calendar_caldav_password_reset_count?: number;
  feed_recom_info?: UserFeedRecomInfo[];
  has_created_calendar?: boolean;
  has_sent_calendar_conflict_message?: boolean;
  has_sent_created5event_message?: boolean;
  has_sent_introduction_message?: boolean;
  has_sent_notify_guide?: boolean;
  has_sent_unsupported_message?: boolean;
  has_sent_welcome_message?: boolean;
  inactive_notify_send_at?: number;
  lifecycle_growth_start_date?: string;
  lifecycle_introduction_start_date?: string;
  lifecycle_mature_start_date?: string;
  onboarding_step?: string;
  origin?: UserOriginOutput;
  service_bot_id?: string;
  stats_calendar_active_days?: number;
  stats_create_event_times?: number;
  stats_delete_event_times?: number;
  stats_query_event_times?: number;
  stats_update_event_times?: number;
}

export type UserSettingsWebapp = { [key: string]: unknown };

export type UserSettingsProperties = {[key: string]: string};

export interface UserSettings {
  calendar_conflict_checker_enabled?: boolean;
  calendar_default_alert_minutes?: number;
  calendar_default_duration_minutes?: number;
  clock_format?: ClockFormat;
  daily_notification_enabled?: boolean;
  daily_notification_hour?: number;
  daily_notification_minute?: number;
  daily_weather_notification_enabled?: boolean;
  feed_recom_enabled?: boolean;
  im_notification_enabled?: boolean;
  language?: string;
  monthly_release_notification_enabled?: boolean;
  nickname?: string;
  nickname_enabled?: boolean;
  persona?: string;
  persona_key?: PersonaKey;
  properties?: UserSettingsProperties;
  /** All recall notifications' switch (include lifecycle push) */
  recall_notification_enabled?: boolean;
  starting_workflow?: string;
  time_format?: TimeFormat;
  timezone?: string;
  units?: Units;
  webapp?: UserSettingsWebapp;
  whatsapp_compatibility_mode?: boolean;
}

export interface UserSessionResponse {
  bot_name?: BotName;
  product: Product;
  source: Source;
}

/**
 * An enumeration.
 */
export enum UserRole {
  user= 'user',
  admin= 'admin',
  tester= 'tester',
  group_member= 'group_member',
  group_admin= 'group_admin',

}
export interface UserPatchRequest {
  password?: string;
  username?: string;
}

export interface UserOriginInput {
  bot_id?: number;
  invitation?: UserInvitationInfo;
  source?: Source;
  type?: SessionType;
  utm?: number;
}

export interface UserStatesInput {
  calendar_caldav_password_reset_count?: number;
  feed_recom_info?: UserFeedRecomInfo[];
  has_created_calendar?: boolean;
  has_sent_calendar_conflict_message?: boolean;
  has_sent_created5event_message?: boolean;
  has_sent_introduction_message?: boolean;
  has_sent_notify_guide?: boolean;
  has_sent_unsupported_message?: boolean;
  has_sent_welcome_message?: boolean;
  inactive_notify_send_at?: number;
  lifecycle_growth_start_date?: string;
  lifecycle_introduction_start_date?: string;
  lifecycle_mature_start_date?: string;
  onboarding_step?: string;
  origin?: UserOriginInput;
  service_bot_id?: string;
  stats_calendar_active_days?: number;
  stats_create_event_times?: number;
  stats_delete_event_times?: number;
  stats_query_event_times?: number;
  stats_update_event_times?: number;
}

export interface UserNoteRequest {
  content: string;
}

export interface UserNoteData {
  content: string;
  created_at: number;
  id: number;
  updated_at: number;
  user_id: number;
}

/**
 * An enumeration.
 */
export enum UserInviteMethod {
  event_sharing= 'event_sharing',

}
export interface UserInvitationInfo {
  invited_by: number;
  invited_method: UserInviteMethod;
}

export interface UserOriginOutput {
  bot_id?: number;
  invitation?: UserInvitationInfo;
  source?: Source;
  type?: SessionType;
  utm?: number;
}

export type UserInfoResponsePersonaKeysItem = {[key: string]: string};

export interface UserInfoResponse {
  accounts?: CalendarAccountInfo[];
  calendars: UserCalendarsInfoOutput;
  created_at: number;
  current_account: CalendarProviderName;
  feature_sets?: string[];
  has_created_calendar?: boolean;
  persona_keys?: UserInfoResponsePersonaKeysItem[];
  roles: UserRole[];
  self_hosted_password_has_been_reset?: boolean;
  session_type: SessionType;
  sessions: UserSessionResponse[];
  settings: PublicUserSettings;
  source: Source;
  sources: Source[];
}

export interface UserInfoOutput {
  avatar_url?: string;
  caldav_username?: string;
  calendar_id?: string;
  calendar_provider?: CalendarProviderName;
  /** User's third-party calendars, include self-hosted */
  calendars?: UserCalendarsInfoOutput;
  created_at?: number;
  email?: string;
  id: number;
  on_waitlist_at?: number;
  phone?: string;
  plan?: number;
  roles?: UserRole[];
  settings?: UserSettings;
  states?: UserStatesOutput;
  tags?: string[];
  type?: UserType;
  /** The nickname / username of the user, whichever is available */
  username?: string;
}

export interface UserInfoInput {
  avatar_url?: string;
  caldav_username?: string;
  calendar_id?: string;
  calendar_provider?: CalendarProviderName;
  /** User's third-party calendars, include self-hosted */
  calendars?: UserCalendarsInfoInput;
  created_at?: number;
  email?: string;
  id: number;
  on_waitlist_at?: number;
  phone?: string;
  plan?: number;
  roles?: UserRole[];
  settings?: UserSettings;
  states?: UserStatesInput;
  tags?: string[];
  type?: UserType;
  /** The nickname / username of the user, whichever is available */
  username?: string;
}

export interface UserFeedRecomInfo {
  last_recom_time: string;
  recom_count: number;
  scene: string;
}

export type UserEventInfoDetails = { [key: string]: unknown };

export interface UserEventInfo {
  action: string;
  created_at: number;
  details: UserEventInfoDetails;
}

export interface UserDeactivateReasonResponse {
  ok?: boolean;
}

export interface UserDeactivateReasonRequest {
  code?: string;
  reason?: string;
  t: string;
}

export interface UserCalendarsItem {
  id: string;
  name: string;
}

export interface UserCalendarsAccount {
  enabled_cals?: UserCalendarsItem[];
  id: string;
  /** Frendly name of the calendar, like google email, show in UI */
  name: string;
  provider: CalendarProviderName;
  /** The last time the calendar names were synchronized. */
  synced_at?: number;
}

export interface UserCalendarsInfoOutput {
  accounts?: UserCalendarsAccount[];
  default_calendar?: CalendarItemWithAccountOutput;
}

export interface UserCalendarsInfoInput {
  accounts?: UserCalendarsAccount[];
  default_calendar?: CalendarItemWithAccountInput;
}

export interface UserCalendarDataResponse {
  events: string[];
}

export type UpdateWorkflowConfigPayloadPositions = {[key: string]: Position};

export type UpdateWorkflowConfigPayloadConfig = { [key: string]: unknown };

export interface UpdateWorkflowConfigPayload {
  classification: string;
  config: UpdateWorkflowConfigPayloadConfig;
  description: string;
  name: string;
  need_notify?: boolean;
  note: string;
  positions?: UpdateWorkflowConfigPayloadPositions;
  version: number;
}

export interface UpdateUserRequest {
  roles?: UserRole[];
  tags?: string[];
}

export interface UpdateFeedResponse {
  ok?: boolean;
}

export interface UpdateFeedRequest {
  notify_enabled?: boolean;
  notify_hour?: number;
  notify_minute?: number;
  notify_period?: string;
}

export interface UnsubFeedResponse {
  ok?: boolean;
}

/**
 * An enumeration.
 */
export enum Units {
  metric= 'metric',
  imperial= 'imperial',

}
export interface TranslateTemplatePayload {
  extra_prompt?: string;
  from_language?: string;
  template_keys: string[];
  to_languages_code: string[];
}

export type TraceContent = { [key: string]: unknown };

export interface Trace {
  content: TraceContent;
  content_type: string;
  recorded_at?: number;
  title: string;
}

export interface ToolResult {
  exception?: string;
  exception_name?: string;
  hidden_result?: string;
  is_ask_human?: boolean;
  related_objects?: RelatedObject[];
  result: string;
  rewrite_action_arguments?: unknown;
  success?: boolean;
}

export interface ToolProcess {
  hidden_result?: string;
  result: string;
  tool_call: ToolCall;
}

export interface ToolExecutorOptionOutput {
  is_parallel?: boolean;
  merge_the_same_argument_config?: MergeArgumentKeyConfig[];
  merge_the_same_argument_name?: string;
  objective_must_all_tools_succeed?: boolean;
  parallel_number?: number;
}

export interface ToolExecutorOptionInput {
  is_parallel?: boolean;
  merge_the_same_argument_config?: MergeArgumentKeyConfig[];
  merge_the_same_argument_name?: string;
  objective_must_all_tools_succeed?: boolean;
  parallel_number?: number;
}

export type ToolConfigPydanticJsonSchema = { [key: string]: unknown };

export type ToolConfigParameters = {[key: string]: string};

export type ToolConfigInputJsonSchema = { [key: string]: unknown };

export interface ToolConfig {
  aliases?: string[];
  description?: string;
  id: string;
  input_json_schema?: ToolConfigInputJsonSchema;
  is_objective?: boolean;
  must_succeed?: boolean;
  name?: string;
  parameters?: ToolConfigParameters;
  pydantic_json_schema?: ToolConfigPydanticJsonSchema;
}

export interface ToolCall {
  function: FunctionCallOutput;
  id: string;
  type: string;
}

export interface Token {
  access_token: string;
  token_type: string;
}

export interface TodayWeatherFeedsResponse {
  weather_list?: WeatherFeedV2[];
}

export interface TodayNewsFeedsResponse {
  fresh?: FeedCard[];
  stale?: FeedCard[];
}

/**
 * An enumeration.
 */
export enum TimeFormat {
  ''= '',
  '%I:%M_%p'= '%I:%M %p',
  '%H:%M'= '%H:%M',
  '%-I:%M_%p'= '%-I:%M %p',
  '%-H:%M'= '%-H:%M',

}
/**
 * An enumeration.
 */
export enum TemplateType {
  external= 'external',
  internal= 'internal',

}
export type TemplateToolConfigPydanticJsonSchema = { [key: string]: unknown };

export type TemplateToolConfigParameters = {[key: string]: string};

export type TemplateToolConfigInputJsonSchema = { [key: string]: unknown };

export interface TemplateToolConfig {
  aliases?: string[];
  description?: string;
  id: string;
  input_json_schema?: TemplateToolConfigInputJsonSchema;
  is_objective?: boolean;
  must_succeed?: boolean;
  name?: string;
  parameters?: TemplateToolConfigParameters;
  pydantic_json_schema?: TemplateToolConfigPydanticJsonSchema;
  template_input_json_schema?: string;
  template_pydantic_json_schema?: string;
}

export interface TemplatePayload {
  description?: string;
  key?: string;
  language?: string;
  name?: string;
  source?: string;
  template?: string;
  type?: TemplateType;
}

export interface TemplateMeta {
  key: string;
  labels?: DashLabel[];
  meta_id?: number;
  type?: TemplateType;
}

export interface TemplateKeyDetail {
  data?: TemplateDetail[];
  meta: TemplateMeta;
}

export interface TemplateInfo {
  detail: unknown[][];
  key: string;
  labels?: DashLabel[];
  meta_id?: number;
  type?: TemplateType;
}

export interface TemplateHistory {
  author?: DashUserInfo;
  created_at?: number;
  description: string;
  history_type: string;
  id: number;
  key: string;
  language: string;
  parent_version?: number;
  source: string;
  template: string;
  updated_at?: number;
}

export interface TemplateEvaluationResult {
  case_id: number;
  result: string;
  status: EvaluationStatus;
}

export interface Template {
  author?: DashUserInfo;
  created_at?: number;
  description: string;
  id: number;
  key: string;
  language: string;
  parent_version?: number;
  source: string;
  template: string;
  updated_at?: number;
}

export interface TemplateDetail {
  language: string;
  latest_draft_template?: Template;
  prod_template?: Template;
  source: string;
}

export interface SwitchSubscribedChannelRequest {
  enabled: boolean;
}

export interface SubscriptionScheduleRequest {
  hour: number;
  minute: number;
}

export interface SubscribedChannel {
  channel_name: string;
  channel_type: ChannelType;
  cid: string;
  notify_enabled: boolean;
  notify_times: ChannelNotifyTime[];
}

export interface SubscribeNewChannelResponse {
  err_code?: AddErrCode;
  finance?: SubscribedChannel;
  news?: SubscribedChannel;
  suggestions?: string[];
  weather?: SubscribedChannel;
}

export interface SubscribeNewChannelRequest {
  target: string;
  type: ChannelTypeS;
}

/**
 * An enumeration.
 */
export enum Source {
  unknown= 'unknown',
  wechat= 'wechat',
  line= 'line',
  admin_web= 'admin_web',
  api= 'api',
  telegram= 'telegram',
  whatsapp= 'whatsapp',
  imessage= 'imessage',
  web= 'web',
  app= 'app',
  chat= 'chat',
  push= 'push',

}
export interface SharedEventCreateRequest {
  event_uid: string;
}

export interface SharedEventAttendeeInfo {
  avatar_url?: string;
  is_me?: boolean;
  language?: string;
  name: string;
  source?: Source;
  timezone?: string;
  type: UserType;
}

export interface SharedEventInfo {
  attendees?: SharedEventAttendeeInfo[];
  organizer: SharedEventAttendeeInfo;
  share_key: string;
}

export interface SessionsDateRangeStats {
  date_range_end: string;
  date_range_start: string;
  messages_stats_by_day: MessageStatsByDay[];
}

/**
 * An enumeration.
 */
export enum SessionType {
  NUMBER_0= 0,
  NUMBER_1= 1,

}
export interface SessionMeta {
  whatsapp_conversation?: WhatsappConversation;
}

export interface SessionOutput {
  bot_info?: BotInfoOutput;
  id: string;
  last_replied_at?: number;
  last_sent_at?: number;
  meta?: SessionMeta;
  owner_info?: UserInfoOutput;
  ref_id?: number;
  source?: Source;
  type: SessionType;
}

export interface SessionInput {
  bot_info?: BotInfoInput;
  id: string;
  last_replied_at?: number;
  last_sent_at?: number;
  meta?: SessionMeta;
  owner_info?: UserInfoInput;
  ref_id?: number;
  source?: Source;
  type: SessionType;
}

export interface SelfHostedAccountInfo {
  password: string;
  username: string;
}

export interface SelfHostedAccountExportResponse {
  expires_at: number;
  url: string;
}

export type SaveAgentConfigPayloadModelsItem = AgentModelConfigInput | AgentModelConfigInput[];

export interface SaveAgentConfigPayload {
  author?: DashUserInfo;
  classification?: string;
  description: string;
  fallback_option?: FallbackExecutorOption;
  id?: number;
  models?: SaveAgentConfigPayloadModelsItem[];
  name: string;
  note?: string;
  objective_must_succeed?: boolean;
  objective_tool?: string;
  option?: ExecutorOption;
  parent_version?: number;
  parser?: Parser;
  pick_type?: string;
  prompt_option?: PromptOption;
  status?: string;
  system_prompt?: string;
  tags?: string[];
  template_tools?: TemplateToolConfig[];
  tool_option?: ToolExecutorOptionInput;
  tools?: ToolConfig[];
  updated_at?: number;
  user_prompt?: string;
  uuid: string;
  version?: number;
}

export type RuleMatcherGoalAnyOf = { [key: string]: unknown };

export type RuleMatcherGoal = number | string | boolean | number | unknown[] | RuleMatcherGoalAnyOf;

export interface RuleMatcher {
  expression: string;
  goal: RuleMatcherGoal;
  goal_formatter?: FormatterMappingConfig;
  length_judgment?: boolean;
  source: FormatterMappingConfig;
}

export interface RuleConfig {
  actor_uuid?: string;
  executed_as_success?: boolean;
  matcher?: RuleMatcher;
}

/**
 * An enumeration.
 */
export enum Role {
  user= 'user',
  assistant= 'assistant',
  system= 'system',
  function= 'function',
  tool= 'tool',
  image= 'image',

}
export type ResponseMessageMeta = { [key: string]: unknown };

export interface ResponseMessage {
  author?: UserInfoOutput;
  content_type?: ContentType;
  created_at?: number;
  id?: string;
  is_done?: boolean;
  message_parts?: MessagePart[];
  meta?: ResponseMessageMeta;
  need_created?: boolean;
  role: Role;
  set_as_done?: boolean;
  source_id?: string;
  text: string;
}

/**
 * An enumeration.
 */
export enum RepeatOption {
  BY_DAY= 'BY_DAY',
  BY_WEEK= 'BY_WEEK',
  BY_MONTH= 'BY_MONTH',
  BY_YEAR= 'BY_YEAR',
  BY_WORKDAY= 'BY_WORKDAY',
  BY_WEEKEND= 'BY_WEEKEND',

}
/**
 * An enumeration.
 */
export enum RelativeOffsetUnit {
  minute= 'minute',
  hour= 'hour',
  day= 'day',

}
export interface RelatedObject {
  data?: string;
  id: string;
  index?: number;
  is_transient?: boolean;
  type: string;
}

export interface Ref {
  title?: string;
  url: string;
}

/**
 * The end date of the recurrence end, or null if the recurrence end is count-based.
 */
export type RecurrenceEndEndDate = string | string;

export interface RecurrenceEnd {
  /** The end date of the recurrence end, or null if the recurrence end is count-based. */
  end_date?: RecurrenceEndEndDate;
  /** The occurrence count of the recurrence end, or 0 if the recurrence end is date-based. */
  occurrence_count?: number;
}

export interface RecurrenceRulesOutput {
  /** The days of the month associated with the recurrence rule, values can be from 1 to 31 and from -1 to -31. Negative values indicate counting backwards from the end of the month. */
  days_of_the_month?: number[];
  /** The days of the nth week associated with the recurrence rule. */
  days_of_the_nth_week?: NthWeekday[];
  /** The days of the week associated with the recurrence rule. */
  days_of_the_week?: DaysEnum[];
  /** The frequency of the recurrence rule. */
  frequency?: FrequencyEnum;
  /** A positive integer representing at which intervals the recurrence rule repeats.For example, within a DAILY rule, a value of 8 means every eight days. */
  interval?: number;
  /** The months of the year associated with the recurrence rule. */
  months_of_the_year?: number[];
  /** For remove recurrence rules ONLY, not for output */
  none?: boolean;
  /** Defines the end of a recurrence rule */
  recurrence_end?: RecurrenceEnd;
  /** For initialize from rrule string ONLY, not for output */
  rrule?: string;
}

export interface RecurrenceRulesInput {
  /** The days of the month associated with the recurrence rule, values can be from 1 to 31 and from -1 to -31. Negative values indicate counting backwards from the end of the month. */
  days_of_the_month?: number[];
  /** The days of the nth week associated with the recurrence rule. */
  days_of_the_nth_week?: NthWeekday[];
  /** The days of the week associated with the recurrence rule. */
  days_of_the_week?: DaysEnum[];
  /** The frequency of the recurrence rule. */
  frequency?: FrequencyEnum;
  /** A positive integer representing at which intervals the recurrence rule repeats.For example, within a DAILY rule, a value of 8 means every eight days. */
  interval?: number;
  /** The months of the year associated with the recurrence rule. */
  months_of_the_year?: number[];
  /** For remove recurrence rules ONLY, not for output */
  none?: boolean;
  /** Defines the end of a recurrence rule */
  recurrence_end?: RecurrenceEnd;
  /** For initialize from rrule string ONLY, not for output */
  rrule?: string;
}

export interface RecoverMeta {
  need_recover?: boolean;
  origin_agent?: AgentExecutionConfig;
  origin_workflow?: OrionAgiCommonWorkflowInfo;
  recover_count?: number;
}

export interface RecoverInfo {
  recover_count?: number;
}

export interface RecomChannelFeed {
  channel_name: string;
  channel_type: ChannelType;
  cid: string;
  heading: string;
  imgs?: string[];
  sid: string;
  snippet: string;
  subscribed?: boolean;
}

export interface RecomNewsFeedResponse {
  feeds?: RecomChannelFeed[];
}

/**
 * An enumeration.
 */
export enum RandomMethod {
  string= 'string',
  number= 'number',

}
export interface PublicUserSettings {
  calendar_conflict_checker_enabled?: boolean;
  calendar_default_alert_minutes?: number;
  calendar_default_duration_minutes?: number;
  clock_format?: ClockFormat;
  daily_notification_enabled?: boolean;
  daily_notification_hour?: number;
  daily_notification_minute?: number;
  daily_weather_notification_enabled?: boolean;
  feed_recom_enabled?: boolean;
  im_notification_enabled?: boolean;
  language?: string;
  monthly_release_notification_enabled?: boolean;
  nickname?: string;
  nickname_enabled?: boolean;
  /** All recall notifications' switch (include lifecycle push) */
  recall_notification_enabled?: boolean;
  time_format?: TimeFormat;
  timezone?: string;
  units?: Units;
  whatsapp_compatibility_mode?: boolean;
}

export interface PromptOption {
  auto_inject_hidden_msg?: boolean;
  auto_inject_success_flag_in_reaction?: boolean;
  auto_inject_tool_calls_tool_msg?: boolean;
  disabled_function_call?: boolean;
  drop_recover_message?: boolean;
  expand_hidden_messages?: boolean;
  expand_private_hidden_messages_only?: boolean;
  inject_history_messages?: boolean;
  inject_tool_calls_author_name?: boolean;
  inject_tool_calls_content?: boolean;
  is_private_hidden_msg?: boolean;
  max_historic_messages?: number;
  max_historic_time?: number;
  msg_preprocess_strategies?: HistoricMessageProcessStrategy[];
  must_keep_user_prompt?: boolean;
  render_user_input_in_recover?: boolean;
  system_prompt_position?: AgentSystemPromptPosition;
  use_context_history_config?: boolean;
}

/**
 * An enumeration.
 */
export enum Product {
  dola= 'dola',
  airnotes= 'airnotes',

}
export interface Position {
  x?: number;
  y?: number;
}

export interface PlaygroundShareRequest {
  data?: string;
  description?: string;
  title: string;
}

export interface PlaygroundShareInfo {
  data: string;
  description?: string;
  title: string;
  user_info?: DashUserInfo;
  uuid: string;
}

export type PlaygroundRunToolRequestParameters = {[key: string]: string};

export type PlaygroundRunToolRequestInputJsonSchema = { [key: string]: unknown };

export type PlaygroundRunToolRequestAction = { [key: string]: unknown };

export interface PlaygroundRunToolRequest {
  action?: PlaygroundRunToolRequestAction;
  input_json_schema?: PlaygroundRunToolRequestInputJsonSchema;
  mock_tools?: boolean;
  name: string;
  parameters?: PlaygroundRunToolRequestParameters;
}

export type PlaygroundRunRequestParameters = { [key: string]: unknown };

export interface PlaygroundRunRequest {
  openai: OrionServerPlaygroundOpenaiRequest;
  parameters?: PlaygroundRunRequestParameters;
  timeout?: number;
}

/**
 * An enumeration.
 */
export enum PersonaKey {
  PERSONA_DEFAULT= 'PERSONA_DEFAULT',
  PERSONA_CUTE_GIRL= 'PERSONA_CUTE_GIRL',
  PERSONA_PERFECT_SISTER= 'PERSONA_PERFECT_SISTER',
  PERSONA_BEST_BUTLER= 'PERSONA_BEST_BUTLER',
  PERSONA_SASSY_LASSY_GAL= 'PERSONA_SASSY_LASSY_GAL',
  PERSONA_MAFIA_BOSS= 'PERSONA_MAFIA_BOSS',

}
export interface Payload {
  apiVersion: string;
  createTime: string;
  id: string;
  type: string;
}

export type PatchEventRequestStartAt = string | string;

export type PatchEventRequestEndAt = string | string;

export interface PatchEventRequest {
  alarm_via_phone?: boolean;
  alarms?: Alarm[];
  emoji?: string;
  end_at?: PatchEventRequestEndAt;
  location?: string;
  notes?: string;
  /** Only works when patch entire event or include_future=True, when patch single instance will ignore this field */
  recurrence_rules?: RecurrenceRulesInput;
  start_at?: PatchEventRequestStartAt;
  title?: string;
}

export type PatchEvalTemplateParams = { [key: string]: unknown };

export interface PatchEvalTemplate {
  description?: string;
  judger?: string;
  label_ids?: number[];
  name?: string;
  params?: PatchEvalTemplateParams;
  recent_messages?: MessageInput[];
  related_annotation_ids?: number[];
  slugs?: string[];
  user_input?: string;
}

export interface ParserCaseRequest {
  name?: string;
  text: string;
}

/**
 * An enumeration.
 */
export enum Parser {
  text= 'text',
  json= 'json',
  plain_json= 'plain_json',
  force_plain_json= 'force_plain_json',
  json_without_thought= 'json_without_thought',
  single_layer_json= 'single_layer_json',
  verification_json_with_funcs= 'verification_json_with_funcs',
  verification_json_with_funcs_auto= 'verification_json_with_funcs_auto',
  function_call= 'function_call',
  human_function_call= 'human_function_call',
  forced_function_call= 'forced_function_call',
  function_call_without_thought= 'function_call_without_thought',
  tool_calls= 'tool_calls',
  single_tool_calls= 'single_tool_calls',
  single_human_valid_tool_calls= 'single_human_valid_tool_calls',
  single_human_tool_calls= 'single_human_tool_calls',
  human_or_first_tool_call= 'human_or_first_tool_call',
  force_tool_calls= 'force_tool_calls',
  human_tool_calls= 'human_tool_calls',
  json_human_tool_calls= 'json_human_tool_calls',
  human_python_tool_calls= 'human_python_tool_calls',
  tool_calls_or_json_response= 'tool_calls_or_json_response',
  tool_calls_must_pick_one= 'tool_calls_must_pick_one',
  required_tool_calls= 'required_tool_calls',
  json_schema_response_format_call= 'json_schema_response_format_call',
  json_schema_response_format= 'json_schema_response_format',

}
export interface OperationResponse {
  ok?: boolean;
}

export enum OauthProviderName {
  google= 'google',
  microsoft= 'microsoft',
  apple= 'apple',
  unknown= 'unknown',

}
export interface NthWeekday {
  nth: number;
  weekday: DaysEnum;
}

export interface NotifyTime {
  hour: number;
  minute: number;
  period: string;
}

export interface NextWorkflowConfig {
  config?: string;
  params_formatter?: FormatterConfig;
  should_extend_result?: boolean;
  start_uuids?: string[];
  status?: string;
  uuid?: string;
  version?: number;
}

export type NewWorkflowConfigPositions = {[key: string]: Position};

export type NewWorkflowConfigConfig = { [key: string]: unknown };

export interface NewWorkflowConfig {
  classification?: string;
  config?: NewWorkflowConfigConfig;
  description?: string;
  name: string;
  positions?: NewWorkflowConfigPositions;
  uuid?: string;
}

export type NewEvalTemplateOutputParams = { [key: string]: unknown };

export interface NewEvalTemplateOutput {
  ai_output_message?: MessageOutput;
  description?: string;
  judger?: string;
  label_ids?: number[];
  name: string;
  params?: NewEvalTemplateOutputParams;
  recent_messages?: MessageOutput[];
  related_annotation_ids?: number[];
  slugs?: string[];
  user_input: string;
  user_input_message?: MessageOutput;
}

export type NewEvalTemplateInputParams = { [key: string]: unknown };

export interface NewEvalTemplateInput {
  ai_output_message?: MessageInput;
  description?: string;
  judger?: string;
  label_ids?: number[];
  name: string;
  params?: NewEvalTemplateInputParams;
  recent_messages?: MessageInput[];
  related_annotation_ids?: number[];
  slugs?: string[];
  user_input: string;
  user_input_message?: MessageInput;
}

export type NewAgentConfigModelsItem = AgentModelConfigInput | AgentModelConfigInput[];

export interface NewAgentConfig {
  author?: DashUserInfo;
  classification?: string;
  description: string;
  fallback_option?: FallbackExecutorOption;
  id?: number;
  models?: NewAgentConfigModelsItem[];
  name: string;
  note?: string;
  objective_must_succeed?: boolean;
  objective_tool?: string;
  option?: ExecutorOption;
  parent_version?: number;
  parser?: Parser;
  pick_type?: string;
  prompt_option?: PromptOption;
  status?: string;
  system_prompt?: string;
  tags?: string[];
  template_tools?: TemplateToolConfig[];
  tool_option?: ToolExecutorOptionInput;
  tools?: ToolConfig[];
  updated_at?: number;
  user_prompt?: string;
  uuid?: string;
  version?: number;
}

/**
 * An enumeration.
 */
export enum MsgType {
  NUMBER_0= 0,
  NUMBER_1= 1,
  NUMBER_2= 2,
  NUMBER_3= 3,
  NUMBER_4= 4,
  NUMBER_5= 5,
  NUMBER_6= 6,
  NUMBER_7= 7,
  NUMBER_8= 8,
  NUMBER_9= 9,
  NUMBER_10= 10,
  NUMBER_11= 11,
  NUMBER_12= 12,
  NUMBER_13= 13,
  NUMBER_9999= 9999,
  NUMBER_10000= 10000,
  NUMBER_10001= 10001,

}
export type ModelInfoResponseTokenLimit = {[key: string]: number};

export type ModelInfoResponsePricings = {[key: string]: {[key: string]: number}};

export interface ModelDetail {
  alias?: string;
  completion_tokens?: number;
  input_token_limit?: number;
  is_finetune: boolean;
  is_support_function_call: boolean;
  is_support_tools_call: boolean;
  is_support_vision: boolean;
  name: string;
  output_token_limit?: number;
  prompt_tokens?: number;
  provider: string;
  token_limit?: number;
}

export interface ModelInfoResponse {
  model_details: ModelDetail[];
  models: string[];
  pricings: ModelInfoResponsePricings;
  token_limit: ModelInfoResponseTokenLimit;
}

export interface MockDataResponse {
  events: GetEventResponse[];
}

export interface MixInLabelPayload {
  label_ids: number[];
}

export interface MessageStatsByDay {
  day: string;
  message_count: number;
}

/**
 * An enumeration.
 */
export enum MessageSource {
  NUMBER_0= 0,
  NUMBER_1= 1,
  NUMBER_2= 2,
  NUMBER_3= 3,
  NUMBER_4= 4,
  NUMBER_5= 5,
  NUMBER_6= 6,
  NUMBER_7= 7,

}
export interface MessageResponse {
  context: Context;
  message: MessageOutput;
  response: AgentExecutionResult;
}

export interface MessageRequest {
  /** 消息体 */
  data: MessageData;
}

export interface MessagePart {
  base64?: string;
  mime_type?: string;
  source_uri?: string;
  text?: string;
  type?: ContentType;
  url?: string;
}

export type MessageMetaServicePrice = {[key: string]: number};

export type MessageMetaServiceCalls = {[key: string]: number};

export interface MessageMeta {
  ai_content?: string;
  author_name?: string;
  channel_currency?: string;
  channel_price?: number;
  delivered_at?: number;
  failed_reason?: string;
  full_content?: string;
  hidden_messages?: MessageOutput[];
  outer_template_name?: string;
  quoted_button_source_id?: string;
  reaction?: string;
  read_at?: number;
  recover_meta?: RecoverMeta;
  related_objects?: RelatedObject[];
  response_to_message?: string;
  sender_id?: number;
  sent_at?: number;
  service_calls?: MessageMetaServiceCalls;
  service_price?: MessageMetaServicePrice;
  status?: CommonMessageStatus;
  total_llm_calls?: number;
  total_price?: number;
  total_service_price?: number;
  total_tokens?: number;
  truncated?: boolean;
}

/**
 * 消息具体内容（详情见下方）
 */
export type MessageDataPayload = { [key: string]: unknown };

export interface MessageData {
  /** 客户或者群头像 */
  avatar: string;
  /** bot的账号id 详见botId */
  botId: string;
  /** bot的企业微信userId 详见wxUserId */
  botWeixin: string;
  /** bot的wxid 详见imBotId */
  botWxid: string;
  /** 聊天id（表示一个bot和一个客户） */
  chatId: string;
  /** 客户wxid 详见contactWxid */
  contactId: string;
  /** 客户名 */
  contactName: string;
  /** 客户的类型（详情见下方contactType） */
  contactType: ContactType;
  /** 是否为内部员工 */
  coworker: boolean;
  /** 消息是否是托管账号自己发送的消息 */
  isSelf: boolean;
  /** 此条消息是否 @ 了托管账号 */
  mentionSelf?: boolean;
  /** 消息id */
  messageId?: string;
  /** 消息具体内容（详情见下方） */
  payload: MessageDataPayload;
  /** 群wxid 详见roomWxid */
  roomId?: string;
  /** 群名 */
  roomTopic?: string;
  /** 消息发送者的秒回id，仅当消息为消息为托管账号发送的，且消息来源为小组级控制台手动发消息时有效 */
  sendBy?: string;
  /** 消息来源，具体来源信息参考下面的枚举定义 */
  source?: MessageSource;
  /** 消息的时间（ms） */
  timestamp: number;
  /** 秒回token */
  token: string;
  /** 消息类型（详情见下方msgType） */
  type: MsgType;
}

export type MessageOutputMeta = { [key: string]: unknown };

export interface MessageOutput {
  author?: UserInfoOutput;
  content_type?: ContentType;
  created_at?: number;
  id?: string;
  message_parts?: MessagePart[];
  meta?: MessageOutputMeta;
  role: Role;
  source_id?: string;
  text: string;
}

export type MessageInputMeta = { [key: string]: unknown };

export interface MessageInput {
  author?: UserInfoInput;
  content_type?: ContentType;
  created_at?: number;
  id?: string;
  message_parts?: MessagePart[];
  meta?: MessageInputMeta;
  role: Role;
  source_id?: string;
  text: string;
}

export interface MergeArgumentKeyConfig {
  converter?: ConverterConfig;
  key: string;
  pattern?: string;
  required?: boolean;
}

export interface LoginWithIDTokenResponse {
  access_token: string;
}

export interface LoginWithIDTokenRequest {
  id_token: string;
}

export interface ListUserCalendarEventsResponseEvent {
  alarm_via_phone?: boolean;
  alarms?: Alarm[];
  calendar_id?: string;
  calendar_name?: string;
  emoji?: string;
  end_at: string;
  ical_data?: string;
  is_all_day: boolean;
  location?: string;
  notes?: string;
  recurrence_id?: string;
  recurrence_rule?: RecurrenceRulesOutput;
  recurrence_rules_text?: string;
  reminders_text?: string;
  share?: SharedEventInfo;
  start_at: string;
  title: string;
  uuid: string;
}

export interface ListUserCalendarEventsResponse {
  events: ListUserCalendarEventsResponseEvent[];
}

export interface ListSubscribedFeedsResponse {
  count: number;
  feeds?: FeedCard[];
}

export interface ListSubscribedChannelResponse {
  news_chans?: BaseChannel[];
  ticker_chans?: BaseChannel[];
  weather_chans?: BaseChannel[];
}

export interface ListFeedResponse {
  news_feeds?: DailyNewsFeed[];
  weather_feeds?: DailyWeatherFeed[];
}

export interface ListFeedRecordResponse {
  count: number;
  records?: FeedRecord[];
}

export interface ListAirNoteTableRowsResponse {
  rows: unknown[][];
  table: AirNoteTable;
}

/**
 * An enumeration.
 */
export enum LabelProperties {
  unique= 'unique',

}
export interface LLMRequestOption {
  connect_timeout?: number;
  max_retry_count?: number;
  request_timeout?: number;
  retry_if_rate_limit?: boolean;
  retry_max_time?: number;
  retry_min_time?: number;
  timeout_multiplier?: number;
  write_timeout?: number;
}

/**
 * An enumeration.
 */
export enum JudgerType {
  auto= 'auto',
  regular= 'regular',
  workflow= 'workflow',
  template= 'template',

}
export interface JudgeDiff {
  destination?: string;
  origin?: string;
  result?: boolean;
  similarity?: number;
}

export interface JudgerTrace {
  diff?: JudgeDiff[];
}

export interface JoinRecomChannelResponse {
  ok?: boolean;
}

export interface InitDataValidationRequest {
  bot_name?: string;
  init_data: string;
}

export enum ImageURLDetail {
  auto= 'auto',
  low= 'low',
  high= 'high',

}
export interface ImageURL {
  detail?: ImageURLDetail;
  url?: string;
}

export interface IcloudAccountInfo {
  password: string;
  username: string;
}

export type HyperparametersNEpochs = 'auto' | number;

export interface Hyperparameters {
  n_epochs: HyperparametersNEpochs;
  [key: string]: unknown;
 }

export interface HistoryConfig {
  max_messages?: number;
  max_time?: number;
}

/**
 * An enumeration.
 */
export enum HistoricMessageProcessStrategy {
  combine_recent_user_msgs= 'combine_recent_user_msgs',
  combine_recent_user_msgs_to_msg_parts= 'combine_recent_user_msgs_to_msg_parts',
  discard_redundant_user_msgs= 'discard_redundant_user_msgs',
  render_message_timestamp= 'render_message_timestamp',
  render_all_message_timestamp= 'render_all_message_timestamp',
  render_message_name= 'render_message_name',
  remove_first_line_user_msgs= 'remove_first_line_user_msgs',
  keep_latest_media_parts= 'keep_latest_media_parts',
  remove_media_parts= 'remove_media_parts',
  remove_image_parts= 'remove_image_parts',
  remove_parsed_image_content= 'remove_parsed_image_content',
  remove_assistant_messages= 'remove_assistant_messages',
  only_keep_latest_consecutive_user_messages= 'only_keep_latest_consecutive_user_messages',

}
export interface HTTPValidationError {
  detail?: ValidationError[];
}

export interface GoogleBindResponse {
  url: string;
}

export interface GiveRecordFeedbackResponse {
  ok?: boolean;
}

export interface GiveFeedbackResponse {
  ok?: boolean;
}

export interface GiveFeedbackRequest {
  email?: string;
  feedback: string;
}

export interface GiveFeedFeedbackResponse {
  ok?: boolean;
}

export interface GiveFeedFeedbackRequest {
  content: string;
}

export interface GetExecutionContextResult {
  context: Context;
  feedback_info: FeedbackInfo;
  last_marker_user_id: number;
  related_id: number;
  related_message?: MessageOutput;
  related_type: string;
}

/**
 * The recurrence id of the recurrence event. Only has value when it's an expanded event.
 */
export type GetEventResponseRecurrenceId = string | string;

/**
 * The patches for the recurrence event.
 */
export type GetEventResponsePatches = {[key: string]: GetEventResponse};

export type GetEventResponseExcludeDatesItem = string | string;

export interface GetCalendarResponse {
  account_id: string;
  calendar_id: string;
  calendar_name: string;
}

/**
 * Note: The time zone of the datetime field on this class is not guaranteed to exist,
      and it remains consistent with the original data from CalDAV.
      Therefore, when using it, you must confirm or adjust the timezone yourself.
 */
export interface GetEventResponse {
  /** end date time of the event */
  alarm_base_start_at?: string;
  alarm_via_phone?: boolean;
  /** The alarms for the calendar event. */
  alarms?: Alarm[];
  /** The number of minutes before the event start that a alert should be sent */
  alert?: number;
  /** The attendees for the calendar event. */
  attendees?: Attendee[];
  calendar?: GetCalendarResponse;
  completed?: boolean;
  emoji?: string;
  /** end date time of the event */
  end_at: string;
  /** The dates to exclude from the recurrence rule. */
  exclude_dates?: GetEventResponseExcludeDatesItem[];
  /** is this event all day */
  is_all_day?: boolean;
  /** location of the event */
  location?: string;
  /** notes of the event */
  notes?: string;
  organizer?: Attendee;
  /** The patches for the recurrence event. */
  patches?: GetEventResponsePatches;
  /** The recurrence id of the recurrence event. Only has value when it's an expanded event. */
  recurrence_id?: GetEventResponseRecurrenceId;
  /** The recurrence rules for the calendar event. */
  recurrence_rules?: RecurrenceRulesOutput;
  /** how should the event repeat, repeat option, choose from: BY_DAY,BY_WEEK,BY_MONTH,BY_YEAR,BY_WORKDAY,BY_WEEKEND */
  repeat?: RepeatOption;
  /** repeat until this date time */
  repeat_until?: string;
  /** The start date time of the schedule event */
  start_at: string;
  /** Time zone when event added. */
  timezone?: string;
  /** title of the event */
  title: string;
  /** ID of the event */
  uuid: string;
  valarm_triggers?: string[];
}

export interface GetCodeRequest {
  next_url?: string;
  random_method?: RandomMethod;
  scopes: string[];
  session_type: SessionType;
  source: Source;
}

export interface FunctionCallOutput {
  arguments: string;
  name: string;
}

export interface FunctionCallInput {
  arguments?: string;
  name?: string;
}

export interface Function {
  arguments?: string;
  name?: string;
}

/**
 * An enumeration.
 */
export enum FrequencyEnum {
  hourly= 'hourly',
  daily= 'daily',
  weekly= 'weekly',
  monthly= 'monthly',
  yearly= 'yearly',

}
/**
 * An enumeration.
 */
export enum FormatterParser {
  jinja= 'jinja',
  literal= 'literal',
  getter= 'getter',

}
export interface FormatterMappingConfig {
  allow_empty?: boolean;
  default?: unknown;
  is_len?: boolean;
  parser?: FormatterParser;
  prefix?: string;
  render?: boolean;
  type?: string;
  value: string;
}

export type FormatterConfigMapping = {[key: string]: FormatterMappingConfig};

export interface FormatterConfig {
  ignore_value?: string;
  mapping?: FormatterConfigMapping;
}

export enum FineTuningJobWandbIntegrationObjectType {
  wandb= 'wandb',

}
export type FineTuningJobWandbIntegrationTags = string[] | null;

export type FineTuningJobWandbIntegrationName = string | null;

export type FineTuningJobWandbIntegrationEntity = string | null;

export interface FineTuningJobWandbIntegration {
  entity?: FineTuningJobWandbIntegrationEntity;
  name?: FineTuningJobWandbIntegrationName;
  project: string;
  tags?: FineTuningJobWandbIntegrationTags;
  [key: string]: unknown;
 }

export interface FineTuningJobWandbIntegrationObject {
  type: FineTuningJobWandbIntegrationObjectType;
  wandb: FineTuningJobWandbIntegration;
  [key: string]: unknown;
 }

export type FineTuningJobValidationFile = string | null;

export type FineTuningJobTrainedTokens = number | null;

export enum FineTuningJobStatus {
  validating_files= 'validating_files',
  queued= 'queued',
  running= 'running',
  succeeded= 'succeeded',
  failed= 'failed',
  cancelled= 'cancelled',

}
export enum FineTuningJobObject {
  fine_tuningjob= 'fine_tuning.job',

}
export type FineTuningJobIntegrations = FineTuningJobWandbIntegrationObject[] | null;

export type FineTuningJobFinishedAt = number | null;

export type FineTuningJobFineTunedModel = string | null;

export type FineTuningJobEstimatedFinish = number | null;

export type FineTuningJobError = Error | null;

export interface FineTuningJob {
  created_at: number;
  error?: FineTuningJobError;
  estimated_finish?: FineTuningJobEstimatedFinish;
  fine_tuned_model?: FineTuningJobFineTunedModel;
  finished_at?: FineTuningJobFinishedAt;
  hyperparameters: Hyperparameters;
  id: string;
  integrations?: FineTuningJobIntegrations;
  model: string;
  object: FineTuningJobObject;
  organization_id: string;
  result_files: string[];
  seed: number;
  status: FineTuningJobStatus;
  trained_tokens?: FineTuningJobTrainedTokens;
  training_file: string;
  validation_file?: FineTuningJobValidationFile;
  [key: string]: unknown;
 }

export interface FineTuneModelInfo {
  base_url?: string;
  batch_size?: number;
  completion_pricing?: number;
  learning_rate_multiplier?: number;
  n_epochs?: number;
  prompt_pricing?: number;
  token_limit?: number;
}

export interface FineTuneKey {
  key: string;
}

export interface FineTuneModelData {
  author?: DashUserInfo;
  base_model?: string;
  description?: string;
  info: FineTuneModelInfo;
  keys?: FineTuneKey[];
  model_id: string;
  name: string;
}

export type FinallyWorkflowInfoParams = { [key: string]: unknown };

export type FinallyWorkflowInfoMessagesDict = {[key: string]: MessageOutput[]};

export interface FinallyWorkflowInfo {
  config?: string;
  messages_dict?: FinallyWorkflowInfoMessagesDict;
  params?: FinallyWorkflowInfoParams;
  replace_response?: boolean;
  should_extend_result?: boolean;
  start_uuids?: string[];
  status?: string;
  uuid?: string;
  version?: number;
}

export interface FinallyWorkflowConfig {
  config?: string;
  params_formatter?: FormatterConfig;
  replace_response?: boolean;
  should_extend_result?: boolean;
  start_uuids?: string[];
  status?: string;
  uuid?: string;
  version?: number;
}

export type FileObjectStatusDetails = string | null;

export enum FileObjectStatus {
  uploaded= 'uploaded',
  processed= 'processed',
  error= 'error',

}
export enum FileObjectPurpose {
  assistants= 'assistants',
  assistants_output= 'assistants_output',
  batch= 'batch',
  batch_output= 'batch_output',
  'fine-tune'= 'fine-tune',
  'fine-tune-results'= 'fine-tune-results',
  vision= 'vision',

}
export enum FileObjectObject {
  file= 'file',

}
export interface FileObject {
  bytes: number;
  created_at: number;
  filename: string;
  id: string;
  object: FileObjectObject;
  purpose: FileObjectPurpose;
  status: FileObjectStatus;
  status_details?: FileObjectStatusDetails;
  [key: string]: unknown;
 }

export interface FeedbackInfo {
  related_agents?: string[];
  reply?: string;
}

/**
 * An enumeration.
 */
export enum FeedTypeS {
  daily_weather= 'daily_weather',
  news_search= 'news_search',

}
/**
 * An enumeration.
 */
export enum FeedType {
  NUMBER_0= 0,
  NUMBER_1= 1,
  NUMBER_2= 2,

}
export interface FeedRef {
  title: string;
  url: string;
}

export interface FeedRecord {
  content: string;
  like?: boolean;
  refs?: Ref[];
  title: string;
  ts: number;
  uid: string;
}

export type FeedDetailResponseFeed = DailyWeatherFeed | DailyNewsFeed;

export interface FeedDetailResponse {
  feed: FeedDetailResponseFeed;
  last_record?: FeedRecord;
}

export interface FeedContentResponse {
  channel_name: string;
  channel_type: ChannelType;
  cid: string;
  content?: string;
  images?: string[];
  joined?: boolean;
  refs?: FeedRef[];
  rid?: string;
}

export interface FeedCard {
  channel_name: string;
  channel_type: ChannelType;
  cid: string;
  heading: string;
  imgs?: string[];
  read: boolean;
  rid: string;
  snippet: string;
  ts: number;
}

export interface FallbackExecutorOption {
  connect_timeout?: number;
  enabled?: boolean;
  llm_option?: OrionAlgCommonLLMOption;
  max_execution_time?: number;
  max_iteration?: number;
  max_retry_count?: number;
  next_model_key?: string;
  request_timeout?: number;
  retry_if_rate_limit?: boolean;
  retry_max_time?: number;
  retry_min_time?: number;
  timeout_multiplier?: number;
  write_timeout?: number;
}

export type ExperimentRequestMockParams = { [key: string]: unknown };

export interface ExperimentRequest {
  config: AgentConfigInput;
  messages?: MessageInput[];
  mock_data?: boolean;
  mock_params?: ExperimentRequestMockParams;
  user_input: string;
}

export interface ExecutorOption {
  connect_timeout?: number;
  llm_option?: OrionAlgCommonLLMOption;
  max_execution_time?: number;
  max_iteration?: number;
  max_retry_count?: number;
  next_model_key?: string;
  request_timeout?: number;
  retry_if_rate_limit?: boolean;
  retry_max_time?: number;
  retry_min_time?: number;
  timeout_multiplier?: number;
  write_timeout?: number;
}

export interface ExecutionContextMeta {
  hidden_messages?: MessageOutput[];
  recover_meta?: RecoverMeta;
  related_objects?: RelatedObject[];
}

export type EvalutaionTemplateDataParams = { [key: string]: unknown };

export interface EvalutaionTemplateData {
  author: DashUserInfo;
  created_at: number;
  description: string;
  id: number;
  judger: string;
  label_ids: number[];
  memories: string[];
  name: string;
  params?: EvalutaionTemplateDataParams;
  recent_messages: MessageOutput[];
  related_annotation_ids?: number[];
  updated_at: number;
}

export interface EvaluationTemplateSummary {
  author: DashUserInfo;
  created_at: number;
  id: number;
  judger: string;
  label_ids: number[];
  name: string;
  updated_at: number;
}

/**
 * An enumeration.
 */
export enum EvaluationStatus {
  NUMBER_0= 0,
  NUMBER_1= 1,
  NUMBER_2= 2,
  NUMBER_3= 3,

}
export interface EvaluationTaskData {
  assertions_result?: AssertionsResult;
  context?: Context;
  errors: string[];
  id: number;
  labels?: string[];
  objective_reached: boolean;
  plan_id: number;
  reason: JudgerTrace;
  result: string;
  status: EvaluationStatus;
}

export interface EvaluationPlanSummary {
  agent_id?: number;
  author: DashUserInfo;
  batch: string;
  created_at: number;
  fail_count?: number;
  has_judger: boolean;
  id: number;
  is_evaluated: boolean;
  name: string;
  running_count?: number;
  success_count?: number;
  template_id?: number;
  times: number;
  workflow_id?: number;
}

export type EvaluationPlanDataParams = { [key: string]: unknown };

export interface EvaluationPlanData {
  agent_config?: AgentConfigOutput;
  author: DashUserInfo;
  batch: string;
  created_at: number;
  id: number;
  is_evaluated: boolean;
  judger: string;
  memories: string[];
  name: string;
  params?: EvaluationPlanDataParams;
  recent_messages: MessageOutput[];
  template_id: number;
  times: number;
  workflow_config?: ActorExecuteConfig;
}

export type ErrorParam = string | null;

export interface Error {
  code: string;
  message: string;
  param?: ErrorParam;
  [key: string]: unknown;
 }

export interface EnableTuneModel {
  description?: string;
  job_id: string;
  name: string;
}

export interface DraftTemplateResult {
  id?: number;
  key?: string;
  language?: string;
  source?: string;
  success: boolean;
  template?: string;
}

export interface DraftTemplateResultDetail {
  results?: DraftTemplateResult[];
  tasks?: EvaluationTaskData[];
}

export interface DeployPayload {
  note: string;
}

export interface DegradeConfig {
  next_model_key?: string;
}

export interface DegradeStrategyConfig {
  if_error?: DegradeConfig;
  if_exception?: DegradeConfig;
  if_objective_unreached?: DegradeConfig;
}

export interface DefaultCalendarReqeustV2 {
  default: CalendarAccountPayload;
  enabled_calendars: CalendarAccountPayload[];
  move_events?: boolean;
}

export interface DefaultCalendarReqeust {
  /** Required if provider is not self_hosted */
  calendar_id?: string;
  move_events?: boolean;
  provider: CalendarProviderName;
}

export interface DeactivateUserResponse {
  ok?: boolean;
  t?: string;
}

export interface DeactivateUserRequest {
  code?: string;
  reason?: string;
}

/**
 * An enumeration.
 */
export enum DaysEnum {
  Mon= 'Mon',
  Tue= 'Tue',
  Wed= 'Wed',
  Thu= 'Thu',
  Fri= 'Fri',
  Sat= 'Sat',
  Sun= 'Sun',

}
export type DashboardRequestParams = { [key: string]: unknown };

export interface DashboardRequest {
  boardered?: boolean;
  id: number;
  params?: DashboardRequestParams;
  titled?: boolean;
}

export interface DashboardData {
  url: string;
}

export interface DashWechatContactWay {
  corp_user: string;
  created_at: number;
  id: number;
  name: string;
  party: string;
  qr_code?: string;
  updated_at: number;
}

export interface DashUserInfo {
  created_at?: number;
  email?: string;
  id: number;
  roles?: UserRole[];
  username?: string;
}

export interface DashLabelPostData {
  description: string;
  name: string;
  parent_id: number;
  properties?: LabelProperties[];
  slug?: string;
}

export interface DashLabelPatchData {
  description: string;
  name: string;
  parent_id: number;
}

export interface DashLabel {
  created_at: number;
  created_by: number;
  depth: number;
  description: string;
  id: number;
  name: string;
  order_number: number;
  parent_id: number;
  properties: LabelProperties[];
  root_id: number;
  slug?: string;
  updated_at: number;
}

export interface DailyWeatherFeed {
  city: string;
  notify_enabled: boolean;
  notify_hour: number;
  notify_minute: number;
  notify_times?: NotifyTime[];
  type: FeedType;
  uid: string;
}

export interface DailyNewsFeed {
  about: string;
  notify_enabled: boolean;
  notify_hour: number;
  notify_minute: number;
  notify_period: string;
  notify_times?: NotifyTime[];
  type: FeedType;
  uid: string;
}

export interface CustomerSession {
  client_secret: string;
  expires_at: number;
}

export interface CreateTuneJob {
  base_model: string;
  batch_size?: number;
  file_id: string;
  learning_rate_multiplier?: number;
  n_epochs?: number;
}

export interface CreateTemplateViaUserIDRequest {
  label_ids?: number[];
  limit?: number;
  slugs?: string[];
  template_prefix?: string;
  token_limit?: number;
  user_ids: number[];
}

export interface CreateTemplateViaMessageIDRequest {
  id_offset?: number;
  label_ids?: number[];
  message_ids: number[];
  slugs?: string[];
}

export type CreateEvaluationRequestParams = { [key: string]: unknown };

export interface CreateEvaluationRequest {
  agent_uuid?: string;
  agent_version?: number;
  batch?: string;
  judger?: string;
  judger_type?: JudgerType;
  name: string;
  params?: CreateEvaluationRequestParams;
  recent_messages?: MessageInput[];
  run_async?: boolean;
  run_single?: boolean;
  start_now?: boolean;
  template_id?: number;
  times: number;
  tpl?: string;
  user_input?: string;
  workflow_run_latest_agents?: boolean;
  workflow_uuid?: string;
  workflow_version?: number;
}

export type ConverterConfigConfig = {[key: string]: string};

export interface ConverterConfig {
  config?: ConverterConfigConfig;
  name: string;
}

export type ContextTotalServicePrice = {[key: string]: number};

export type ContextTotalServiceCalls = {[key: string]: number};

export interface Context {
  agent_logs?: AgentExecutionTrace[];
  current_responding_agent_name?: string;
  duration?: number;
  hidden_messages?: MessageOutput[];
  history_config?: HistoryConfig;
  in_responding?: ResponseMessage[];
  in_response_to?: MessageOutput;
  in_response_to_messages?: MessageOutput[];
  is_actor?: boolean;
  is_private?: boolean;
  memory: string[];
  message_history: MessageOutput[];
  mock_tools?: boolean;
  now?: number;
  recover_info?: RecoverInfo;
  session: SessionOutput;
  started_at?: number;
  total_llm_calls?: number;
  total_llm_reactions?: number;
  total_price?: number;
  total_service_calls?: ContextTotalServiceCalls;
  total_service_price?: ContextTotalServicePrice;
  total_tokens?: number;
  user_info?: UserInfoOutput;
  user_input?: string;
  user_now_str?: string;
  workflow_logs?: WorkflowTrace[];
}

/**
 * An enumeration.
 */
export enum ContentType {
  text= 'text',
  image= 'image',
  audio= 'audio',
  video= 'video',
  chat_record= 'chat_record',

}
export interface ContactWayPatchRequest {
  corp_user?: string;
  name: string;
  party?: string;
}

export interface ContactWayCreateRequest {
  corp_user?: string;
  name: string;
  party?: string;
}

/**
 * An enumeration.
 */
export enum ContactType {
  NUMBER_0= 0,
  NUMBER_1= 1,
  NUMBER_2= 2,
  NUMBER_3= 3,

}
export type ConditionConfigRules = {[key: string]: RuleConfig};

export interface ConditionConfig {
  expression: string;
  rules: ConditionConfigRules;
}

/**
 * An enumeration.
 */
export enum CommonMessageStatus {
  unknown= 'unknown',
  sent= 'sent',
  delivered= 'delivered',
  read= 'read',
  failed= 'failed',
  closed= 'closed',

}
/**
 * An enumeration.
 */
export enum ClockFormat {
  '12h'= '12h',
  '24h'= '24h',

}
export interface ChatRequest {
  is_evaluation?: boolean;
  user_input: string;
  workflow_uuid?: string;
}

export interface ChatHistoryResponse {
  messages: MessageOutput[];
  total: number;
}

export enum ChatCompletionUserMessageParamRole {
  user= 'user',

}
export type ChatCompletionUserMessageParamContent = string | ChatCompletionUserMessageParamContentAnyOfItem[];

export interface ChatCompletionUserMessageParam {
  content?: ChatCompletionUserMessageParamContent;
  name?: string;
  role?: ChatCompletionUserMessageParamRole;
}

export enum ChatCompletionToolMessageParamRole {
  tool= 'tool',

}
export interface ChatCompletionToolMessageParam {
  content?: string;
  role?: ChatCompletionToolMessageParamRole;
  tool_call_id?: string;
}

export enum ChatCompletionSystemMessageParamRole {
  system= 'system',

}
export interface ChatCompletionSystemMessageParam {
  content?: string;
  name?: string;
  role?: ChatCompletionSystemMessageParamRole;
}

export enum ChatCompletionMessageToolCallParamType {
  function= 'function',

}
export interface ChatCompletionMessageToolCallParam {
  function?: Function;
  id?: string;
  type?: ChatCompletionMessageToolCallParamType;
}

export enum ChatCompletionFunctionMessageParamRole {
  function= 'function',

}
export interface ChatCompletionFunctionMessageParam {
  content?: string;
  name?: string;
  role?: ChatCompletionFunctionMessageParamRole;
}

export enum ChatCompletionContentPartTextParamType {
  text= 'text',

}
export interface ChatCompletionContentPartTextParam {
  text?: string;
  type?: ChatCompletionContentPartTextParamType;
}

export enum ChatCompletionContentPartImageParamType {
  image_url= 'image_url',

}
export interface ChatCompletionContentPartImageParam {
  image_url?: ImageURL;
  type?: ChatCompletionContentPartImageParamType;
}

export type ChatCompletionUserMessageParamContentAnyOfItem = ChatCompletionContentPartTextParam | ChatCompletionContentPartImageParam;

export enum ChatCompletionAssistantMessageParamRole {
  assistant= 'assistant',

}
export interface ChatCompletionAssistantMessageParam {
  content?: string;
  function_call?: FunctionCallInput;
  name?: string;
  role?: ChatCompletionAssistantMessageParamRole;
  tool_calls?: ChatCompletionMessageToolCallParam[];
}

/**
 * An enumeration.
 */
export enum ChannelTypeS {
  daily_weather= 'daily_weather',
  news= 'news',
  finance= 'finance',

}
/**
 * An enumeration.
 */
export enum ChannelType {
  NUMBER_0= 0,
  NUMBER_1= 1,
  NUMBER_2= 2,
  NUMBER_3= 3,

}
export interface ChannelNotifyTime {
  hour: number;
  minute: number;
}

/**
 * An enumeration.
 */
export enum CalendarProviderName {
  self_hosted= 'self_hosted',
  google= 'google',
  icloud= 'icloud',
  feishu= 'feishu',
  outlook= 'outlook',
  custom= 'custom',

}
export interface CalendarItemWithAccountOutput {
  account: UserCalendarsAccount;
  id: string;
  name: string;
}

export interface CalendarItemWithAccountInput {
  account: UserCalendarsAccount;
  id: string;
  name: string;
}

export interface CalendarInfoResponse {
  color?: string;
  id: string;
  is_default?: boolean;
  is_enabled?: boolean;
  name?: string;
  provider: CalendarProviderName;
}

export interface CalendarAccountPayload {
  account_id: string;
  calendar_id: string;
  provider: CalendarProviderName;
}

export interface CalendarAccountInfo {
  account_name: string;
  provider: CalendarProviderName;
}

/**
 * An enumeration.
 */
export enum BotType {
  public= 'public',
  private= 'private',

}
/**
 * An enumeration.
 */
export enum BotStatus {
  stop= 'stop',
  active= 'active',

}
export interface BotSettings {
  is_twilio_provider?: boolean;
  starting_workflow?: string;
  telegram_bot_orion_token?: string;
  telegram_bot_source_id_prefix?: string;
  telegram_bot_token?: string;
  whatsapp_phone_number?: string;
}

export interface BotName {
  telegram?: string;
  whatsapp?: string;
}

export interface BotInfoOutput {
  id: number;
  owner_id?: number;
  owner_info?: UserInfoOutput;
  service_id: string;
  service_name: string;
  settings?: BotSettings;
  source: Source;
  status?: BotStatus;
  type?: BotType;
}

export interface BotInfoInput {
  id: number;
  owner_id?: number;
  owner_info?: UserInfoInput;
  service_id: string;
  service_name: string;
  settings?: BotSettings;
  source: Source;
  status?: BotStatus;
  type?: BotType;
}

export interface BodyUploadExcelAdminFinetuneUploadExcelPost {
  file: Blob;
}

export type BodyStatusCallbackCallTwilioStatusCallbackPostRecordingUrl = string | null;

export type BodyStatusCallbackCallTwilioStatusCallbackPostRecordingDuration = number | null;

export type BodyStatusCallbackCallTwilioStatusCallbackPostCallDuration = number | null;

export interface BodyStatusCallbackCallTwilioStatusCallbackPost {
  call_duration?: BodyStatusCallbackCallTwilioStatusCallbackPostCallDuration;
  call_sid: string;
  call_status: string;
  recording_duration?: BodyStatusCallbackCallTwilioStatusCallbackPostRecordingDuration;
  recording_url?: BodyStatusCallbackCallTwilioStatusCallbackPostRecordingUrl;
  timestamp_str: string;
}

export type BodyLoginForAccessTokenAuthTokenPostGrantType = string | null;

export type BodyLoginForAccessTokenAuthTokenPostClientSecret = string | null;

export type BodyLoginForAccessTokenAuthTokenPostClientId = string | null;

export interface BodyLoginForAccessTokenAuthTokenPost {
  client_id?: BodyLoginForAccessTokenAuthTokenPostClientId;
  client_secret?: BodyLoginForAccessTokenAuthTokenPostClientSecret;
  grant_type?: BodyLoginForAccessTokenAuthTokenPostGrantType;
  password: string;
  scope?: string;
  username: string;
}

export interface BizUsrGroupInfo {
  description: string;
  id: number;
  name: string;
  session_id: string;
  settings?: UserSettings;
  source: Source;
}

export type BizMessageMeta = { [key: string]: unknown };

export interface BizMessage {
  author?: UserInfoOutput;
  content_type?: ContentType;
  created_at?: number;
  id?: string;
  label_ids?: number[];
  message_parts?: MessagePart[];
  meta?: BizMessageMeta;
  role: Role;
  session_ref_id?: number;
  source: Source;
  source_id: string;
  text: string;
}

export interface BizUserInfo {
  avatar_url?: string;
  bound_sources?: Source[];
  caldav_username?: string;
  calendar_id?: string;
  calendar_provider?: CalendarProviderName;
  /** User's third-party calendars, include self-hosted */
  calendars?: UserCalendarsInfoOutput;
  created_at?: number;
  email?: string;
  id: number;
  latest_message?: BizMessage;
  on_waitlist_at?: number;
  phone?: string;
  plan?: number;
  roles?: UserRole[];
  settings?: UserSettings;
  states?: UserStatesOutput;
  tags?: string[];
  type?: UserType;
  /** The nickname / username of the user, whichever is available */
  username?: string;
}

export interface BillingPortalSessionCreateRequest {
  return_url: string;
}

export type BatchEvalTemplateParams = { [key: string]: unknown };

export interface BatchEvalTemplate {
  ai_output_message?: MessageInput;
  description?: string;
  id?: number;
  judger?: string;
  label_ids?: number[];
  name: string;
  params?: BatchEvalTemplateParams;
  recent_messages?: MessageInput[];
  related_annotation_ids?: number[];
  slugs?: string[];
  user_input: string;
  user_input_message?: MessageInput;
}

export interface BatchDeployPayload {
  ids: number[];
}

export interface BatchCreateTemplateEvaluationResponse {
  tasks: TemplateEvaluationResult[];
}

export interface BatchCreateTemplateEvaluationRequest {
  case_ids: number[];
  tpl: string;
}

export interface BatchCreateEvaluationResponse {
  batch: string;
  plans: EvaluationPlanSummary[];
}

export interface BatchCreateEvaluationRequest {
  agent_uuid?: string;
  agent_version?: number;
  batch?: string;
  judger_type?: JudgerType;
  name?: string;
  run_single?: boolean;
  start_now?: boolean;
  template_ids: number[];
  times?: number;
  workflow_run_latest_agents?: boolean;
  workflow_uuid?: string;
  workflow_version?: number;
}

export interface BaseChannel {
  channel_name: string;
  channel_type: ChannelType;
  cid: string;
}

export interface AuthResponse {
  error?: string;
  token?: Token;
}

export interface AuthPayload {
  /** code */
  code: string;
}

/**
 * An enumeration.
 */
export enum AttendeeRole {
  REQUIRED= 'REQUIRED',
  OPTIONAL= 'OPTIONAL',
  NON_PARTICIPANT= 'NON_PARTICIPANT',

}
export interface Attendee {
  address?: string;
  common_name: string;
  role?: AttendeeRole;
  x_im_id?: string;
  x_im_type?: Source;
}

export interface AssertionMeta {
  labels?: string[];
  required?: boolean;
}

export interface AssertionResult {
  assertion: AssertionContext;
  meta: AssertionMeta;
}

export type AssertionsResult = AssertionResult[];

/**
 * An enumeration.
 */
export enum AssertionMessageLevel {
  info= 'info',
  warning= 'warning',
  error= 'error',

}
export interface AssertionMessage {
  level: AssertionMessageLevel;
  message: string;
}

export interface AssertionContext {
  failed?: boolean;
  logs?: AssertionMessage[];
}

export interface AnnotationData {
  author: DashUserInfo;
  created_at: number;
  duration: number;
  id: number;
  label_ids: number[];
  message_ids: number[];
  messages: BizMessage[];
  note?: string;
  rounds: number;
  updated_at: number;
}

export interface AnnotationCreate {
  label_ids?: number[];
  msg_ids: number[];
  note?: string;
  slugs?: string[];
}

export interface Alarm {
  absolute_time?: string;
  /** The relative offset, negative means before the event, positive means after the event */
  relative_offset?: number;
  relative_offset_unit?: RelativeOffsetUnit;
}

export interface AirNoteTableColumn {
  default_value?: string;
  name: string;
  pk: boolean;
  required: boolean;
  type: string;
}

export interface AirNoteTable {
  columns: AirNoteTableColumn[];
  name: string;
}

export type AirNoteRowWithTableRow = { [key: string]: unknown };

export interface AirNoteRowWithTable {
  row: AirNoteRowWithTableRow;
  table: AirNoteTable;
}

export interface AirNoteActivityResponse {
  recently_inserted_rows: AirNoteRowWithTable[];
}

/**
 * An enumeration.
 */
export enum AgentSystemPromptPosition {
  start= 'start',
  after_last_completion= 'after_last_completion',

}
/**
 * 作为列表返回时的 Summary 信息
 */
export interface AgentSummary {
  author: DashUserInfo;
  classification: string;
  description: string;
  model: string;
  name: string;
  note: string;
  parent_version?: number;
  status: string;
  tags: string[];
  updated_at: number;
  uuid: string;
  version: number;
}

/**
 * An enumeration.
 */
export enum AgentStatus {
  draft= 'draft',
  prod= 'prod',

}
export interface AgentRunResult {
  context: Context;
  meta: MessageMeta;
  response: AgentExecutionResult;
}

export interface AgentPlan {
  function_call?: FunctionCallOutput;
  llm_response: string;
  objective_reached?: boolean;
  tool?: string;
  tool_calls?: ToolCall[];
  tool_input?: unknown;
}

export interface AgentUsedToolResult {
  action: AgentPlan;
  agent: string;
  exception?: string;
  hidden_result?: string;
  is_action_rewrite?: boolean;
  is_ask_human?: boolean;
  objective_reached?: boolean;
  related_objects?: RelatedObject[];
  result: string;
  should_end?: boolean;
  tool_call_id?: string;
  tool_calls?: AgentUsedToolResult[];
  tool_processes?: ToolProcess[];
}

export type AgentOptionsResponseToolsItem = { [key: string]: unknown };

export interface AgentOptionsResponse {
  parsers: string[];
  sources: Source[];
  tools: AgentOptionsResponseToolsItem[];
}

export interface AgentModelConfigOutput {
  degrade_strategy?: DegradeStrategyConfig;
  enabled_global_control?: boolean;
  is_default?: boolean;
  key: string;
  llm_option?: OrionAlgLlmCommonLLMOption;
  llm_request_option?: LLMRequestOption;
  name?: string;
  weight?: number;
}

export interface AgentModelConfigInput {
  degrade_strategy?: DegradeStrategyConfig;
  enabled_global_control?: boolean;
  is_default?: boolean;
  key: string;
  llm_option?: OrionAlgLlmCommonLLMOption;
  llm_request_option?: LLMRequestOption;
  name?: string;
  weight?: number;
}

export interface AgentExecutionTrace {
  actions?: AgentUsedToolResult[];
  agent: string;
  duration?: number;
  objective_reached?: boolean;
  result?: string;
  started_at?: number;
  traces?: Trace[];
  uuid?: string;
  version?: number;
}

export interface AgentExecutionResult {
  end_tool?: string;
  end_tool_arguments?: string;
  end_tools?: ToolCall[];
  is_ask_human?: boolean;
  llm_response?: string;
  objective_reached?: boolean;
  result: string;
  tool_processes?: ToolProcess[];
}

export interface AgentRunTaskResult {
  context: Context;
  meta: MessageMeta;
  related_id: number;
  related_type: string;
  response: AgentExecutionResult;
}

export interface AgentExecutionConfig {
  messages?: MessageOutput[];
  uuid: string;
  version?: number;
}

export type AgentConfigOutputModelsItem = AgentModelConfigOutput | AgentModelConfigOutput[];

export interface AgentConfigOutput {
  author?: DashUserInfo;
  classification?: string;
  description: string;
  fallback_option?: FallbackExecutorOption;
  id?: number;
  models?: AgentConfigOutputModelsItem[];
  name: string;
  note?: string;
  objective_must_succeed?: boolean;
  objective_tool?: string;
  option?: ExecutorOption;
  parent_version?: number;
  parser?: Parser;
  pick_type?: string;
  prompt_option?: PromptOption;
  status?: string;
  system_prompt?: string;
  tags?: string[];
  template_tools?: TemplateToolConfig[];
  tool_option?: ToolExecutorOptionOutput;
  tools?: ToolConfig[];
  updated_at?: number;
  user_prompt?: string;
  uuid: string;
  version?: number;
}

export type AgentConfigInputModelsItem = AgentModelConfigInput | AgentModelConfigInput[];

export interface AgentConfigInput {
  author?: DashUserInfo;
  classification?: string;
  description: string;
  fallback_option?: FallbackExecutorOption;
  id?: number;
  models?: AgentConfigInputModelsItem[];
  name: string;
  note?: string;
  objective_must_succeed?: boolean;
  objective_tool?: string;
  option?: ExecutorOption;
  parent_version?: number;
  parser?: Parser;
  pick_type?: string;
  prompt_option?: PromptOption;
  status?: string;
  system_prompt?: string;
  tags?: string[];
  template_tools?: TemplateToolConfig[];
  tool_option?: ToolExecutorOptionInput;
  tools?: ToolConfig[];
  updated_at?: number;
  user_prompt?: string;
  uuid: string;
  version?: number;
}

export type AgentApiRequestParams = {[key: string]: string};

export interface AgentApiRequest {
  agent_status_filter?: AgentStatus[];
  messages?: MessageInput[];
  params: AgentApiRequestParams;
  session?: SessionInput;
  user_id: number;
}

export interface AddFeedRequest {
  target: string;
  type: FeedTypeS;
}

/**
 * An enumeration.
 */
export enum AddErrCode {
  ''= '',
  failed= 'failed',
  advice= 'advice',
  duplicated= 'duplicated',
  quota_exceeded= 'quota_exceeded',
  unsupported_city= 'unsupported_city',
  non_dynamic_content= 'non_dynamic_content',
  unsupported_category= 'unsupported_category',
  timezone_not_ready= 'timezone_not_ready',
  unsupported_ticker= 'unsupported_ticker',

}
export interface AddFeedResponse {
  err_code?: AddErrCode;
  news_feed?: DailyNewsFeed;
  suggestions?: string[];
  weather_feed?: DailyWeatherFeed;
}

/**
 * An enumeration.
 */
export enum ActorStatus {
  draft= 'draft',
  prod= 'prod',

}
export interface ActorResponseInfo {
  ai_content?: string;
  content?: string;
  may_sensitive?: boolean;
  need_response?: boolean;
}

export type ActorExecutionResultExtendedExecutionResult = {[key: string]: unknown[]};

export interface ActorExecutionResult {
  context_meta?: ExecutionContextMeta;
  duration?: number;
  exception?: string;
  extended_execution_result?: ActorExecutionResultExtendedExecutionResult;
  finally_workflow?: FinallyWorkflowInfo;
  next_workflow?: OrionAgiCommonWorkflowInfo;
  response?: ActorResponseInfo;
  result?: unknown;
  total_price?: number;
  uuid?: string;
}

export interface ActorExecuteConfig {
  actors: ActorConfig[];
  detection_cycle?: number;
  exit_condition?: ConditionConfig;
  exit_formatter?: FormatterConfig;
  max_concurrent?: number;
  max_execution_count?: number;
  max_execution_time?: number;
  name?: string;
  start_uuids?: string[];
  uuid?: string;
  version?: number;
}

export type ActorContextInfoStartParams = {[key: string]: string};

export interface ActorContextInfo {
  hidden_messages?: MessageOutput[];
  may_sensitive?: boolean;
  messages?: MessageOutput[];
  need_response?: boolean;
  record?: boolean;
  related_objects?: RelatedObject[];
  start_params?: ActorContextInfoStartParams;
  start_uuids?: string[];
  with_latest_message?: boolean;
}

export interface ActorExecutionTrace {
  context_info?: ActorContextInfo;
  duration?: number;
  ended_at?: number;
  exception?: string;
  name?: string;
  result?: string;
  started_at?: number;
  traces?: Trace[];
  uuid?: string;
  workflow_name?: string;
  workflow_uuid?: string;
  workflow_version?: number;
}

export type ActorConfigAction = { [key: string]: unknown };

/**
 * An enumeration.
 */
export enum ActionType {
  agent= 'agent',
  tool= 'tool',
  code= 'code',
  loop= 'loop',
  workflow= 'workflow',
  condition= 'condition',
  condition_v2= 'condition_v2',
  empty= 'empty',

}
export interface ActorConfig {
  action: ActorConfigAction;
  action_type: ActionType;
  allow_exit?: boolean;
  context_start_params_formatter?: FormatterConfig;
  context_start_uuids?: string[];
  finally_workflow?: FinallyWorkflowConfig;
  inject_hidden_message?: boolean;
  input_formatter?: FormatterConfig;
  is_task_actor?: boolean;
  keep_context?: boolean;
  max_round_count?: number;
  may_sensitive?: boolean;
  name: string;
  need_response?: boolean;
  next_actors: string[];
  next_workflow?: NextWorkflowConfig;
  output_formatter?: FormatterConfig;
  uuid: string;
  with_latest_message?: boolean;
}





/**
 * @summary Login For Access Token
 */
export const loginForAccessTokenAuthTokenPost = (
    bodyLoginForAccessTokenAuthTokenPost: BodyLoginForAccessTokenAuthTokenPost,
    params?: LoginForAccessTokenAuthTokenPostParams,
 ) => {
      
      const formUrlEncoded = new URLSearchParams();
if(bodyLoginForAccessTokenAuthTokenPost.grant_type !== undefined) {
 formUrlEncoded.append('grant_type', bodyLoginForAccessTokenAuthTokenPost.grant_type)
 }
formUrlEncoded.append('username', bodyLoginForAccessTokenAuthTokenPost.username)
formUrlEncoded.append('password', bodyLoginForAccessTokenAuthTokenPost.password)
if(bodyLoginForAccessTokenAuthTokenPost.scope !== undefined) {
 formUrlEncoded.append('scope', bodyLoginForAccessTokenAuthTokenPost.scope)
 }
if(bodyLoginForAccessTokenAuthTokenPost.client_id !== undefined) {
 formUrlEncoded.append('client_id', bodyLoginForAccessTokenAuthTokenPost.client_id)
 }
if(bodyLoginForAccessTokenAuthTokenPost.client_secret !== undefined) {
 formUrlEncoded.append('client_secret', bodyLoginForAccessTokenAuthTokenPost.client_secret)
 }

      return loginForAccessTokenAuthTokenPostMutator<Token>(
      {url: `/auth/token`, method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded', },
       data: formUrlEncoded,
        params
    },
      );
    }
  


export const getLoginForAccessTokenAuthTokenPostMutationOptions = <TError = LoginForAccessTokenAuthTokenPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginForAccessTokenAuthTokenPost>>, TError,{data: BodyLoginForAccessTokenAuthTokenPost;params?: LoginForAccessTokenAuthTokenPostParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof loginForAccessTokenAuthTokenPost>>, TError,{data: BodyLoginForAccessTokenAuthTokenPost;params?: LoginForAccessTokenAuthTokenPostParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginForAccessTokenAuthTokenPost>>, {data: BodyLoginForAccessTokenAuthTokenPost;params?: LoginForAccessTokenAuthTokenPostParams}> = (props) => {
          const {data,params} = props ?? {};

          return  loginForAccessTokenAuthTokenPost(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LoginForAccessTokenAuthTokenPostMutationResult = NonNullable<Awaited<ReturnType<typeof loginForAccessTokenAuthTokenPost>>>
    export type LoginForAccessTokenAuthTokenPostMutationBody = BodyLoginForAccessTokenAuthTokenPost
    export type LoginForAccessTokenAuthTokenPostMutationError = LoginForAccessTokenAuthTokenPostErrorType<HTTPValidationError>

    /**
 * @summary Login For Access Token
 */
export const useLoginForAccessTokenAuthTokenPost = <TError = LoginForAccessTokenAuthTokenPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginForAccessTokenAuthTokenPost>>, TError,{data: BodyLoginForAccessTokenAuthTokenPost;params?: LoginForAccessTokenAuthTokenPostParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof loginForAccessTokenAuthTokenPost>>,
        TError,
        {data: BodyLoginForAccessTokenAuthTokenPost;params?: LoginForAccessTokenAuthTokenPostParams},
        TContext
      > => {

      const mutationOptions = getLoginForAccessTokenAuthTokenPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Login For Access Token By Code
 */
export const loginForAccessTokenByCodeAuthAuthorizationCodePost = (
    params?: LoginForAccessTokenByCodeAuthAuthorizationCodePostParams,
 ) => {
      
      
      return loginForAccessTokenByCodeAuthAuthorizationCodePostMutator<Token>(
      {url: `/auth/authorization-code`, method: 'POST',
        params
    },
      );
    }
  


export const getLoginForAccessTokenByCodeAuthAuthorizationCodePostMutationOptions = <TError = LoginForAccessTokenByCodeAuthAuthorizationCodePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginForAccessTokenByCodeAuthAuthorizationCodePost>>, TError,{params?: LoginForAccessTokenByCodeAuthAuthorizationCodePostParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof loginForAccessTokenByCodeAuthAuthorizationCodePost>>, TError,{params?: LoginForAccessTokenByCodeAuthAuthorizationCodePostParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginForAccessTokenByCodeAuthAuthorizationCodePost>>, {params?: LoginForAccessTokenByCodeAuthAuthorizationCodePostParams}> = (props) => {
          const {params} = props ?? {};

          return  loginForAccessTokenByCodeAuthAuthorizationCodePost(params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LoginForAccessTokenByCodeAuthAuthorizationCodePostMutationResult = NonNullable<Awaited<ReturnType<typeof loginForAccessTokenByCodeAuthAuthorizationCodePost>>>
    
    export type LoginForAccessTokenByCodeAuthAuthorizationCodePostMutationError = LoginForAccessTokenByCodeAuthAuthorizationCodePostErrorType<HTTPValidationError>

    /**
 * @summary Login For Access Token By Code
 */
export const useLoginForAccessTokenByCodeAuthAuthorizationCodePost = <TError = LoginForAccessTokenByCodeAuthAuthorizationCodePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginForAccessTokenByCodeAuthAuthorizationCodePost>>, TError,{params?: LoginForAccessTokenByCodeAuthAuthorizationCodePostParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof loginForAccessTokenByCodeAuthAuthorizationCodePost>>,
        TError,
        {params?: LoginForAccessTokenByCodeAuthAuthorizationCodePostParams},
        TContext
      > => {

      const mutationOptions = getLoginForAccessTokenByCodeAuthAuthorizationCodePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Change Password
 */
export const changePasswordAuthUserPatch = (
    userPatchRequest: UserPatchRequest,
 ) => {
      
      
      return changePasswordAuthUserPatchMutator<unknown>(
      {url: `/auth/user`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: userPatchRequest
    },
      );
    }
  


export const getChangePasswordAuthUserPatchMutationOptions = <TError = ChangePasswordAuthUserPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changePasswordAuthUserPatch>>, TError,{data: UserPatchRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof changePasswordAuthUserPatch>>, TError,{data: UserPatchRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changePasswordAuthUserPatch>>, {data: UserPatchRequest}> = (props) => {
          const {data} = props ?? {};

          return  changePasswordAuthUserPatch(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChangePasswordAuthUserPatchMutationResult = NonNullable<Awaited<ReturnType<typeof changePasswordAuthUserPatch>>>
    export type ChangePasswordAuthUserPatchMutationBody = UserPatchRequest
    export type ChangePasswordAuthUserPatchMutationError = ChangePasswordAuthUserPatchErrorType<HTTPValidationError>

    /**
 * @summary Change Password
 */
export const useChangePasswordAuthUserPatch = <TError = ChangePasswordAuthUserPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changePasswordAuthUserPatch>>, TError,{data: UserPatchRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof changePasswordAuthUserPatch>>,
        TError,
        {data: UserPatchRequest},
        TContext
      > => {

      const mutationOptions = getChangePasswordAuthUserPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Message
 */
export const messageWechatMessagePost = (
    messageRequest: MessageRequest,
 ) => {
      
      
      return messageWechatMessagePostMutator<unknown>(
      {url: `/wechat/message`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: messageRequest
    },
      );
    }
  


export const getMessageWechatMessagePostMutationOptions = <TError = MessageWechatMessagePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof messageWechatMessagePost>>, TError,{data: MessageRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof messageWechatMessagePost>>, TError,{data: MessageRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof messageWechatMessagePost>>, {data: MessageRequest}> = (props) => {
          const {data} = props ?? {};

          return  messageWechatMessagePost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MessageWechatMessagePostMutationResult = NonNullable<Awaited<ReturnType<typeof messageWechatMessagePost>>>
    export type MessageWechatMessagePostMutationBody = MessageRequest
    export type MessageWechatMessagePostMutationError = MessageWechatMessagePostErrorType<HTTPValidationError>

    /**
 * @summary Message
 */
export const useMessageWechatMessagePost = <TError = MessageWechatMessagePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof messageWechatMessagePost>>, TError,{data: MessageRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof messageWechatMessagePost>>,
        TError,
        {data: MessageRequest},
        TContext
      > => {

      const mutationOptions = getMessageWechatMessagePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Sent Callback
 */
export const sentCallbackWechatSentResultPost = (
    
 ) => {
      
      
      return sentCallbackWechatSentResultPostMutator<unknown>(
      {url: `/wechat/sentResult`, method: 'POST'
    },
      );
    }
  


export const getSentCallbackWechatSentResultPostMutationOptions = <TError = SentCallbackWechatSentResultPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sentCallbackWechatSentResultPost>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof sentCallbackWechatSentResultPost>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sentCallbackWechatSentResultPost>>, void> = () => {
          

          return  sentCallbackWechatSentResultPost()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SentCallbackWechatSentResultPostMutationResult = NonNullable<Awaited<ReturnType<typeof sentCallbackWechatSentResultPost>>>
    
    export type SentCallbackWechatSentResultPostMutationError = SentCallbackWechatSentResultPostErrorType<unknown>

    /**
 * @summary Sent Callback
 */
export const useSentCallbackWechatSentResultPost = <TError = SentCallbackWechatSentResultPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sentCallbackWechatSentResultPost>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof sentCallbackWechatSentResultPost>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getSentCallbackWechatSentResultPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Wework Callback Verify
 */
export const weworkCallbackVerifyWechatWeworkCallbackGet = (
    params: WeworkCallbackVerifyWechatWeworkCallbackGetParams,
 signal?: AbortSignal
) => {
      
      
      return weworkCallbackVerifyWechatWeworkCallbackGetMutator<unknown>(
      {url: `/wechat/wework/callback`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getWeworkCallbackVerifyWechatWeworkCallbackGetQueryKey = (params: WeworkCallbackVerifyWechatWeworkCallbackGetParams,) => {
    return [`/wechat/wework/callback`, ...(params ? [params]: [])] as const;
    }

    
export const getWeworkCallbackVerifyWechatWeworkCallbackGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, WeworkCallbackVerifyWechatWeworkCallbackGetParams['after']>, TError = WeworkCallbackVerifyWechatWeworkCallbackGetErrorType<HTTPValidationError>>(params: WeworkCallbackVerifyWechatWeworkCallbackGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError, TData, Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, QueryKey, WeworkCallbackVerifyWechatWeworkCallbackGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWeworkCallbackVerifyWechatWeworkCallbackGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, QueryKey, WeworkCallbackVerifyWechatWeworkCallbackGetParams['after']> = ({ signal, pageParam }) => weworkCallbackVerifyWechatWeworkCallbackGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError, TData, Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, QueryKey, WeworkCallbackVerifyWechatWeworkCallbackGetParams['after']> & { queryKey: QueryKey }
}

export type WeworkCallbackVerifyWechatWeworkCallbackGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>>
export type WeworkCallbackVerifyWechatWeworkCallbackGetInfiniteQueryError = WeworkCallbackVerifyWechatWeworkCallbackGetErrorType<HTTPValidationError>

/**
 * @summary Wework Callback Verify
 */
export const useWeworkCallbackVerifyWechatWeworkCallbackGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, WeworkCallbackVerifyWechatWeworkCallbackGetParams['after']>, TError = WeworkCallbackVerifyWechatWeworkCallbackGetErrorType<HTTPValidationError>>(
 params: WeworkCallbackVerifyWechatWeworkCallbackGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError, TData, Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, QueryKey, WeworkCallbackVerifyWechatWeworkCallbackGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWeworkCallbackVerifyWechatWeworkCallbackGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWeworkCallbackVerifyWechatWeworkCallbackGetQueryOptions = <TData = Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError = WeworkCallbackVerifyWechatWeworkCallbackGetErrorType<HTTPValidationError>>(params: WeworkCallbackVerifyWechatWeworkCallbackGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWeworkCallbackVerifyWechatWeworkCallbackGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>> = ({ signal }) => weworkCallbackVerifyWechatWeworkCallbackGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError, TData> & { queryKey: QueryKey }
}

export type WeworkCallbackVerifyWechatWeworkCallbackGetQueryResult = NonNullable<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>>
export type WeworkCallbackVerifyWechatWeworkCallbackGetQueryError = WeworkCallbackVerifyWechatWeworkCallbackGetErrorType<HTTPValidationError>

/**
 * @summary Wework Callback Verify
 */
export const useWeworkCallbackVerifyWechatWeworkCallbackGet = <TData = Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError = WeworkCallbackVerifyWechatWeworkCallbackGetErrorType<HTTPValidationError>>(
 params: WeworkCallbackVerifyWechatWeworkCallbackGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWeworkCallbackVerifyWechatWeworkCallbackGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWeworkCallbackVerifyWechatWeworkCallbackGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError = WeworkCallbackVerifyWechatWeworkCallbackGetErrorType<HTTPValidationError>>(params: WeworkCallbackVerifyWechatWeworkCallbackGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWeworkCallbackVerifyWechatWeworkCallbackGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>> = ({ signal }) => weworkCallbackVerifyWechatWeworkCallbackGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError, TData> & { queryKey: QueryKey }
}

export type WeworkCallbackVerifyWechatWeworkCallbackGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>>
export type WeworkCallbackVerifyWechatWeworkCallbackGetSuspenseQueryError = WeworkCallbackVerifyWechatWeworkCallbackGetErrorType<HTTPValidationError>

/**
 * @summary Wework Callback Verify
 */
export const useWeworkCallbackVerifyWechatWeworkCallbackGetSuspense = <TData = Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError = WeworkCallbackVerifyWechatWeworkCallbackGetErrorType<HTTPValidationError>>(
 params: WeworkCallbackVerifyWechatWeworkCallbackGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof weworkCallbackVerifyWechatWeworkCallbackGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWeworkCallbackVerifyWechatWeworkCallbackGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Wework Callback
 */
export const weworkCallbackWechatWeworkCallbackPost = (
    params: WeworkCallbackWechatWeworkCallbackPostParams,
 ) => {
      
      
      return weworkCallbackWechatWeworkCallbackPostMutator<unknown>(
      {url: `/wechat/wework/callback`, method: 'POST',
        params
    },
      );
    }
  


export const getWeworkCallbackWechatWeworkCallbackPostMutationOptions = <TError = WeworkCallbackWechatWeworkCallbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof weworkCallbackWechatWeworkCallbackPost>>, TError,{params: WeworkCallbackWechatWeworkCallbackPostParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof weworkCallbackWechatWeworkCallbackPost>>, TError,{params: WeworkCallbackWechatWeworkCallbackPostParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof weworkCallbackWechatWeworkCallbackPost>>, {params: WeworkCallbackWechatWeworkCallbackPostParams}> = (props) => {
          const {params} = props ?? {};

          return  weworkCallbackWechatWeworkCallbackPost(params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WeworkCallbackWechatWeworkCallbackPostMutationResult = NonNullable<Awaited<ReturnType<typeof weworkCallbackWechatWeworkCallbackPost>>>
    
    export type WeworkCallbackWechatWeworkCallbackPostMutationError = WeworkCallbackWechatWeworkCallbackPostErrorType<HTTPValidationError>

    /**
 * @summary Wework Callback
 */
export const useWeworkCallbackWechatWeworkCallbackPost = <TError = WeworkCallbackWechatWeworkCallbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof weworkCallbackWechatWeworkCallbackPost>>, TError,{params: WeworkCallbackWechatWeworkCallbackPostParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof weworkCallbackWechatWeworkCallbackPost>>,
        TError,
        {params: WeworkCallbackWechatWeworkCallbackPostParams},
        TContext
      > => {

      const mutationOptions = getWeworkCallbackWechatWeworkCallbackPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Wechat Auth
 */
export const wechatAuthWechatAuthPost = (
    authPayload: AuthPayload,
 ) => {
      
      
      return wechatAuthWechatAuthPostMutator<AuthResponse>(
      {url: `/wechat/auth`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: authPayload
    },
      );
    }
  


export const getWechatAuthWechatAuthPostMutationOptions = <TError = WechatAuthWechatAuthPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wechatAuthWechatAuthPost>>, TError,{data: AuthPayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof wechatAuthWechatAuthPost>>, TError,{data: AuthPayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof wechatAuthWechatAuthPost>>, {data: AuthPayload}> = (props) => {
          const {data} = props ?? {};

          return  wechatAuthWechatAuthPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WechatAuthWechatAuthPostMutationResult = NonNullable<Awaited<ReturnType<typeof wechatAuthWechatAuthPost>>>
    export type WechatAuthWechatAuthPostMutationBody = AuthPayload
    export type WechatAuthWechatAuthPostMutationError = WechatAuthWechatAuthPostErrorType<HTTPValidationError>

    /**
 * @summary Wechat Auth
 */
export const useWechatAuthWechatAuthPost = <TError = WechatAuthWechatAuthPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wechatAuthWechatAuthPost>>, TError,{data: AuthPayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof wechatAuthWechatAuthPost>>,
        TError,
        {data: AuthPayload},
        TContext
      > => {

      const mutationOptions = getWechatAuthWechatAuthPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Wechat Auth Test
 */
export const wechatAuthTestWechatAuthTestPost = (
    authPayload: AuthPayload,
 ) => {
      
      
      return wechatAuthTestWechatAuthTestPostMutator<AuthResponse>(
      {url: `/wechat/auth-test`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: authPayload
    },
      );
    }
  


export const getWechatAuthTestWechatAuthTestPostMutationOptions = <TError = WechatAuthTestWechatAuthTestPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wechatAuthTestWechatAuthTestPost>>, TError,{data: AuthPayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof wechatAuthTestWechatAuthTestPost>>, TError,{data: AuthPayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof wechatAuthTestWechatAuthTestPost>>, {data: AuthPayload}> = (props) => {
          const {data} = props ?? {};

          return  wechatAuthTestWechatAuthTestPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WechatAuthTestWechatAuthTestPostMutationResult = NonNullable<Awaited<ReturnType<typeof wechatAuthTestWechatAuthTestPost>>>
    export type WechatAuthTestWechatAuthTestPostMutationBody = AuthPayload
    export type WechatAuthTestWechatAuthTestPostMutationError = WechatAuthTestWechatAuthTestPostErrorType<HTTPValidationError>

    /**
 * @summary Wechat Auth Test
 */
export const useWechatAuthTestWechatAuthTestPost = <TError = WechatAuthTestWechatAuthTestPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wechatAuthTestWechatAuthTestPost>>, TError,{data: AuthPayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof wechatAuthTestWechatAuthTestPost>>,
        TError,
        {data: AuthPayload},
        TContext
      > => {

      const mutationOptions = getWechatAuthTestWechatAuthTestPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Wechat Mp Auth
 */
export const wechatMpAuthWechatMpAuthPost = (
    authPayload: AuthPayload,
 ) => {
      
      
      return wechatMpAuthWechatMpAuthPostMutator<AuthResponse>(
      {url: `/wechat/mp-auth`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: authPayload
    },
      );
    }
  


export const getWechatMpAuthWechatMpAuthPostMutationOptions = <TError = WechatMpAuthWechatMpAuthPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wechatMpAuthWechatMpAuthPost>>, TError,{data: AuthPayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof wechatMpAuthWechatMpAuthPost>>, TError,{data: AuthPayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof wechatMpAuthWechatMpAuthPost>>, {data: AuthPayload}> = (props) => {
          const {data} = props ?? {};

          return  wechatMpAuthWechatMpAuthPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WechatMpAuthWechatMpAuthPostMutationResult = NonNullable<Awaited<ReturnType<typeof wechatMpAuthWechatMpAuthPost>>>
    export type WechatMpAuthWechatMpAuthPostMutationBody = AuthPayload
    export type WechatMpAuthWechatMpAuthPostMutationError = WechatMpAuthWechatMpAuthPostErrorType<HTTPValidationError>

    /**
 * @summary Wechat Mp Auth
 */
export const useWechatMpAuthWechatMpAuthPost = <TError = WechatMpAuthWechatMpAuthPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof wechatMpAuthWechatMpAuthPost>>, TError,{data: AuthPayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof wechatMpAuthWechatMpAuthPost>>,
        TError,
        {data: AuthPayload},
        TContext
      > => {

      const mutationOptions = getWechatMpAuthWechatMpAuthPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Xfyun Raasr Callback
 */
export const xfyunRaasrCallbackHookXfyunRaasrGet = (
    params: XfyunRaasrCallbackHookXfyunRaasrGetParams,
 signal?: AbortSignal
) => {
      
      
      return xfyunRaasrCallbackHookXfyunRaasrGetMutator<unknown>(
      {url: `/hook/xfyun/raasr`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getXfyunRaasrCallbackHookXfyunRaasrGetQueryKey = (params: XfyunRaasrCallbackHookXfyunRaasrGetParams,) => {
    return [`/hook/xfyun/raasr`, ...(params ? [params]: [])] as const;
    }

    
export const getXfyunRaasrCallbackHookXfyunRaasrGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, XfyunRaasrCallbackHookXfyunRaasrGetParams['after']>, TError = XfyunRaasrCallbackHookXfyunRaasrGetErrorType<HTTPValidationError>>(params: XfyunRaasrCallbackHookXfyunRaasrGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError, TData, Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, QueryKey, XfyunRaasrCallbackHookXfyunRaasrGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getXfyunRaasrCallbackHookXfyunRaasrGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, QueryKey, XfyunRaasrCallbackHookXfyunRaasrGetParams['after']> = ({ signal, pageParam }) => xfyunRaasrCallbackHookXfyunRaasrGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError, TData, Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, QueryKey, XfyunRaasrCallbackHookXfyunRaasrGetParams['after']> & { queryKey: QueryKey }
}

export type XfyunRaasrCallbackHookXfyunRaasrGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>>
export type XfyunRaasrCallbackHookXfyunRaasrGetInfiniteQueryError = XfyunRaasrCallbackHookXfyunRaasrGetErrorType<HTTPValidationError>

/**
 * @summary Xfyun Raasr Callback
 */
export const useXfyunRaasrCallbackHookXfyunRaasrGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, XfyunRaasrCallbackHookXfyunRaasrGetParams['after']>, TError = XfyunRaasrCallbackHookXfyunRaasrGetErrorType<HTTPValidationError>>(
 params: XfyunRaasrCallbackHookXfyunRaasrGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError, TData, Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, QueryKey, XfyunRaasrCallbackHookXfyunRaasrGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getXfyunRaasrCallbackHookXfyunRaasrGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getXfyunRaasrCallbackHookXfyunRaasrGetQueryOptions = <TData = Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError = XfyunRaasrCallbackHookXfyunRaasrGetErrorType<HTTPValidationError>>(params: XfyunRaasrCallbackHookXfyunRaasrGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getXfyunRaasrCallbackHookXfyunRaasrGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>> = ({ signal }) => xfyunRaasrCallbackHookXfyunRaasrGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError, TData> & { queryKey: QueryKey }
}

export type XfyunRaasrCallbackHookXfyunRaasrGetQueryResult = NonNullable<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>>
export type XfyunRaasrCallbackHookXfyunRaasrGetQueryError = XfyunRaasrCallbackHookXfyunRaasrGetErrorType<HTTPValidationError>

/**
 * @summary Xfyun Raasr Callback
 */
export const useXfyunRaasrCallbackHookXfyunRaasrGet = <TData = Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError = XfyunRaasrCallbackHookXfyunRaasrGetErrorType<HTTPValidationError>>(
 params: XfyunRaasrCallbackHookXfyunRaasrGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getXfyunRaasrCallbackHookXfyunRaasrGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getXfyunRaasrCallbackHookXfyunRaasrGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError = XfyunRaasrCallbackHookXfyunRaasrGetErrorType<HTTPValidationError>>(params: XfyunRaasrCallbackHookXfyunRaasrGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getXfyunRaasrCallbackHookXfyunRaasrGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>> = ({ signal }) => xfyunRaasrCallbackHookXfyunRaasrGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError, TData> & { queryKey: QueryKey }
}

export type XfyunRaasrCallbackHookXfyunRaasrGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>>
export type XfyunRaasrCallbackHookXfyunRaasrGetSuspenseQueryError = XfyunRaasrCallbackHookXfyunRaasrGetErrorType<HTTPValidationError>

/**
 * @summary Xfyun Raasr Callback
 */
export const useXfyunRaasrCallbackHookXfyunRaasrGetSuspense = <TData = Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError = XfyunRaasrCallbackHookXfyunRaasrGetErrorType<HTTPValidationError>>(
 params: XfyunRaasrCallbackHookXfyunRaasrGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof xfyunRaasrCallbackHookXfyunRaasrGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getXfyunRaasrCallbackHookXfyunRaasrGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * details: https://developers.google.com/calendar/api/guides/push
 * @summary Google Calendar Channel Callback
 */
export const googleCalendarChannelCallbackHookGoogleCalendarChannelPost = (
    
 ) => {
      
      
      return googleCalendarChannelCallbackHookGoogleCalendarChannelPostMutator<unknown>(
      {url: `/hook/google-calendar/channel`, method: 'POST'
    },
      );
    }
  


export const getGoogleCalendarChannelCallbackHookGoogleCalendarChannelPostMutationOptions = <TError = GoogleCalendarChannelCallbackHookGoogleCalendarChannelPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof googleCalendarChannelCallbackHookGoogleCalendarChannelPost>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof googleCalendarChannelCallbackHookGoogleCalendarChannelPost>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof googleCalendarChannelCallbackHookGoogleCalendarChannelPost>>, void> = () => {
          

          return  googleCalendarChannelCallbackHookGoogleCalendarChannelPost()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GoogleCalendarChannelCallbackHookGoogleCalendarChannelPostMutationResult = NonNullable<Awaited<ReturnType<typeof googleCalendarChannelCallbackHookGoogleCalendarChannelPost>>>
    
    export type GoogleCalendarChannelCallbackHookGoogleCalendarChannelPostMutationError = GoogleCalendarChannelCallbackHookGoogleCalendarChannelPostErrorType<unknown>

    /**
 * @summary Google Calendar Channel Callback
 */
export const useGoogleCalendarChannelCallbackHookGoogleCalendarChannelPost = <TError = GoogleCalendarChannelCallbackHookGoogleCalendarChannelPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof googleCalendarChannelCallbackHookGoogleCalendarChannelPost>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof googleCalendarChannelCallbackHookGoogleCalendarChannelPost>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getGoogleCalendarChannelCallbackHookGoogleCalendarChannelPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Stripe Webhook
 */
export const stripeWebhookHookStripeWebhookPost = (
    
 ) => {
      
      
      return stripeWebhookHookStripeWebhookPostMutator<unknown>(
      {url: `/hook/stripe/webhook`, method: 'POST'
    },
      );
    }
  


export const getStripeWebhookHookStripeWebhookPostMutationOptions = <TError = StripeWebhookHookStripeWebhookPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof stripeWebhookHookStripeWebhookPost>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof stripeWebhookHookStripeWebhookPost>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof stripeWebhookHookStripeWebhookPost>>, void> = () => {
          

          return  stripeWebhookHookStripeWebhookPost()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type StripeWebhookHookStripeWebhookPostMutationResult = NonNullable<Awaited<ReturnType<typeof stripeWebhookHookStripeWebhookPost>>>
    
    export type StripeWebhookHookStripeWebhookPostMutationError = StripeWebhookHookStripeWebhookPostErrorType<HTTPValidationError>

    /**
 * @summary Stripe Webhook
 */
export const useStripeWebhookHookStripeWebhookPost = <TError = StripeWebhookHookStripeWebhookPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof stripeWebhookHookStripeWebhookPost>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof stripeWebhookHookStripeWebhookPost>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getStripeWebhookHookStripeWebhookPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Callback
 */
export const callbackLineCallbackPost = (
    
 ) => {
      
      
      return callbackLineCallbackPostMutator<unknown>(
      {url: `/line/callback`, method: 'POST'
    },
      );
    }
  


export const getCallbackLineCallbackPostMutationOptions = <TError = CallbackLineCallbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof callbackLineCallbackPost>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof callbackLineCallbackPost>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof callbackLineCallbackPost>>, void> = () => {
          

          return  callbackLineCallbackPost()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CallbackLineCallbackPostMutationResult = NonNullable<Awaited<ReturnType<typeof callbackLineCallbackPost>>>
    
    export type CallbackLineCallbackPostMutationError = CallbackLineCallbackPostErrorType<HTTPValidationError>

    /**
 * @summary Callback
 */
export const useCallbackLineCallbackPost = <TError = CallbackLineCallbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof callbackLineCallbackPost>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof callbackLineCallbackPost>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getCallbackLineCallbackPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Login With Id Token
 */
export const loginWithIdTokenLineLoginWithIdTokenPost = (
    loginWithIDTokenRequest: LoginWithIDTokenRequest,
 ) => {
      
      
      return loginWithIdTokenLineLoginWithIdTokenPostMutator<LoginWithIDTokenResponse>(
      {url: `/line/login_with_id_token`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: loginWithIDTokenRequest
    },
      );
    }
  


export const getLoginWithIdTokenLineLoginWithIdTokenPostMutationOptions = <TError = LoginWithIdTokenLineLoginWithIdTokenPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginWithIdTokenLineLoginWithIdTokenPost>>, TError,{data: LoginWithIDTokenRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof loginWithIdTokenLineLoginWithIdTokenPost>>, TError,{data: LoginWithIDTokenRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginWithIdTokenLineLoginWithIdTokenPost>>, {data: LoginWithIDTokenRequest}> = (props) => {
          const {data} = props ?? {};

          return  loginWithIdTokenLineLoginWithIdTokenPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LoginWithIdTokenLineLoginWithIdTokenPostMutationResult = NonNullable<Awaited<ReturnType<typeof loginWithIdTokenLineLoginWithIdTokenPost>>>
    export type LoginWithIdTokenLineLoginWithIdTokenPostMutationBody = LoginWithIDTokenRequest
    export type LoginWithIdTokenLineLoginWithIdTokenPostMutationError = LoginWithIdTokenLineLoginWithIdTokenPostErrorType<HTTPValidationError>

    /**
 * @summary Login With Id Token
 */
export const useLoginWithIdTokenLineLoginWithIdTokenPost = <TError = LoginWithIdTokenLineLoginWithIdTokenPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginWithIdTokenLineLoginWithIdTokenPost>>, TError,{data: LoginWithIDTokenRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof loginWithIdTokenLineLoginWithIdTokenPost>>,
        TError,
        {data: LoginWithIDTokenRequest},
        TContext
      > => {

      const mutationOptions = getLoginWithIdTokenLineLoginWithIdTokenPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Callback
 */
export const callbackWhatsappCallbackPost = (
    payload: Payload,
 ) => {
      
      
      return callbackWhatsappCallbackPostMutator<unknown>(
      {url: `/whatsapp/callback`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: payload
    },
      );
    }
  


export const getCallbackWhatsappCallbackPostMutationOptions = <TError = CallbackWhatsappCallbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof callbackWhatsappCallbackPost>>, TError,{data: Payload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof callbackWhatsappCallbackPost>>, TError,{data: Payload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof callbackWhatsappCallbackPost>>, {data: Payload}> = (props) => {
          const {data} = props ?? {};

          return  callbackWhatsappCallbackPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CallbackWhatsappCallbackPostMutationResult = NonNullable<Awaited<ReturnType<typeof callbackWhatsappCallbackPost>>>
    export type CallbackWhatsappCallbackPostMutationBody = Payload
    export type CallbackWhatsappCallbackPostMutationError = CallbackWhatsappCallbackPostErrorType<HTTPValidationError>

    /**
 * @summary Callback
 */
export const useCallbackWhatsappCallbackPost = <TError = CallbackWhatsappCallbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof callbackWhatsappCallbackPost>>, TError,{data: Payload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof callbackWhatsappCallbackPost>>,
        TError,
        {data: Payload},
        TContext
      > => {

      const mutationOptions = getCallbackWhatsappCallbackPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Callback
 */
export const callbackWhatsappTwilioCallbackPost = (
    
 ) => {
      
      
      return callbackWhatsappTwilioCallbackPostMutator<unknown>(
      {url: `/whatsapp_twilio/callback`, method: 'POST'
    },
      );
    }
  


export const getCallbackWhatsappTwilioCallbackPostMutationOptions = <TError = CallbackWhatsappTwilioCallbackPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof callbackWhatsappTwilioCallbackPost>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof callbackWhatsappTwilioCallbackPost>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof callbackWhatsappTwilioCallbackPost>>, void> = () => {
          

          return  callbackWhatsappTwilioCallbackPost()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CallbackWhatsappTwilioCallbackPostMutationResult = NonNullable<Awaited<ReturnType<typeof callbackWhatsappTwilioCallbackPost>>>
    
    export type CallbackWhatsappTwilioCallbackPostMutationError = CallbackWhatsappTwilioCallbackPostErrorType<unknown>

    /**
 * @summary Callback
 */
export const useCallbackWhatsappTwilioCallbackPost = <TError = CallbackWhatsappTwilioCallbackPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof callbackWhatsappTwilioCallbackPost>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof callbackWhatsappTwilioCallbackPost>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getCallbackWhatsappTwilioCallbackPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Text:

{
    "webhook_id": "f9c42b20-8c38-47e7-bacb-0e2bf55de6a3",
    "secret_key": "l6QlR4VPDQMboGEFmzZDNDIcIWiUMWaP",
    "action": "incoming_event",
    "organization_id": "8f17c544-da91-478d-b084-8e99219404de",
    "payload": {
        "chat_id": "S413UDHPW4",
        "thread_id": "S423OLFODU",
        "event": {
            "id": "S423OLFODU_9",
            "custom_id": "",
            "visibility": "all",
            "created_at": "2023-11-14T05:52:02.992000Z",
            "author_id": "65412e8c-7017-48cf-bdcf-024203e2c0e4",
            "properties": {
                "source": {"client_id": "22cc83ef7905d174b6a0eccb9ce404c0"}
            },
            "type": "message",
            "text": "Asdfasdf",
        },
    },
    "additional_data": {},
}

Audio:
{
    "webhook_id": "f9c42b20-8c38-47e7-bacb-0e2bf55de6a3",
    "secret_key": "l6QlR4VPDQMboGEFmzZDNDIcIWiUMWaP",
    "action": "incoming_event",
    "organization_id": "8f17c544-da91-478d-b084-8e99219404de",
    "payload": {
        "chat_id": "S413UDHPW4",
        "thread_id": "S423OLFODU",
        "event": {
            "id": "S423OLFODU_10",
            "custom_id": "",
            "visibility": "all",
            "created_at": "2023-11-14T05:58:40.369000Z",
            "author_id": "65412e8c-7017-48cf-bdcf-024203e2c0e4",
            "properties": {
                "source": {"client_id": "22cc83ef7905d174b6a0eccb9ce404c0"}
            },
            "type": "file",
            "name": "Audio Message.amr",
            "url": "https://cdn.livechat-files.com/api/file/lc/att/16590480/1ec3d101dddbbf5215ab820acdf4385c/Audio%20Message.amr",
            "content_type": "audio/AMR",
            "size": 2796,
        },
    },
    "additional_data": {},
}

Image:
{
    "webhook_id": "f9c42b20-8c38-47e7-bacb-0e2bf55de6a3",
    "secret_key": "l6QlR4VPDQMboGEFmzZDNDIcIWiUMWaP",
    "action": "incoming_event",
    "organization_id": "8f17c544-da91-478d-b084-8e99219404de",
    "payload": {
        "chat_id": "S413UDHPW4",
        "thread_id": "S423OLFODU",
        "event": {
            "id": "S423OLFODU_11",
            "custom_id": "",
            "visibility": "all",
            "created_at": "2023-11-14T06:03:52.306000Z",
            "author_id": "65412e8c-7017-48cf-bdcf-024203e2c0e4",
            "properties": {
                "source": {"client_id": "22cc83ef7905d174b6a0eccb9ce404c0"}
            },
            "type": "file",
            "name": "IMG_6918.jpeg",
            "url": "https://cdn.livechat-files.com/api/file/lc/att/16590480/999a766690ec7903834cd3d7683de5d7/IMG_6918.jpeg",
            "content_type": "image/jpeg",
            "size": 20509,
            "height": 232,
            "width": 416,
            "thumbnail_url": "https://cdn.livechat-files.com/api/file/lc/att/16590480/999a766690ec7903834cd3d7683de5d7/IMG_6918.jpeg?impolicy=thumbnail",
            "thumbnail2x_url": "https://cdn.livechat-files.com/api/file/lc/att/16590480/999a766690ec7903834cd3d7683de5d7/IMG_6918.jpeg?impolicy=thumbnail2x",
        },
    },
    "additional_data": {},
}

Incoming chat:
{
    "webhook_id": "9e1899c9-818f-47f8-bb37-5cfefce08d05",
    "secret_key": "l6QlR4VPDQMboGEFmzZDNDIcIWiUMWaP",
    "action": "incoming_chat",
    "organization_id": "8f17c544-da91-478d-b084-8e99219404de",
    "payload": {
        "chat": {
            "id": "S413UDHPW4",
            "users": [
                {
                    "id": "65412e8c-7017-48cf-bdcf-024203e2c0e4",
                    "name": "Customer #65412E8C-701",
                    "email": "",
                    "present": True,
                    "last_seen_timestamp": 1699950390,
                    "type": "customer",
                    "session_fields": [
                        {"Capabilities": "AUTH,LIST,TIME,QUICK,AUTH2"},
                        {"Device": ""},
                    ],
                    "created_at": "2023-11-14T0 2:31:51.507769Z",
                    "statistics": {
                        "visits_count": 0,
                        "threads_count": 5,
                        "chats_count": 1,
                        "page_views_count": 0,
                        "greetings_shown_count": 0,
                        "greetings_accepted_count": 0,
                    },
                    "agent_last_event_created_at": "1970-01-01T00:00:00.000000Z",
                    "customer_last_event_created_at": "2023-11-14T08:26:30.964003Z",
                },
                {
                    "id": "haochuan.guo@liehu.ai",
                    "name": "Haochuan Guo",
                    "email": "haochuan.guo@liehu.ai",
                    "present": True,
                    "last_seen_timestamp": 1699948290,
                    "type": "agent",
                    "avatar": "https://cdn.livechat-static.com/api/file/lc/img/default/avatars/394ad6e3df2320941ab8e0ca147d5221.png",
                    "routing_status": "accepting_chats",
                    "visibility": "all",
                },
                {
                    "id": "7403652b86f93409a66dcc0497edb79f",
                    "name": "Orion",
                    "email": "7403652b86f93409a66dcc0497edb79f",
                    "present": False,
                    "last_seen_timestamp": 1699940365,
                    "type": "agent",
                    "avatar": "https://cdn.livechat-static.com/api/file/lc/img/default/avatars/394ad6e3df2320941ab8e0ca147d5221.png",
                    "routing_status": "offline",
                    "visibility": "all",
                },
            ],
            "thread": {
                "id": "S433KU74V6",
                "created_at": "2023-11-14T08:26:30.964000Z",
                "active": True,
                "properties": {
                    "routing": {
                        "continuous": True,
                        "group_status_at_start": "online",
                        "idle": False,
                        "pinned": True,
                        "unassigned": False,
                    },
                    "source": {
                        "client_id": "22cc83ef7905d174b6a0eccb9ce404c0",
                        "customer_client_id": "22cc83ef7905d174b6a0eccb9ce404c0",
                    },
                },
                "user_ids": [
                    "65412e8c-7017-48cf-bdcf-024203e2c0e4",
                    "haochuan.guo@liehu.ai",
                ],
                "events": [
                    {
                        "id": "S433KU74V6_1",
                        "custom_id": "",
                        "visibility": "all",
                        "created_at": "2023-11-14T08:26:30.964003Z",
                        "author_id": "65412e8c-7017-48cf-bdcf-024203e2c0e4",
                        "properties": {
                            "source": {
                                "client_id": "22cc83ef7905d174b6a0eccb9ce404c0"
                            }
                        },
                        "type": "message",
                        "text": "Hi",
                    }
                ],
                "access": {"group_ids": [0]},
                "previous_thread_id": "S433CSQ8WJ",
            },
            "properties": {
                "routing": {
                    "continuous": True,
                    "pinned": True,
                    "was_pinned": False,
                },
                "source": {
                    "client_id": "22cc83ef7905d174b6a0eccb9ce404c0",
                    "customer_client_id": "22cc83ef7905d174b6a0eccb9ce404c0",
                },
                "supervising": {"agent_ids": ""},
            },
            "access": {"group_ids": [0]},
        }
    },
    "additional_data": {},
}

Incoming Rich Message Postback
{
    "webhook_id": "b1923a00-ff3d-4ff6-9dba-ff9f94947c78",
    "secret_key": "******",
    "action": "incoming_rich_message_postback",
    "organization_id": "8f17c544-da91-478d-b084-8e99219404de",
    "payload": {
        "user_id": "cdd3c4e0-7709-4e6e-b1b3-d5252da2d89b",
        "chat_id": "S8CHMAAK82",
        "thread_id": "SEU8QSAIN2",
        "event_id": "SEU8QSAIN2_7",
        "postback": {
            "id": "test_1234",
            "toggled": true
        }
    },
    "additional_data": {}
}
 * @summary Callback
 */
export const callbackImessageCallbackLivechatPost = (
    
 ) => {
      
      
      return callbackImessageCallbackLivechatPostMutator<unknown>(
      {url: `/imessage/callback/livechat`, method: 'POST'
    },
      );
    }
  


export const getCallbackImessageCallbackLivechatPostMutationOptions = <TError = CallbackImessageCallbackLivechatPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof callbackImessageCallbackLivechatPost>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof callbackImessageCallbackLivechatPost>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof callbackImessageCallbackLivechatPost>>, void> = () => {
          

          return  callbackImessageCallbackLivechatPost()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CallbackImessageCallbackLivechatPostMutationResult = NonNullable<Awaited<ReturnType<typeof callbackImessageCallbackLivechatPost>>>
    
    export type CallbackImessageCallbackLivechatPostMutationError = CallbackImessageCallbackLivechatPostErrorType<unknown>

    /**
 * @summary Callback
 */
export const useCallbackImessageCallbackLivechatPost = <TError = CallbackImessageCallbackLivechatPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof callbackImessageCallbackLivechatPost>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof callbackImessageCallbackLivechatPost>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getCallbackImessageCallbackLivechatPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Lark Ops Command
 */
export const larkOpsCommandOpsLarkHookPost = (
    
 ) => {
      
      
      return larkOpsCommandOpsLarkHookPostMutator<unknown>(
      {url: `/ops/lark_hook`, method: 'POST'
    },
      );
    }
  


export const getLarkOpsCommandOpsLarkHookPostMutationOptions = <TError = LarkOpsCommandOpsLarkHookPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof larkOpsCommandOpsLarkHookPost>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof larkOpsCommandOpsLarkHookPost>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof larkOpsCommandOpsLarkHookPost>>, void> = () => {
          

          return  larkOpsCommandOpsLarkHookPost()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LarkOpsCommandOpsLarkHookPostMutationResult = NonNullable<Awaited<ReturnType<typeof larkOpsCommandOpsLarkHookPost>>>
    
    export type LarkOpsCommandOpsLarkHookPostMutationError = LarkOpsCommandOpsLarkHookPostErrorType<unknown>

    /**
 * @summary Lark Ops Command
 */
export const useLarkOpsCommandOpsLarkHookPost = <TError = LarkOpsCommandOpsLarkHookPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof larkOpsCommandOpsLarkHookPost>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof larkOpsCommandOpsLarkHookPost>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getLarkOpsCommandOpsLarkHookPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Auth
 */
export const authTelegramAuthPost = (
    initDataValidationRequest: InitDataValidationRequest,
 ) => {
      
      
      return authTelegramAuthPostMutator<Token>(
      {url: `/telegram/auth`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: initDataValidationRequest
    },
      );
    }
  


export const getAuthTelegramAuthPostMutationOptions = <TError = AuthTelegramAuthPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authTelegramAuthPost>>, TError,{data: InitDataValidationRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof authTelegramAuthPost>>, TError,{data: InitDataValidationRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authTelegramAuthPost>>, {data: InitDataValidationRequest}> = (props) => {
          const {data} = props ?? {};

          return  authTelegramAuthPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthTelegramAuthPostMutationResult = NonNullable<Awaited<ReturnType<typeof authTelegramAuthPost>>>
    export type AuthTelegramAuthPostMutationBody = InitDataValidationRequest
    export type AuthTelegramAuthPostMutationError = AuthTelegramAuthPostErrorType<HTTPValidationError>

    /**
 * @summary Auth
 */
export const useAuthTelegramAuthPost = <TError = AuthTelegramAuthPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authTelegramAuthPost>>, TError,{data: InitDataValidationRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof authTelegramAuthPost>>,
        TError,
        {data: InitDataValidationRequest},
        TContext
      > => {

      const mutationOptions = getAuthTelegramAuthPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Callback
 */
export const callbackTelegramCallbackTokenPost = (
    { token }: CallbackTelegramCallbackTokenPostPathParameters,
 ) => {
      
      
      return callbackTelegramCallbackTokenPostMutator<unknown>(
      {url: `/telegram/callback/${token}`, method: 'POST'
    },
      );
    }
  


export const getCallbackTelegramCallbackTokenPostMutationOptions = <TError = CallbackTelegramCallbackTokenPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof callbackTelegramCallbackTokenPost>>, TError,{pathParams: CallbackTelegramCallbackTokenPostPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof callbackTelegramCallbackTokenPost>>, TError,{pathParams: CallbackTelegramCallbackTokenPostPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof callbackTelegramCallbackTokenPost>>, {pathParams: CallbackTelegramCallbackTokenPostPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  callbackTelegramCallbackTokenPost(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CallbackTelegramCallbackTokenPostMutationResult = NonNullable<Awaited<ReturnType<typeof callbackTelegramCallbackTokenPost>>>
    
    export type CallbackTelegramCallbackTokenPostMutationError = CallbackTelegramCallbackTokenPostErrorType<HTTPValidationError>

    /**
 * @summary Callback
 */
export const useCallbackTelegramCallbackTokenPost = <TError = CallbackTelegramCallbackTokenPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof callbackTelegramCallbackTokenPost>>, TError,{pathParams: CallbackTelegramCallbackTokenPostPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof callbackTelegramCallbackTokenPost>>,
        TError,
        {pathParams: CallbackTelegramCallbackTokenPostPathParameters},
        TContext
      > => {

      const mutationOptions = getCallbackTelegramCallbackTokenPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Oauth Login
 */
export const oauthLoginOauthLoginProviderNameGet = (
    { providerName }: OauthLoginOauthLoginProviderNameGetPathParameters,
    params: OauthLoginOauthLoginProviderNameGetParams,
 signal?: AbortSignal
) => {
      
      
      return oauthLoginOauthLoginProviderNameGetMutator<unknown>(
      {url: `/oauth/login/${providerName}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getOauthLoginOauthLoginProviderNameGetQueryKey = ({ providerName }: OauthLoginOauthLoginProviderNameGetPathParameters,
    params: OauthLoginOauthLoginProviderNameGetParams,) => {
    return [`/oauth/login/${providerName}`, ...(params ? [params]: [])] as const;
    }

    
export const getOauthLoginOauthLoginProviderNameGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, OauthLoginOauthLoginProviderNameGetParams['after']>, TError = OauthLoginOauthLoginProviderNameGetErrorType<HTTPValidationError>>({ providerName }: OauthLoginOauthLoginProviderNameGetPathParameters,
    params: OauthLoginOauthLoginProviderNameGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError, TData, Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, QueryKey, OauthLoginOauthLoginProviderNameGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOauthLoginOauthLoginProviderNameGetQueryKey({ providerName },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, QueryKey, OauthLoginOauthLoginProviderNameGetParams['after']> = ({ signal, pageParam }) => oauthLoginOauthLoginProviderNameGet({ providerName },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError, TData, Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, QueryKey, OauthLoginOauthLoginProviderNameGetParams['after']> & { queryKey: QueryKey }
}

export type OauthLoginOauthLoginProviderNameGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>>
export type OauthLoginOauthLoginProviderNameGetInfiniteQueryError = OauthLoginOauthLoginProviderNameGetErrorType<HTTPValidationError>

/**
 * @summary Oauth Login
 */
export const useOauthLoginOauthLoginProviderNameGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, OauthLoginOauthLoginProviderNameGetParams['after']>, TError = OauthLoginOauthLoginProviderNameGetErrorType<HTTPValidationError>>(
 { providerName }: OauthLoginOauthLoginProviderNameGetPathParameters,
    params: OauthLoginOauthLoginProviderNameGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError, TData, Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, QueryKey, OauthLoginOauthLoginProviderNameGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOauthLoginOauthLoginProviderNameGetInfiniteQueryOptions({ providerName },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOauthLoginOauthLoginProviderNameGetQueryOptions = <TData = Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError = OauthLoginOauthLoginProviderNameGetErrorType<HTTPValidationError>>({ providerName }: OauthLoginOauthLoginProviderNameGetPathParameters,
    params: OauthLoginOauthLoginProviderNameGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOauthLoginOauthLoginProviderNameGetQueryKey({ providerName },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>> = ({ signal }) => oauthLoginOauthLoginProviderNameGet({ providerName },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError, TData> & { queryKey: QueryKey }
}

export type OauthLoginOauthLoginProviderNameGetQueryResult = NonNullable<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>>
export type OauthLoginOauthLoginProviderNameGetQueryError = OauthLoginOauthLoginProviderNameGetErrorType<HTTPValidationError>

/**
 * @summary Oauth Login
 */
export const useOauthLoginOauthLoginProviderNameGet = <TData = Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError = OauthLoginOauthLoginProviderNameGetErrorType<HTTPValidationError>>(
 { providerName }: OauthLoginOauthLoginProviderNameGetPathParameters,
    params: OauthLoginOauthLoginProviderNameGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOauthLoginOauthLoginProviderNameGetQueryOptions({ providerName },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOauthLoginOauthLoginProviderNameGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError = OauthLoginOauthLoginProviderNameGetErrorType<HTTPValidationError>>({ providerName }: OauthLoginOauthLoginProviderNameGetPathParameters,
    params: OauthLoginOauthLoginProviderNameGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOauthLoginOauthLoginProviderNameGetQueryKey({ providerName },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>> = ({ signal }) => oauthLoginOauthLoginProviderNameGet({ providerName },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError, TData> & { queryKey: QueryKey }
}

export type OauthLoginOauthLoginProviderNameGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>>
export type OauthLoginOauthLoginProviderNameGetSuspenseQueryError = OauthLoginOauthLoginProviderNameGetErrorType<HTTPValidationError>

/**
 * @summary Oauth Login
 */
export const useOauthLoginOauthLoginProviderNameGetSuspense = <TData = Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError = OauthLoginOauthLoginProviderNameGetErrorType<HTTPValidationError>>(
 { providerName }: OauthLoginOauthLoginProviderNameGetPathParameters,
    params: OauthLoginOauthLoginProviderNameGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof oauthLoginOauthLoginProviderNameGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOauthLoginOauthLoginProviderNameGetSuspenseQueryOptions({ providerName },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Oauth Callback
 */
export const oauthCallbackOauthCallbackProviderNameGet = (
    { providerName }: OauthCallbackOauthCallbackProviderNameGetPathParameters,
    params: OauthCallbackOauthCallbackProviderNameGetParams,
 signal?: AbortSignal
) => {
      
      
      return oauthCallbackOauthCallbackProviderNameGetMutator<unknown>(
      {url: `/oauth/callback/${providerName}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getOauthCallbackOauthCallbackProviderNameGetQueryKey = ({ providerName }: OauthCallbackOauthCallbackProviderNameGetPathParameters,
    params: OauthCallbackOauthCallbackProviderNameGetParams,) => {
    return [`/oauth/callback/${providerName}`, ...(params ? [params]: [])] as const;
    }

    
export const getOauthCallbackOauthCallbackProviderNameGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, OauthCallbackOauthCallbackProviderNameGetParams['after']>, TError = OauthCallbackOauthCallbackProviderNameGetErrorType<HTTPValidationError>>({ providerName }: OauthCallbackOauthCallbackProviderNameGetPathParameters,
    params: OauthCallbackOauthCallbackProviderNameGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError, TData, Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, QueryKey, OauthCallbackOauthCallbackProviderNameGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOauthCallbackOauthCallbackProviderNameGetQueryKey({ providerName },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, QueryKey, OauthCallbackOauthCallbackProviderNameGetParams['after']> = ({ signal, pageParam }) => oauthCallbackOauthCallbackProviderNameGet({ providerName },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError, TData, Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, QueryKey, OauthCallbackOauthCallbackProviderNameGetParams['after']> & { queryKey: QueryKey }
}

export type OauthCallbackOauthCallbackProviderNameGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>>
export type OauthCallbackOauthCallbackProviderNameGetInfiniteQueryError = OauthCallbackOauthCallbackProviderNameGetErrorType<HTTPValidationError>

/**
 * @summary Oauth Callback
 */
export const useOauthCallbackOauthCallbackProviderNameGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, OauthCallbackOauthCallbackProviderNameGetParams['after']>, TError = OauthCallbackOauthCallbackProviderNameGetErrorType<HTTPValidationError>>(
 { providerName }: OauthCallbackOauthCallbackProviderNameGetPathParameters,
    params: OauthCallbackOauthCallbackProviderNameGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError, TData, Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, QueryKey, OauthCallbackOauthCallbackProviderNameGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOauthCallbackOauthCallbackProviderNameGetInfiniteQueryOptions({ providerName },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOauthCallbackOauthCallbackProviderNameGetQueryOptions = <TData = Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError = OauthCallbackOauthCallbackProviderNameGetErrorType<HTTPValidationError>>({ providerName }: OauthCallbackOauthCallbackProviderNameGetPathParameters,
    params: OauthCallbackOauthCallbackProviderNameGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOauthCallbackOauthCallbackProviderNameGetQueryKey({ providerName },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>> = ({ signal }) => oauthCallbackOauthCallbackProviderNameGet({ providerName },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError, TData> & { queryKey: QueryKey }
}

export type OauthCallbackOauthCallbackProviderNameGetQueryResult = NonNullable<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>>
export type OauthCallbackOauthCallbackProviderNameGetQueryError = OauthCallbackOauthCallbackProviderNameGetErrorType<HTTPValidationError>

/**
 * @summary Oauth Callback
 */
export const useOauthCallbackOauthCallbackProviderNameGet = <TData = Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError = OauthCallbackOauthCallbackProviderNameGetErrorType<HTTPValidationError>>(
 { providerName }: OauthCallbackOauthCallbackProviderNameGetPathParameters,
    params: OauthCallbackOauthCallbackProviderNameGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOauthCallbackOauthCallbackProviderNameGetQueryOptions({ providerName },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getOauthCallbackOauthCallbackProviderNameGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError = OauthCallbackOauthCallbackProviderNameGetErrorType<HTTPValidationError>>({ providerName }: OauthCallbackOauthCallbackProviderNameGetPathParameters,
    params: OauthCallbackOauthCallbackProviderNameGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOauthCallbackOauthCallbackProviderNameGetQueryKey({ providerName },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>> = ({ signal }) => oauthCallbackOauthCallbackProviderNameGet({ providerName },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError, TData> & { queryKey: QueryKey }
}

export type OauthCallbackOauthCallbackProviderNameGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>>
export type OauthCallbackOauthCallbackProviderNameGetSuspenseQueryError = OauthCallbackOauthCallbackProviderNameGetErrorType<HTTPValidationError>

/**
 * @summary Oauth Callback
 */
export const useOauthCallbackOauthCallbackProviderNameGetSuspense = <TData = Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError = OauthCallbackOauthCallbackProviderNameGetErrorType<HTTPValidationError>>(
 { providerName }: OauthCallbackOauthCallbackProviderNameGetPathParameters,
    params: OauthCallbackOauthCallbackProviderNameGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof oauthCallbackOauthCallbackProviderNameGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOauthCallbackOauthCallbackProviderNameGetSuspenseQueryOptions({ providerName },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Shared Event Action
 */
export const getSharedEventActionGuestCalendarSharedEventsShareKeyGet = (
    { shareKey }: GetSharedEventActionGuestCalendarSharedEventsShareKeyGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getSharedEventActionGuestCalendarSharedEventsShareKeyGetMutator<ListUserCalendarEventsResponseEvent>(
      {url: `/guest/calendar/shared-events/${shareKey}`, method: 'GET', signal
    },
      );
    }
  

export const getGetSharedEventActionGuestCalendarSharedEventsShareKeyGetQueryKey = ({ shareKey }: GetSharedEventActionGuestCalendarSharedEventsShareKeyGetPathParameters,) => {
    return [`/guest/calendar/shared-events/${shareKey}`] as const;
    }

    
export const getGetSharedEventActionGuestCalendarSharedEventsShareKeyGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>>, TError = GetSharedEventActionGuestCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>>({ shareKey }: GetSharedEventActionGuestCalendarSharedEventsShareKeyGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSharedEventActionGuestCalendarSharedEventsShareKeyGetQueryKey({ shareKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>> = ({ signal }) => getSharedEventActionGuestCalendarSharedEventsShareKeyGet({ shareKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSharedEventActionGuestCalendarSharedEventsShareKeyGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>>
export type GetSharedEventActionGuestCalendarSharedEventsShareKeyGetInfiniteQueryError = GetSharedEventActionGuestCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>

/**
 * @summary Get Shared Event Action
 */
export const useGetSharedEventActionGuestCalendarSharedEventsShareKeyGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>>, TError = GetSharedEventActionGuestCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>>(
 { shareKey }: GetSharedEventActionGuestCalendarSharedEventsShareKeyGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSharedEventActionGuestCalendarSharedEventsShareKeyGetInfiniteQueryOptions({ shareKey },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSharedEventActionGuestCalendarSharedEventsShareKeyGetQueryOptions = <TData = Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError = GetSharedEventActionGuestCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>>({ shareKey }: GetSharedEventActionGuestCalendarSharedEventsShareKeyGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSharedEventActionGuestCalendarSharedEventsShareKeyGetQueryKey({ shareKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>> = ({ signal }) => getSharedEventActionGuestCalendarSharedEventsShareKeyGet({ shareKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSharedEventActionGuestCalendarSharedEventsShareKeyGetQueryResult = NonNullable<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>>
export type GetSharedEventActionGuestCalendarSharedEventsShareKeyGetQueryError = GetSharedEventActionGuestCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>

/**
 * @summary Get Shared Event Action
 */
export const useGetSharedEventActionGuestCalendarSharedEventsShareKeyGet = <TData = Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError = GetSharedEventActionGuestCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>>(
 { shareKey }: GetSharedEventActionGuestCalendarSharedEventsShareKeyGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSharedEventActionGuestCalendarSharedEventsShareKeyGetQueryOptions({ shareKey },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSharedEventActionGuestCalendarSharedEventsShareKeyGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError = GetSharedEventActionGuestCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>>({ shareKey }: GetSharedEventActionGuestCalendarSharedEventsShareKeyGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSharedEventActionGuestCalendarSharedEventsShareKeyGetQueryKey({ shareKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>> = ({ signal }) => getSharedEventActionGuestCalendarSharedEventsShareKeyGet({ shareKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSharedEventActionGuestCalendarSharedEventsShareKeyGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>>
export type GetSharedEventActionGuestCalendarSharedEventsShareKeyGetSuspenseQueryError = GetSharedEventActionGuestCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>

/**
 * @summary Get Shared Event Action
 */
export const useGetSharedEventActionGuestCalendarSharedEventsShareKeyGetSuspense = <TData = Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError = GetSharedEventActionGuestCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>>(
 { shareKey }: GetSharedEventActionGuestCalendarSharedEventsShareKeyGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSharedEventActionGuestCalendarSharedEventsShareKeyGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSharedEventActionGuestCalendarSharedEventsShareKeyGetSuspenseQueryOptions({ shareKey },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Download Exported Ics
 */
export const downloadExportedIcsGuestCalendarExportDownloadExportKeyGet = (
    { exportKey }: DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return downloadExportedIcsGuestCalendarExportDownloadExportKeyGetMutator<void>(
      {url: `/guest/calendar/export/download/${exportKey}`, method: 'GET', signal
    },
      );
    }
  

export const getDownloadExportedIcsGuestCalendarExportDownloadExportKeyGetQueryKey = ({ exportKey }: DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetPathParameters,) => {
    return [`/guest/calendar/export/download/${exportKey}`] as const;
    }

    
export const getDownloadExportedIcsGuestCalendarExportDownloadExportKeyGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>>, TError = DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetErrorType<HTTPValidationError>>({ exportKey }: DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadExportedIcsGuestCalendarExportDownloadExportKeyGetQueryKey({ exportKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>> = ({ signal }) => downloadExportedIcsGuestCalendarExportDownloadExportKeyGet({ exportKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>>
export type DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetInfiniteQueryError = DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetErrorType<HTTPValidationError>

/**
 * @summary Download Exported Ics
 */
export const useDownloadExportedIcsGuestCalendarExportDownloadExportKeyGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>>, TError = DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetErrorType<HTTPValidationError>>(
 { exportKey }: DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadExportedIcsGuestCalendarExportDownloadExportKeyGetInfiniteQueryOptions({ exportKey },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDownloadExportedIcsGuestCalendarExportDownloadExportKeyGetQueryOptions = <TData = Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError = DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetErrorType<HTTPValidationError>>({ exportKey }: DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadExportedIcsGuestCalendarExportDownloadExportKeyGetQueryKey({ exportKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>> = ({ signal }) => downloadExportedIcsGuestCalendarExportDownloadExportKeyGet({ exportKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetQueryResult = NonNullable<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>>
export type DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetQueryError = DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetErrorType<HTTPValidationError>

/**
 * @summary Download Exported Ics
 */
export const useDownloadExportedIcsGuestCalendarExportDownloadExportKeyGet = <TData = Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError = DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetErrorType<HTTPValidationError>>(
 { exportKey }: DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadExportedIcsGuestCalendarExportDownloadExportKeyGetQueryOptions({ exportKey },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDownloadExportedIcsGuestCalendarExportDownloadExportKeyGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError = DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetErrorType<HTTPValidationError>>({ exportKey }: DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadExportedIcsGuestCalendarExportDownloadExportKeyGetQueryKey({ exportKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>> = ({ signal }) => downloadExportedIcsGuestCalendarExportDownloadExportKeyGet({ exportKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>>
export type DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetSuspenseQueryError = DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetErrorType<HTTPValidationError>

/**
 * @summary Download Exported Ics
 */
export const useDownloadExportedIcsGuestCalendarExportDownloadExportKeyGetSuspense = <TData = Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError = DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetErrorType<HTTPValidationError>>(
 { exportKey }: DownloadExportedIcsGuestCalendarExportDownloadExportKeyGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof downloadExportedIcsGuestCalendarExportDownloadExportKeyGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadExportedIcsGuestCalendarExportDownloadExportKeyGetSuspenseQueryOptions({ exportKey },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary User Deactivate Reason
 */
export const userDeactivateReasonGuestDeactivateReasonPost = (
    userDeactivateReasonRequest: UserDeactivateReasonRequest,
 ) => {
      
      
      return userDeactivateReasonGuestDeactivateReasonPostMutator<UserDeactivateReasonResponse>(
      {url: `/guest/deactivate/reason`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userDeactivateReasonRequest
    },
      );
    }
  


export const getUserDeactivateReasonGuestDeactivateReasonPostMutationOptions = <TError = UserDeactivateReasonGuestDeactivateReasonPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userDeactivateReasonGuestDeactivateReasonPost>>, TError,{data: UserDeactivateReasonRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof userDeactivateReasonGuestDeactivateReasonPost>>, TError,{data: UserDeactivateReasonRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userDeactivateReasonGuestDeactivateReasonPost>>, {data: UserDeactivateReasonRequest}> = (props) => {
          const {data} = props ?? {};

          return  userDeactivateReasonGuestDeactivateReasonPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserDeactivateReasonGuestDeactivateReasonPostMutationResult = NonNullable<Awaited<ReturnType<typeof userDeactivateReasonGuestDeactivateReasonPost>>>
    export type UserDeactivateReasonGuestDeactivateReasonPostMutationBody = UserDeactivateReasonRequest
    export type UserDeactivateReasonGuestDeactivateReasonPostMutationError = UserDeactivateReasonGuestDeactivateReasonPostErrorType<HTTPValidationError>

    /**
 * @summary User Deactivate Reason
 */
export const useUserDeactivateReasonGuestDeactivateReasonPost = <TError = UserDeactivateReasonGuestDeactivateReasonPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userDeactivateReasonGuestDeactivateReasonPost>>, TError,{data: UserDeactivateReasonRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof userDeactivateReasonGuestDeactivateReasonPost>>,
        TError,
        {data: UserDeactivateReasonRequest},
        TContext
      > => {

      const mutationOptions = getUserDeactivateReasonGuestDeactivateReasonPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Callback
 */
export const callbackCallAliyunCallbackPost = (
    voiceReport: VoiceReport[],
 ) => {
      
      
      return callbackCallAliyunCallbackPostMutator<unknown>(
      {url: `/call/aliyun/callback`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: voiceReport
    },
      );
    }
  


export const getCallbackCallAliyunCallbackPostMutationOptions = <TError = CallbackCallAliyunCallbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof callbackCallAliyunCallbackPost>>, TError,{data: VoiceReport[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof callbackCallAliyunCallbackPost>>, TError,{data: VoiceReport[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof callbackCallAliyunCallbackPost>>, {data: VoiceReport[]}> = (props) => {
          const {data} = props ?? {};

          return  callbackCallAliyunCallbackPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CallbackCallAliyunCallbackPostMutationResult = NonNullable<Awaited<ReturnType<typeof callbackCallAliyunCallbackPost>>>
    export type CallbackCallAliyunCallbackPostMutationBody = VoiceReport[]
    export type CallbackCallAliyunCallbackPostMutationError = CallbackCallAliyunCallbackPostErrorType<HTTPValidationError>

    /**
 * @summary Callback
 */
export const useCallbackCallAliyunCallbackPost = <TError = CallbackCallAliyunCallbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof callbackCallAliyunCallbackPost>>, TError,{data: VoiceReport[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof callbackCallAliyunCallbackPost>>,
        TError,
        {data: VoiceReport[]},
        TContext
      > => {

      const mutationOptions = getCallbackCallAliyunCallbackPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Status Callback
 */
export const statusCallbackCallTwilioStatusCallbackPost = (
    bodyStatusCallbackCallTwilioStatusCallbackPost: BodyStatusCallbackCallTwilioStatusCallbackPost,
 ) => {
      
      const formUrlEncoded = new URLSearchParams();
formUrlEncoded.append('call_sid', bodyStatusCallbackCallTwilioStatusCallbackPost.call_sid)
formUrlEncoded.append('timestamp_str', bodyStatusCallbackCallTwilioStatusCallbackPost.timestamp_str)
if(bodyStatusCallbackCallTwilioStatusCallbackPost.recording_url !== undefined) {
 formUrlEncoded.append('recording_url', bodyStatusCallbackCallTwilioStatusCallbackPost.recording_url)
 }
if(bodyStatusCallbackCallTwilioStatusCallbackPost.recording_duration !== undefined) {
 formUrlEncoded.append('recording_duration', bodyStatusCallbackCallTwilioStatusCallbackPost.recording_duration)
 }
formUrlEncoded.append('call_status', bodyStatusCallbackCallTwilioStatusCallbackPost.call_status)
if(bodyStatusCallbackCallTwilioStatusCallbackPost.call_duration !== undefined) {
 formUrlEncoded.append('call_duration', bodyStatusCallbackCallTwilioStatusCallbackPost.call_duration)
 }

      return statusCallbackCallTwilioStatusCallbackPostMutator<unknown>(
      {url: `/call/twilio/status-callback`, method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded', },
       data: formUrlEncoded
    },
      );
    }
  


export const getStatusCallbackCallTwilioStatusCallbackPostMutationOptions = <TError = StatusCallbackCallTwilioStatusCallbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof statusCallbackCallTwilioStatusCallbackPost>>, TError,{data: BodyStatusCallbackCallTwilioStatusCallbackPost}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof statusCallbackCallTwilioStatusCallbackPost>>, TError,{data: BodyStatusCallbackCallTwilioStatusCallbackPost}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof statusCallbackCallTwilioStatusCallbackPost>>, {data: BodyStatusCallbackCallTwilioStatusCallbackPost}> = (props) => {
          const {data} = props ?? {};

          return  statusCallbackCallTwilioStatusCallbackPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type StatusCallbackCallTwilioStatusCallbackPostMutationResult = NonNullable<Awaited<ReturnType<typeof statusCallbackCallTwilioStatusCallbackPost>>>
    export type StatusCallbackCallTwilioStatusCallbackPostMutationBody = BodyStatusCallbackCallTwilioStatusCallbackPost
    export type StatusCallbackCallTwilioStatusCallbackPostMutationError = StatusCallbackCallTwilioStatusCallbackPostErrorType<HTTPValidationError>

    /**
 * @summary Status Callback
 */
export const useStatusCallbackCallTwilioStatusCallbackPost = <TError = StatusCallbackCallTwilioStatusCallbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof statusCallbackCallTwilioStatusCallbackPost>>, TError,{data: BodyStatusCallbackCallTwilioStatusCallbackPost}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof statusCallbackCallTwilioStatusCallbackPost>>,
        TError,
        {data: BodyStatusCallbackCallTwilioStatusCallbackPost},
        TContext
      > => {

      const mutationOptions = getStatusCallbackCallTwilioStatusCallbackPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Whatsapp Incoming
 */
export const whatsappIncomingCallTwilioWhatsappIncomingPost = (
    
 ) => {
      
      
      return whatsappIncomingCallTwilioWhatsappIncomingPostMutator<unknown>(
      {url: `/call/twilio/whatsapp/incoming`, method: 'POST'
    },
      );
    }
  


export const getWhatsappIncomingCallTwilioWhatsappIncomingPostMutationOptions = <TError = WhatsappIncomingCallTwilioWhatsappIncomingPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof whatsappIncomingCallTwilioWhatsappIncomingPost>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof whatsappIncomingCallTwilioWhatsappIncomingPost>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof whatsappIncomingCallTwilioWhatsappIncomingPost>>, void> = () => {
          

          return  whatsappIncomingCallTwilioWhatsappIncomingPost()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WhatsappIncomingCallTwilioWhatsappIncomingPostMutationResult = NonNullable<Awaited<ReturnType<typeof whatsappIncomingCallTwilioWhatsappIncomingPost>>>
    
    export type WhatsappIncomingCallTwilioWhatsappIncomingPostMutationError = WhatsappIncomingCallTwilioWhatsappIncomingPostErrorType<unknown>

    /**
 * @summary Whatsapp Incoming
 */
export const useWhatsappIncomingCallTwilioWhatsappIncomingPost = <TError = WhatsappIncomingCallTwilioWhatsappIncomingPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof whatsappIncomingCallTwilioWhatsappIncomingPost>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof whatsappIncomingCallTwilioWhatsappIncomingPost>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getWhatsappIncomingCallTwilioWhatsappIncomingPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Whatsapp Status
 */
export const whatsappStatusCallTwilioWhatsappStatusPost = (
    
 ) => {
      
      
      return whatsappStatusCallTwilioWhatsappStatusPostMutator<unknown>(
      {url: `/call/twilio/whatsapp/status`, method: 'POST'
    },
      );
    }
  


export const getWhatsappStatusCallTwilioWhatsappStatusPostMutationOptions = <TError = WhatsappStatusCallTwilioWhatsappStatusPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof whatsappStatusCallTwilioWhatsappStatusPost>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof whatsappStatusCallTwilioWhatsappStatusPost>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof whatsappStatusCallTwilioWhatsappStatusPost>>, void> = () => {
          

          return  whatsappStatusCallTwilioWhatsappStatusPost()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WhatsappStatusCallTwilioWhatsappStatusPostMutationResult = NonNullable<Awaited<ReturnType<typeof whatsappStatusCallTwilioWhatsappStatusPost>>>
    
    export type WhatsappStatusCallTwilioWhatsappStatusPostMutationError = WhatsappStatusCallTwilioWhatsappStatusPostErrorType<unknown>

    /**
 * @summary Whatsapp Status
 */
export const useWhatsappStatusCallTwilioWhatsappStatusPost = <TError = WhatsappStatusCallTwilioWhatsappStatusPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof whatsappStatusCallTwilioWhatsappStatusPost>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof whatsappStatusCallTwilioWhatsappStatusPost>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getWhatsappStatusCallTwilioWhatsappStatusPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Web Template Api
 */
export const webTemplateApiWebI18nTemplateKeyGet = (
    { templateKey }: WebTemplateApiWebI18nTemplateKeyGetPathParameters,
    params?: WebTemplateApiWebI18nTemplateKeyGetParams,
 signal?: AbortSignal
) => {
      
      
      return webTemplateApiWebI18nTemplateKeyGetMutator<unknown>(
      {url: `/web/i18n/${templateKey}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getWebTemplateApiWebI18nTemplateKeyGetQueryKey = ({ templateKey }: WebTemplateApiWebI18nTemplateKeyGetPathParameters,
    params?: WebTemplateApiWebI18nTemplateKeyGetParams,) => {
    return [`/web/i18n/${templateKey}`, ...(params ? [params]: [])] as const;
    }

    
export const getWebTemplateApiWebI18nTemplateKeyGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, WebTemplateApiWebI18nTemplateKeyGetParams['after']>, TError = WebTemplateApiWebI18nTemplateKeyGetErrorType<HTTPValidationError>>({ templateKey }: WebTemplateApiWebI18nTemplateKeyGetPathParameters,
    params?: WebTemplateApiWebI18nTemplateKeyGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError, TData, Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, QueryKey, WebTemplateApiWebI18nTemplateKeyGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWebTemplateApiWebI18nTemplateKeyGetQueryKey({ templateKey },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, QueryKey, WebTemplateApiWebI18nTemplateKeyGetParams['after']> = ({ signal, pageParam }) => webTemplateApiWebI18nTemplateKeyGet({ templateKey },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError, TData, Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, QueryKey, WebTemplateApiWebI18nTemplateKeyGetParams['after']> & { queryKey: QueryKey }
}

export type WebTemplateApiWebI18nTemplateKeyGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>>
export type WebTemplateApiWebI18nTemplateKeyGetInfiniteQueryError = WebTemplateApiWebI18nTemplateKeyGetErrorType<HTTPValidationError>

/**
 * @summary Web Template Api
 */
export const useWebTemplateApiWebI18nTemplateKeyGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, WebTemplateApiWebI18nTemplateKeyGetParams['after']>, TError = WebTemplateApiWebI18nTemplateKeyGetErrorType<HTTPValidationError>>(
 { templateKey }: WebTemplateApiWebI18nTemplateKeyGetPathParameters,
    params?: WebTemplateApiWebI18nTemplateKeyGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError, TData, Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, QueryKey, WebTemplateApiWebI18nTemplateKeyGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWebTemplateApiWebI18nTemplateKeyGetInfiniteQueryOptions({ templateKey },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWebTemplateApiWebI18nTemplateKeyGetQueryOptions = <TData = Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError = WebTemplateApiWebI18nTemplateKeyGetErrorType<HTTPValidationError>>({ templateKey }: WebTemplateApiWebI18nTemplateKeyGetPathParameters,
    params?: WebTemplateApiWebI18nTemplateKeyGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWebTemplateApiWebI18nTemplateKeyGetQueryKey({ templateKey },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>> = ({ signal }) => webTemplateApiWebI18nTemplateKeyGet({ templateKey },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type WebTemplateApiWebI18nTemplateKeyGetQueryResult = NonNullable<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>>
export type WebTemplateApiWebI18nTemplateKeyGetQueryError = WebTemplateApiWebI18nTemplateKeyGetErrorType<HTTPValidationError>

/**
 * @summary Web Template Api
 */
export const useWebTemplateApiWebI18nTemplateKeyGet = <TData = Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError = WebTemplateApiWebI18nTemplateKeyGetErrorType<HTTPValidationError>>(
 { templateKey }: WebTemplateApiWebI18nTemplateKeyGetPathParameters,
    params?: WebTemplateApiWebI18nTemplateKeyGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWebTemplateApiWebI18nTemplateKeyGetQueryOptions({ templateKey },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getWebTemplateApiWebI18nTemplateKeyGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError = WebTemplateApiWebI18nTemplateKeyGetErrorType<HTTPValidationError>>({ templateKey }: WebTemplateApiWebI18nTemplateKeyGetPathParameters,
    params?: WebTemplateApiWebI18nTemplateKeyGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWebTemplateApiWebI18nTemplateKeyGetQueryKey({ templateKey },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>> = ({ signal }) => webTemplateApiWebI18nTemplateKeyGet({ templateKey },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type WebTemplateApiWebI18nTemplateKeyGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>>
export type WebTemplateApiWebI18nTemplateKeyGetSuspenseQueryError = WebTemplateApiWebI18nTemplateKeyGetErrorType<HTTPValidationError>

/**
 * @summary Web Template Api
 */
export const useWebTemplateApiWebI18nTemplateKeyGetSuspense = <TData = Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError = WebTemplateApiWebI18nTemplateKeyGetErrorType<HTTPValidationError>>(
 { templateKey }: WebTemplateApiWebI18nTemplateKeyGetPathParameters,
    params?: WebTemplateApiWebI18nTemplateKeyGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof webTemplateApiWebI18nTemplateKeyGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWebTemplateApiWebI18nTemplateKeyGetSuspenseQueryOptions({ templateKey },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get User Traffic Data
 */
export const getUserTrafficDataUserTrafficGet = (
    params: GetUserTrafficDataUserTrafficGetParams,
 signal?: AbortSignal
) => {
      
      
      return getUserTrafficDataUserTrafficGetMutator<UserTrafficData>(
      {url: `/user-traffic`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetUserTrafficDataUserTrafficGetQueryKey = (params: GetUserTrafficDataUserTrafficGetParams,) => {
    return [`/user-traffic`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserTrafficDataUserTrafficGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, GetUserTrafficDataUserTrafficGetParams['after']>, TError = GetUserTrafficDataUserTrafficGetErrorType<HTTPValidationError>>(params: GetUserTrafficDataUserTrafficGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError, TData, Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, QueryKey, GetUserTrafficDataUserTrafficGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserTrafficDataUserTrafficGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, QueryKey, GetUserTrafficDataUserTrafficGetParams['after']> = ({ signal, pageParam }) => getUserTrafficDataUserTrafficGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError, TData, Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, QueryKey, GetUserTrafficDataUserTrafficGetParams['after']> & { queryKey: QueryKey }
}

export type GetUserTrafficDataUserTrafficGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>>
export type GetUserTrafficDataUserTrafficGetInfiniteQueryError = GetUserTrafficDataUserTrafficGetErrorType<HTTPValidationError>

/**
 * @summary Get User Traffic Data
 */
export const useGetUserTrafficDataUserTrafficGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, GetUserTrafficDataUserTrafficGetParams['after']>, TError = GetUserTrafficDataUserTrafficGetErrorType<HTTPValidationError>>(
 params: GetUserTrafficDataUserTrafficGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError, TData, Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, QueryKey, GetUserTrafficDataUserTrafficGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserTrafficDataUserTrafficGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserTrafficDataUserTrafficGetQueryOptions = <TData = Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError = GetUserTrafficDataUserTrafficGetErrorType<HTTPValidationError>>(params: GetUserTrafficDataUserTrafficGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserTrafficDataUserTrafficGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>> = ({ signal }) => getUserTrafficDataUserTrafficGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserTrafficDataUserTrafficGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>>
export type GetUserTrafficDataUserTrafficGetQueryError = GetUserTrafficDataUserTrafficGetErrorType<HTTPValidationError>

/**
 * @summary Get User Traffic Data
 */
export const useGetUserTrafficDataUserTrafficGet = <TData = Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError = GetUserTrafficDataUserTrafficGetErrorType<HTTPValidationError>>(
 params: GetUserTrafficDataUserTrafficGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserTrafficDataUserTrafficGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserTrafficDataUserTrafficGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError = GetUserTrafficDataUserTrafficGetErrorType<HTTPValidationError>>(params: GetUserTrafficDataUserTrafficGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserTrafficDataUserTrafficGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>> = ({ signal }) => getUserTrafficDataUserTrafficGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserTrafficDataUserTrafficGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>>
export type GetUserTrafficDataUserTrafficGetSuspenseQueryError = GetUserTrafficDataUserTrafficGetErrorType<HTTPValidationError>

/**
 * @summary Get User Traffic Data
 */
export const useGetUserTrafficDataUserTrafficGetSuspense = <TData = Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError = GetUserTrafficDataUserTrafficGetErrorType<HTTPValidationError>>(
 params: GetUserTrafficDataUserTrafficGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserTrafficDataUserTrafficGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserTrafficDataUserTrafficGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Card Content
 */
export const getCardContentFeedOpenV2FeedRidGet = (
    { rid }: GetCardContentFeedOpenV2FeedRidGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getCardContentFeedOpenV2FeedRidGetMutator<FeedContentResponse>(
      {url: `/feed/open/v2/feed/${rid}`, method: 'GET', signal
    },
      );
    }
  

export const getGetCardContentFeedOpenV2FeedRidGetQueryKey = ({ rid }: GetCardContentFeedOpenV2FeedRidGetPathParameters,) => {
    return [`/feed/open/v2/feed/${rid}`] as const;
    }

    
export const getGetCardContentFeedOpenV2FeedRidGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>>, TError = GetCardContentFeedOpenV2FeedRidGetErrorType<HTTPValidationError>>({ rid }: GetCardContentFeedOpenV2FeedRidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCardContentFeedOpenV2FeedRidGetQueryKey({ rid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>> = ({ signal }) => getCardContentFeedOpenV2FeedRidGet({ rid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCardContentFeedOpenV2FeedRidGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>>
export type GetCardContentFeedOpenV2FeedRidGetInfiniteQueryError = GetCardContentFeedOpenV2FeedRidGetErrorType<HTTPValidationError>

/**
 * @summary Get Card Content
 */
export const useGetCardContentFeedOpenV2FeedRidGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>>, TError = GetCardContentFeedOpenV2FeedRidGetErrorType<HTTPValidationError>>(
 { rid }: GetCardContentFeedOpenV2FeedRidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCardContentFeedOpenV2FeedRidGetInfiniteQueryOptions({ rid },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCardContentFeedOpenV2FeedRidGetQueryOptions = <TData = Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError = GetCardContentFeedOpenV2FeedRidGetErrorType<HTTPValidationError>>({ rid }: GetCardContentFeedOpenV2FeedRidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCardContentFeedOpenV2FeedRidGetQueryKey({ rid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>> = ({ signal }) => getCardContentFeedOpenV2FeedRidGet({ rid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCardContentFeedOpenV2FeedRidGetQueryResult = NonNullable<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>>
export type GetCardContentFeedOpenV2FeedRidGetQueryError = GetCardContentFeedOpenV2FeedRidGetErrorType<HTTPValidationError>

/**
 * @summary Get Card Content
 */
export const useGetCardContentFeedOpenV2FeedRidGet = <TData = Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError = GetCardContentFeedOpenV2FeedRidGetErrorType<HTTPValidationError>>(
 { rid }: GetCardContentFeedOpenV2FeedRidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCardContentFeedOpenV2FeedRidGetQueryOptions({ rid },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCardContentFeedOpenV2FeedRidGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError = GetCardContentFeedOpenV2FeedRidGetErrorType<HTTPValidationError>>({ rid }: GetCardContentFeedOpenV2FeedRidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCardContentFeedOpenV2FeedRidGetQueryKey({ rid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>> = ({ signal }) => getCardContentFeedOpenV2FeedRidGet({ rid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCardContentFeedOpenV2FeedRidGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>>
export type GetCardContentFeedOpenV2FeedRidGetSuspenseQueryError = GetCardContentFeedOpenV2FeedRidGetErrorType<HTTPValidationError>

/**
 * @summary Get Card Content
 */
export const useGetCardContentFeedOpenV2FeedRidGetSuspense = <TData = Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError = GetCardContentFeedOpenV2FeedRidGetErrorType<HTTPValidationError>>(
 { rid }: GetCardContentFeedOpenV2FeedRidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCardContentFeedOpenV2FeedRidGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCardContentFeedOpenV2FeedRidGetSuspenseQueryOptions({ rid },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List Feeds
 */
export const listFeedsFeedGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listFeedsFeedGetMutator<ListFeedResponse>(
      {url: `/feed`, method: 'GET', signal
    },
      );
    }
  

export const getListFeedsFeedGetQueryKey = () => {
    return [`/feed`] as const;
    }

    
export const getListFeedsFeedGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listFeedsFeedGet>>>, TError = ListFeedsFeedGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFeedsFeedGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFeedsFeedGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFeedsFeedGet>>> = ({ signal }) => listFeedsFeedGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFeedsFeedGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFeedsFeedGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listFeedsFeedGet>>>
export type ListFeedsFeedGetInfiniteQueryError = ListFeedsFeedGetErrorType<unknown>

/**
 * @summary List Feeds
 */
export const useListFeedsFeedGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listFeedsFeedGet>>>, TError = ListFeedsFeedGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFeedsFeedGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFeedsFeedGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListFeedsFeedGetQueryOptions = <TData = Awaited<ReturnType<typeof listFeedsFeedGet>>, TError = ListFeedsFeedGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFeedsFeedGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFeedsFeedGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFeedsFeedGet>>> = ({ signal }) => listFeedsFeedGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listFeedsFeedGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFeedsFeedGetQueryResult = NonNullable<Awaited<ReturnType<typeof listFeedsFeedGet>>>
export type ListFeedsFeedGetQueryError = ListFeedsFeedGetErrorType<unknown>

/**
 * @summary List Feeds
 */
export const useListFeedsFeedGet = <TData = Awaited<ReturnType<typeof listFeedsFeedGet>>, TError = ListFeedsFeedGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFeedsFeedGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFeedsFeedGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListFeedsFeedGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listFeedsFeedGet>>, TError = ListFeedsFeedGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFeedsFeedGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFeedsFeedGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFeedsFeedGet>>> = ({ signal }) => listFeedsFeedGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFeedsFeedGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFeedsFeedGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listFeedsFeedGet>>>
export type ListFeedsFeedGetSuspenseQueryError = ListFeedsFeedGetErrorType<unknown>

/**
 * @summary List Feeds
 */
export const useListFeedsFeedGetSuspense = <TData = Awaited<ReturnType<typeof listFeedsFeedGet>>, TError = ListFeedsFeedGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFeedsFeedGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFeedsFeedGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Add Feed
 */
export const addFeedFeedPost = (
    addFeedRequest: AddFeedRequest,
 ) => {
      
      
      return addFeedFeedPostMutator<AddFeedResponse>(
      {url: `/feed`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addFeedRequest
    },
      );
    }
  


export const getAddFeedFeedPostMutationOptions = <TError = AddFeedFeedPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addFeedFeedPost>>, TError,{data: AddFeedRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addFeedFeedPost>>, TError,{data: AddFeedRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addFeedFeedPost>>, {data: AddFeedRequest}> = (props) => {
          const {data} = props ?? {};

          return  addFeedFeedPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddFeedFeedPostMutationResult = NonNullable<Awaited<ReturnType<typeof addFeedFeedPost>>>
    export type AddFeedFeedPostMutationBody = AddFeedRequest
    export type AddFeedFeedPostMutationError = AddFeedFeedPostErrorType<HTTPValidationError>

    /**
 * @summary Add Feed
 */
export const useAddFeedFeedPost = <TError = AddFeedFeedPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addFeedFeedPost>>, TError,{data: AddFeedRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof addFeedFeedPost>>,
        TError,
        {data: AddFeedRequest},
        TContext
      > => {

      const mutationOptions = getAddFeedFeedPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Feed
 */
export const getFeedFeedUidGet = (
    { uid }: GetFeedFeedUidGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getFeedFeedUidGetMutator<FeedDetailResponse>(
      {url: `/feed/${uid}`, method: 'GET', signal
    },
      );
    }
  

export const getGetFeedFeedUidGetQueryKey = ({ uid }: GetFeedFeedUidGetPathParameters,) => {
    return [`/feed/${uid}`] as const;
    }

    
export const getGetFeedFeedUidGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFeedFeedUidGet>>>, TError = GetFeedFeedUidGetErrorType<HTTPValidationError>>({ uid }: GetFeedFeedUidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedFeedUidGetQueryKey({ uid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedFeedUidGet>>> = ({ signal }) => getFeedFeedUidGet({ uid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFeedFeedUidGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedFeedUidGet>>>
export type GetFeedFeedUidGetInfiniteQueryError = GetFeedFeedUidGetErrorType<HTTPValidationError>

/**
 * @summary Get Feed
 */
export const useGetFeedFeedUidGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFeedFeedUidGet>>>, TError = GetFeedFeedUidGetErrorType<HTTPValidationError>>(
 { uid }: GetFeedFeedUidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFeedFeedUidGetInfiniteQueryOptions({ uid },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFeedFeedUidGetQueryOptions = <TData = Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError = GetFeedFeedUidGetErrorType<HTTPValidationError>>({ uid }: GetFeedFeedUidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedFeedUidGetQueryKey({ uid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedFeedUidGet>>> = ({ signal }) => getFeedFeedUidGet({ uid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFeedFeedUidGetQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedFeedUidGet>>>
export type GetFeedFeedUidGetQueryError = GetFeedFeedUidGetErrorType<HTTPValidationError>

/**
 * @summary Get Feed
 */
export const useGetFeedFeedUidGet = <TData = Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError = GetFeedFeedUidGetErrorType<HTTPValidationError>>(
 { uid }: GetFeedFeedUidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFeedFeedUidGetQueryOptions({ uid },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFeedFeedUidGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError = GetFeedFeedUidGetErrorType<HTTPValidationError>>({ uid }: GetFeedFeedUidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedFeedUidGetQueryKey({ uid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedFeedUidGet>>> = ({ signal }) => getFeedFeedUidGet({ uid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFeedFeedUidGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedFeedUidGet>>>
export type GetFeedFeedUidGetSuspenseQueryError = GetFeedFeedUidGetErrorType<HTTPValidationError>

/**
 * @summary Get Feed
 */
export const useGetFeedFeedUidGetSuspense = <TData = Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError = GetFeedFeedUidGetErrorType<HTTPValidationError>>(
 { uid }: GetFeedFeedUidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFeedFeedUidGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFeedFeedUidGetSuspenseQueryOptions({ uid },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Feed
 */
export const updateFeedFeedUidPatch = (
    { uid }: UpdateFeedFeedUidPatchPathParameters,
    updateFeedRequest: UpdateFeedRequest,
 ) => {
      
      
      return updateFeedFeedUidPatchMutator<UpdateFeedResponse>(
      {url: `/feed/${uid}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateFeedRequest
    },
      );
    }
  


export const getUpdateFeedFeedUidPatchMutationOptions = <TError = UpdateFeedFeedUidPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFeedFeedUidPatch>>, TError,{pathParams: UpdateFeedFeedUidPatchPathParameters;data: UpdateFeedRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateFeedFeedUidPatch>>, TError,{pathParams: UpdateFeedFeedUidPatchPathParameters;data: UpdateFeedRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFeedFeedUidPatch>>, {pathParams: UpdateFeedFeedUidPatchPathParameters;data: UpdateFeedRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  updateFeedFeedUidPatch(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateFeedFeedUidPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateFeedFeedUidPatch>>>
    export type UpdateFeedFeedUidPatchMutationBody = UpdateFeedRequest
    export type UpdateFeedFeedUidPatchMutationError = UpdateFeedFeedUidPatchErrorType<HTTPValidationError>

    /**
 * @summary Update Feed
 */
export const useUpdateFeedFeedUidPatch = <TError = UpdateFeedFeedUidPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFeedFeedUidPatch>>, TError,{pathParams: UpdateFeedFeedUidPatchPathParameters;data: UpdateFeedRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateFeedFeedUidPatch>>,
        TError,
        {pathParams: UpdateFeedFeedUidPatchPathParameters;data: UpdateFeedRequest},
        TContext
      > => {

      const mutationOptions = getUpdateFeedFeedUidPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Unsub Feed
 */
export const unsubFeedFeedUidDelete = (
    { uid }: UnsubFeedFeedUidDeletePathParameters,
 ) => {
      
      
      return unsubFeedFeedUidDeleteMutator<UnsubFeedResponse>(
      {url: `/feed/${uid}`, method: 'DELETE'
    },
      );
    }
  


export const getUnsubFeedFeedUidDeleteMutationOptions = <TError = UnsubFeedFeedUidDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unsubFeedFeedUidDelete>>, TError,{pathParams: UnsubFeedFeedUidDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof unsubFeedFeedUidDelete>>, TError,{pathParams: UnsubFeedFeedUidDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unsubFeedFeedUidDelete>>, {pathParams: UnsubFeedFeedUidDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  unsubFeedFeedUidDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UnsubFeedFeedUidDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof unsubFeedFeedUidDelete>>>
    
    export type UnsubFeedFeedUidDeleteMutationError = UnsubFeedFeedUidDeleteErrorType<HTTPValidationError>

    /**
 * @summary Unsub Feed
 */
export const useUnsubFeedFeedUidDelete = <TError = UnsubFeedFeedUidDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unsubFeedFeedUidDelete>>, TError,{pathParams: UnsubFeedFeedUidDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof unsubFeedFeedUidDelete>>,
        TError,
        {pathParams: UnsubFeedFeedUidDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getUnsubFeedFeedUidDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Feed Records
 */
export const listFeedRecordsFeedUidHistoryGet = (
    { uid }: ListFeedRecordsFeedUidHistoryGetPathParameters,
    params?: ListFeedRecordsFeedUidHistoryGetParams,
 signal?: AbortSignal
) => {
      
      
      return listFeedRecordsFeedUidHistoryGetMutator<ListFeedRecordResponse>(
      {url: `/feed/${uid}/history`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListFeedRecordsFeedUidHistoryGetQueryKey = ({ uid }: ListFeedRecordsFeedUidHistoryGetPathParameters,
    params?: ListFeedRecordsFeedUidHistoryGetParams,) => {
    return [`/feed/${uid}/history`, ...(params ? [params]: [])] as const;
    }

    
export const getListFeedRecordsFeedUidHistoryGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, ListFeedRecordsFeedUidHistoryGetParams['after']>, TError = ListFeedRecordsFeedUidHistoryGetErrorType<HTTPValidationError>>({ uid }: ListFeedRecordsFeedUidHistoryGetPathParameters,
    params?: ListFeedRecordsFeedUidHistoryGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError, TData, Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, QueryKey, ListFeedRecordsFeedUidHistoryGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFeedRecordsFeedUidHistoryGetQueryKey({ uid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, QueryKey, ListFeedRecordsFeedUidHistoryGetParams['after']> = ({ signal, pageParam }) => listFeedRecordsFeedUidHistoryGet({ uid },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError, TData, Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, QueryKey, ListFeedRecordsFeedUidHistoryGetParams['after']> & { queryKey: QueryKey }
}

export type ListFeedRecordsFeedUidHistoryGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>>
export type ListFeedRecordsFeedUidHistoryGetInfiniteQueryError = ListFeedRecordsFeedUidHistoryGetErrorType<HTTPValidationError>

/**
 * @summary List Feed Records
 */
export const useListFeedRecordsFeedUidHistoryGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, ListFeedRecordsFeedUidHistoryGetParams['after']>, TError = ListFeedRecordsFeedUidHistoryGetErrorType<HTTPValidationError>>(
 { uid }: ListFeedRecordsFeedUidHistoryGetPathParameters,
    params?: ListFeedRecordsFeedUidHistoryGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError, TData, Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, QueryKey, ListFeedRecordsFeedUidHistoryGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFeedRecordsFeedUidHistoryGetInfiniteQueryOptions({ uid },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListFeedRecordsFeedUidHistoryGetQueryOptions = <TData = Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError = ListFeedRecordsFeedUidHistoryGetErrorType<HTTPValidationError>>({ uid }: ListFeedRecordsFeedUidHistoryGetPathParameters,
    params?: ListFeedRecordsFeedUidHistoryGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFeedRecordsFeedUidHistoryGetQueryKey({ uid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>> = ({ signal }) => listFeedRecordsFeedUidHistoryGet({ uid },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFeedRecordsFeedUidHistoryGetQueryResult = NonNullable<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>>
export type ListFeedRecordsFeedUidHistoryGetQueryError = ListFeedRecordsFeedUidHistoryGetErrorType<HTTPValidationError>

/**
 * @summary List Feed Records
 */
export const useListFeedRecordsFeedUidHistoryGet = <TData = Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError = ListFeedRecordsFeedUidHistoryGetErrorType<HTTPValidationError>>(
 { uid }: ListFeedRecordsFeedUidHistoryGetPathParameters,
    params?: ListFeedRecordsFeedUidHistoryGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFeedRecordsFeedUidHistoryGetQueryOptions({ uid },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListFeedRecordsFeedUidHistoryGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError = ListFeedRecordsFeedUidHistoryGetErrorType<HTTPValidationError>>({ uid }: ListFeedRecordsFeedUidHistoryGetPathParameters,
    params?: ListFeedRecordsFeedUidHistoryGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFeedRecordsFeedUidHistoryGetQueryKey({ uid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>> = ({ signal }) => listFeedRecordsFeedUidHistoryGet({ uid },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFeedRecordsFeedUidHistoryGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>>
export type ListFeedRecordsFeedUidHistoryGetSuspenseQueryError = ListFeedRecordsFeedUidHistoryGetErrorType<HTTPValidationError>

/**
 * @summary List Feed Records
 */
export const useListFeedRecordsFeedUidHistoryGetSuspense = <TData = Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError = ListFeedRecordsFeedUidHistoryGetErrorType<HTTPValidationError>>(
 { uid }: ListFeedRecordsFeedUidHistoryGetPathParameters,
    params?: ListFeedRecordsFeedUidHistoryGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFeedRecordsFeedUidHistoryGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFeedRecordsFeedUidHistoryGetSuspenseQueryOptions({ uid },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Give Feed Feedback
 */
export const giveFeedFeedbackFeedUidFeedbackPost = (
    { uid }: GiveFeedFeedbackFeedUidFeedbackPostPathParameters,
    giveFeedFeedbackRequest: GiveFeedFeedbackRequest,
 ) => {
      
      
      return giveFeedFeedbackFeedUidFeedbackPostMutator<GiveFeedFeedbackResponse>(
      {url: `/feed/${uid}/feedback`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: giveFeedFeedbackRequest
    },
      );
    }
  


export const getGiveFeedFeedbackFeedUidFeedbackPostMutationOptions = <TError = GiveFeedFeedbackFeedUidFeedbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveFeedFeedbackFeedUidFeedbackPost>>, TError,{pathParams: GiveFeedFeedbackFeedUidFeedbackPostPathParameters;data: GiveFeedFeedbackRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof giveFeedFeedbackFeedUidFeedbackPost>>, TError,{pathParams: GiveFeedFeedbackFeedUidFeedbackPostPathParameters;data: GiveFeedFeedbackRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof giveFeedFeedbackFeedUidFeedbackPost>>, {pathParams: GiveFeedFeedbackFeedUidFeedbackPostPathParameters;data: GiveFeedFeedbackRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  giveFeedFeedbackFeedUidFeedbackPost(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GiveFeedFeedbackFeedUidFeedbackPostMutationResult = NonNullable<Awaited<ReturnType<typeof giveFeedFeedbackFeedUidFeedbackPost>>>
    export type GiveFeedFeedbackFeedUidFeedbackPostMutationBody = GiveFeedFeedbackRequest
    export type GiveFeedFeedbackFeedUidFeedbackPostMutationError = GiveFeedFeedbackFeedUidFeedbackPostErrorType<HTTPValidationError>

    /**
 * @summary Give Feed Feedback
 */
export const useGiveFeedFeedbackFeedUidFeedbackPost = <TError = GiveFeedFeedbackFeedUidFeedbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveFeedFeedbackFeedUidFeedbackPost>>, TError,{pathParams: GiveFeedFeedbackFeedUidFeedbackPostPathParameters;data: GiveFeedFeedbackRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof giveFeedFeedbackFeedUidFeedbackPost>>,
        TError,
        {pathParams: GiveFeedFeedbackFeedUidFeedbackPostPathParameters;data: GiveFeedFeedbackRequest},
        TContext
      > => {

      const mutationOptions = getGiveFeedFeedbackFeedUidFeedbackPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Give Record Like
 */
export const giveRecordLikeFeedRecordUidLikePost = (
    { uid }: GiveRecordLikeFeedRecordUidLikePostPathParameters,
 ) => {
      
      
      return giveRecordLikeFeedRecordUidLikePostMutator<GiveRecordFeedbackResponse>(
      {url: `/feed/record/${uid}/like`, method: 'POST'
    },
      );
    }
  


export const getGiveRecordLikeFeedRecordUidLikePostMutationOptions = <TError = GiveRecordLikeFeedRecordUidLikePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveRecordLikeFeedRecordUidLikePost>>, TError,{pathParams: GiveRecordLikeFeedRecordUidLikePostPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof giveRecordLikeFeedRecordUidLikePost>>, TError,{pathParams: GiveRecordLikeFeedRecordUidLikePostPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof giveRecordLikeFeedRecordUidLikePost>>, {pathParams: GiveRecordLikeFeedRecordUidLikePostPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  giveRecordLikeFeedRecordUidLikePost(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GiveRecordLikeFeedRecordUidLikePostMutationResult = NonNullable<Awaited<ReturnType<typeof giveRecordLikeFeedRecordUidLikePost>>>
    
    export type GiveRecordLikeFeedRecordUidLikePostMutationError = GiveRecordLikeFeedRecordUidLikePostErrorType<HTTPValidationError>

    /**
 * @summary Give Record Like
 */
export const useGiveRecordLikeFeedRecordUidLikePost = <TError = GiveRecordLikeFeedRecordUidLikePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveRecordLikeFeedRecordUidLikePost>>, TError,{pathParams: GiveRecordLikeFeedRecordUidLikePostPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof giveRecordLikeFeedRecordUidLikePost>>,
        TError,
        {pathParams: GiveRecordLikeFeedRecordUidLikePostPathParameters},
        TContext
      > => {

      const mutationOptions = getGiveRecordLikeFeedRecordUidLikePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cancel Record Dislike
 */
export const cancelRecordDislikeFeedRecordUidLikeDelete = (
    { uid }: CancelRecordDislikeFeedRecordUidLikeDeletePathParameters,
 ) => {
      
      
      return cancelRecordDislikeFeedRecordUidLikeDeleteMutator<GiveRecordFeedbackResponse>(
      {url: `/feed/record/${uid}/like`, method: 'DELETE'
    },
      );
    }
  


export const getCancelRecordDislikeFeedRecordUidLikeDeleteMutationOptions = <TError = CancelRecordDislikeFeedRecordUidLikeDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelRecordDislikeFeedRecordUidLikeDelete>>, TError,{pathParams: CancelRecordDislikeFeedRecordUidLikeDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof cancelRecordDislikeFeedRecordUidLikeDelete>>, TError,{pathParams: CancelRecordDislikeFeedRecordUidLikeDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelRecordDislikeFeedRecordUidLikeDelete>>, {pathParams: CancelRecordDislikeFeedRecordUidLikeDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  cancelRecordDislikeFeedRecordUidLikeDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CancelRecordDislikeFeedRecordUidLikeDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof cancelRecordDislikeFeedRecordUidLikeDelete>>>
    
    export type CancelRecordDislikeFeedRecordUidLikeDeleteMutationError = CancelRecordDislikeFeedRecordUidLikeDeleteErrorType<HTTPValidationError>

    /**
 * @summary Cancel Record Dislike
 */
export const useCancelRecordDislikeFeedRecordUidLikeDelete = <TError = CancelRecordDislikeFeedRecordUidLikeDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelRecordDislikeFeedRecordUidLikeDelete>>, TError,{pathParams: CancelRecordDislikeFeedRecordUidLikeDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof cancelRecordDislikeFeedRecordUidLikeDelete>>,
        TError,
        {pathParams: CancelRecordDislikeFeedRecordUidLikeDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getCancelRecordDislikeFeedRecordUidLikeDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Give Record Dislike
 */
export const giveRecordDislikeFeedRecordUidDislikePost = (
    { uid }: GiveRecordDislikeFeedRecordUidDislikePostPathParameters,
 ) => {
      
      
      return giveRecordDislikeFeedRecordUidDislikePostMutator<GiveRecordFeedbackResponse>(
      {url: `/feed/record/${uid}/dislike`, method: 'POST'
    },
      );
    }
  


export const getGiveRecordDislikeFeedRecordUidDislikePostMutationOptions = <TError = GiveRecordDislikeFeedRecordUidDislikePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveRecordDislikeFeedRecordUidDislikePost>>, TError,{pathParams: GiveRecordDislikeFeedRecordUidDislikePostPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof giveRecordDislikeFeedRecordUidDislikePost>>, TError,{pathParams: GiveRecordDislikeFeedRecordUidDislikePostPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof giveRecordDislikeFeedRecordUidDislikePost>>, {pathParams: GiveRecordDislikeFeedRecordUidDislikePostPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  giveRecordDislikeFeedRecordUidDislikePost(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GiveRecordDislikeFeedRecordUidDislikePostMutationResult = NonNullable<Awaited<ReturnType<typeof giveRecordDislikeFeedRecordUidDislikePost>>>
    
    export type GiveRecordDislikeFeedRecordUidDislikePostMutationError = GiveRecordDislikeFeedRecordUidDislikePostErrorType<HTTPValidationError>

    /**
 * @summary Give Record Dislike
 */
export const useGiveRecordDislikeFeedRecordUidDislikePost = <TError = GiveRecordDislikeFeedRecordUidDislikePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveRecordDislikeFeedRecordUidDislikePost>>, TError,{pathParams: GiveRecordDislikeFeedRecordUidDislikePostPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof giveRecordDislikeFeedRecordUidDislikePost>>,
        TError,
        {pathParams: GiveRecordDislikeFeedRecordUidDislikePostPathParameters},
        TContext
      > => {

      const mutationOptions = getGiveRecordDislikeFeedRecordUidDislikePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Subscribed Channels
 */
export const listSubscribedChannelsFeedV2ChannelGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listSubscribedChannelsFeedV2ChannelGetMutator<ListSubscribedChannelResponse>(
      {url: `/feed/v2/channel`, method: 'GET', signal
    },
      );
    }
  

export const getListSubscribedChannelsFeedV2ChannelGetQueryKey = () => {
    return [`/feed/v2/channel`] as const;
    }

    
export const getListSubscribedChannelsFeedV2ChannelGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>>, TError = ListSubscribedChannelsFeedV2ChannelGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListSubscribedChannelsFeedV2ChannelGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>> = ({ signal }) => listSubscribedChannelsFeedV2ChannelGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListSubscribedChannelsFeedV2ChannelGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>>
export type ListSubscribedChannelsFeedV2ChannelGetInfiniteQueryError = ListSubscribedChannelsFeedV2ChannelGetErrorType<unknown>

/**
 * @summary List Subscribed Channels
 */
export const useListSubscribedChannelsFeedV2ChannelGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>>, TError = ListSubscribedChannelsFeedV2ChannelGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListSubscribedChannelsFeedV2ChannelGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListSubscribedChannelsFeedV2ChannelGetQueryOptions = <TData = Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError = ListSubscribedChannelsFeedV2ChannelGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListSubscribedChannelsFeedV2ChannelGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>> = ({ signal }) => listSubscribedChannelsFeedV2ChannelGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListSubscribedChannelsFeedV2ChannelGetQueryResult = NonNullable<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>>
export type ListSubscribedChannelsFeedV2ChannelGetQueryError = ListSubscribedChannelsFeedV2ChannelGetErrorType<unknown>

/**
 * @summary List Subscribed Channels
 */
export const useListSubscribedChannelsFeedV2ChannelGet = <TData = Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError = ListSubscribedChannelsFeedV2ChannelGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListSubscribedChannelsFeedV2ChannelGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListSubscribedChannelsFeedV2ChannelGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError = ListSubscribedChannelsFeedV2ChannelGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListSubscribedChannelsFeedV2ChannelGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>> = ({ signal }) => listSubscribedChannelsFeedV2ChannelGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListSubscribedChannelsFeedV2ChannelGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>>
export type ListSubscribedChannelsFeedV2ChannelGetSuspenseQueryError = ListSubscribedChannelsFeedV2ChannelGetErrorType<unknown>

/**
 * @summary List Subscribed Channels
 */
export const useListSubscribedChannelsFeedV2ChannelGetSuspense = <TData = Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError = ListSubscribedChannelsFeedV2ChannelGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelsFeedV2ChannelGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListSubscribedChannelsFeedV2ChannelGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Subscribe New Channel
 */
export const subscribeNewChannelFeedV2ChannelPost = (
    subscribeNewChannelRequest: SubscribeNewChannelRequest,
 ) => {
      
      
      return subscribeNewChannelFeedV2ChannelPostMutator<SubscribeNewChannelResponse>(
      {url: `/feed/v2/channel`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: subscribeNewChannelRequest
    },
      );
    }
  


export const getSubscribeNewChannelFeedV2ChannelPostMutationOptions = <TError = SubscribeNewChannelFeedV2ChannelPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof subscribeNewChannelFeedV2ChannelPost>>, TError,{data: SubscribeNewChannelRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof subscribeNewChannelFeedV2ChannelPost>>, TError,{data: SubscribeNewChannelRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof subscribeNewChannelFeedV2ChannelPost>>, {data: SubscribeNewChannelRequest}> = (props) => {
          const {data} = props ?? {};

          return  subscribeNewChannelFeedV2ChannelPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SubscribeNewChannelFeedV2ChannelPostMutationResult = NonNullable<Awaited<ReturnType<typeof subscribeNewChannelFeedV2ChannelPost>>>
    export type SubscribeNewChannelFeedV2ChannelPostMutationBody = SubscribeNewChannelRequest
    export type SubscribeNewChannelFeedV2ChannelPostMutationError = SubscribeNewChannelFeedV2ChannelPostErrorType<HTTPValidationError>

    /**
 * @summary Subscribe New Channel
 */
export const useSubscribeNewChannelFeedV2ChannelPost = <TError = SubscribeNewChannelFeedV2ChannelPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof subscribeNewChannelFeedV2ChannelPost>>, TError,{data: SubscribeNewChannelRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof subscribeNewChannelFeedV2ChannelPost>>,
        TError,
        {data: SubscribeNewChannelRequest},
        TContext
      > => {

      const mutationOptions = getSubscribeNewChannelFeedV2ChannelPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Subscribed Channel Detail
 */
export const getSubscribedChannelDetailFeedV2ChannelCidGet = (
    { cid }: GetSubscribedChannelDetailFeedV2ChannelCidGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getSubscribedChannelDetailFeedV2ChannelCidGetMutator<SubscribedChannel>(
      {url: `/feed/v2/channel/${cid}`, method: 'GET', signal
    },
      );
    }
  

export const getGetSubscribedChannelDetailFeedV2ChannelCidGetQueryKey = ({ cid }: GetSubscribedChannelDetailFeedV2ChannelCidGetPathParameters,) => {
    return [`/feed/v2/channel/${cid}`] as const;
    }

    
export const getGetSubscribedChannelDetailFeedV2ChannelCidGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>>, TError = GetSubscribedChannelDetailFeedV2ChannelCidGetErrorType<HTTPValidationError>>({ cid }: GetSubscribedChannelDetailFeedV2ChannelCidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSubscribedChannelDetailFeedV2ChannelCidGetQueryKey({ cid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>> = ({ signal }) => getSubscribedChannelDetailFeedV2ChannelCidGet({ cid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSubscribedChannelDetailFeedV2ChannelCidGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>>
export type GetSubscribedChannelDetailFeedV2ChannelCidGetInfiniteQueryError = GetSubscribedChannelDetailFeedV2ChannelCidGetErrorType<HTTPValidationError>

/**
 * @summary Get Subscribed Channel Detail
 */
export const useGetSubscribedChannelDetailFeedV2ChannelCidGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>>, TError = GetSubscribedChannelDetailFeedV2ChannelCidGetErrorType<HTTPValidationError>>(
 { cid }: GetSubscribedChannelDetailFeedV2ChannelCidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSubscribedChannelDetailFeedV2ChannelCidGetInfiniteQueryOptions({ cid },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSubscribedChannelDetailFeedV2ChannelCidGetQueryOptions = <TData = Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError = GetSubscribedChannelDetailFeedV2ChannelCidGetErrorType<HTTPValidationError>>({ cid }: GetSubscribedChannelDetailFeedV2ChannelCidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSubscribedChannelDetailFeedV2ChannelCidGetQueryKey({ cid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>> = ({ signal }) => getSubscribedChannelDetailFeedV2ChannelCidGet({ cid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSubscribedChannelDetailFeedV2ChannelCidGetQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>>
export type GetSubscribedChannelDetailFeedV2ChannelCidGetQueryError = GetSubscribedChannelDetailFeedV2ChannelCidGetErrorType<HTTPValidationError>

/**
 * @summary Get Subscribed Channel Detail
 */
export const useGetSubscribedChannelDetailFeedV2ChannelCidGet = <TData = Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError = GetSubscribedChannelDetailFeedV2ChannelCidGetErrorType<HTTPValidationError>>(
 { cid }: GetSubscribedChannelDetailFeedV2ChannelCidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSubscribedChannelDetailFeedV2ChannelCidGetQueryOptions({ cid },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSubscribedChannelDetailFeedV2ChannelCidGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError = GetSubscribedChannelDetailFeedV2ChannelCidGetErrorType<HTTPValidationError>>({ cid }: GetSubscribedChannelDetailFeedV2ChannelCidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSubscribedChannelDetailFeedV2ChannelCidGetQueryKey({ cid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>> = ({ signal }) => getSubscribedChannelDetailFeedV2ChannelCidGet({ cid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSubscribedChannelDetailFeedV2ChannelCidGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>>
export type GetSubscribedChannelDetailFeedV2ChannelCidGetSuspenseQueryError = GetSubscribedChannelDetailFeedV2ChannelCidGetErrorType<HTTPValidationError>

/**
 * @summary Get Subscribed Channel Detail
 */
export const useGetSubscribedChannelDetailFeedV2ChannelCidGetSuspense = <TData = Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError = GetSubscribedChannelDetailFeedV2ChannelCidGetErrorType<HTTPValidationError>>(
 { cid }: GetSubscribedChannelDetailFeedV2ChannelCidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSubscribedChannelDetailFeedV2ChannelCidGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSubscribedChannelDetailFeedV2ChannelCidGetSuspenseQueryOptions({ cid },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Unsub Channel
 */
export const unsubChannelFeedV2ChannelCidDelete = (
    { cid }: UnsubChannelFeedV2ChannelCidDeletePathParameters,
 ) => {
      
      
      return unsubChannelFeedV2ChannelCidDeleteMutator<OperationResponse>(
      {url: `/feed/v2/channel/${cid}`, method: 'DELETE'
    },
      );
    }
  


export const getUnsubChannelFeedV2ChannelCidDeleteMutationOptions = <TError = UnsubChannelFeedV2ChannelCidDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unsubChannelFeedV2ChannelCidDelete>>, TError,{pathParams: UnsubChannelFeedV2ChannelCidDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof unsubChannelFeedV2ChannelCidDelete>>, TError,{pathParams: UnsubChannelFeedV2ChannelCidDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unsubChannelFeedV2ChannelCidDelete>>, {pathParams: UnsubChannelFeedV2ChannelCidDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  unsubChannelFeedV2ChannelCidDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UnsubChannelFeedV2ChannelCidDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof unsubChannelFeedV2ChannelCidDelete>>>
    
    export type UnsubChannelFeedV2ChannelCidDeleteMutationError = UnsubChannelFeedV2ChannelCidDeleteErrorType<HTTPValidationError>

    /**
 * @summary Unsub Channel
 */
export const useUnsubChannelFeedV2ChannelCidDelete = <TError = UnsubChannelFeedV2ChannelCidDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unsubChannelFeedV2ChannelCidDelete>>, TError,{pathParams: UnsubChannelFeedV2ChannelCidDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof unsubChannelFeedV2ChannelCidDelete>>,
        TError,
        {pathParams: UnsubChannelFeedV2ChannelCidDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getUnsubChannelFeedV2ChannelCidDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Switch Subscribed Channel
 */
export const switchSubscribedChannelFeedV2ChannelCidSwitchPatch = (
    { cid }: SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchPathParameters,
    switchSubscribedChannelRequest: SwitchSubscribedChannelRequest,
 ) => {
      
      
      return switchSubscribedChannelFeedV2ChannelCidSwitchPatchMutator<OperationResponse>(
      {url: `/feed/v2/channel/${cid}/switch`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: switchSubscribedChannelRequest
    },
      );
    }
  


export const getSwitchSubscribedChannelFeedV2ChannelCidSwitchPatchMutationOptions = <TError = SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof switchSubscribedChannelFeedV2ChannelCidSwitchPatch>>, TError,{pathParams: SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchPathParameters;data: SwitchSubscribedChannelRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof switchSubscribedChannelFeedV2ChannelCidSwitchPatch>>, TError,{pathParams: SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchPathParameters;data: SwitchSubscribedChannelRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof switchSubscribedChannelFeedV2ChannelCidSwitchPatch>>, {pathParams: SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchPathParameters;data: SwitchSubscribedChannelRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  switchSubscribedChannelFeedV2ChannelCidSwitchPatch(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchMutationResult = NonNullable<Awaited<ReturnType<typeof switchSubscribedChannelFeedV2ChannelCidSwitchPatch>>>
    export type SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchMutationBody = SwitchSubscribedChannelRequest
    export type SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchMutationError = SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchErrorType<HTTPValidationError>

    /**
 * @summary Switch Subscribed Channel
 */
export const useSwitchSubscribedChannelFeedV2ChannelCidSwitchPatch = <TError = SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof switchSubscribedChannelFeedV2ChannelCidSwitchPatch>>, TError,{pathParams: SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchPathParameters;data: SwitchSubscribedChannelRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof switchSubscribedChannelFeedV2ChannelCidSwitchPatch>>,
        TError,
        {pathParams: SwitchSubscribedChannelFeedV2ChannelCidSwitchPatchPathParameters;data: SwitchSubscribedChannelRequest},
        TContext
      > => {

      const mutationOptions = getSwitchSubscribedChannelFeedV2ChannelCidSwitchPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Subscription Schedule
 */
export const addSubscriptionScheduleFeedV2ChannelCidTimePost = (
    { cid }: AddSubscriptionScheduleFeedV2ChannelCidTimePostPathParameters,
    subscriptionScheduleRequest: SubscriptionScheduleRequest,
 ) => {
      
      
      return addSubscriptionScheduleFeedV2ChannelCidTimePostMutator<OperationResponse>(
      {url: `/feed/v2/channel/${cid}/time`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: subscriptionScheduleRequest
    },
      );
    }
  


export const getAddSubscriptionScheduleFeedV2ChannelCidTimePostMutationOptions = <TError = AddSubscriptionScheduleFeedV2ChannelCidTimePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addSubscriptionScheduleFeedV2ChannelCidTimePost>>, TError,{pathParams: AddSubscriptionScheduleFeedV2ChannelCidTimePostPathParameters;data: SubscriptionScheduleRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addSubscriptionScheduleFeedV2ChannelCidTimePost>>, TError,{pathParams: AddSubscriptionScheduleFeedV2ChannelCidTimePostPathParameters;data: SubscriptionScheduleRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addSubscriptionScheduleFeedV2ChannelCidTimePost>>, {pathParams: AddSubscriptionScheduleFeedV2ChannelCidTimePostPathParameters;data: SubscriptionScheduleRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  addSubscriptionScheduleFeedV2ChannelCidTimePost(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddSubscriptionScheduleFeedV2ChannelCidTimePostMutationResult = NonNullable<Awaited<ReturnType<typeof addSubscriptionScheduleFeedV2ChannelCidTimePost>>>
    export type AddSubscriptionScheduleFeedV2ChannelCidTimePostMutationBody = SubscriptionScheduleRequest
    export type AddSubscriptionScheduleFeedV2ChannelCidTimePostMutationError = AddSubscriptionScheduleFeedV2ChannelCidTimePostErrorType<HTTPValidationError>

    /**
 * @summary Add Subscription Schedule
 */
export const useAddSubscriptionScheduleFeedV2ChannelCidTimePost = <TError = AddSubscriptionScheduleFeedV2ChannelCidTimePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addSubscriptionScheduleFeedV2ChannelCidTimePost>>, TError,{pathParams: AddSubscriptionScheduleFeedV2ChannelCidTimePostPathParameters;data: SubscriptionScheduleRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof addSubscriptionScheduleFeedV2ChannelCidTimePost>>,
        TError,
        {pathParams: AddSubscriptionScheduleFeedV2ChannelCidTimePostPathParameters;data: SubscriptionScheduleRequest},
        TContext
      > => {

      const mutationOptions = getAddSubscriptionScheduleFeedV2ChannelCidTimePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Subscription Schedule
 */
export const updateSubscriptionScheduleFeedV2ChannelCidTimePut = (
    { cid }: UpdateSubscriptionScheduleFeedV2ChannelCidTimePutPathParameters,
    subscriptionScheduleRequest: SubscriptionScheduleRequest,
 ) => {
      
      
      return updateSubscriptionScheduleFeedV2ChannelCidTimePutMutator<OperationResponse>(
      {url: `/feed/v2/channel/${cid}/time`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: subscriptionScheduleRequest
    },
      );
    }
  


export const getUpdateSubscriptionScheduleFeedV2ChannelCidTimePutMutationOptions = <TError = UpdateSubscriptionScheduleFeedV2ChannelCidTimePutErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSubscriptionScheduleFeedV2ChannelCidTimePut>>, TError,{pathParams: UpdateSubscriptionScheduleFeedV2ChannelCidTimePutPathParameters;data: SubscriptionScheduleRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateSubscriptionScheduleFeedV2ChannelCidTimePut>>, TError,{pathParams: UpdateSubscriptionScheduleFeedV2ChannelCidTimePutPathParameters;data: SubscriptionScheduleRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSubscriptionScheduleFeedV2ChannelCidTimePut>>, {pathParams: UpdateSubscriptionScheduleFeedV2ChannelCidTimePutPathParameters;data: SubscriptionScheduleRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  updateSubscriptionScheduleFeedV2ChannelCidTimePut(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateSubscriptionScheduleFeedV2ChannelCidTimePutMutationResult = NonNullable<Awaited<ReturnType<typeof updateSubscriptionScheduleFeedV2ChannelCidTimePut>>>
    export type UpdateSubscriptionScheduleFeedV2ChannelCidTimePutMutationBody = SubscriptionScheduleRequest
    export type UpdateSubscriptionScheduleFeedV2ChannelCidTimePutMutationError = UpdateSubscriptionScheduleFeedV2ChannelCidTimePutErrorType<HTTPValidationError>

    /**
 * @summary Update Subscription Schedule
 */
export const useUpdateSubscriptionScheduleFeedV2ChannelCidTimePut = <TError = UpdateSubscriptionScheduleFeedV2ChannelCidTimePutErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSubscriptionScheduleFeedV2ChannelCidTimePut>>, TError,{pathParams: UpdateSubscriptionScheduleFeedV2ChannelCidTimePutPathParameters;data: SubscriptionScheduleRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateSubscriptionScheduleFeedV2ChannelCidTimePut>>,
        TError,
        {pathParams: UpdateSubscriptionScheduleFeedV2ChannelCidTimePutPathParameters;data: SubscriptionScheduleRequest},
        TContext
      > => {

      const mutationOptions = getUpdateSubscriptionScheduleFeedV2ChannelCidTimePutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Subscription Schedule
 */
export const deleteSubscriptionScheduleFeedV2ChannelCidTimeDelete = (
    { cid }: DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeletePathParameters,
    subscriptionScheduleRequest: SubscriptionScheduleRequest,
 ) => {
      
      
      return deleteSubscriptionScheduleFeedV2ChannelCidTimeDeleteMutator<OperationResponse>(
      {url: `/feed/v2/channel/${cid}/time`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: subscriptionScheduleRequest
    },
      );
    }
  


export const getDeleteSubscriptionScheduleFeedV2ChannelCidTimeDeleteMutationOptions = <TError = DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSubscriptionScheduleFeedV2ChannelCidTimeDelete>>, TError,{pathParams: DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeletePathParameters;data: SubscriptionScheduleRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteSubscriptionScheduleFeedV2ChannelCidTimeDelete>>, TError,{pathParams: DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeletePathParameters;data: SubscriptionScheduleRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSubscriptionScheduleFeedV2ChannelCidTimeDelete>>, {pathParams: DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeletePathParameters;data: SubscriptionScheduleRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  deleteSubscriptionScheduleFeedV2ChannelCidTimeDelete(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSubscriptionScheduleFeedV2ChannelCidTimeDelete>>>
    export type DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeleteMutationBody = SubscriptionScheduleRequest
    export type DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeleteMutationError = DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Subscription Schedule
 */
export const useDeleteSubscriptionScheduleFeedV2ChannelCidTimeDelete = <TError = DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSubscriptionScheduleFeedV2ChannelCidTimeDelete>>, TError,{pathParams: DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeletePathParameters;data: SubscriptionScheduleRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteSubscriptionScheduleFeedV2ChannelCidTimeDelete>>,
        TError,
        {pathParams: DeleteSubscriptionScheduleFeedV2ChannelCidTimeDeletePathParameters;data: SubscriptionScheduleRequest},
        TContext
      > => {

      const mutationOptions = getDeleteSubscriptionScheduleFeedV2ChannelCidTimeDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Subscribed Channel Feeds
 */
export const listSubscribedChannelFeedsFeedV2FeedsGet = (
    params?: ListSubscribedChannelFeedsFeedV2FeedsGetParams,
 signal?: AbortSignal
) => {
      
      
      return listSubscribedChannelFeedsFeedV2FeedsGetMutator<ListSubscribedFeedsResponse>(
      {url: `/feed/v2/feeds`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListSubscribedChannelFeedsFeedV2FeedsGetQueryKey = (params?: ListSubscribedChannelFeedsFeedV2FeedsGetParams,) => {
    return [`/feed/v2/feeds`, ...(params ? [params]: [])] as const;
    }

    
export const getListSubscribedChannelFeedsFeedV2FeedsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, ListSubscribedChannelFeedsFeedV2FeedsGetParams['after']>, TError = ListSubscribedChannelFeedsFeedV2FeedsGetErrorType<HTTPValidationError>>(params?: ListSubscribedChannelFeedsFeedV2FeedsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError, TData, Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, QueryKey, ListSubscribedChannelFeedsFeedV2FeedsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListSubscribedChannelFeedsFeedV2FeedsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, QueryKey, ListSubscribedChannelFeedsFeedV2FeedsGetParams['after']> = ({ signal, pageParam }) => listSubscribedChannelFeedsFeedV2FeedsGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError, TData, Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, QueryKey, ListSubscribedChannelFeedsFeedV2FeedsGetParams['after']> & { queryKey: QueryKey }
}

export type ListSubscribedChannelFeedsFeedV2FeedsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>>
export type ListSubscribedChannelFeedsFeedV2FeedsGetInfiniteQueryError = ListSubscribedChannelFeedsFeedV2FeedsGetErrorType<HTTPValidationError>

/**
 * @summary List Subscribed Channel Feeds
 */
export const useListSubscribedChannelFeedsFeedV2FeedsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, ListSubscribedChannelFeedsFeedV2FeedsGetParams['after']>, TError = ListSubscribedChannelFeedsFeedV2FeedsGetErrorType<HTTPValidationError>>(
 params?: ListSubscribedChannelFeedsFeedV2FeedsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError, TData, Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, QueryKey, ListSubscribedChannelFeedsFeedV2FeedsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListSubscribedChannelFeedsFeedV2FeedsGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListSubscribedChannelFeedsFeedV2FeedsGetQueryOptions = <TData = Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError = ListSubscribedChannelFeedsFeedV2FeedsGetErrorType<HTTPValidationError>>(params?: ListSubscribedChannelFeedsFeedV2FeedsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListSubscribedChannelFeedsFeedV2FeedsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>> = ({ signal }) => listSubscribedChannelFeedsFeedV2FeedsGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListSubscribedChannelFeedsFeedV2FeedsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>>
export type ListSubscribedChannelFeedsFeedV2FeedsGetQueryError = ListSubscribedChannelFeedsFeedV2FeedsGetErrorType<HTTPValidationError>

/**
 * @summary List Subscribed Channel Feeds
 */
export const useListSubscribedChannelFeedsFeedV2FeedsGet = <TData = Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError = ListSubscribedChannelFeedsFeedV2FeedsGetErrorType<HTTPValidationError>>(
 params?: ListSubscribedChannelFeedsFeedV2FeedsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListSubscribedChannelFeedsFeedV2FeedsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListSubscribedChannelFeedsFeedV2FeedsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError = ListSubscribedChannelFeedsFeedV2FeedsGetErrorType<HTTPValidationError>>(params?: ListSubscribedChannelFeedsFeedV2FeedsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListSubscribedChannelFeedsFeedV2FeedsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>> = ({ signal }) => listSubscribedChannelFeedsFeedV2FeedsGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListSubscribedChannelFeedsFeedV2FeedsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>>
export type ListSubscribedChannelFeedsFeedV2FeedsGetSuspenseQueryError = ListSubscribedChannelFeedsFeedV2FeedsGetErrorType<HTTPValidationError>

/**
 * @summary List Subscribed Channel Feeds
 */
export const useListSubscribedChannelFeedsFeedV2FeedsGetSuspense = <TData = Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError = ListSubscribedChannelFeedsFeedV2FeedsGetErrorType<HTTPValidationError>>(
 params?: ListSubscribedChannelFeedsFeedV2FeedsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listSubscribedChannelFeedsFeedV2FeedsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListSubscribedChannelFeedsFeedV2FeedsGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List Today Weather Feeds
 */
export const listTodayWeatherFeedsFeedV2TodayWeatherGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listTodayWeatherFeedsFeedV2TodayWeatherGetMutator<TodayWeatherFeedsResponse>(
      {url: `/feed/v2/today/weather`, method: 'GET', signal
    },
      );
    }
  

export const getListTodayWeatherFeedsFeedV2TodayWeatherGetQueryKey = () => {
    return [`/feed/v2/today/weather`] as const;
    }

    
export const getListTodayWeatherFeedsFeedV2TodayWeatherGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>>, TError = ListTodayWeatherFeedsFeedV2TodayWeatherGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTodayWeatherFeedsFeedV2TodayWeatherGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>> = ({ signal }) => listTodayWeatherFeedsFeedV2TodayWeatherGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListTodayWeatherFeedsFeedV2TodayWeatherGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>>
export type ListTodayWeatherFeedsFeedV2TodayWeatherGetInfiniteQueryError = ListTodayWeatherFeedsFeedV2TodayWeatherGetErrorType<unknown>

/**
 * @summary List Today Weather Feeds
 */
export const useListTodayWeatherFeedsFeedV2TodayWeatherGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>>, TError = ListTodayWeatherFeedsFeedV2TodayWeatherGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTodayWeatherFeedsFeedV2TodayWeatherGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListTodayWeatherFeedsFeedV2TodayWeatherGetQueryOptions = <TData = Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError = ListTodayWeatherFeedsFeedV2TodayWeatherGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTodayWeatherFeedsFeedV2TodayWeatherGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>> = ({ signal }) => listTodayWeatherFeedsFeedV2TodayWeatherGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListTodayWeatherFeedsFeedV2TodayWeatherGetQueryResult = NonNullable<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>>
export type ListTodayWeatherFeedsFeedV2TodayWeatherGetQueryError = ListTodayWeatherFeedsFeedV2TodayWeatherGetErrorType<unknown>

/**
 * @summary List Today Weather Feeds
 */
export const useListTodayWeatherFeedsFeedV2TodayWeatherGet = <TData = Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError = ListTodayWeatherFeedsFeedV2TodayWeatherGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTodayWeatherFeedsFeedV2TodayWeatherGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListTodayWeatherFeedsFeedV2TodayWeatherGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError = ListTodayWeatherFeedsFeedV2TodayWeatherGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTodayWeatherFeedsFeedV2TodayWeatherGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>> = ({ signal }) => listTodayWeatherFeedsFeedV2TodayWeatherGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListTodayWeatherFeedsFeedV2TodayWeatherGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>>
export type ListTodayWeatherFeedsFeedV2TodayWeatherGetSuspenseQueryError = ListTodayWeatherFeedsFeedV2TodayWeatherGetErrorType<unknown>

/**
 * @summary List Today Weather Feeds
 */
export const useListTodayWeatherFeedsFeedV2TodayWeatherGetSuspense = <TData = Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError = ListTodayWeatherFeedsFeedV2TodayWeatherGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listTodayWeatherFeedsFeedV2TodayWeatherGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTodayWeatherFeedsFeedV2TodayWeatherGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List Today News Feeds
 */
export const listTodayNewsFeedsFeedV2TodayNewsGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listTodayNewsFeedsFeedV2TodayNewsGetMutator<TodayNewsFeedsResponse>(
      {url: `/feed/v2/today/news`, method: 'GET', signal
    },
      );
    }
  

export const getListTodayNewsFeedsFeedV2TodayNewsGetQueryKey = () => {
    return [`/feed/v2/today/news`] as const;
    }

    
export const getListTodayNewsFeedsFeedV2TodayNewsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>>, TError = ListTodayNewsFeedsFeedV2TodayNewsGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTodayNewsFeedsFeedV2TodayNewsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>> = ({ signal }) => listTodayNewsFeedsFeedV2TodayNewsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListTodayNewsFeedsFeedV2TodayNewsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>>
export type ListTodayNewsFeedsFeedV2TodayNewsGetInfiniteQueryError = ListTodayNewsFeedsFeedV2TodayNewsGetErrorType<unknown>

/**
 * @summary List Today News Feeds
 */
export const useListTodayNewsFeedsFeedV2TodayNewsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>>, TError = ListTodayNewsFeedsFeedV2TodayNewsGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTodayNewsFeedsFeedV2TodayNewsGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListTodayNewsFeedsFeedV2TodayNewsGetQueryOptions = <TData = Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError = ListTodayNewsFeedsFeedV2TodayNewsGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTodayNewsFeedsFeedV2TodayNewsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>> = ({ signal }) => listTodayNewsFeedsFeedV2TodayNewsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListTodayNewsFeedsFeedV2TodayNewsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>>
export type ListTodayNewsFeedsFeedV2TodayNewsGetQueryError = ListTodayNewsFeedsFeedV2TodayNewsGetErrorType<unknown>

/**
 * @summary List Today News Feeds
 */
export const useListTodayNewsFeedsFeedV2TodayNewsGet = <TData = Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError = ListTodayNewsFeedsFeedV2TodayNewsGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTodayNewsFeedsFeedV2TodayNewsGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListTodayNewsFeedsFeedV2TodayNewsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError = ListTodayNewsFeedsFeedV2TodayNewsGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTodayNewsFeedsFeedV2TodayNewsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>> = ({ signal }) => listTodayNewsFeedsFeedV2TodayNewsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListTodayNewsFeedsFeedV2TodayNewsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>>
export type ListTodayNewsFeedsFeedV2TodayNewsGetSuspenseQueryError = ListTodayNewsFeedsFeedV2TodayNewsGetErrorType<unknown>

/**
 * @summary List Today News Feeds
 */
export const useListTodayNewsFeedsFeedV2TodayNewsGetSuspense = <TData = Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError = ListTodayNewsFeedsFeedV2TodayNewsGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listTodayNewsFeedsFeedV2TodayNewsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTodayNewsFeedsFeedV2TodayNewsGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Read Today News
 */
export const readTodayNewsFeedV2TodayReadGet = (
    
 signal?: AbortSignal
) => {
      
      
      return readTodayNewsFeedV2TodayReadGetMutator<OperationResponse>(
      {url: `/feed/v2/today/read`, method: 'GET', signal
    },
      );
    }
  

export const getReadTodayNewsFeedV2TodayReadGetQueryKey = () => {
    return [`/feed/v2/today/read`] as const;
    }

    
export const getReadTodayNewsFeedV2TodayReadGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>>, TError = ReadTodayNewsFeedV2TodayReadGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadTodayNewsFeedV2TodayReadGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>> = ({ signal }) => readTodayNewsFeedV2TodayReadGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadTodayNewsFeedV2TodayReadGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>>
export type ReadTodayNewsFeedV2TodayReadGetInfiniteQueryError = ReadTodayNewsFeedV2TodayReadGetErrorType<unknown>

/**
 * @summary Read Today News
 */
export const useReadTodayNewsFeedV2TodayReadGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>>, TError = ReadTodayNewsFeedV2TodayReadGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadTodayNewsFeedV2TodayReadGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getReadTodayNewsFeedV2TodayReadGetQueryOptions = <TData = Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError = ReadTodayNewsFeedV2TodayReadGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadTodayNewsFeedV2TodayReadGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>> = ({ signal }) => readTodayNewsFeedV2TodayReadGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadTodayNewsFeedV2TodayReadGetQueryResult = NonNullable<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>>
export type ReadTodayNewsFeedV2TodayReadGetQueryError = ReadTodayNewsFeedV2TodayReadGetErrorType<unknown>

/**
 * @summary Read Today News
 */
export const useReadTodayNewsFeedV2TodayReadGet = <TData = Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError = ReadTodayNewsFeedV2TodayReadGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadTodayNewsFeedV2TodayReadGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getReadTodayNewsFeedV2TodayReadGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError = ReadTodayNewsFeedV2TodayReadGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadTodayNewsFeedV2TodayReadGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>> = ({ signal }) => readTodayNewsFeedV2TodayReadGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadTodayNewsFeedV2TodayReadGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>>
export type ReadTodayNewsFeedV2TodayReadGetSuspenseQueryError = ReadTodayNewsFeedV2TodayReadGetErrorType<unknown>

/**
 * @summary Read Today News
 */
export const useReadTodayNewsFeedV2TodayReadGetSuspense = <TData = Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError = ReadTodayNewsFeedV2TodayReadGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof readTodayNewsFeedV2TodayReadGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadTodayNewsFeedV2TodayReadGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Feed Like
 */
export const getFeedLikeFeedV2RecordRidLikeGet = (
    { rid }: GetFeedLikeFeedV2RecordRidLikeGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getFeedLikeFeedV2RecordRidLikeGetMutator<unknown>(
      {url: `/feed/v2/record/${rid}/like`, method: 'GET', signal
    },
      );
    }
  

export const getGetFeedLikeFeedV2RecordRidLikeGetQueryKey = ({ rid }: GetFeedLikeFeedV2RecordRidLikeGetPathParameters,) => {
    return [`/feed/v2/record/${rid}/like`] as const;
    }

    
export const getGetFeedLikeFeedV2RecordRidLikeGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>>, TError = GetFeedLikeFeedV2RecordRidLikeGetErrorType<HTTPValidationError>>({ rid }: GetFeedLikeFeedV2RecordRidLikeGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedLikeFeedV2RecordRidLikeGetQueryKey({ rid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>> = ({ signal }) => getFeedLikeFeedV2RecordRidLikeGet({ rid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFeedLikeFeedV2RecordRidLikeGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>>
export type GetFeedLikeFeedV2RecordRidLikeGetInfiniteQueryError = GetFeedLikeFeedV2RecordRidLikeGetErrorType<HTTPValidationError>

/**
 * @summary Get Feed Like
 */
export const useGetFeedLikeFeedV2RecordRidLikeGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>>, TError = GetFeedLikeFeedV2RecordRidLikeGetErrorType<HTTPValidationError>>(
 { rid }: GetFeedLikeFeedV2RecordRidLikeGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFeedLikeFeedV2RecordRidLikeGetInfiniteQueryOptions({ rid },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFeedLikeFeedV2RecordRidLikeGetQueryOptions = <TData = Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError = GetFeedLikeFeedV2RecordRidLikeGetErrorType<HTTPValidationError>>({ rid }: GetFeedLikeFeedV2RecordRidLikeGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedLikeFeedV2RecordRidLikeGetQueryKey({ rid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>> = ({ signal }) => getFeedLikeFeedV2RecordRidLikeGet({ rid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFeedLikeFeedV2RecordRidLikeGetQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>>
export type GetFeedLikeFeedV2RecordRidLikeGetQueryError = GetFeedLikeFeedV2RecordRidLikeGetErrorType<HTTPValidationError>

/**
 * @summary Get Feed Like
 */
export const useGetFeedLikeFeedV2RecordRidLikeGet = <TData = Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError = GetFeedLikeFeedV2RecordRidLikeGetErrorType<HTTPValidationError>>(
 { rid }: GetFeedLikeFeedV2RecordRidLikeGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFeedLikeFeedV2RecordRidLikeGetQueryOptions({ rid },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFeedLikeFeedV2RecordRidLikeGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError = GetFeedLikeFeedV2RecordRidLikeGetErrorType<HTTPValidationError>>({ rid }: GetFeedLikeFeedV2RecordRidLikeGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedLikeFeedV2RecordRidLikeGetQueryKey({ rid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>> = ({ signal }) => getFeedLikeFeedV2RecordRidLikeGet({ rid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFeedLikeFeedV2RecordRidLikeGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>>
export type GetFeedLikeFeedV2RecordRidLikeGetSuspenseQueryError = GetFeedLikeFeedV2RecordRidLikeGetErrorType<HTTPValidationError>

/**
 * @summary Get Feed Like
 */
export const useGetFeedLikeFeedV2RecordRidLikeGetSuspense = <TData = Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError = GetFeedLikeFeedV2RecordRidLikeGetErrorType<HTTPValidationError>>(
 { rid }: GetFeedLikeFeedV2RecordRidLikeGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFeedLikeFeedV2RecordRidLikeGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFeedLikeFeedV2RecordRidLikeGetSuspenseQueryOptions({ rid },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Like Feed
 */
export const likeFeedFeedV2RecordRidLikePost = (
    { rid }: LikeFeedFeedV2RecordRidLikePostPathParameters,
 ) => {
      
      
      return likeFeedFeedV2RecordRidLikePostMutator<unknown>(
      {url: `/feed/v2/record/${rid}/like`, method: 'POST'
    },
      );
    }
  


export const getLikeFeedFeedV2RecordRidLikePostMutationOptions = <TError = LikeFeedFeedV2RecordRidLikePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof likeFeedFeedV2RecordRidLikePost>>, TError,{pathParams: LikeFeedFeedV2RecordRidLikePostPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof likeFeedFeedV2RecordRidLikePost>>, TError,{pathParams: LikeFeedFeedV2RecordRidLikePostPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof likeFeedFeedV2RecordRidLikePost>>, {pathParams: LikeFeedFeedV2RecordRidLikePostPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  likeFeedFeedV2RecordRidLikePost(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LikeFeedFeedV2RecordRidLikePostMutationResult = NonNullable<Awaited<ReturnType<typeof likeFeedFeedV2RecordRidLikePost>>>
    
    export type LikeFeedFeedV2RecordRidLikePostMutationError = LikeFeedFeedV2RecordRidLikePostErrorType<HTTPValidationError>

    /**
 * @summary Like Feed
 */
export const useLikeFeedFeedV2RecordRidLikePost = <TError = LikeFeedFeedV2RecordRidLikePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof likeFeedFeedV2RecordRidLikePost>>, TError,{pathParams: LikeFeedFeedV2RecordRidLikePostPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof likeFeedFeedV2RecordRidLikePost>>,
        TError,
        {pathParams: LikeFeedFeedV2RecordRidLikePostPathParameters},
        TContext
      > => {

      const mutationOptions = getLikeFeedFeedV2RecordRidLikePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cancel Feed Reaction
 */
export const cancelFeedReactionFeedV2RecordRidLikeDelete = (
    { rid }: CancelFeedReactionFeedV2RecordRidLikeDeletePathParameters,
 ) => {
      
      
      return cancelFeedReactionFeedV2RecordRidLikeDeleteMutator<unknown>(
      {url: `/feed/v2/record/${rid}/like`, method: 'DELETE'
    },
      );
    }
  


export const getCancelFeedReactionFeedV2RecordRidLikeDeleteMutationOptions = <TError = CancelFeedReactionFeedV2RecordRidLikeDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelFeedReactionFeedV2RecordRidLikeDelete>>, TError,{pathParams: CancelFeedReactionFeedV2RecordRidLikeDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof cancelFeedReactionFeedV2RecordRidLikeDelete>>, TError,{pathParams: CancelFeedReactionFeedV2RecordRidLikeDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelFeedReactionFeedV2RecordRidLikeDelete>>, {pathParams: CancelFeedReactionFeedV2RecordRidLikeDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  cancelFeedReactionFeedV2RecordRidLikeDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CancelFeedReactionFeedV2RecordRidLikeDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof cancelFeedReactionFeedV2RecordRidLikeDelete>>>
    
    export type CancelFeedReactionFeedV2RecordRidLikeDeleteMutationError = CancelFeedReactionFeedV2RecordRidLikeDeleteErrorType<HTTPValidationError>

    /**
 * @summary Cancel Feed Reaction
 */
export const useCancelFeedReactionFeedV2RecordRidLikeDelete = <TError = CancelFeedReactionFeedV2RecordRidLikeDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelFeedReactionFeedV2RecordRidLikeDelete>>, TError,{pathParams: CancelFeedReactionFeedV2RecordRidLikeDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof cancelFeedReactionFeedV2RecordRidLikeDelete>>,
        TError,
        {pathParams: CancelFeedReactionFeedV2RecordRidLikeDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getCancelFeedReactionFeedV2RecordRidLikeDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dislike Feed
 */
export const dislikeFeedFeedV2RecordRidDislikePost = (
    { rid }: DislikeFeedFeedV2RecordRidDislikePostPathParameters,
 ) => {
      
      
      return dislikeFeedFeedV2RecordRidDislikePostMutator<unknown>(
      {url: `/feed/v2/record/${rid}/dislike`, method: 'POST'
    },
      );
    }
  


export const getDislikeFeedFeedV2RecordRidDislikePostMutationOptions = <TError = DislikeFeedFeedV2RecordRidDislikePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dislikeFeedFeedV2RecordRidDislikePost>>, TError,{pathParams: DislikeFeedFeedV2RecordRidDislikePostPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof dislikeFeedFeedV2RecordRidDislikePost>>, TError,{pathParams: DislikeFeedFeedV2RecordRidDislikePostPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dislikeFeedFeedV2RecordRidDislikePost>>, {pathParams: DislikeFeedFeedV2RecordRidDislikePostPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  dislikeFeedFeedV2RecordRidDislikePost(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DislikeFeedFeedV2RecordRidDislikePostMutationResult = NonNullable<Awaited<ReturnType<typeof dislikeFeedFeedV2RecordRidDislikePost>>>
    
    export type DislikeFeedFeedV2RecordRidDislikePostMutationError = DislikeFeedFeedV2RecordRidDislikePostErrorType<HTTPValidationError>

    /**
 * @summary Dislike Feed
 */
export const useDislikeFeedFeedV2RecordRidDislikePost = <TError = DislikeFeedFeedV2RecordRidDislikePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dislikeFeedFeedV2RecordRidDislikePost>>, TError,{pathParams: DislikeFeedFeedV2RecordRidDislikePostPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof dislikeFeedFeedV2RecordRidDislikePost>>,
        TError,
        {pathParams: DislikeFeedFeedV2RecordRidDislikePostPathParameters},
        TContext
      > => {

      const mutationOptions = getDislikeFeedFeedV2RecordRidDislikePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Recom Channels
 */
export const queryRecomChannelsFeedV2RecomGet = (
    params?: QueryRecomChannelsFeedV2RecomGetParams,
 signal?: AbortSignal
) => {
      
      
      return queryRecomChannelsFeedV2RecomGetMutator<RecomNewsFeedResponse>(
      {url: `/feed/v2/recom`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQueryRecomChannelsFeedV2RecomGetQueryKey = (params?: QueryRecomChannelsFeedV2RecomGetParams,) => {
    return [`/feed/v2/recom`, ...(params ? [params]: [])] as const;
    }

    
export const getQueryRecomChannelsFeedV2RecomGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, QueryRecomChannelsFeedV2RecomGetParams['after']>, TError = QueryRecomChannelsFeedV2RecomGetErrorType<HTTPValidationError>>(params?: QueryRecomChannelsFeedV2RecomGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError, TData, Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, QueryKey, QueryRecomChannelsFeedV2RecomGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryRecomChannelsFeedV2RecomGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, QueryKey, QueryRecomChannelsFeedV2RecomGetParams['after']> = ({ signal, pageParam }) => queryRecomChannelsFeedV2RecomGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError, TData, Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, QueryKey, QueryRecomChannelsFeedV2RecomGetParams['after']> & { queryKey: QueryKey }
}

export type QueryRecomChannelsFeedV2RecomGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>>
export type QueryRecomChannelsFeedV2RecomGetInfiniteQueryError = QueryRecomChannelsFeedV2RecomGetErrorType<HTTPValidationError>

/**
 * @summary Query Recom Channels
 */
export const useQueryRecomChannelsFeedV2RecomGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, QueryRecomChannelsFeedV2RecomGetParams['after']>, TError = QueryRecomChannelsFeedV2RecomGetErrorType<HTTPValidationError>>(
 params?: QueryRecomChannelsFeedV2RecomGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError, TData, Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, QueryKey, QueryRecomChannelsFeedV2RecomGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryRecomChannelsFeedV2RecomGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getQueryRecomChannelsFeedV2RecomGetQueryOptions = <TData = Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError = QueryRecomChannelsFeedV2RecomGetErrorType<HTTPValidationError>>(params?: QueryRecomChannelsFeedV2RecomGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryRecomChannelsFeedV2RecomGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>> = ({ signal }) => queryRecomChannelsFeedV2RecomGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryRecomChannelsFeedV2RecomGetQueryResult = NonNullable<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>>
export type QueryRecomChannelsFeedV2RecomGetQueryError = QueryRecomChannelsFeedV2RecomGetErrorType<HTTPValidationError>

/**
 * @summary Query Recom Channels
 */
export const useQueryRecomChannelsFeedV2RecomGet = <TData = Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError = QueryRecomChannelsFeedV2RecomGetErrorType<HTTPValidationError>>(
 params?: QueryRecomChannelsFeedV2RecomGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryRecomChannelsFeedV2RecomGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getQueryRecomChannelsFeedV2RecomGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError = QueryRecomChannelsFeedV2RecomGetErrorType<HTTPValidationError>>(params?: QueryRecomChannelsFeedV2RecomGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryRecomChannelsFeedV2RecomGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>> = ({ signal }) => queryRecomChannelsFeedV2RecomGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryRecomChannelsFeedV2RecomGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>>
export type QueryRecomChannelsFeedV2RecomGetSuspenseQueryError = QueryRecomChannelsFeedV2RecomGetErrorType<HTTPValidationError>

/**
 * @summary Query Recom Channels
 */
export const useQueryRecomChannelsFeedV2RecomGetSuspense = <TData = Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError = QueryRecomChannelsFeedV2RecomGetErrorType<HTTPValidationError>>(
 params?: QueryRecomChannelsFeedV2RecomGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof queryRecomChannelsFeedV2RecomGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryRecomChannelsFeedV2RecomGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Join Recom Channels
 */
export const joinRecomChannelsFeedV2RecomRidJoinPost = (
    { rid }: JoinRecomChannelsFeedV2RecomRidJoinPostPathParameters,
 ) => {
      
      
      return joinRecomChannelsFeedV2RecomRidJoinPostMutator<JoinRecomChannelResponse>(
      {url: `/feed/v2/recom/${rid}/join`, method: 'POST'
    },
      );
    }
  


export const getJoinRecomChannelsFeedV2RecomRidJoinPostMutationOptions = <TError = JoinRecomChannelsFeedV2RecomRidJoinPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof joinRecomChannelsFeedV2RecomRidJoinPost>>, TError,{pathParams: JoinRecomChannelsFeedV2RecomRidJoinPostPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof joinRecomChannelsFeedV2RecomRidJoinPost>>, TError,{pathParams: JoinRecomChannelsFeedV2RecomRidJoinPostPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof joinRecomChannelsFeedV2RecomRidJoinPost>>, {pathParams: JoinRecomChannelsFeedV2RecomRidJoinPostPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  joinRecomChannelsFeedV2RecomRidJoinPost(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type JoinRecomChannelsFeedV2RecomRidJoinPostMutationResult = NonNullable<Awaited<ReturnType<typeof joinRecomChannelsFeedV2RecomRidJoinPost>>>
    
    export type JoinRecomChannelsFeedV2RecomRidJoinPostMutationError = JoinRecomChannelsFeedV2RecomRidJoinPostErrorType<HTTPValidationError>

    /**
 * @summary Join Recom Channels
 */
export const useJoinRecomChannelsFeedV2RecomRidJoinPost = <TError = JoinRecomChannelsFeedV2RecomRidJoinPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof joinRecomChannelsFeedV2RecomRidJoinPost>>, TError,{pathParams: JoinRecomChannelsFeedV2RecomRidJoinPostPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof joinRecomChannelsFeedV2RecomRidJoinPost>>,
        TError,
        {pathParams: JoinRecomChannelsFeedV2RecomRidJoinPostPathParameters},
        TContext
      > => {

      const mutationOptions = getJoinRecomChannelsFeedV2RecomRidJoinPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List User Calendar Events
 */
export const listUserCalendarEventsUserCalendarEventsGet = (
    params: ListUserCalendarEventsUserCalendarEventsGetParams,
 signal?: AbortSignal
) => {
      
      
      return listUserCalendarEventsUserCalendarEventsGetMutator<ListUserCalendarEventsResponse>(
      {url: `/user/calendar/events`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListUserCalendarEventsUserCalendarEventsGetQueryKey = (params: ListUserCalendarEventsUserCalendarEventsGetParams,) => {
    return [`/user/calendar/events`, ...(params ? [params]: [])] as const;
    }

    
export const getListUserCalendarEventsUserCalendarEventsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, ListUserCalendarEventsUserCalendarEventsGetParams['after']>, TError = ListUserCalendarEventsUserCalendarEventsGetErrorType<HTTPValidationError>>(params: ListUserCalendarEventsUserCalendarEventsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError, TData, Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, QueryKey, ListUserCalendarEventsUserCalendarEventsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserCalendarEventsUserCalendarEventsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, QueryKey, ListUserCalendarEventsUserCalendarEventsGetParams['after']> = ({ signal, pageParam }) => listUserCalendarEventsUserCalendarEventsGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError, TData, Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, QueryKey, ListUserCalendarEventsUserCalendarEventsGetParams['after']> & { queryKey: QueryKey }
}

export type ListUserCalendarEventsUserCalendarEventsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>>
export type ListUserCalendarEventsUserCalendarEventsGetInfiniteQueryError = ListUserCalendarEventsUserCalendarEventsGetErrorType<HTTPValidationError>

/**
 * @summary List User Calendar Events
 */
export const useListUserCalendarEventsUserCalendarEventsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, ListUserCalendarEventsUserCalendarEventsGetParams['after']>, TError = ListUserCalendarEventsUserCalendarEventsGetErrorType<HTTPValidationError>>(
 params: ListUserCalendarEventsUserCalendarEventsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError, TData, Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, QueryKey, ListUserCalendarEventsUserCalendarEventsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserCalendarEventsUserCalendarEventsGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListUserCalendarEventsUserCalendarEventsGetQueryOptions = <TData = Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError = ListUserCalendarEventsUserCalendarEventsGetErrorType<HTTPValidationError>>(params: ListUserCalendarEventsUserCalendarEventsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserCalendarEventsUserCalendarEventsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>> = ({ signal }) => listUserCalendarEventsUserCalendarEventsGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListUserCalendarEventsUserCalendarEventsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>>
export type ListUserCalendarEventsUserCalendarEventsGetQueryError = ListUserCalendarEventsUserCalendarEventsGetErrorType<HTTPValidationError>

/**
 * @summary List User Calendar Events
 */
export const useListUserCalendarEventsUserCalendarEventsGet = <TData = Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError = ListUserCalendarEventsUserCalendarEventsGetErrorType<HTTPValidationError>>(
 params: ListUserCalendarEventsUserCalendarEventsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserCalendarEventsUserCalendarEventsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListUserCalendarEventsUserCalendarEventsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError = ListUserCalendarEventsUserCalendarEventsGetErrorType<HTTPValidationError>>(params: ListUserCalendarEventsUserCalendarEventsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserCalendarEventsUserCalendarEventsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>> = ({ signal }) => listUserCalendarEventsUserCalendarEventsGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListUserCalendarEventsUserCalendarEventsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>>
export type ListUserCalendarEventsUserCalendarEventsGetSuspenseQueryError = ListUserCalendarEventsUserCalendarEventsGetErrorType<HTTPValidationError>

/**
 * @summary List User Calendar Events
 */
export const useListUserCalendarEventsUserCalendarEventsGetSuspense = <TData = Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError = ListUserCalendarEventsUserCalendarEventsGetErrorType<HTTPValidationError>>(
 params: ListUserCalendarEventsUserCalendarEventsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsUserCalendarEventsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserCalendarEventsUserCalendarEventsGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get User Calendar Event
 */
export const getUserCalendarEventUserCalendarEventsEventUuidGet = (
    { eventUuid }: GetUserCalendarEventUserCalendarEventsEventUuidGetPathParameters,
    params?: GetUserCalendarEventUserCalendarEventsEventUuidGetParams,
 signal?: AbortSignal
) => {
      
      
      return getUserCalendarEventUserCalendarEventsEventUuidGetMutator<ListUserCalendarEventsResponseEvent>(
      {url: `/user/calendar/events/${eventUuid}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetUserCalendarEventUserCalendarEventsEventUuidGetQueryKey = ({ eventUuid }: GetUserCalendarEventUserCalendarEventsEventUuidGetPathParameters,
    params?: GetUserCalendarEventUserCalendarEventsEventUuidGetParams,) => {
    return [`/user/calendar/events/${eventUuid}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserCalendarEventUserCalendarEventsEventUuidGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, GetUserCalendarEventUserCalendarEventsEventUuidGetParams['after']>, TError = GetUserCalendarEventUserCalendarEventsEventUuidGetErrorType<HTTPValidationError>>({ eventUuid }: GetUserCalendarEventUserCalendarEventsEventUuidGetPathParameters,
    params?: GetUserCalendarEventUserCalendarEventsEventUuidGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError, TData, Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, QueryKey, GetUserCalendarEventUserCalendarEventsEventUuidGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserCalendarEventUserCalendarEventsEventUuidGetQueryKey({ eventUuid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, QueryKey, GetUserCalendarEventUserCalendarEventsEventUuidGetParams['after']> = ({ signal, pageParam }) => getUserCalendarEventUserCalendarEventsEventUuidGet({ eventUuid },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError, TData, Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, QueryKey, GetUserCalendarEventUserCalendarEventsEventUuidGetParams['after']> & { queryKey: QueryKey }
}

export type GetUserCalendarEventUserCalendarEventsEventUuidGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>>
export type GetUserCalendarEventUserCalendarEventsEventUuidGetInfiniteQueryError = GetUserCalendarEventUserCalendarEventsEventUuidGetErrorType<HTTPValidationError>

/**
 * @summary Get User Calendar Event
 */
export const useGetUserCalendarEventUserCalendarEventsEventUuidGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, GetUserCalendarEventUserCalendarEventsEventUuidGetParams['after']>, TError = GetUserCalendarEventUserCalendarEventsEventUuidGetErrorType<HTTPValidationError>>(
 { eventUuid }: GetUserCalendarEventUserCalendarEventsEventUuidGetPathParameters,
    params?: GetUserCalendarEventUserCalendarEventsEventUuidGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError, TData, Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, QueryKey, GetUserCalendarEventUserCalendarEventsEventUuidGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserCalendarEventUserCalendarEventsEventUuidGetInfiniteQueryOptions({ eventUuid },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserCalendarEventUserCalendarEventsEventUuidGetQueryOptions = <TData = Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError = GetUserCalendarEventUserCalendarEventsEventUuidGetErrorType<HTTPValidationError>>({ eventUuid }: GetUserCalendarEventUserCalendarEventsEventUuidGetPathParameters,
    params?: GetUserCalendarEventUserCalendarEventsEventUuidGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserCalendarEventUserCalendarEventsEventUuidGetQueryKey({ eventUuid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>> = ({ signal }) => getUserCalendarEventUserCalendarEventsEventUuidGet({ eventUuid },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserCalendarEventUserCalendarEventsEventUuidGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>>
export type GetUserCalendarEventUserCalendarEventsEventUuidGetQueryError = GetUserCalendarEventUserCalendarEventsEventUuidGetErrorType<HTTPValidationError>

/**
 * @summary Get User Calendar Event
 */
export const useGetUserCalendarEventUserCalendarEventsEventUuidGet = <TData = Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError = GetUserCalendarEventUserCalendarEventsEventUuidGetErrorType<HTTPValidationError>>(
 { eventUuid }: GetUserCalendarEventUserCalendarEventsEventUuidGetPathParameters,
    params?: GetUserCalendarEventUserCalendarEventsEventUuidGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserCalendarEventUserCalendarEventsEventUuidGetQueryOptions({ eventUuid },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserCalendarEventUserCalendarEventsEventUuidGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError = GetUserCalendarEventUserCalendarEventsEventUuidGetErrorType<HTTPValidationError>>({ eventUuid }: GetUserCalendarEventUserCalendarEventsEventUuidGetPathParameters,
    params?: GetUserCalendarEventUserCalendarEventsEventUuidGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserCalendarEventUserCalendarEventsEventUuidGetQueryKey({ eventUuid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>> = ({ signal }) => getUserCalendarEventUserCalendarEventsEventUuidGet({ eventUuid },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserCalendarEventUserCalendarEventsEventUuidGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>>
export type GetUserCalendarEventUserCalendarEventsEventUuidGetSuspenseQueryError = GetUserCalendarEventUserCalendarEventsEventUuidGetErrorType<HTTPValidationError>

/**
 * @summary Get User Calendar Event
 */
export const useGetUserCalendarEventUserCalendarEventsEventUuidGetSuspense = <TData = Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError = GetUserCalendarEventUserCalendarEventsEventUuidGetErrorType<HTTPValidationError>>(
 { eventUuid }: GetUserCalendarEventUserCalendarEventsEventUuidGetPathParameters,
    params?: GetUserCalendarEventUserCalendarEventsEventUuidGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventUserCalendarEventsEventUuidGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserCalendarEventUserCalendarEventsEventUuidGetSuspenseQueryOptions({ eventUuid },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Delete User Calendar Event
 */
export const deleteUserCalendarEventUserCalendarEventsEventUuidDelete = (
    { eventUuid }: DeleteUserCalendarEventUserCalendarEventsEventUuidDeletePathParameters,
    params?: DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteParams,
 ) => {
      
      
      return deleteUserCalendarEventUserCalendarEventsEventUuidDeleteMutator<void>(
      {url: `/user/calendar/events/${eventUuid}`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteUserCalendarEventUserCalendarEventsEventUuidDeleteMutationOptions = <TError = DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserCalendarEventUserCalendarEventsEventUuidDelete>>, TError,{pathParams: DeleteUserCalendarEventUserCalendarEventsEventUuidDeletePathParameters;params?: DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteUserCalendarEventUserCalendarEventsEventUuidDelete>>, TError,{pathParams: DeleteUserCalendarEventUserCalendarEventsEventUuidDeletePathParameters;params?: DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserCalendarEventUserCalendarEventsEventUuidDelete>>, {pathParams: DeleteUserCalendarEventUserCalendarEventsEventUuidDeletePathParameters;params?: DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteParams}> = (props) => {
          const {pathParams,params} = props ?? {};

          return  deleteUserCalendarEventUserCalendarEventsEventUuidDelete(pathParams,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserCalendarEventUserCalendarEventsEventUuidDelete>>>
    
    export type DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteMutationError = DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete User Calendar Event
 */
export const useDeleteUserCalendarEventUserCalendarEventsEventUuidDelete = <TError = DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserCalendarEventUserCalendarEventsEventUuidDelete>>, TError,{pathParams: DeleteUserCalendarEventUserCalendarEventsEventUuidDeletePathParameters;params?: DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteUserCalendarEventUserCalendarEventsEventUuidDelete>>,
        TError,
        {pathParams: DeleteUserCalendarEventUserCalendarEventsEventUuidDeletePathParameters;params?: DeleteUserCalendarEventUserCalendarEventsEventUuidDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteUserCalendarEventUserCalendarEventsEventUuidDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Patch User Calendar Event
 */
export const patchUserCalendarEventUserCalendarEventsEventUuidPatch = (
    { eventUuid }: PatchUserCalendarEventUserCalendarEventsEventUuidPatchPathParameters,
    patchEventRequest: PatchEventRequest,
    params?: PatchUserCalendarEventUserCalendarEventsEventUuidPatchParams,
 ) => {
      
      
      return patchUserCalendarEventUserCalendarEventsEventUuidPatchMutator<void>(
      {url: `/user/calendar/events/${eventUuid}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchEventRequest,
        params
    },
      );
    }
  


export const getPatchUserCalendarEventUserCalendarEventsEventUuidPatchMutationOptions = <TError = PatchUserCalendarEventUserCalendarEventsEventUuidPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchUserCalendarEventUserCalendarEventsEventUuidPatch>>, TError,{pathParams: PatchUserCalendarEventUserCalendarEventsEventUuidPatchPathParameters;data: PatchEventRequest;params?: PatchUserCalendarEventUserCalendarEventsEventUuidPatchParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patchUserCalendarEventUserCalendarEventsEventUuidPatch>>, TError,{pathParams: PatchUserCalendarEventUserCalendarEventsEventUuidPatchPathParameters;data: PatchEventRequest;params?: PatchUserCalendarEventUserCalendarEventsEventUuidPatchParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchUserCalendarEventUserCalendarEventsEventUuidPatch>>, {pathParams: PatchUserCalendarEventUserCalendarEventsEventUuidPatchPathParameters;data: PatchEventRequest;params?: PatchUserCalendarEventUserCalendarEventsEventUuidPatchParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  patchUserCalendarEventUserCalendarEventsEventUuidPatch(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchUserCalendarEventUserCalendarEventsEventUuidPatchMutationResult = NonNullable<Awaited<ReturnType<typeof patchUserCalendarEventUserCalendarEventsEventUuidPatch>>>
    export type PatchUserCalendarEventUserCalendarEventsEventUuidPatchMutationBody = PatchEventRequest
    export type PatchUserCalendarEventUserCalendarEventsEventUuidPatchMutationError = PatchUserCalendarEventUserCalendarEventsEventUuidPatchErrorType<HTTPValidationError>

    /**
 * @summary Patch User Calendar Event
 */
export const usePatchUserCalendarEventUserCalendarEventsEventUuidPatch = <TError = PatchUserCalendarEventUserCalendarEventsEventUuidPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchUserCalendarEventUserCalendarEventsEventUuidPatch>>, TError,{pathParams: PatchUserCalendarEventUserCalendarEventsEventUuidPatchPathParameters;data: PatchEventRequest;params?: PatchUserCalendarEventUserCalendarEventsEventUuidPatchParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patchUserCalendarEventUserCalendarEventsEventUuidPatch>>,
        TError,
        {pathParams: PatchUserCalendarEventUserCalendarEventsEventUuidPatchPathParameters;data: PatchEventRequest;params?: PatchUserCalendarEventUserCalendarEventsEventUuidPatchParams},
        TContext
      > => {

      const mutationOptions = getPatchUserCalendarEventUserCalendarEventsEventUuidPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Shared Event Action
 */
export const createSharedEventActionUserCalendarSharedEventsPost = (
    sharedEventCreateRequest: SharedEventCreateRequest,
 ) => {
      
      
      return createSharedEventActionUserCalendarSharedEventsPostMutator<SharedEventInfo>(
      {url: `/user/calendar/shared-events`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sharedEventCreateRequest
    },
      );
    }
  


export const getCreateSharedEventActionUserCalendarSharedEventsPostMutationOptions = <TError = CreateSharedEventActionUserCalendarSharedEventsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSharedEventActionUserCalendarSharedEventsPost>>, TError,{data: SharedEventCreateRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createSharedEventActionUserCalendarSharedEventsPost>>, TError,{data: SharedEventCreateRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSharedEventActionUserCalendarSharedEventsPost>>, {data: SharedEventCreateRequest}> = (props) => {
          const {data} = props ?? {};

          return  createSharedEventActionUserCalendarSharedEventsPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateSharedEventActionUserCalendarSharedEventsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createSharedEventActionUserCalendarSharedEventsPost>>>
    export type CreateSharedEventActionUserCalendarSharedEventsPostMutationBody = SharedEventCreateRequest
    export type CreateSharedEventActionUserCalendarSharedEventsPostMutationError = CreateSharedEventActionUserCalendarSharedEventsPostErrorType<HTTPValidationError>

    /**
 * @summary Create Shared Event Action
 */
export const useCreateSharedEventActionUserCalendarSharedEventsPost = <TError = CreateSharedEventActionUserCalendarSharedEventsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSharedEventActionUserCalendarSharedEventsPost>>, TError,{data: SharedEventCreateRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createSharedEventActionUserCalendarSharedEventsPost>>,
        TError,
        {data: SharedEventCreateRequest},
        TContext
      > => {

      const mutationOptions = getCreateSharedEventActionUserCalendarSharedEventsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Shared Event Action
 */
export const getSharedEventActionUserCalendarSharedEventsShareKeyGet = (
    { shareKey }: GetSharedEventActionUserCalendarSharedEventsShareKeyGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getSharedEventActionUserCalendarSharedEventsShareKeyGetMutator<ListUserCalendarEventsResponseEvent>(
      {url: `/user/calendar/shared-events/${shareKey}`, method: 'GET', signal
    },
      );
    }
  

export const getGetSharedEventActionUserCalendarSharedEventsShareKeyGetQueryKey = ({ shareKey }: GetSharedEventActionUserCalendarSharedEventsShareKeyGetPathParameters,) => {
    return [`/user/calendar/shared-events/${shareKey}`] as const;
    }

    
export const getGetSharedEventActionUserCalendarSharedEventsShareKeyGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>>, TError = GetSharedEventActionUserCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>>({ shareKey }: GetSharedEventActionUserCalendarSharedEventsShareKeyGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSharedEventActionUserCalendarSharedEventsShareKeyGetQueryKey({ shareKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>> = ({ signal }) => getSharedEventActionUserCalendarSharedEventsShareKeyGet({ shareKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSharedEventActionUserCalendarSharedEventsShareKeyGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>>
export type GetSharedEventActionUserCalendarSharedEventsShareKeyGetInfiniteQueryError = GetSharedEventActionUserCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>

/**
 * @summary Get Shared Event Action
 */
export const useGetSharedEventActionUserCalendarSharedEventsShareKeyGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>>, TError = GetSharedEventActionUserCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>>(
 { shareKey }: GetSharedEventActionUserCalendarSharedEventsShareKeyGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSharedEventActionUserCalendarSharedEventsShareKeyGetInfiniteQueryOptions({ shareKey },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSharedEventActionUserCalendarSharedEventsShareKeyGetQueryOptions = <TData = Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError = GetSharedEventActionUserCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>>({ shareKey }: GetSharedEventActionUserCalendarSharedEventsShareKeyGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSharedEventActionUserCalendarSharedEventsShareKeyGetQueryKey({ shareKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>> = ({ signal }) => getSharedEventActionUserCalendarSharedEventsShareKeyGet({ shareKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSharedEventActionUserCalendarSharedEventsShareKeyGetQueryResult = NonNullable<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>>
export type GetSharedEventActionUserCalendarSharedEventsShareKeyGetQueryError = GetSharedEventActionUserCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>

/**
 * @summary Get Shared Event Action
 */
export const useGetSharedEventActionUserCalendarSharedEventsShareKeyGet = <TData = Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError = GetSharedEventActionUserCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>>(
 { shareKey }: GetSharedEventActionUserCalendarSharedEventsShareKeyGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSharedEventActionUserCalendarSharedEventsShareKeyGetQueryOptions({ shareKey },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSharedEventActionUserCalendarSharedEventsShareKeyGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError = GetSharedEventActionUserCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>>({ shareKey }: GetSharedEventActionUserCalendarSharedEventsShareKeyGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSharedEventActionUserCalendarSharedEventsShareKeyGetQueryKey({ shareKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>> = ({ signal }) => getSharedEventActionUserCalendarSharedEventsShareKeyGet({ shareKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSharedEventActionUserCalendarSharedEventsShareKeyGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>>
export type GetSharedEventActionUserCalendarSharedEventsShareKeyGetSuspenseQueryError = GetSharedEventActionUserCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>

/**
 * @summary Get Shared Event Action
 */
export const useGetSharedEventActionUserCalendarSharedEventsShareKeyGetSuspense = <TData = Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError = GetSharedEventActionUserCalendarSharedEventsShareKeyGetErrorType<HTTPValidationError>>(
 { shareKey }: GetSharedEventActionUserCalendarSharedEventsShareKeyGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSharedEventActionUserCalendarSharedEventsShareKeyGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSharedEventActionUserCalendarSharedEventsShareKeyGetSuspenseQueryOptions({ shareKey },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Join Shared Event Action
 */
export const joinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPost = (
    { shareKey }: JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostPathParameters,
 ) => {
      
      
      return joinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostMutator<unknown>(
      {url: `/user/calendar/shared-events/${shareKey}/attendees`, method: 'POST'
    },
      );
    }
  


export const getJoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostMutationOptions = <TError = JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof joinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPost>>, TError,{pathParams: JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof joinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPost>>, TError,{pathParams: JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof joinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPost>>, {pathParams: JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  joinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPost(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostMutationResult = NonNullable<Awaited<ReturnType<typeof joinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPost>>>
    
    export type JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostMutationError = JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostErrorType<HTTPValidationError>

    /**
 * @summary Join Shared Event Action
 */
export const useJoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPost = <TError = JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof joinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPost>>, TError,{pathParams: JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof joinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPost>>,
        TError,
        {pathParams: JoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostPathParameters},
        TContext
      > => {

      const mutationOptions = getJoinSharedEventActionUserCalendarSharedEventsShareKeyAttendeesPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deactivate User
 */
export const deactivateUserUserDeactivatePost = (
    deactivateUserRequest: DeactivateUserRequest,
 ) => {
      
      
      return deactivateUserUserDeactivatePostMutator<DeactivateUserResponse>(
      {url: `/user/deactivate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: deactivateUserRequest
    },
      );
    }
  


export const getDeactivateUserUserDeactivatePostMutationOptions = <TError = DeactivateUserUserDeactivatePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deactivateUserUserDeactivatePost>>, TError,{data: DeactivateUserRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deactivateUserUserDeactivatePost>>, TError,{data: DeactivateUserRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deactivateUserUserDeactivatePost>>, {data: DeactivateUserRequest}> = (props) => {
          const {data} = props ?? {};

          return  deactivateUserUserDeactivatePost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeactivateUserUserDeactivatePostMutationResult = NonNullable<Awaited<ReturnType<typeof deactivateUserUserDeactivatePost>>>
    export type DeactivateUserUserDeactivatePostMutationBody = DeactivateUserRequest
    export type DeactivateUserUserDeactivatePostMutationError = DeactivateUserUserDeactivatePostErrorType<HTTPValidationError>

    /**
 * @summary Deactivate User
 */
export const useDeactivateUserUserDeactivatePost = <TError = DeactivateUserUserDeactivatePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deactivateUserUserDeactivatePost>>, TError,{data: DeactivateUserRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deactivateUserUserDeactivatePost>>,
        TError,
        {data: DeactivateUserRequest},
        TContext
      > => {

      const mutationOptions = getDeactivateUserUserDeactivatePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Give Feedback
 */
export const giveFeedbackUserFeedbackPost = (
    giveFeedbackRequest: GiveFeedbackRequest,
 ) => {
      
      
      return giveFeedbackUserFeedbackPostMutator<GiveFeedbackResponse>(
      {url: `/user/feedback`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: giveFeedbackRequest
    },
      );
    }
  


export const getGiveFeedbackUserFeedbackPostMutationOptions = <TError = GiveFeedbackUserFeedbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveFeedbackUserFeedbackPost>>, TError,{data: GiveFeedbackRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof giveFeedbackUserFeedbackPost>>, TError,{data: GiveFeedbackRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof giveFeedbackUserFeedbackPost>>, {data: GiveFeedbackRequest}> = (props) => {
          const {data} = props ?? {};

          return  giveFeedbackUserFeedbackPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GiveFeedbackUserFeedbackPostMutationResult = NonNullable<Awaited<ReturnType<typeof giveFeedbackUserFeedbackPost>>>
    export type GiveFeedbackUserFeedbackPostMutationBody = GiveFeedbackRequest
    export type GiveFeedbackUserFeedbackPostMutationError = GiveFeedbackUserFeedbackPostErrorType<HTTPValidationError>

    /**
 * @summary Give Feedback
 */
export const useGiveFeedbackUserFeedbackPost = <TError = GiveFeedbackUserFeedbackPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof giveFeedbackUserFeedbackPost>>, TError,{data: GiveFeedbackRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof giveFeedbackUserFeedbackPost>>,
        TError,
        {data: GiveFeedbackRequest},
        TContext
      > => {

      const mutationOptions = getGiveFeedbackUserFeedbackPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get User Info
 */
export const getUserInfoUserInfoGet = (
    
 signal?: AbortSignal
) => {
      
      
      return getUserInfoUserInfoGetMutator<UserInfoResponse>(
      {url: `/user/info`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserInfoUserInfoGetQueryKey = () => {
    return [`/user/info`] as const;
    }

    
export const getGetUserInfoUserInfoGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>>, TError = GetUserInfoUserInfoGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserInfoUserInfoGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>> = ({ signal }) => getUserInfoUserInfoGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserInfoUserInfoGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>>
export type GetUserInfoUserInfoGetInfiniteQueryError = GetUserInfoUserInfoGetErrorType<unknown>

/**
 * @summary Get User Info
 */
export const useGetUserInfoUserInfoGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>>, TError = GetUserInfoUserInfoGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserInfoUserInfoGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserInfoUserInfoGetQueryOptions = <TData = Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError = GetUserInfoUserInfoGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserInfoUserInfoGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>> = ({ signal }) => getUserInfoUserInfoGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserInfoUserInfoGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>>
export type GetUserInfoUserInfoGetQueryError = GetUserInfoUserInfoGetErrorType<unknown>

/**
 * @summary Get User Info
 */
export const useGetUserInfoUserInfoGet = <TData = Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError = GetUserInfoUserInfoGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserInfoUserInfoGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserInfoUserInfoGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError = GetUserInfoUserInfoGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserInfoUserInfoGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>> = ({ signal }) => getUserInfoUserInfoGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserInfoUserInfoGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>>
export type GetUserInfoUserInfoGetSuspenseQueryError = GetUserInfoUserInfoGetErrorType<unknown>

/**
 * @summary Get User Info
 */
export const useGetUserInfoUserInfoGetSuspense = <TData = Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError = GetUserInfoUserInfoGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserInfoUserInfoGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserInfoUserInfoGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update User Settings
 */
export const updateUserSettingsUserSettingsPut = (
    userSettings: UserSettings,
 ) => {
      
      
      return updateUserSettingsUserSettingsPutMutator<PublicUserSettings>(
      {url: `/user/settings`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: userSettings
    },
      );
    }
  


export const getUpdateUserSettingsUserSettingsPutMutationOptions = <TError = UpdateUserSettingsUserSettingsPutErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserSettingsUserSettingsPut>>, TError,{data: UserSettings}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateUserSettingsUserSettingsPut>>, TError,{data: UserSettings}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserSettingsUserSettingsPut>>, {data: UserSettings}> = (props) => {
          const {data} = props ?? {};

          return  updateUserSettingsUserSettingsPut(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUserSettingsUserSettingsPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserSettingsUserSettingsPut>>>
    export type UpdateUserSettingsUserSettingsPutMutationBody = UserSettings
    export type UpdateUserSettingsUserSettingsPutMutationError = UpdateUserSettingsUserSettingsPutErrorType<HTTPValidationError>

    /**
 * @summary Update User Settings
 */
export const useUpdateUserSettingsUserSettingsPut = <TError = UpdateUserSettingsUserSettingsPutErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserSettingsUserSettingsPut>>, TError,{data: UserSettings}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateUserSettingsUserSettingsPut>>,
        TError,
        {data: UserSettings},
        TContext
      > => {

      const mutationOptions = getUpdateUserSettingsUserSettingsPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Reset Self Hosted Password
 */
export const resetSelfHostedPasswordUserAccountsSelfHostedPasswordPost = (
    
 ) => {
      
      
      return resetSelfHostedPasswordUserAccountsSelfHostedPasswordPostMutator<SelfHostedAccountInfo>(
      {url: `/user/accounts/self_hosted/password`, method: 'POST'
    },
      );
    }
  


export const getResetSelfHostedPasswordUserAccountsSelfHostedPasswordPostMutationOptions = <TError = ResetSelfHostedPasswordUserAccountsSelfHostedPasswordPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetSelfHostedPasswordUserAccountsSelfHostedPasswordPost>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof resetSelfHostedPasswordUserAccountsSelfHostedPasswordPost>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetSelfHostedPasswordUserAccountsSelfHostedPasswordPost>>, void> = () => {
          

          return  resetSelfHostedPasswordUserAccountsSelfHostedPasswordPost()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ResetSelfHostedPasswordUserAccountsSelfHostedPasswordPostMutationResult = NonNullable<Awaited<ReturnType<typeof resetSelfHostedPasswordUserAccountsSelfHostedPasswordPost>>>
    
    export type ResetSelfHostedPasswordUserAccountsSelfHostedPasswordPostMutationError = ResetSelfHostedPasswordUserAccountsSelfHostedPasswordPostErrorType<unknown>

    /**
 * @summary Reset Self Hosted Password
 */
export const useResetSelfHostedPasswordUserAccountsSelfHostedPasswordPost = <TError = ResetSelfHostedPasswordUserAccountsSelfHostedPasswordPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetSelfHostedPasswordUserAccountsSelfHostedPasswordPost>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof resetSelfHostedPasswordUserAccountsSelfHostedPasswordPost>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getResetSelfHostedPasswordUserAccountsSelfHostedPasswordPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Export Self Hosted Calendar
 */
export const exportSelfHostedCalendarUserAccountsSelfHostedExportGet = (
    
 signal?: AbortSignal
) => {
      
      
      return exportSelfHostedCalendarUserAccountsSelfHostedExportGetMutator<SelfHostedAccountExportResponse>(
      {url: `/user/accounts/self_hosted/export`, method: 'GET', signal
    },
      );
    }
  

export const getExportSelfHostedCalendarUserAccountsSelfHostedExportGetQueryKey = () => {
    return [`/user/accounts/self_hosted/export`] as const;
    }

    
export const getExportSelfHostedCalendarUserAccountsSelfHostedExportGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>>, TError = ExportSelfHostedCalendarUserAccountsSelfHostedExportGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExportSelfHostedCalendarUserAccountsSelfHostedExportGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>> = ({ signal }) => exportSelfHostedCalendarUserAccountsSelfHostedExportGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ExportSelfHostedCalendarUserAccountsSelfHostedExportGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>>
export type ExportSelfHostedCalendarUserAccountsSelfHostedExportGetInfiniteQueryError = ExportSelfHostedCalendarUserAccountsSelfHostedExportGetErrorType<unknown>

/**
 * @summary Export Self Hosted Calendar
 */
export const useExportSelfHostedCalendarUserAccountsSelfHostedExportGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>>, TError = ExportSelfHostedCalendarUserAccountsSelfHostedExportGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getExportSelfHostedCalendarUserAccountsSelfHostedExportGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getExportSelfHostedCalendarUserAccountsSelfHostedExportGetQueryOptions = <TData = Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError = ExportSelfHostedCalendarUserAccountsSelfHostedExportGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExportSelfHostedCalendarUserAccountsSelfHostedExportGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>> = ({ signal }) => exportSelfHostedCalendarUserAccountsSelfHostedExportGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ExportSelfHostedCalendarUserAccountsSelfHostedExportGetQueryResult = NonNullable<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>>
export type ExportSelfHostedCalendarUserAccountsSelfHostedExportGetQueryError = ExportSelfHostedCalendarUserAccountsSelfHostedExportGetErrorType<unknown>

/**
 * @summary Export Self Hosted Calendar
 */
export const useExportSelfHostedCalendarUserAccountsSelfHostedExportGet = <TData = Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError = ExportSelfHostedCalendarUserAccountsSelfHostedExportGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getExportSelfHostedCalendarUserAccountsSelfHostedExportGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getExportSelfHostedCalendarUserAccountsSelfHostedExportGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError = ExportSelfHostedCalendarUserAccountsSelfHostedExportGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExportSelfHostedCalendarUserAccountsSelfHostedExportGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>> = ({ signal }) => exportSelfHostedCalendarUserAccountsSelfHostedExportGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ExportSelfHostedCalendarUserAccountsSelfHostedExportGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>>
export type ExportSelfHostedCalendarUserAccountsSelfHostedExportGetSuspenseQueryError = ExportSelfHostedCalendarUserAccountsSelfHostedExportGetErrorType<unknown>

/**
 * @summary Export Self Hosted Calendar
 */
export const useExportSelfHostedCalendarUserAccountsSelfHostedExportGetSuspense = <TData = Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError = ExportSelfHostedCalendarUserAccountsSelfHostedExportGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof exportSelfHostedCalendarUserAccountsSelfHostedExportGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getExportSelfHostedCalendarUserAccountsSelfHostedExportGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Add Icloud Account
 */
export const addIcloudAccountUserAccountsIcloudPost = (
    icloudAccountInfo: IcloudAccountInfo,
 ) => {
      
      
      return addIcloudAccountUserAccountsIcloudPostMutator<unknown>(
      {url: `/user/accounts/icloud`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: icloudAccountInfo
    },
      );
    }
  


export const getAddIcloudAccountUserAccountsIcloudPostMutationOptions = <TError = AddIcloudAccountUserAccountsIcloudPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addIcloudAccountUserAccountsIcloudPost>>, TError,{data: IcloudAccountInfo}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addIcloudAccountUserAccountsIcloudPost>>, TError,{data: IcloudAccountInfo}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addIcloudAccountUserAccountsIcloudPost>>, {data: IcloudAccountInfo}> = (props) => {
          const {data} = props ?? {};

          return  addIcloudAccountUserAccountsIcloudPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddIcloudAccountUserAccountsIcloudPostMutationResult = NonNullable<Awaited<ReturnType<typeof addIcloudAccountUserAccountsIcloudPost>>>
    export type AddIcloudAccountUserAccountsIcloudPostMutationBody = IcloudAccountInfo
    export type AddIcloudAccountUserAccountsIcloudPostMutationError = AddIcloudAccountUserAccountsIcloudPostErrorType<HTTPValidationError>

    /**
 * @summary Add Icloud Account
 */
export const useAddIcloudAccountUserAccountsIcloudPost = <TError = AddIcloudAccountUserAccountsIcloudPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addIcloudAccountUserAccountsIcloudPost>>, TError,{data: IcloudAccountInfo}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof addIcloudAccountUserAccountsIcloudPost>>,
        TError,
        {data: IcloudAccountInfo},
        TContext
      > => {

      const mutationOptions = getAddIcloudAccountUserAccountsIcloudPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Bind Google Account
 */
export const bindGoogleAccountUserAccountsGoogleGet = (
    params?: BindGoogleAccountUserAccountsGoogleGetParams,
 signal?: AbortSignal
) => {
      
      
      return bindGoogleAccountUserAccountsGoogleGetMutator<GoogleBindResponse>(
      {url: `/user/accounts/google`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getBindGoogleAccountUserAccountsGoogleGetQueryKey = (params?: BindGoogleAccountUserAccountsGoogleGetParams,) => {
    return [`/user/accounts/google`, ...(params ? [params]: [])] as const;
    }

    
export const getBindGoogleAccountUserAccountsGoogleGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, BindGoogleAccountUserAccountsGoogleGetParams['after']>, TError = BindGoogleAccountUserAccountsGoogleGetErrorType<HTTPValidationError>>(params?: BindGoogleAccountUserAccountsGoogleGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError, TData, Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, QueryKey, BindGoogleAccountUserAccountsGoogleGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getBindGoogleAccountUserAccountsGoogleGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, QueryKey, BindGoogleAccountUserAccountsGoogleGetParams['after']> = ({ signal, pageParam }) => bindGoogleAccountUserAccountsGoogleGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError, TData, Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, QueryKey, BindGoogleAccountUserAccountsGoogleGetParams['after']> & { queryKey: QueryKey }
}

export type BindGoogleAccountUserAccountsGoogleGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>>
export type BindGoogleAccountUserAccountsGoogleGetInfiniteQueryError = BindGoogleAccountUserAccountsGoogleGetErrorType<HTTPValidationError>

/**
 * @summary Bind Google Account
 */
export const useBindGoogleAccountUserAccountsGoogleGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, BindGoogleAccountUserAccountsGoogleGetParams['after']>, TError = BindGoogleAccountUserAccountsGoogleGetErrorType<HTTPValidationError>>(
 params?: BindGoogleAccountUserAccountsGoogleGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError, TData, Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, QueryKey, BindGoogleAccountUserAccountsGoogleGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getBindGoogleAccountUserAccountsGoogleGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getBindGoogleAccountUserAccountsGoogleGetQueryOptions = <TData = Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError = BindGoogleAccountUserAccountsGoogleGetErrorType<HTTPValidationError>>(params?: BindGoogleAccountUserAccountsGoogleGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getBindGoogleAccountUserAccountsGoogleGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>> = ({ signal }) => bindGoogleAccountUserAccountsGoogleGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError, TData> & { queryKey: QueryKey }
}

export type BindGoogleAccountUserAccountsGoogleGetQueryResult = NonNullable<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>>
export type BindGoogleAccountUserAccountsGoogleGetQueryError = BindGoogleAccountUserAccountsGoogleGetErrorType<HTTPValidationError>

/**
 * @summary Bind Google Account
 */
export const useBindGoogleAccountUserAccountsGoogleGet = <TData = Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError = BindGoogleAccountUserAccountsGoogleGetErrorType<HTTPValidationError>>(
 params?: BindGoogleAccountUserAccountsGoogleGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getBindGoogleAccountUserAccountsGoogleGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getBindGoogleAccountUserAccountsGoogleGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError = BindGoogleAccountUserAccountsGoogleGetErrorType<HTTPValidationError>>(params?: BindGoogleAccountUserAccountsGoogleGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getBindGoogleAccountUserAccountsGoogleGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>> = ({ signal }) => bindGoogleAccountUserAccountsGoogleGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError, TData> & { queryKey: QueryKey }
}

export type BindGoogleAccountUserAccountsGoogleGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>>
export type BindGoogleAccountUserAccountsGoogleGetSuspenseQueryError = BindGoogleAccountUserAccountsGoogleGetErrorType<HTTPValidationError>

/**
 * @summary Bind Google Account
 */
export const useBindGoogleAccountUserAccountsGoogleGetSuspense = <TData = Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError = BindGoogleAccountUserAccountsGoogleGetErrorType<HTTPValidationError>>(
 params?: BindGoogleAccountUserAccountsGoogleGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof bindGoogleAccountUserAccountsGoogleGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getBindGoogleAccountUserAccountsGoogleGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Unbind Calendar Account
 */
export const unbindCalendarAccountUserAccountsProviderDelete = (
    { provider }: UnbindCalendarAccountUserAccountsProviderDeletePathParameters,
 ) => {
      
      
      return unbindCalendarAccountUserAccountsProviderDeleteMutator<unknown>(
      {url: `/user/accounts/${provider}`, method: 'DELETE'
    },
      );
    }
  


export const getUnbindCalendarAccountUserAccountsProviderDeleteMutationOptions = <TError = UnbindCalendarAccountUserAccountsProviderDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unbindCalendarAccountUserAccountsProviderDelete>>, TError,{pathParams: UnbindCalendarAccountUserAccountsProviderDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof unbindCalendarAccountUserAccountsProviderDelete>>, TError,{pathParams: UnbindCalendarAccountUserAccountsProviderDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unbindCalendarAccountUserAccountsProviderDelete>>, {pathParams: UnbindCalendarAccountUserAccountsProviderDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  unbindCalendarAccountUserAccountsProviderDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UnbindCalendarAccountUserAccountsProviderDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof unbindCalendarAccountUserAccountsProviderDelete>>>
    
    export type UnbindCalendarAccountUserAccountsProviderDeleteMutationError = UnbindCalendarAccountUserAccountsProviderDeleteErrorType<HTTPValidationError>

    /**
 * @summary Unbind Calendar Account
 */
export const useUnbindCalendarAccountUserAccountsProviderDelete = <TError = UnbindCalendarAccountUserAccountsProviderDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unbindCalendarAccountUserAccountsProviderDelete>>, TError,{pathParams: UnbindCalendarAccountUserAccountsProviderDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof unbindCalendarAccountUserAccountsProviderDelete>>,
        TError,
        {pathParams: UnbindCalendarAccountUserAccountsProviderDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getUnbindCalendarAccountUserAccountsProviderDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Calendars
 */
export const listCalendarsUserCalendarsGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listCalendarsUserCalendarsGetMutator<CalendarInfoResponse[]>(
      {url: `/user/calendars`, method: 'GET', signal
    },
      );
    }
  

export const getListCalendarsUserCalendarsGetQueryKey = () => {
    return [`/user/calendars`] as const;
    }

    
export const getListCalendarsUserCalendarsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>>, TError = ListCalendarsUserCalendarsGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCalendarsUserCalendarsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>> = ({ signal }) => listCalendarsUserCalendarsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListCalendarsUserCalendarsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>>
export type ListCalendarsUserCalendarsGetInfiniteQueryError = ListCalendarsUserCalendarsGetErrorType<unknown>

/**
 * @summary List Calendars
 */
export const useListCalendarsUserCalendarsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>>, TError = ListCalendarsUserCalendarsGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCalendarsUserCalendarsGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListCalendarsUserCalendarsGetQueryOptions = <TData = Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError = ListCalendarsUserCalendarsGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCalendarsUserCalendarsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>> = ({ signal }) => listCalendarsUserCalendarsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListCalendarsUserCalendarsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>>
export type ListCalendarsUserCalendarsGetQueryError = ListCalendarsUserCalendarsGetErrorType<unknown>

/**
 * @summary List Calendars
 */
export const useListCalendarsUserCalendarsGet = <TData = Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError = ListCalendarsUserCalendarsGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCalendarsUserCalendarsGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListCalendarsUserCalendarsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError = ListCalendarsUserCalendarsGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCalendarsUserCalendarsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>> = ({ signal }) => listCalendarsUserCalendarsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListCalendarsUserCalendarsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>>
export type ListCalendarsUserCalendarsGetSuspenseQueryError = ListCalendarsUserCalendarsGetErrorType<unknown>

/**
 * @summary List Calendars
 */
export const useListCalendarsUserCalendarsGetSuspense = <TData = Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError = ListCalendarsUserCalendarsGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listCalendarsUserCalendarsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCalendarsUserCalendarsGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Set Default Calendar
 */
export const setDefaultCalendarUserCalendarsDefaultPut = (
    defaultCalendarReqeust: DefaultCalendarReqeust,
 ) => {
      
      
      return setDefaultCalendarUserCalendarsDefaultPutMutator<unknown>(
      {url: `/user/calendars/default`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: defaultCalendarReqeust
    },
      );
    }
  


export const getSetDefaultCalendarUserCalendarsDefaultPutMutationOptions = <TError = SetDefaultCalendarUserCalendarsDefaultPutErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setDefaultCalendarUserCalendarsDefaultPut>>, TError,{data: DefaultCalendarReqeust}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof setDefaultCalendarUserCalendarsDefaultPut>>, TError,{data: DefaultCalendarReqeust}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setDefaultCalendarUserCalendarsDefaultPut>>, {data: DefaultCalendarReqeust}> = (props) => {
          const {data} = props ?? {};

          return  setDefaultCalendarUserCalendarsDefaultPut(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SetDefaultCalendarUserCalendarsDefaultPutMutationResult = NonNullable<Awaited<ReturnType<typeof setDefaultCalendarUserCalendarsDefaultPut>>>
    export type SetDefaultCalendarUserCalendarsDefaultPutMutationBody = DefaultCalendarReqeust
    export type SetDefaultCalendarUserCalendarsDefaultPutMutationError = SetDefaultCalendarUserCalendarsDefaultPutErrorType<HTTPValidationError>

    /**
 * @summary Set Default Calendar
 */
export const useSetDefaultCalendarUserCalendarsDefaultPut = <TError = SetDefaultCalendarUserCalendarsDefaultPutErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setDefaultCalendarUserCalendarsDefaultPut>>, TError,{data: DefaultCalendarReqeust}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof setDefaultCalendarUserCalendarsDefaultPut>>,
        TError,
        {data: DefaultCalendarReqeust},
        TContext
      > => {

      const mutationOptions = getSetDefaultCalendarUserCalendarsDefaultPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Create a customer session for checkout
use with stripe checkout: https://docs.stripe.com/payments/checkout/pricing-table
 * @summary Create Customer Session Action
 */
export const createCustomerSessionActionUserPaymentsCustomerSessionPost = (
    
 ) => {
      
      
      return createCustomerSessionActionUserPaymentsCustomerSessionPostMutator<CustomerSession>(
      {url: `/user/payments/customer-session`, method: 'POST'
    },
      );
    }
  


export const getCreateCustomerSessionActionUserPaymentsCustomerSessionPostMutationOptions = <TError = CreateCustomerSessionActionUserPaymentsCustomerSessionPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCustomerSessionActionUserPaymentsCustomerSessionPost>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createCustomerSessionActionUserPaymentsCustomerSessionPost>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCustomerSessionActionUserPaymentsCustomerSessionPost>>, void> = () => {
          

          return  createCustomerSessionActionUserPaymentsCustomerSessionPost()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateCustomerSessionActionUserPaymentsCustomerSessionPostMutationResult = NonNullable<Awaited<ReturnType<typeof createCustomerSessionActionUserPaymentsCustomerSessionPost>>>
    
    export type CreateCustomerSessionActionUserPaymentsCustomerSessionPostMutationError = CreateCustomerSessionActionUserPaymentsCustomerSessionPostErrorType<unknown>

    /**
 * @summary Create Customer Session Action
 */
export const useCreateCustomerSessionActionUserPaymentsCustomerSessionPost = <TError = CreateCustomerSessionActionUserPaymentsCustomerSessionPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCustomerSessionActionUserPaymentsCustomerSessionPost>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createCustomerSessionActionUserPaymentsCustomerSessionPost>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getCreateCustomerSessionActionUserPaymentsCustomerSessionPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Create a billing portal session for customer

use with stripe billing portal:
    https://docs.stripe.com/customer-management/integrate-customer-portal#redirect
 * @summary Create Billing Portal Session Action
 */
export const createBillingPortalSessionActionUserPaymentsBillingPortalSessionPost = (
    billingPortalSessionCreateRequest: BillingPortalSessionCreateRequest,
 ) => {
      
      
      return createBillingPortalSessionActionUserPaymentsBillingPortalSessionPostMutator<unknown>(
      {url: `/user/payments/billing-portal/session`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: billingPortalSessionCreateRequest
    },
      );
    }
  


export const getCreateBillingPortalSessionActionUserPaymentsBillingPortalSessionPostMutationOptions = <TError = CreateBillingPortalSessionActionUserPaymentsBillingPortalSessionPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBillingPortalSessionActionUserPaymentsBillingPortalSessionPost>>, TError,{data: BillingPortalSessionCreateRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createBillingPortalSessionActionUserPaymentsBillingPortalSessionPost>>, TError,{data: BillingPortalSessionCreateRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createBillingPortalSessionActionUserPaymentsBillingPortalSessionPost>>, {data: BillingPortalSessionCreateRequest}> = (props) => {
          const {data} = props ?? {};

          return  createBillingPortalSessionActionUserPaymentsBillingPortalSessionPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateBillingPortalSessionActionUserPaymentsBillingPortalSessionPostMutationResult = NonNullable<Awaited<ReturnType<typeof createBillingPortalSessionActionUserPaymentsBillingPortalSessionPost>>>
    export type CreateBillingPortalSessionActionUserPaymentsBillingPortalSessionPostMutationBody = BillingPortalSessionCreateRequest
    export type CreateBillingPortalSessionActionUserPaymentsBillingPortalSessionPostMutationError = CreateBillingPortalSessionActionUserPaymentsBillingPortalSessionPostErrorType<HTTPValidationError>

    /**
 * @summary Create Billing Portal Session Action
 */
export const useCreateBillingPortalSessionActionUserPaymentsBillingPortalSessionPost = <TError = CreateBillingPortalSessionActionUserPaymentsBillingPortalSessionPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBillingPortalSessionActionUserPaymentsBillingPortalSessionPost>>, TError,{data: BillingPortalSessionCreateRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createBillingPortalSessionActionUserPaymentsBillingPortalSessionPost>>,
        TError,
        {data: BillingPortalSessionCreateRequest},
        TContext
      > => {

      const mutationOptions = getCreateBillingPortalSessionActionUserPaymentsBillingPortalSessionPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List User Airnote Tables
 */
export const listUserAirnoteTablesUserAirnoteTablesGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listUserAirnoteTablesUserAirnoteTablesGetMutator<AirNoteTable[]>(
      {url: `/user/airnote/tables`, method: 'GET', signal
    },
      );
    }
  

export const getListUserAirnoteTablesUserAirnoteTablesGetQueryKey = () => {
    return [`/user/airnote/tables`] as const;
    }

    
export const getListUserAirnoteTablesUserAirnoteTablesGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>>, TError = ListUserAirnoteTablesUserAirnoteTablesGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserAirnoteTablesUserAirnoteTablesGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>> = ({ signal }) => listUserAirnoteTablesUserAirnoteTablesGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListUserAirnoteTablesUserAirnoteTablesGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>>
export type ListUserAirnoteTablesUserAirnoteTablesGetInfiniteQueryError = ListUserAirnoteTablesUserAirnoteTablesGetErrorType<unknown>

/**
 * @summary List User Airnote Tables
 */
export const useListUserAirnoteTablesUserAirnoteTablesGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>>, TError = ListUserAirnoteTablesUserAirnoteTablesGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserAirnoteTablesUserAirnoteTablesGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListUserAirnoteTablesUserAirnoteTablesGetQueryOptions = <TData = Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError = ListUserAirnoteTablesUserAirnoteTablesGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserAirnoteTablesUserAirnoteTablesGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>> = ({ signal }) => listUserAirnoteTablesUserAirnoteTablesGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListUserAirnoteTablesUserAirnoteTablesGetQueryResult = NonNullable<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>>
export type ListUserAirnoteTablesUserAirnoteTablesGetQueryError = ListUserAirnoteTablesUserAirnoteTablesGetErrorType<unknown>

/**
 * @summary List User Airnote Tables
 */
export const useListUserAirnoteTablesUserAirnoteTablesGet = <TData = Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError = ListUserAirnoteTablesUserAirnoteTablesGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserAirnoteTablesUserAirnoteTablesGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListUserAirnoteTablesUserAirnoteTablesGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError = ListUserAirnoteTablesUserAirnoteTablesGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserAirnoteTablesUserAirnoteTablesGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>> = ({ signal }) => listUserAirnoteTablesUserAirnoteTablesGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListUserAirnoteTablesUserAirnoteTablesGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>>
export type ListUserAirnoteTablesUserAirnoteTablesGetSuspenseQueryError = ListUserAirnoteTablesUserAirnoteTablesGetErrorType<unknown>

/**
 * @summary List User Airnote Tables
 */
export const useListUserAirnoteTablesUserAirnoteTablesGetSuspense = <TData = Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError = ListUserAirnoteTablesUserAirnoteTablesGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTablesUserAirnoteTablesGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserAirnoteTablesUserAirnoteTablesGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List User Airnote Table Rows
 */
export const listUserAirnoteTableRowsUserAirnoteTablesTableNameGet = (
    { tableName }: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetPathParameters,
    params?: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams,
 signal?: AbortSignal
) => {
      
      
      return listUserAirnoteTableRowsUserAirnoteTablesTableNameGetMutator<ListAirNoteTableRowsResponse>(
      {url: `/user/airnote/tables/${tableName}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListUserAirnoteTableRowsUserAirnoteTablesTableNameGetQueryKey = ({ tableName }: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetPathParameters,
    params?: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams,) => {
    return [`/user/airnote/tables/${tableName}`, ...(params ? [params]: [])] as const;
    }

    
export const getListUserAirnoteTableRowsUserAirnoteTablesTableNameGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams['after']>, TError = ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetErrorType<HTTPValidationError>>({ tableName }: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetPathParameters,
    params?: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError, TData, Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, QueryKey, ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserAirnoteTableRowsUserAirnoteTablesTableNameGetQueryKey({ tableName },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, QueryKey, ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams['after']> = ({ signal, pageParam }) => listUserAirnoteTableRowsUserAirnoteTablesTableNameGet({ tableName },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError, TData, Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, QueryKey, ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams['after']> & { queryKey: QueryKey }
}

export type ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>>
export type ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetInfiniteQueryError = ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetErrorType<HTTPValidationError>

/**
 * @summary List User Airnote Table Rows
 */
export const useListUserAirnoteTableRowsUserAirnoteTablesTableNameGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams['after']>, TError = ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetErrorType<HTTPValidationError>>(
 { tableName }: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetPathParameters,
    params?: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError, TData, Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, QueryKey, ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserAirnoteTableRowsUserAirnoteTablesTableNameGetInfiniteQueryOptions({ tableName },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListUserAirnoteTableRowsUserAirnoteTablesTableNameGetQueryOptions = <TData = Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError = ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetErrorType<HTTPValidationError>>({ tableName }: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetPathParameters,
    params?: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserAirnoteTableRowsUserAirnoteTablesTableNameGetQueryKey({ tableName },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>> = ({ signal }) => listUserAirnoteTableRowsUserAirnoteTablesTableNameGet({ tableName },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetQueryResult = NonNullable<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>>
export type ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetQueryError = ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetErrorType<HTTPValidationError>

/**
 * @summary List User Airnote Table Rows
 */
export const useListUserAirnoteTableRowsUserAirnoteTablesTableNameGet = <TData = Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError = ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetErrorType<HTTPValidationError>>(
 { tableName }: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetPathParameters,
    params?: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserAirnoteTableRowsUserAirnoteTablesTableNameGetQueryOptions({ tableName },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListUserAirnoteTableRowsUserAirnoteTablesTableNameGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError = ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetErrorType<HTTPValidationError>>({ tableName }: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetPathParameters,
    params?: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserAirnoteTableRowsUserAirnoteTablesTableNameGetQueryKey({ tableName },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>> = ({ signal }) => listUserAirnoteTableRowsUserAirnoteTablesTableNameGet({ tableName },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>>
export type ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetSuspenseQueryError = ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetErrorType<HTTPValidationError>

/**
 * @summary List User Airnote Table Rows
 */
export const useListUserAirnoteTableRowsUserAirnoteTablesTableNameGetSuspense = <TData = Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError = ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetErrorType<HTTPValidationError>>(
 { tableName }: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetPathParameters,
    params?: ListUserAirnoteTableRowsUserAirnoteTablesTableNameGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteTableRowsUserAirnoteTablesTableNameGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserAirnoteTableRowsUserAirnoteTablesTableNameGetSuspenseQueryOptions({ tableName },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List User Airnote Activity
 */
export const listUserAirnoteActivityUserAirnoteActivityGet = (
    params?: ListUserAirnoteActivityUserAirnoteActivityGetParams,
 signal?: AbortSignal
) => {
      
      
      return listUserAirnoteActivityUserAirnoteActivityGetMutator<AirNoteActivityResponse>(
      {url: `/user/airnote/activity`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListUserAirnoteActivityUserAirnoteActivityGetQueryKey = (params?: ListUserAirnoteActivityUserAirnoteActivityGetParams,) => {
    return [`/user/airnote/activity`, ...(params ? [params]: [])] as const;
    }

    
export const getListUserAirnoteActivityUserAirnoteActivityGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, ListUserAirnoteActivityUserAirnoteActivityGetParams['after']>, TError = ListUserAirnoteActivityUserAirnoteActivityGetErrorType<HTTPValidationError>>(params?: ListUserAirnoteActivityUserAirnoteActivityGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError, TData, Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, QueryKey, ListUserAirnoteActivityUserAirnoteActivityGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserAirnoteActivityUserAirnoteActivityGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, QueryKey, ListUserAirnoteActivityUserAirnoteActivityGetParams['after']> = ({ signal, pageParam }) => listUserAirnoteActivityUserAirnoteActivityGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError, TData, Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, QueryKey, ListUserAirnoteActivityUserAirnoteActivityGetParams['after']> & { queryKey: QueryKey }
}

export type ListUserAirnoteActivityUserAirnoteActivityGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>>
export type ListUserAirnoteActivityUserAirnoteActivityGetInfiniteQueryError = ListUserAirnoteActivityUserAirnoteActivityGetErrorType<HTTPValidationError>

/**
 * @summary List User Airnote Activity
 */
export const useListUserAirnoteActivityUserAirnoteActivityGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, ListUserAirnoteActivityUserAirnoteActivityGetParams['after']>, TError = ListUserAirnoteActivityUserAirnoteActivityGetErrorType<HTTPValidationError>>(
 params?: ListUserAirnoteActivityUserAirnoteActivityGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError, TData, Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, QueryKey, ListUserAirnoteActivityUserAirnoteActivityGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserAirnoteActivityUserAirnoteActivityGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListUserAirnoteActivityUserAirnoteActivityGetQueryOptions = <TData = Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError = ListUserAirnoteActivityUserAirnoteActivityGetErrorType<HTTPValidationError>>(params?: ListUserAirnoteActivityUserAirnoteActivityGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserAirnoteActivityUserAirnoteActivityGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>> = ({ signal }) => listUserAirnoteActivityUserAirnoteActivityGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListUserAirnoteActivityUserAirnoteActivityGetQueryResult = NonNullable<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>>
export type ListUserAirnoteActivityUserAirnoteActivityGetQueryError = ListUserAirnoteActivityUserAirnoteActivityGetErrorType<HTTPValidationError>

/**
 * @summary List User Airnote Activity
 */
export const useListUserAirnoteActivityUserAirnoteActivityGet = <TData = Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError = ListUserAirnoteActivityUserAirnoteActivityGetErrorType<HTTPValidationError>>(
 params?: ListUserAirnoteActivityUserAirnoteActivityGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserAirnoteActivityUserAirnoteActivityGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListUserAirnoteActivityUserAirnoteActivityGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError = ListUserAirnoteActivityUserAirnoteActivityGetErrorType<HTTPValidationError>>(params?: ListUserAirnoteActivityUserAirnoteActivityGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserAirnoteActivityUserAirnoteActivityGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>> = ({ signal }) => listUserAirnoteActivityUserAirnoteActivityGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListUserAirnoteActivityUserAirnoteActivityGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>>
export type ListUserAirnoteActivityUserAirnoteActivityGetSuspenseQueryError = ListUserAirnoteActivityUserAirnoteActivityGetErrorType<HTTPValidationError>

/**
 * @summary List User Airnote Activity
 */
export const useListUserAirnoteActivityUserAirnoteActivityGetSuspense = <TData = Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError = ListUserAirnoteActivityUserAirnoteActivityGetErrorType<HTTPValidationError>>(
 params?: ListUserAirnoteActivityUserAirnoteActivityGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserAirnoteActivityUserAirnoteActivityGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserAirnoteActivityUserAirnoteActivityGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Set Default Calendar V2
 */
export const setDefaultCalendarV2UserV2CalendarsDefaultPut = (
    defaultCalendarReqeustV2: DefaultCalendarReqeustV2,
 ) => {
      
      
      return setDefaultCalendarV2UserV2CalendarsDefaultPutMutator<UserCalendarsInfoOutput>(
      {url: `/user/v2/calendars/default`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: defaultCalendarReqeustV2
    },
      );
    }
  


export const getSetDefaultCalendarV2UserV2CalendarsDefaultPutMutationOptions = <TError = SetDefaultCalendarV2UserV2CalendarsDefaultPutErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setDefaultCalendarV2UserV2CalendarsDefaultPut>>, TError,{data: DefaultCalendarReqeustV2}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof setDefaultCalendarV2UserV2CalendarsDefaultPut>>, TError,{data: DefaultCalendarReqeustV2}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setDefaultCalendarV2UserV2CalendarsDefaultPut>>, {data: DefaultCalendarReqeustV2}> = (props) => {
          const {data} = props ?? {};

          return  setDefaultCalendarV2UserV2CalendarsDefaultPut(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SetDefaultCalendarV2UserV2CalendarsDefaultPutMutationResult = NonNullable<Awaited<ReturnType<typeof setDefaultCalendarV2UserV2CalendarsDefaultPut>>>
    export type SetDefaultCalendarV2UserV2CalendarsDefaultPutMutationBody = DefaultCalendarReqeustV2
    export type SetDefaultCalendarV2UserV2CalendarsDefaultPutMutationError = SetDefaultCalendarV2UserV2CalendarsDefaultPutErrorType<HTTPValidationError>

    /**
 * @summary Set Default Calendar V2
 */
export const useSetDefaultCalendarV2UserV2CalendarsDefaultPut = <TError = SetDefaultCalendarV2UserV2CalendarsDefaultPutErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setDefaultCalendarV2UserV2CalendarsDefaultPut>>, TError,{data: DefaultCalendarReqeustV2}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof setDefaultCalendarV2UserV2CalendarsDefaultPut>>,
        TError,
        {data: DefaultCalendarReqeustV2},
        TContext
      > => {

      const mutationOptions = getSetDefaultCalendarV2UserV2CalendarsDefaultPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Code
 */
export const getCodeUserAuthCodePost = (
    getCodeRequest: GetCodeRequest,
 ) => {
      
      
      return getCodeUserAuthCodePostMutator<unknown>(
      {url: `/user/auth/code`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getCodeRequest
    },
      );
    }
  


export const getGetCodeUserAuthCodePostMutationOptions = <TError = GetCodeUserAuthCodePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getCodeUserAuthCodePost>>, TError,{data: GetCodeRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof getCodeUserAuthCodePost>>, TError,{data: GetCodeRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getCodeUserAuthCodePost>>, {data: GetCodeRequest}> = (props) => {
          const {data} = props ?? {};

          return  getCodeUserAuthCodePost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetCodeUserAuthCodePostMutationResult = NonNullable<Awaited<ReturnType<typeof getCodeUserAuthCodePost>>>
    export type GetCodeUserAuthCodePostMutationBody = GetCodeRequest
    export type GetCodeUserAuthCodePostMutationError = GetCodeUserAuthCodePostErrorType<HTTPValidationError>

    /**
 * @summary Get Code
 */
export const useGetCodeUserAuthCodePost = <TError = GetCodeUserAuthCodePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getCodeUserAuthCodePost>>, TError,{data: GetCodeRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof getCodeUserAuthCodePost>>,
        TError,
        {data: GetCodeRequest},
        TContext
      > => {

      const mutationOptions = getGetCodeUserAuthCodePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Token
 */
export const getTokenUserAuthTokenGet = (
    params: GetTokenUserAuthTokenGetParams,
 signal?: AbortSignal
) => {
      
      
      return getTokenUserAuthTokenGetMutator<unknown>(
      {url: `/user/auth/token`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetTokenUserAuthTokenGetQueryKey = (params: GetTokenUserAuthTokenGetParams,) => {
    return [`/user/auth/token`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokenUserAuthTokenGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, GetTokenUserAuthTokenGetParams['after']>, TError = GetTokenUserAuthTokenGetErrorType<HTTPValidationError>>(params: GetTokenUserAuthTokenGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError, TData, Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, QueryKey, GetTokenUserAuthTokenGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokenUserAuthTokenGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, QueryKey, GetTokenUserAuthTokenGetParams['after']> = ({ signal, pageParam }) => getTokenUserAuthTokenGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError, TData, Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, QueryKey, GetTokenUserAuthTokenGetParams['after']> & { queryKey: QueryKey }
}

export type GetTokenUserAuthTokenGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>>
export type GetTokenUserAuthTokenGetInfiniteQueryError = GetTokenUserAuthTokenGetErrorType<HTTPValidationError>

/**
 * @summary Get Token
 */
export const useGetTokenUserAuthTokenGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, GetTokenUserAuthTokenGetParams['after']>, TError = GetTokenUserAuthTokenGetErrorType<HTTPValidationError>>(
 params: GetTokenUserAuthTokenGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError, TData, Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, QueryKey, GetTokenUserAuthTokenGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokenUserAuthTokenGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokenUserAuthTokenGetQueryOptions = <TData = Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError = GetTokenUserAuthTokenGetErrorType<HTTPValidationError>>(params: GetTokenUserAuthTokenGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokenUserAuthTokenGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>> = ({ signal }) => getTokenUserAuthTokenGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokenUserAuthTokenGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>>
export type GetTokenUserAuthTokenGetQueryError = GetTokenUserAuthTokenGetErrorType<HTTPValidationError>

/**
 * @summary Get Token
 */
export const useGetTokenUserAuthTokenGet = <TData = Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError = GetTokenUserAuthTokenGetErrorType<HTTPValidationError>>(
 params: GetTokenUserAuthTokenGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokenUserAuthTokenGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokenUserAuthTokenGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError = GetTokenUserAuthTokenGetErrorType<HTTPValidationError>>(params: GetTokenUserAuthTokenGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokenUserAuthTokenGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>> = ({ signal }) => getTokenUserAuthTokenGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokenUserAuthTokenGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>>
export type GetTokenUserAuthTokenGetSuspenseQueryError = GetTokenUserAuthTokenGetErrorType<HTTPValidationError>

/**
 * @summary Get Token
 */
export const useGetTokenUserAuthTokenGetSuspense = <TData = Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError = GetTokenUserAuthTokenGetErrorType<HTTPValidationError>>(
 params: GetTokenUserAuthTokenGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTokenUserAuthTokenGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokenUserAuthTokenGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Download File
 */
export const downloadFileExperimentalSandboxFilesFilePathGet = (
    { filePath }: DownloadFileExperimentalSandboxFilesFilePathGetPathParameters,
    params?: DownloadFileExperimentalSandboxFilesFilePathGetParams,
 signal?: AbortSignal
) => {
      
      
      return downloadFileExperimentalSandboxFilesFilePathGetMutator<unknown>(
      {url: `/experimental/sandbox/files/${filePath}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getDownloadFileExperimentalSandboxFilesFilePathGetQueryKey = ({ filePath }: DownloadFileExperimentalSandboxFilesFilePathGetPathParameters,
    params?: DownloadFileExperimentalSandboxFilesFilePathGetParams,) => {
    return [`/experimental/sandbox/files/${filePath}`, ...(params ? [params]: [])] as const;
    }

    
export const getDownloadFileExperimentalSandboxFilesFilePathGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, DownloadFileExperimentalSandboxFilesFilePathGetParams['after']>, TError = DownloadFileExperimentalSandboxFilesFilePathGetErrorType<HTTPValidationError>>({ filePath }: DownloadFileExperimentalSandboxFilesFilePathGetPathParameters,
    params?: DownloadFileExperimentalSandboxFilesFilePathGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError, TData, Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, QueryKey, DownloadFileExperimentalSandboxFilesFilePathGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadFileExperimentalSandboxFilesFilePathGetQueryKey({ filePath },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, QueryKey, DownloadFileExperimentalSandboxFilesFilePathGetParams['after']> = ({ signal, pageParam }) => downloadFileExperimentalSandboxFilesFilePathGet({ filePath },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError, TData, Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, QueryKey, DownloadFileExperimentalSandboxFilesFilePathGetParams['after']> & { queryKey: QueryKey }
}

export type DownloadFileExperimentalSandboxFilesFilePathGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>>
export type DownloadFileExperimentalSandboxFilesFilePathGetInfiniteQueryError = DownloadFileExperimentalSandboxFilesFilePathGetErrorType<HTTPValidationError>

/**
 * @summary Download File
 */
export const useDownloadFileExperimentalSandboxFilesFilePathGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, DownloadFileExperimentalSandboxFilesFilePathGetParams['after']>, TError = DownloadFileExperimentalSandboxFilesFilePathGetErrorType<HTTPValidationError>>(
 { filePath }: DownloadFileExperimentalSandboxFilesFilePathGetPathParameters,
    params?: DownloadFileExperimentalSandboxFilesFilePathGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError, TData, Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, QueryKey, DownloadFileExperimentalSandboxFilesFilePathGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadFileExperimentalSandboxFilesFilePathGetInfiniteQueryOptions({ filePath },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDownloadFileExperimentalSandboxFilesFilePathGetQueryOptions = <TData = Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError = DownloadFileExperimentalSandboxFilesFilePathGetErrorType<HTTPValidationError>>({ filePath }: DownloadFileExperimentalSandboxFilesFilePathGetPathParameters,
    params?: DownloadFileExperimentalSandboxFilesFilePathGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadFileExperimentalSandboxFilesFilePathGetQueryKey({ filePath },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>> = ({ signal }) => downloadFileExperimentalSandboxFilesFilePathGet({ filePath },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError, TData> & { queryKey: QueryKey }
}

export type DownloadFileExperimentalSandboxFilesFilePathGetQueryResult = NonNullable<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>>
export type DownloadFileExperimentalSandboxFilesFilePathGetQueryError = DownloadFileExperimentalSandboxFilesFilePathGetErrorType<HTTPValidationError>

/**
 * @summary Download File
 */
export const useDownloadFileExperimentalSandboxFilesFilePathGet = <TData = Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError = DownloadFileExperimentalSandboxFilesFilePathGetErrorType<HTTPValidationError>>(
 { filePath }: DownloadFileExperimentalSandboxFilesFilePathGetPathParameters,
    params?: DownloadFileExperimentalSandboxFilesFilePathGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadFileExperimentalSandboxFilesFilePathGetQueryOptions({ filePath },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDownloadFileExperimentalSandboxFilesFilePathGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError = DownloadFileExperimentalSandboxFilesFilePathGetErrorType<HTTPValidationError>>({ filePath }: DownloadFileExperimentalSandboxFilesFilePathGetPathParameters,
    params?: DownloadFileExperimentalSandboxFilesFilePathGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadFileExperimentalSandboxFilesFilePathGetQueryKey({ filePath },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>> = ({ signal }) => downloadFileExperimentalSandboxFilesFilePathGet({ filePath },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError, TData> & { queryKey: QueryKey }
}

export type DownloadFileExperimentalSandboxFilesFilePathGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>>
export type DownloadFileExperimentalSandboxFilesFilePathGetSuspenseQueryError = DownloadFileExperimentalSandboxFilesFilePathGetErrorType<HTTPValidationError>

/**
 * @summary Download File
 */
export const useDownloadFileExperimentalSandboxFilesFilePathGetSuspense = <TData = Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError = DownloadFileExperimentalSandboxFilesFilePathGetErrorType<HTTPValidationError>>(
 { filePath }: DownloadFileExperimentalSandboxFilesFilePathGetPathParameters,
    params?: DownloadFileExperimentalSandboxFilesFilePathGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof downloadFileExperimentalSandboxFilesFilePathGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadFileExperimentalSandboxFilesFilePathGetSuspenseQueryOptions({ filePath },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Sqlite Viewer
 */
export const sqliteViewerExperimentalSandboxSqliteViewerGet = (
    
 signal?: AbortSignal
) => {
      
      
      return sqliteViewerExperimentalSandboxSqliteViewerGetMutator<unknown>(
      {url: `/experimental/sandbox/sqlite-viewer`, method: 'GET', signal
    },
      );
    }
  

export const getSqliteViewerExperimentalSandboxSqliteViewerGetQueryKey = () => {
    return [`/experimental/sandbox/sqlite-viewer`] as const;
    }

    
export const getSqliteViewerExperimentalSandboxSqliteViewerGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>>, TError = SqliteViewerExperimentalSandboxSqliteViewerGetErrorType<HTTPValidationError>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSqliteViewerExperimentalSandboxSqliteViewerGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>> = ({ signal }) => sqliteViewerExperimentalSandboxSqliteViewerGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError, TData> & { queryKey: QueryKey }
}

export type SqliteViewerExperimentalSandboxSqliteViewerGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>>
export type SqliteViewerExperimentalSandboxSqliteViewerGetInfiniteQueryError = SqliteViewerExperimentalSandboxSqliteViewerGetErrorType<HTTPValidationError>

/**
 * @summary Sqlite Viewer
 */
export const useSqliteViewerExperimentalSandboxSqliteViewerGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>>, TError = SqliteViewerExperimentalSandboxSqliteViewerGetErrorType<HTTPValidationError>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSqliteViewerExperimentalSandboxSqliteViewerGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSqliteViewerExperimentalSandboxSqliteViewerGetQueryOptions = <TData = Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError = SqliteViewerExperimentalSandboxSqliteViewerGetErrorType<HTTPValidationError>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSqliteViewerExperimentalSandboxSqliteViewerGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>> = ({ signal }) => sqliteViewerExperimentalSandboxSqliteViewerGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError, TData> & { queryKey: QueryKey }
}

export type SqliteViewerExperimentalSandboxSqliteViewerGetQueryResult = NonNullable<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>>
export type SqliteViewerExperimentalSandboxSqliteViewerGetQueryError = SqliteViewerExperimentalSandboxSqliteViewerGetErrorType<HTTPValidationError>

/**
 * @summary Sqlite Viewer
 */
export const useSqliteViewerExperimentalSandboxSqliteViewerGet = <TData = Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError = SqliteViewerExperimentalSandboxSqliteViewerGetErrorType<HTTPValidationError>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSqliteViewerExperimentalSandboxSqliteViewerGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSqliteViewerExperimentalSandboxSqliteViewerGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError = SqliteViewerExperimentalSandboxSqliteViewerGetErrorType<HTTPValidationError>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSqliteViewerExperimentalSandboxSqliteViewerGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>> = ({ signal }) => sqliteViewerExperimentalSandboxSqliteViewerGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError, TData> & { queryKey: QueryKey }
}

export type SqliteViewerExperimentalSandboxSqliteViewerGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>>
export type SqliteViewerExperimentalSandboxSqliteViewerGetSuspenseQueryError = SqliteViewerExperimentalSandboxSqliteViewerGetErrorType<HTTPValidationError>

/**
 * @summary Sqlite Viewer
 */
export const useSqliteViewerExperimentalSandboxSqliteViewerGetSuspense = <TData = Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError = SqliteViewerExperimentalSandboxSqliteViewerGetErrorType<HTTPValidationError>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof sqliteViewerExperimentalSandboxSqliteViewerGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSqliteViewerExperimentalSandboxSqliteViewerGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List User Calendar Events
 */
export const listUserCalendarEventsExperimentalClockCalendarNextEventsGet = (
    params?: ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams,
 signal?: AbortSignal
) => {
      
      
      return listUserCalendarEventsExperimentalClockCalendarNextEventsGetMutator<ListUserCalendarEventsResponse>(
      {url: `/experimental/clock/calendar/next_events`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListUserCalendarEventsExperimentalClockCalendarNextEventsGetQueryKey = (params?: ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams,) => {
    return [`/experimental/clock/calendar/next_events`, ...(params ? [params]: [])] as const;
    }

    
export const getListUserCalendarEventsExperimentalClockCalendarNextEventsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams['after']>, TError = ListUserCalendarEventsExperimentalClockCalendarNextEventsGetErrorType<HTTPValidationError>>(params?: ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError, TData, Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, QueryKey, ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserCalendarEventsExperimentalClockCalendarNextEventsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, QueryKey, ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams['after']> = ({ signal, pageParam }) => listUserCalendarEventsExperimentalClockCalendarNextEventsGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError, TData, Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, QueryKey, ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams['after']> & { queryKey: QueryKey }
}

export type ListUserCalendarEventsExperimentalClockCalendarNextEventsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>>
export type ListUserCalendarEventsExperimentalClockCalendarNextEventsGetInfiniteQueryError = ListUserCalendarEventsExperimentalClockCalendarNextEventsGetErrorType<HTTPValidationError>

/**
 * @summary List User Calendar Events
 */
export const useListUserCalendarEventsExperimentalClockCalendarNextEventsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams['after']>, TError = ListUserCalendarEventsExperimentalClockCalendarNextEventsGetErrorType<HTTPValidationError>>(
 params?: ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError, TData, Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, QueryKey, ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserCalendarEventsExperimentalClockCalendarNextEventsGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListUserCalendarEventsExperimentalClockCalendarNextEventsGetQueryOptions = <TData = Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError = ListUserCalendarEventsExperimentalClockCalendarNextEventsGetErrorType<HTTPValidationError>>(params?: ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserCalendarEventsExperimentalClockCalendarNextEventsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>> = ({ signal }) => listUserCalendarEventsExperimentalClockCalendarNextEventsGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListUserCalendarEventsExperimentalClockCalendarNextEventsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>>
export type ListUserCalendarEventsExperimentalClockCalendarNextEventsGetQueryError = ListUserCalendarEventsExperimentalClockCalendarNextEventsGetErrorType<HTTPValidationError>

/**
 * @summary List User Calendar Events
 */
export const useListUserCalendarEventsExperimentalClockCalendarNextEventsGet = <TData = Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError = ListUserCalendarEventsExperimentalClockCalendarNextEventsGetErrorType<HTTPValidationError>>(
 params?: ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserCalendarEventsExperimentalClockCalendarNextEventsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListUserCalendarEventsExperimentalClockCalendarNextEventsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError = ListUserCalendarEventsExperimentalClockCalendarNextEventsGetErrorType<HTTPValidationError>>(params?: ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserCalendarEventsExperimentalClockCalendarNextEventsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>> = ({ signal }) => listUserCalendarEventsExperimentalClockCalendarNextEventsGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListUserCalendarEventsExperimentalClockCalendarNextEventsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>>
export type ListUserCalendarEventsExperimentalClockCalendarNextEventsGetSuspenseQueryError = ListUserCalendarEventsExperimentalClockCalendarNextEventsGetErrorType<HTTPValidationError>

/**
 * @summary List User Calendar Events
 */
export const useListUserCalendarEventsExperimentalClockCalendarNextEventsGetSuspense = <TData = Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError = ListUserCalendarEventsExperimentalClockCalendarNextEventsGetErrorType<HTTPValidationError>>(
 params?: ListUserCalendarEventsExperimentalClockCalendarNextEventsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listUserCalendarEventsExperimentalClockCalendarNextEventsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUserCalendarEventsExperimentalClockCalendarNextEventsGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Complete
 */
export const completeExperimentalOpenaiChatCompletionsPost = (
    orionBizAppExperimentalOpenaiOpenaiRequest: OrionBizAppExperimentalOpenaiOpenaiRequest,
 ) => {
      
      
      return completeExperimentalOpenaiChatCompletionsPostMutator<unknown>(
      {url: `/experimental/openai/chat/completions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: orionBizAppExperimentalOpenaiOpenaiRequest
    },
      );
    }
  


export const getCompleteExperimentalOpenaiChatCompletionsPostMutationOptions = <TError = CompleteExperimentalOpenaiChatCompletionsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof completeExperimentalOpenaiChatCompletionsPost>>, TError,{data: OrionBizAppExperimentalOpenaiOpenaiRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof completeExperimentalOpenaiChatCompletionsPost>>, TError,{data: OrionBizAppExperimentalOpenaiOpenaiRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof completeExperimentalOpenaiChatCompletionsPost>>, {data: OrionBizAppExperimentalOpenaiOpenaiRequest}> = (props) => {
          const {data} = props ?? {};

          return  completeExperimentalOpenaiChatCompletionsPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompleteExperimentalOpenaiChatCompletionsPostMutationResult = NonNullable<Awaited<ReturnType<typeof completeExperimentalOpenaiChatCompletionsPost>>>
    export type CompleteExperimentalOpenaiChatCompletionsPostMutationBody = OrionBizAppExperimentalOpenaiOpenaiRequest
    export type CompleteExperimentalOpenaiChatCompletionsPostMutationError = CompleteExperimentalOpenaiChatCompletionsPostErrorType<HTTPValidationError>

    /**
 * @summary Complete
 */
export const useCompleteExperimentalOpenaiChatCompletionsPost = <TError = CompleteExperimentalOpenaiChatCompletionsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof completeExperimentalOpenaiChatCompletionsPost>>, TError,{data: OrionBizAppExperimentalOpenaiOpenaiRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof completeExperimentalOpenaiChatCompletionsPost>>,
        TError,
        {data: OrionBizAppExperimentalOpenaiOpenaiRequest},
        TContext
      > => {

      const mutationOptions = getCompleteExperimentalOpenaiChatCompletionsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Labels
 */
export const listLabelsAdminAgentsUuidLabelsGet = (
    { uuid }: ListLabelsAdminAgentsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminAgentsUuidLabelsGetParams,
 signal?: AbortSignal
) => {
      
      
      return listLabelsAdminAgentsUuidLabelsGetMutator<DashLabel[]>(
      {url: `/admin/agents/${uuid}/labels`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListLabelsAdminAgentsUuidLabelsGetQueryKey = ({ uuid }: ListLabelsAdminAgentsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminAgentsUuidLabelsGetParams,) => {
    return [`/admin/agents/${uuid}/labels`, ...(params ? [params]: [])] as const;
    }

    
export const getListLabelsAdminAgentsUuidLabelsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, ListLabelsAdminAgentsUuidLabelsGetParams['after']>, TError = ListLabelsAdminAgentsUuidLabelsGetErrorType<HTTPValidationError>>({ uuid }: ListLabelsAdminAgentsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminAgentsUuidLabelsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, QueryKey, ListLabelsAdminAgentsUuidLabelsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminAgentsUuidLabelsGetQueryKey({ uuid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, QueryKey, ListLabelsAdminAgentsUuidLabelsGetParams['after']> = ({ signal, pageParam }) => listLabelsAdminAgentsUuidLabelsGet({ uuid },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, QueryKey, ListLabelsAdminAgentsUuidLabelsGetParams['after']> & { queryKey: QueryKey }
}

export type ListLabelsAdminAgentsUuidLabelsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>>
export type ListLabelsAdminAgentsUuidLabelsGetInfiniteQueryError = ListLabelsAdminAgentsUuidLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminAgentsUuidLabelsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, ListLabelsAdminAgentsUuidLabelsGetParams['after']>, TError = ListLabelsAdminAgentsUuidLabelsGetErrorType<HTTPValidationError>>(
 { uuid }: ListLabelsAdminAgentsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminAgentsUuidLabelsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, QueryKey, ListLabelsAdminAgentsUuidLabelsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminAgentsUuidLabelsGetInfiniteQueryOptions({ uuid },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminAgentsUuidLabelsGetQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError = ListLabelsAdminAgentsUuidLabelsGetErrorType<HTTPValidationError>>({ uuid }: ListLabelsAdminAgentsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminAgentsUuidLabelsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminAgentsUuidLabelsGetQueryKey({ uuid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>> = ({ signal }) => listLabelsAdminAgentsUuidLabelsGet({ uuid },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminAgentsUuidLabelsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>>
export type ListLabelsAdminAgentsUuidLabelsGetQueryError = ListLabelsAdminAgentsUuidLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminAgentsUuidLabelsGet = <TData = Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError = ListLabelsAdminAgentsUuidLabelsGetErrorType<HTTPValidationError>>(
 { uuid }: ListLabelsAdminAgentsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminAgentsUuidLabelsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminAgentsUuidLabelsGetQueryOptions({ uuid },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminAgentsUuidLabelsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError = ListLabelsAdminAgentsUuidLabelsGetErrorType<HTTPValidationError>>({ uuid }: ListLabelsAdminAgentsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminAgentsUuidLabelsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminAgentsUuidLabelsGetQueryKey({ uuid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>> = ({ signal }) => listLabelsAdminAgentsUuidLabelsGet({ uuid },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminAgentsUuidLabelsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>>
export type ListLabelsAdminAgentsUuidLabelsGetSuspenseQueryError = ListLabelsAdminAgentsUuidLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminAgentsUuidLabelsGetSuspense = <TData = Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError = ListLabelsAdminAgentsUuidLabelsGetErrorType<HTTPValidationError>>(
 { uuid }: ListLabelsAdminAgentsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminAgentsUuidLabelsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAgentsUuidLabelsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminAgentsUuidLabelsGetSuspenseQueryOptions({ uuid },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update
 */
export const updateAdminAgentsUuidLabelsPatch = (
    { uuid }: UpdateAdminAgentsUuidLabelsPatchPathParameters,
    mixInLabelPayload: MixInLabelPayload,
    params?: UpdateAdminAgentsUuidLabelsPatchParams,
 ) => {
      
      
      return updateAdminAgentsUuidLabelsPatchMutator<DashLabel[]>(
      {url: `/admin/agents/${uuid}/labels`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: mixInLabelPayload,
        params
    },
      );
    }
  


export const getUpdateAdminAgentsUuidLabelsPatchMutationOptions = <TError = UpdateAdminAgentsUuidLabelsPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminAgentsUuidLabelsPatch>>, TError,{pathParams: UpdateAdminAgentsUuidLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminAgentsUuidLabelsPatchParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAdminAgentsUuidLabelsPatch>>, TError,{pathParams: UpdateAdminAgentsUuidLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminAgentsUuidLabelsPatchParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAdminAgentsUuidLabelsPatch>>, {pathParams: UpdateAdminAgentsUuidLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminAgentsUuidLabelsPatchParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  updateAdminAgentsUuidLabelsPatch(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAdminAgentsUuidLabelsPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateAdminAgentsUuidLabelsPatch>>>
    export type UpdateAdminAgentsUuidLabelsPatchMutationBody = MixInLabelPayload
    export type UpdateAdminAgentsUuidLabelsPatchMutationError = UpdateAdminAgentsUuidLabelsPatchErrorType<HTTPValidationError>

    /**
 * @summary Update
 */
export const useUpdateAdminAgentsUuidLabelsPatch = <TError = UpdateAdminAgentsUuidLabelsPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminAgentsUuidLabelsPatch>>, TError,{pathParams: UpdateAdminAgentsUuidLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminAgentsUuidLabelsPatchParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateAdminAgentsUuidLabelsPatch>>,
        TError,
        {pathParams: UpdateAdminAgentsUuidLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminAgentsUuidLabelsPatchParams},
        TContext
      > => {

      const mutationOptions = getUpdateAdminAgentsUuidLabelsPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Labels
 */
export const addLabelsAdminAgentsUuidLabelsPost = (
    { uuid }: AddLabelsAdminAgentsUuidLabelsPostPathParameters,
    mixInLabelPayload: MixInLabelPayload,
    params?: AddLabelsAdminAgentsUuidLabelsPostParams,
 ) => {
      
      
      return addLabelsAdminAgentsUuidLabelsPostMutator<DashLabel[]>(
      {url: `/admin/agents/${uuid}/labels`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: mixInLabelPayload,
        params
    },
      );
    }
  


export const getAddLabelsAdminAgentsUuidLabelsPostMutationOptions = <TError = AddLabelsAdminAgentsUuidLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminAgentsUuidLabelsPost>>, TError,{pathParams: AddLabelsAdminAgentsUuidLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminAgentsUuidLabelsPostParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminAgentsUuidLabelsPost>>, TError,{pathParams: AddLabelsAdminAgentsUuidLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminAgentsUuidLabelsPostParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addLabelsAdminAgentsUuidLabelsPost>>, {pathParams: AddLabelsAdminAgentsUuidLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminAgentsUuidLabelsPostParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  addLabelsAdminAgentsUuidLabelsPost(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddLabelsAdminAgentsUuidLabelsPostMutationResult = NonNullable<Awaited<ReturnType<typeof addLabelsAdminAgentsUuidLabelsPost>>>
    export type AddLabelsAdminAgentsUuidLabelsPostMutationBody = MixInLabelPayload
    export type AddLabelsAdminAgentsUuidLabelsPostMutationError = AddLabelsAdminAgentsUuidLabelsPostErrorType<HTTPValidationError>

    /**
 * @summary Add Labels
 */
export const useAddLabelsAdminAgentsUuidLabelsPost = <TError = AddLabelsAdminAgentsUuidLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminAgentsUuidLabelsPost>>, TError,{pathParams: AddLabelsAdminAgentsUuidLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminAgentsUuidLabelsPostParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof addLabelsAdminAgentsUuidLabelsPost>>,
        TError,
        {pathParams: AddLabelsAdminAgentsUuidLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminAgentsUuidLabelsPostParams},
        TContext
      > => {

      const mutationOptions = getAddLabelsAdminAgentsUuidLabelsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Label
 */
export const deleteLabelAdminAgentsUuidLabelsLabelIdDelete = (
    { uuid, labelId }: DeleteLabelAdminAgentsUuidLabelsLabelIdDeletePathParameters,
    params?: DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteParams,
 ) => {
      
      
      return deleteLabelAdminAgentsUuidLabelsLabelIdDeleteMutator<unknown>(
      {url: `/admin/agents/${uuid}/labels/${labelId}`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteLabelAdminAgentsUuidLabelsLabelIdDeleteMutationOptions = <TError = DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminAgentsUuidLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminAgentsUuidLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminAgentsUuidLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminAgentsUuidLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLabelAdminAgentsUuidLabelsLabelIdDelete>>, {pathParams: DeleteLabelAdminAgentsUuidLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteParams}> = (props) => {
          const {pathParams,params} = props ?? {};

          return  deleteLabelAdminAgentsUuidLabelsLabelIdDelete(pathParams,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLabelAdminAgentsUuidLabelsLabelIdDelete>>>
    
    export type DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteMutationError = DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Label
 */
export const useDeleteLabelAdminAgentsUuidLabelsLabelIdDelete = <TError = DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminAgentsUuidLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminAgentsUuidLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteLabelAdminAgentsUuidLabelsLabelIdDelete>>,
        TError,
        {pathParams: DeleteLabelAdminAgentsUuidLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminAgentsUuidLabelsLabelIdDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteLabelAdminAgentsUuidLabelsLabelIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Models
 */
export const getModelsAdminAgentsModelsGet = (
    
 signal?: AbortSignal
) => {
      
      
      return getModelsAdminAgentsModelsGetMutator<ModelInfoResponse>(
      {url: `/admin/agents/models`, method: 'GET', signal
    },
      );
    }
  

export const getGetModelsAdminAgentsModelsGetQueryKey = () => {
    return [`/admin/agents/models`] as const;
    }

    
export const getGetModelsAdminAgentsModelsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>>, TError = GetModelsAdminAgentsModelsGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetModelsAdminAgentsModelsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>> = ({ signal }) => getModelsAdminAgentsModelsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetModelsAdminAgentsModelsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>>
export type GetModelsAdminAgentsModelsGetInfiniteQueryError = GetModelsAdminAgentsModelsGetErrorType<unknown>

/**
 * @summary Get Models
 */
export const useGetModelsAdminAgentsModelsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>>, TError = GetModelsAdminAgentsModelsGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetModelsAdminAgentsModelsGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetModelsAdminAgentsModelsGetQueryOptions = <TData = Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError = GetModelsAdminAgentsModelsGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetModelsAdminAgentsModelsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>> = ({ signal }) => getModelsAdminAgentsModelsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetModelsAdminAgentsModelsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>>
export type GetModelsAdminAgentsModelsGetQueryError = GetModelsAdminAgentsModelsGetErrorType<unknown>

/**
 * @summary Get Models
 */
export const useGetModelsAdminAgentsModelsGet = <TData = Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError = GetModelsAdminAgentsModelsGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetModelsAdminAgentsModelsGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetModelsAdminAgentsModelsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError = GetModelsAdminAgentsModelsGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetModelsAdminAgentsModelsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>> = ({ signal }) => getModelsAdminAgentsModelsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetModelsAdminAgentsModelsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>>
export type GetModelsAdminAgentsModelsGetSuspenseQueryError = GetModelsAdminAgentsModelsGetErrorType<unknown>

/**
 * @summary Get Models
 */
export const useGetModelsAdminAgentsModelsGetSuspense = <TData = Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError = GetModelsAdminAgentsModelsGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getModelsAdminAgentsModelsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetModelsAdminAgentsModelsGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * List all agents based on types
 * @summary List Agents
 */
export const listAgentsAdminAgentsGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listAgentsAdminAgentsGetMutator<AgentSummary[]>(
      {url: `/admin/agents`, method: 'GET', signal
    },
      );
    }
  

export const getListAgentsAdminAgentsGetQueryKey = () => {
    return [`/admin/agents`] as const;
    }

    
export const getListAgentsAdminAgentsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>>, TError = ListAgentsAdminAgentsGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAgentsAdminAgentsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>> = ({ signal }) => listAgentsAdminAgentsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListAgentsAdminAgentsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>>
export type ListAgentsAdminAgentsGetInfiniteQueryError = ListAgentsAdminAgentsGetErrorType<unknown>

/**
 * @summary List Agents
 */
export const useListAgentsAdminAgentsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>>, TError = ListAgentsAdminAgentsGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAgentsAdminAgentsGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListAgentsAdminAgentsGetQueryOptions = <TData = Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError = ListAgentsAdminAgentsGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAgentsAdminAgentsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>> = ({ signal }) => listAgentsAdminAgentsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListAgentsAdminAgentsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>>
export type ListAgentsAdminAgentsGetQueryError = ListAgentsAdminAgentsGetErrorType<unknown>

/**
 * @summary List Agents
 */
export const useListAgentsAdminAgentsGet = <TData = Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError = ListAgentsAdminAgentsGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAgentsAdminAgentsGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListAgentsAdminAgentsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError = ListAgentsAdminAgentsGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAgentsAdminAgentsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>> = ({ signal }) => listAgentsAdminAgentsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListAgentsAdminAgentsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>>
export type ListAgentsAdminAgentsGetSuspenseQueryError = ListAgentsAdminAgentsGetErrorType<unknown>

/**
 * @summary List Agents
 */
export const useListAgentsAdminAgentsGetSuspense = <TData = Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError = ListAgentsAdminAgentsGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listAgentsAdminAgentsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAgentsAdminAgentsGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * 前端下拉列表中的选项动态获取
 * @summary Get Agent Options
 */
export const getAgentOptionsAdminAgentsOptionsGet = (
    
 signal?: AbortSignal
) => {
      
      
      return getAgentOptionsAdminAgentsOptionsGetMutator<AgentOptionsResponse>(
      {url: `/admin/agents/options`, method: 'GET', signal
    },
      );
    }
  

export const getGetAgentOptionsAdminAgentsOptionsGetQueryKey = () => {
    return [`/admin/agents/options`] as const;
    }

    
export const getGetAgentOptionsAdminAgentsOptionsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>>, TError = GetAgentOptionsAdminAgentsOptionsGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentOptionsAdminAgentsOptionsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>> = ({ signal }) => getAgentOptionsAdminAgentsOptionsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentOptionsAdminAgentsOptionsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>>
export type GetAgentOptionsAdminAgentsOptionsGetInfiniteQueryError = GetAgentOptionsAdminAgentsOptionsGetErrorType<unknown>

/**
 * @summary Get Agent Options
 */
export const useGetAgentOptionsAdminAgentsOptionsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>>, TError = GetAgentOptionsAdminAgentsOptionsGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentOptionsAdminAgentsOptionsGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAgentOptionsAdminAgentsOptionsGetQueryOptions = <TData = Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError = GetAgentOptionsAdminAgentsOptionsGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentOptionsAdminAgentsOptionsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>> = ({ signal }) => getAgentOptionsAdminAgentsOptionsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentOptionsAdminAgentsOptionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>>
export type GetAgentOptionsAdminAgentsOptionsGetQueryError = GetAgentOptionsAdminAgentsOptionsGetErrorType<unknown>

/**
 * @summary Get Agent Options
 */
export const useGetAgentOptionsAdminAgentsOptionsGet = <TData = Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError = GetAgentOptionsAdminAgentsOptionsGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentOptionsAdminAgentsOptionsGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAgentOptionsAdminAgentsOptionsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError = GetAgentOptionsAdminAgentsOptionsGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentOptionsAdminAgentsOptionsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>> = ({ signal }) => getAgentOptionsAdminAgentsOptionsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentOptionsAdminAgentsOptionsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>>
export type GetAgentOptionsAdminAgentsOptionsGetSuspenseQueryError = GetAgentOptionsAdminAgentsOptionsGetErrorType<unknown>

/**
 * @summary Get Agent Options
 */
export const useGetAgentOptionsAdminAgentsOptionsGetSuspense = <TData = Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError = GetAgentOptionsAdminAgentsOptionsGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentOptionsAdminAgentsOptionsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentOptionsAdminAgentsOptionsGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Agent
 */
export const getAgentAdminAgentsUuidGet = (
    { uuid }: GetAgentAdminAgentsUuidGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getAgentAdminAgentsUuidGetMutator<AgentConfigOutput>(
      {url: `/admin/agents/${uuid}`, method: 'GET', signal
    },
      );
    }
  

export const getGetAgentAdminAgentsUuidGetQueryKey = ({ uuid }: GetAgentAdminAgentsUuidGetPathParameters,) => {
    return [`/admin/agents/${uuid}`] as const;
    }

    
export const getGetAgentAdminAgentsUuidGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>>, TError = GetAgentAdminAgentsUuidGetErrorType<HTTPValidationError>>({ uuid }: GetAgentAdminAgentsUuidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentAdminAgentsUuidGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>> = ({ signal }) => getAgentAdminAgentsUuidGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentAdminAgentsUuidGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>>
export type GetAgentAdminAgentsUuidGetInfiniteQueryError = GetAgentAdminAgentsUuidGetErrorType<HTTPValidationError>

/**
 * @summary Get Agent
 */
export const useGetAgentAdminAgentsUuidGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>>, TError = GetAgentAdminAgentsUuidGetErrorType<HTTPValidationError>>(
 { uuid }: GetAgentAdminAgentsUuidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentAdminAgentsUuidGetInfiniteQueryOptions({ uuid },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAgentAdminAgentsUuidGetQueryOptions = <TData = Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError = GetAgentAdminAgentsUuidGetErrorType<HTTPValidationError>>({ uuid }: GetAgentAdminAgentsUuidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentAdminAgentsUuidGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>> = ({ signal }) => getAgentAdminAgentsUuidGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentAdminAgentsUuidGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>>
export type GetAgentAdminAgentsUuidGetQueryError = GetAgentAdminAgentsUuidGetErrorType<HTTPValidationError>

/**
 * @summary Get Agent
 */
export const useGetAgentAdminAgentsUuidGet = <TData = Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError = GetAgentAdminAgentsUuidGetErrorType<HTTPValidationError>>(
 { uuid }: GetAgentAdminAgentsUuidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentAdminAgentsUuidGetQueryOptions({ uuid },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAgentAdminAgentsUuidGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError = GetAgentAdminAgentsUuidGetErrorType<HTTPValidationError>>({ uuid }: GetAgentAdminAgentsUuidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentAdminAgentsUuidGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>> = ({ signal }) => getAgentAdminAgentsUuidGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentAdminAgentsUuidGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>>
export type GetAgentAdminAgentsUuidGetSuspenseQueryError = GetAgentAdminAgentsUuidGetErrorType<HTTPValidationError>

/**
 * @summary Get Agent
 */
export const useGetAgentAdminAgentsUuidGetSuspense = <TData = Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError = GetAgentAdminAgentsUuidGetErrorType<HTTPValidationError>>(
 { uuid }: GetAgentAdminAgentsUuidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentAdminAgentsUuidGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentAdminAgentsUuidGetSuspenseQueryOptions({ uuid },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Delete Agent
 */
export const deleteAgentAdminAgentsUuidDelete = (
    { uuid }: DeleteAgentAdminAgentsUuidDeletePathParameters,
 ) => {
      
      
      return deleteAgentAdminAgentsUuidDeleteMutator<unknown>(
      {url: `/admin/agents/${uuid}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteAgentAdminAgentsUuidDeleteMutationOptions = <TError = DeleteAgentAdminAgentsUuidDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAgentAdminAgentsUuidDelete>>, TError,{pathParams: DeleteAgentAdminAgentsUuidDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteAgentAdminAgentsUuidDelete>>, TError,{pathParams: DeleteAgentAdminAgentsUuidDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAgentAdminAgentsUuidDelete>>, {pathParams: DeleteAgentAdminAgentsUuidDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  deleteAgentAdminAgentsUuidDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAgentAdminAgentsUuidDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAgentAdminAgentsUuidDelete>>>
    
    export type DeleteAgentAdminAgentsUuidDeleteMutationError = DeleteAgentAdminAgentsUuidDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Agent
 */
export const useDeleteAgentAdminAgentsUuidDelete = <TError = DeleteAgentAdminAgentsUuidDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAgentAdminAgentsUuidDelete>>, TError,{pathParams: DeleteAgentAdminAgentsUuidDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteAgentAdminAgentsUuidDelete>>,
        TError,
        {pathParams: DeleteAgentAdminAgentsUuidDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getDeleteAgentAdminAgentsUuidDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Save Agent
 */
export const saveAgentAdminAgentsUuidPut = (
    { uuid }: SaveAgentAdminAgentsUuidPutPathParameters,
    saveAgentConfigPayload: SaveAgentConfigPayload,
 ) => {
      
      
      return saveAgentAdminAgentsUuidPutMutator<AgentConfigOutput>(
      {url: `/admin/agents/${uuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: saveAgentConfigPayload
    },
      );
    }
  


export const getSaveAgentAdminAgentsUuidPutMutationOptions = <TError = SaveAgentAdminAgentsUuidPutErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveAgentAdminAgentsUuidPut>>, TError,{pathParams: SaveAgentAdminAgentsUuidPutPathParameters;data: SaveAgentConfigPayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof saveAgentAdminAgentsUuidPut>>, TError,{pathParams: SaveAgentAdminAgentsUuidPutPathParameters;data: SaveAgentConfigPayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveAgentAdminAgentsUuidPut>>, {pathParams: SaveAgentAdminAgentsUuidPutPathParameters;data: SaveAgentConfigPayload}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  saveAgentAdminAgentsUuidPut(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveAgentAdminAgentsUuidPutMutationResult = NonNullable<Awaited<ReturnType<typeof saveAgentAdminAgentsUuidPut>>>
    export type SaveAgentAdminAgentsUuidPutMutationBody = SaveAgentConfigPayload
    export type SaveAgentAdminAgentsUuidPutMutationError = SaveAgentAdminAgentsUuidPutErrorType<HTTPValidationError>

    /**
 * @summary Save Agent
 */
export const useSaveAgentAdminAgentsUuidPut = <TError = SaveAgentAdminAgentsUuidPutErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveAgentAdminAgentsUuidPut>>, TError,{pathParams: SaveAgentAdminAgentsUuidPutPathParameters;data: SaveAgentConfigPayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof saveAgentAdminAgentsUuidPut>>,
        TError,
        {pathParams: SaveAgentAdminAgentsUuidPutPathParameters;data: SaveAgentConfigPayload},
        TContext
      > => {

      const mutationOptions = getSaveAgentAdminAgentsUuidPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Patch Agent
 */
export const patchAgentAdminAgentsUuidVersionsVersionPatch = (
    { uuid, version }: PatchAgentAdminAgentsUuidVersionsVersionPatchPathParameters,
 ) => {
      
      
      return patchAgentAdminAgentsUuidVersionsVersionPatchMutator<AgentConfigOutput>(
      {url: `/admin/agents/${uuid}/versions/${version}`, method: 'PATCH'
    },
      );
    }
  


export const getPatchAgentAdminAgentsUuidVersionsVersionPatchMutationOptions = <TError = PatchAgentAdminAgentsUuidVersionsVersionPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchAgentAdminAgentsUuidVersionsVersionPatch>>, TError,{pathParams: PatchAgentAdminAgentsUuidVersionsVersionPatchPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patchAgentAdminAgentsUuidVersionsVersionPatch>>, TError,{pathParams: PatchAgentAdminAgentsUuidVersionsVersionPatchPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchAgentAdminAgentsUuidVersionsVersionPatch>>, {pathParams: PatchAgentAdminAgentsUuidVersionsVersionPatchPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  patchAgentAdminAgentsUuidVersionsVersionPatch(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchAgentAdminAgentsUuidVersionsVersionPatchMutationResult = NonNullable<Awaited<ReturnType<typeof patchAgentAdminAgentsUuidVersionsVersionPatch>>>
    
    export type PatchAgentAdminAgentsUuidVersionsVersionPatchMutationError = PatchAgentAdminAgentsUuidVersionsVersionPatchErrorType<HTTPValidationError>

    /**
 * @summary Patch Agent
 */
export const usePatchAgentAdminAgentsUuidVersionsVersionPatch = <TError = PatchAgentAdminAgentsUuidVersionsVersionPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchAgentAdminAgentsUuidVersionsVersionPatch>>, TError,{pathParams: PatchAgentAdminAgentsUuidVersionsVersionPatchPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patchAgentAdminAgentsUuidVersionsVersionPatch>>,
        TError,
        {pathParams: PatchAgentAdminAgentsUuidVersionsVersionPatchPathParameters},
        TContext
      > => {

      const mutationOptions = getPatchAgentAdminAgentsUuidVersionsVersionPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Versioned Agent
 */
export const getVersionedAgentAdminAgentsUuidVersionsVersionGet = (
    { uuid, version }: GetVersionedAgentAdminAgentsUuidVersionsVersionGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getVersionedAgentAdminAgentsUuidVersionsVersionGetMutator<AgentConfigOutput>(
      {url: `/admin/agents/${uuid}/versions/${version}`, method: 'GET', signal
    },
      );
    }
  

export const getGetVersionedAgentAdminAgentsUuidVersionsVersionGetQueryKey = ({ uuid, version }: GetVersionedAgentAdminAgentsUuidVersionsVersionGetPathParameters,) => {
    return [`/admin/agents/${uuid}/versions/${version}`] as const;
    }

    
export const getGetVersionedAgentAdminAgentsUuidVersionsVersionGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>>, TError = GetVersionedAgentAdminAgentsUuidVersionsVersionGetErrorType<HTTPValidationError>>({ uuid, version }: GetVersionedAgentAdminAgentsUuidVersionsVersionGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVersionedAgentAdminAgentsUuidVersionsVersionGetQueryKey({ uuid, version });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>> = ({ signal }) => getVersionedAgentAdminAgentsUuidVersionsVersionGet({ uuid, version }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVersionedAgentAdminAgentsUuidVersionsVersionGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>>
export type GetVersionedAgentAdminAgentsUuidVersionsVersionGetInfiniteQueryError = GetVersionedAgentAdminAgentsUuidVersionsVersionGetErrorType<HTTPValidationError>

/**
 * @summary Get Versioned Agent
 */
export const useGetVersionedAgentAdminAgentsUuidVersionsVersionGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>>, TError = GetVersionedAgentAdminAgentsUuidVersionsVersionGetErrorType<HTTPValidationError>>(
 { uuid, version }: GetVersionedAgentAdminAgentsUuidVersionsVersionGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVersionedAgentAdminAgentsUuidVersionsVersionGetInfiniteQueryOptions({ uuid, version },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetVersionedAgentAdminAgentsUuidVersionsVersionGetQueryOptions = <TData = Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError = GetVersionedAgentAdminAgentsUuidVersionsVersionGetErrorType<HTTPValidationError>>({ uuid, version }: GetVersionedAgentAdminAgentsUuidVersionsVersionGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVersionedAgentAdminAgentsUuidVersionsVersionGetQueryKey({ uuid, version });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>> = ({ signal }) => getVersionedAgentAdminAgentsUuidVersionsVersionGet({ uuid, version }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVersionedAgentAdminAgentsUuidVersionsVersionGetQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>>
export type GetVersionedAgentAdminAgentsUuidVersionsVersionGetQueryError = GetVersionedAgentAdminAgentsUuidVersionsVersionGetErrorType<HTTPValidationError>

/**
 * @summary Get Versioned Agent
 */
export const useGetVersionedAgentAdminAgentsUuidVersionsVersionGet = <TData = Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError = GetVersionedAgentAdminAgentsUuidVersionsVersionGetErrorType<HTTPValidationError>>(
 { uuid, version }: GetVersionedAgentAdminAgentsUuidVersionsVersionGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVersionedAgentAdminAgentsUuidVersionsVersionGetQueryOptions({ uuid, version },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetVersionedAgentAdminAgentsUuidVersionsVersionGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError = GetVersionedAgentAdminAgentsUuidVersionsVersionGetErrorType<HTTPValidationError>>({ uuid, version }: GetVersionedAgentAdminAgentsUuidVersionsVersionGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVersionedAgentAdminAgentsUuidVersionsVersionGetQueryKey({ uuid, version });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>> = ({ signal }) => getVersionedAgentAdminAgentsUuidVersionsVersionGet({ uuid, version }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVersionedAgentAdminAgentsUuidVersionsVersionGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>>
export type GetVersionedAgentAdminAgentsUuidVersionsVersionGetSuspenseQueryError = GetVersionedAgentAdminAgentsUuidVersionsVersionGetErrorType<HTTPValidationError>

/**
 * @summary Get Versioned Agent
 */
export const useGetVersionedAgentAdminAgentsUuidVersionsVersionGetSuspense = <TData = Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError = GetVersionedAgentAdminAgentsUuidVersionsVersionGetErrorType<HTTPValidationError>>(
 { uuid, version }: GetVersionedAgentAdminAgentsUuidVersionsVersionGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVersionedAgentAdminAgentsUuidVersionsVersionGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVersionedAgentAdminAgentsUuidVersionsVersionGetSuspenseQueryOptions({ uuid, version },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Delete Version
 */
export const deleteVersionAdminAgentsUuidVersionsVersionDelete = (
    { uuid, version }: DeleteVersionAdminAgentsUuidVersionsVersionDeletePathParameters,
 ) => {
      
      
      return deleteVersionAdminAgentsUuidVersionsVersionDeleteMutator<unknown>(
      {url: `/admin/agents/${uuid}/versions/${version}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteVersionAdminAgentsUuidVersionsVersionDeleteMutationOptions = <TError = DeleteVersionAdminAgentsUuidVersionsVersionDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionAdminAgentsUuidVersionsVersionDelete>>, TError,{pathParams: DeleteVersionAdminAgentsUuidVersionsVersionDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteVersionAdminAgentsUuidVersionsVersionDelete>>, TError,{pathParams: DeleteVersionAdminAgentsUuidVersionsVersionDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVersionAdminAgentsUuidVersionsVersionDelete>>, {pathParams: DeleteVersionAdminAgentsUuidVersionsVersionDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  deleteVersionAdminAgentsUuidVersionsVersionDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteVersionAdminAgentsUuidVersionsVersionDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVersionAdminAgentsUuidVersionsVersionDelete>>>
    
    export type DeleteVersionAdminAgentsUuidVersionsVersionDeleteMutationError = DeleteVersionAdminAgentsUuidVersionsVersionDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Version
 */
export const useDeleteVersionAdminAgentsUuidVersionsVersionDelete = <TError = DeleteVersionAdminAgentsUuidVersionsVersionDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionAdminAgentsUuidVersionsVersionDelete>>, TError,{pathParams: DeleteVersionAdminAgentsUuidVersionsVersionDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteVersionAdminAgentsUuidVersionsVersionDelete>>,
        TError,
        {pathParams: DeleteVersionAdminAgentsUuidVersionsVersionDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getDeleteVersionAdminAgentsUuidVersionsVersionDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 查询某个 agent 的执行记录
 * @summary Get Agent Evaluations
 */
export const getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet = (
    { uuid, version }: GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetMutator<EvaluationPlanSummary[]>(
      {url: `/admin/agents/${uuid}/versions/${version}/evaluations`, method: 'GET', signal
    },
      );
    }
  

export const getGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetQueryKey = ({ uuid, version }: GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetPathParameters,) => {
    return [`/admin/agents/${uuid}/versions/${version}/evaluations`] as const;
    }

    
export const getGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>>, TError = GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetErrorType<HTTPValidationError>>({ uuid, version }: GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetQueryKey({ uuid, version });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>> = ({ signal }) => getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet({ uuid, version }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>>
export type GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetInfiniteQueryError = GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetErrorType<HTTPValidationError>

/**
 * @summary Get Agent Evaluations
 */
export const useGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>>, TError = GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetErrorType<HTTPValidationError>>(
 { uuid, version }: GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetInfiniteQueryOptions({ uuid, version },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetQueryOptions = <TData = Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError = GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetErrorType<HTTPValidationError>>({ uuid, version }: GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetQueryKey({ uuid, version });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>> = ({ signal }) => getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet({ uuid, version }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>>
export type GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetQueryError = GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetErrorType<HTTPValidationError>

/**
 * @summary Get Agent Evaluations
 */
export const useGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet = <TData = Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError = GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetErrorType<HTTPValidationError>>(
 { uuid, version }: GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetQueryOptions({ uuid, version },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError = GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetErrorType<HTTPValidationError>>({ uuid, version }: GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetQueryKey({ uuid, version });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>> = ({ signal }) => getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet({ uuid, version }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>>
export type GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetSuspenseQueryError = GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetErrorType<HTTPValidationError>

/**
 * @summary Get Agent Evaluations
 */
export const useGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetSuspense = <TData = Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError = GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetErrorType<HTTPValidationError>>(
 { uuid, version }: GetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentEvaluationsAdminAgentsUuidVersionsVersionEvaluationsGetSuspenseQueryOptions({ uuid, version },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Agent Versions
 */
export const getAgentVersionsAdminAgentsUuidVersionsGet = (
    { uuid }: GetAgentVersionsAdminAgentsUuidVersionsGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getAgentVersionsAdminAgentsUuidVersionsGetMutator<AgentSummary[]>(
      {url: `/admin/agents/${uuid}/versions`, method: 'GET', signal
    },
      );
    }
  

export const getGetAgentVersionsAdminAgentsUuidVersionsGetQueryKey = ({ uuid }: GetAgentVersionsAdminAgentsUuidVersionsGetPathParameters,) => {
    return [`/admin/agents/${uuid}/versions`] as const;
    }

    
export const getGetAgentVersionsAdminAgentsUuidVersionsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>>, TError = GetAgentVersionsAdminAgentsUuidVersionsGetErrorType<HTTPValidationError>>({ uuid }: GetAgentVersionsAdminAgentsUuidVersionsGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentVersionsAdminAgentsUuidVersionsGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>> = ({ signal }) => getAgentVersionsAdminAgentsUuidVersionsGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentVersionsAdminAgentsUuidVersionsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>>
export type GetAgentVersionsAdminAgentsUuidVersionsGetInfiniteQueryError = GetAgentVersionsAdminAgentsUuidVersionsGetErrorType<HTTPValidationError>

/**
 * @summary Get Agent Versions
 */
export const useGetAgentVersionsAdminAgentsUuidVersionsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>>, TError = GetAgentVersionsAdminAgentsUuidVersionsGetErrorType<HTTPValidationError>>(
 { uuid }: GetAgentVersionsAdminAgentsUuidVersionsGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentVersionsAdminAgentsUuidVersionsGetInfiniteQueryOptions({ uuid },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAgentVersionsAdminAgentsUuidVersionsGetQueryOptions = <TData = Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError = GetAgentVersionsAdminAgentsUuidVersionsGetErrorType<HTTPValidationError>>({ uuid }: GetAgentVersionsAdminAgentsUuidVersionsGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentVersionsAdminAgentsUuidVersionsGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>> = ({ signal }) => getAgentVersionsAdminAgentsUuidVersionsGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentVersionsAdminAgentsUuidVersionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>>
export type GetAgentVersionsAdminAgentsUuidVersionsGetQueryError = GetAgentVersionsAdminAgentsUuidVersionsGetErrorType<HTTPValidationError>

/**
 * @summary Get Agent Versions
 */
export const useGetAgentVersionsAdminAgentsUuidVersionsGet = <TData = Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError = GetAgentVersionsAdminAgentsUuidVersionsGetErrorType<HTTPValidationError>>(
 { uuid }: GetAgentVersionsAdminAgentsUuidVersionsGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentVersionsAdminAgentsUuidVersionsGetQueryOptions({ uuid },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAgentVersionsAdminAgentsUuidVersionsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError = GetAgentVersionsAdminAgentsUuidVersionsGetErrorType<HTTPValidationError>>({ uuid }: GetAgentVersionsAdminAgentsUuidVersionsGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentVersionsAdminAgentsUuidVersionsGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>> = ({ signal }) => getAgentVersionsAdminAgentsUuidVersionsGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentVersionsAdminAgentsUuidVersionsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>>
export type GetAgentVersionsAdminAgentsUuidVersionsGetSuspenseQueryError = GetAgentVersionsAdminAgentsUuidVersionsGetErrorType<HTTPValidationError>

/**
 * @summary Get Agent Versions
 */
export const useGetAgentVersionsAdminAgentsUuidVersionsGetSuspense = <TData = Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError = GetAgentVersionsAdminAgentsUuidVersionsGetErrorType<HTTPValidationError>>(
 { uuid }: GetAgentVersionsAdminAgentsUuidVersionsGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentVersionsAdminAgentsUuidVersionsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentVersionsAdminAgentsUuidVersionsGetSuspenseQueryOptions({ uuid },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Deploy Agent To Production
 */
export const deployAgentToProductionAdminAgentsUuidVersionsVersionDeployPost = (
    { uuid, version }: DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostPathParameters,
    deployPayload: DeployPayload,
 ) => {
      
      
      return deployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostMutator<AgentConfigOutput>(
      {url: `/admin/agents/${uuid}/versions/${version}/deploy`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: deployPayload
    },
      );
    }
  


export const getDeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostMutationOptions = <TError = DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deployAgentToProductionAdminAgentsUuidVersionsVersionDeployPost>>, TError,{pathParams: DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostPathParameters;data: DeployPayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deployAgentToProductionAdminAgentsUuidVersionsVersionDeployPost>>, TError,{pathParams: DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostPathParameters;data: DeployPayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deployAgentToProductionAdminAgentsUuidVersionsVersionDeployPost>>, {pathParams: DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostPathParameters;data: DeployPayload}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  deployAgentToProductionAdminAgentsUuidVersionsVersionDeployPost(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostMutationResult = NonNullable<Awaited<ReturnType<typeof deployAgentToProductionAdminAgentsUuidVersionsVersionDeployPost>>>
    export type DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostMutationBody = DeployPayload
    export type DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostMutationError = DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostErrorType<HTTPValidationError>

    /**
 * @summary Deploy Agent To Production
 */
export const useDeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPost = <TError = DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deployAgentToProductionAdminAgentsUuidVersionsVersionDeployPost>>, TError,{pathParams: DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostPathParameters;data: DeployPayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deployAgentToProductionAdminAgentsUuidVersionsVersionDeployPost>>,
        TError,
        {pathParams: DeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostPathParameters;data: DeployPayload},
        TContext
      > => {

      const mutationOptions = getDeployAgentToProductionAdminAgentsUuidVersionsVersionDeployPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Prompt Parameters
 */
export const getPromptParametersAdminAgentsUuidPromptParamsGet = (
    { uuid }: GetPromptParametersAdminAgentsUuidPromptParamsGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getPromptParametersAdminAgentsUuidPromptParamsGetMutator<GetPromptParametersAdminAgentsUuidPromptParamsGet200>(
      {url: `/admin/agents/${uuid}/prompt/params`, method: 'GET', signal
    },
      );
    }
  

export const getGetPromptParametersAdminAgentsUuidPromptParamsGetQueryKey = ({ uuid }: GetPromptParametersAdminAgentsUuidPromptParamsGetPathParameters,) => {
    return [`/admin/agents/${uuid}/prompt/params`] as const;
    }

    
export const getGetPromptParametersAdminAgentsUuidPromptParamsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>>, TError = GetPromptParametersAdminAgentsUuidPromptParamsGetErrorType<HTTPValidationError>>({ uuid }: GetPromptParametersAdminAgentsUuidPromptParamsGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPromptParametersAdminAgentsUuidPromptParamsGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>> = ({ signal }) => getPromptParametersAdminAgentsUuidPromptParamsGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPromptParametersAdminAgentsUuidPromptParamsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>>
export type GetPromptParametersAdminAgentsUuidPromptParamsGetInfiniteQueryError = GetPromptParametersAdminAgentsUuidPromptParamsGetErrorType<HTTPValidationError>

/**
 * @summary Get Prompt Parameters
 */
export const useGetPromptParametersAdminAgentsUuidPromptParamsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>>, TError = GetPromptParametersAdminAgentsUuidPromptParamsGetErrorType<HTTPValidationError>>(
 { uuid }: GetPromptParametersAdminAgentsUuidPromptParamsGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPromptParametersAdminAgentsUuidPromptParamsGetInfiniteQueryOptions({ uuid },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetPromptParametersAdminAgentsUuidPromptParamsGetQueryOptions = <TData = Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError = GetPromptParametersAdminAgentsUuidPromptParamsGetErrorType<HTTPValidationError>>({ uuid }: GetPromptParametersAdminAgentsUuidPromptParamsGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPromptParametersAdminAgentsUuidPromptParamsGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>> = ({ signal }) => getPromptParametersAdminAgentsUuidPromptParamsGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPromptParametersAdminAgentsUuidPromptParamsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>>
export type GetPromptParametersAdminAgentsUuidPromptParamsGetQueryError = GetPromptParametersAdminAgentsUuidPromptParamsGetErrorType<HTTPValidationError>

/**
 * @summary Get Prompt Parameters
 */
export const useGetPromptParametersAdminAgentsUuidPromptParamsGet = <TData = Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError = GetPromptParametersAdminAgentsUuidPromptParamsGetErrorType<HTTPValidationError>>(
 { uuid }: GetPromptParametersAdminAgentsUuidPromptParamsGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPromptParametersAdminAgentsUuidPromptParamsGetQueryOptions({ uuid },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetPromptParametersAdminAgentsUuidPromptParamsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError = GetPromptParametersAdminAgentsUuidPromptParamsGetErrorType<HTTPValidationError>>({ uuid }: GetPromptParametersAdminAgentsUuidPromptParamsGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPromptParametersAdminAgentsUuidPromptParamsGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>> = ({ signal }) => getPromptParametersAdminAgentsUuidPromptParamsGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPromptParametersAdminAgentsUuidPromptParamsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>>
export type GetPromptParametersAdminAgentsUuidPromptParamsGetSuspenseQueryError = GetPromptParametersAdminAgentsUuidPromptParamsGetErrorType<HTTPValidationError>

/**
 * @summary Get Prompt Parameters
 */
export const useGetPromptParametersAdminAgentsUuidPromptParamsGetSuspense = <TData = Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError = GetPromptParametersAdminAgentsUuidPromptParamsGetErrorType<HTTPValidationError>>(
 { uuid }: GetPromptParametersAdminAgentsUuidPromptParamsGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPromptParametersAdminAgentsUuidPromptParamsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPromptParametersAdminAgentsUuidPromptParamsGetSuspenseQueryOptions({ uuid },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Draft Agent
 */
export const createDraftAgentAdminAgentsPost = (
    newAgentConfig: NewAgentConfig,
 ) => {
      
      
      return createDraftAgentAdminAgentsPostMutator<AgentConfigOutput>(
      {url: `/admin/agents/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newAgentConfig
    },
      );
    }
  


export const getCreateDraftAgentAdminAgentsPostMutationOptions = <TError = CreateDraftAgentAdminAgentsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDraftAgentAdminAgentsPost>>, TError,{data: NewAgentConfig}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createDraftAgentAdminAgentsPost>>, TError,{data: NewAgentConfig}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDraftAgentAdminAgentsPost>>, {data: NewAgentConfig}> = (props) => {
          const {data} = props ?? {};

          return  createDraftAgentAdminAgentsPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateDraftAgentAdminAgentsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createDraftAgentAdminAgentsPost>>>
    export type CreateDraftAgentAdminAgentsPostMutationBody = NewAgentConfig
    export type CreateDraftAgentAdminAgentsPostMutationError = CreateDraftAgentAdminAgentsPostErrorType<HTTPValidationError>

    /**
 * @summary Create Draft Agent
 */
export const useCreateDraftAgentAdminAgentsPost = <TError = CreateDraftAgentAdminAgentsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDraftAgentAdminAgentsPost>>, TError,{data: NewAgentConfig}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createDraftAgentAdminAgentsPost>>,
        TError,
        {data: NewAgentConfig},
        TContext
      > => {

      const mutationOptions = getCreateDraftAgentAdminAgentsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Labels
 */
export const listLabelsAdminWorkflowsUuidLabelsGet = (
    { uuid }: ListLabelsAdminWorkflowsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminWorkflowsUuidLabelsGetParams,
 signal?: AbortSignal
) => {
      
      
      return listLabelsAdminWorkflowsUuidLabelsGetMutator<DashLabel[]>(
      {url: `/admin/workflows/${uuid}/labels`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListLabelsAdminWorkflowsUuidLabelsGetQueryKey = ({ uuid }: ListLabelsAdminWorkflowsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminWorkflowsUuidLabelsGetParams,) => {
    return [`/admin/workflows/${uuid}/labels`, ...(params ? [params]: [])] as const;
    }

    
export const getListLabelsAdminWorkflowsUuidLabelsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, ListLabelsAdminWorkflowsUuidLabelsGetParams['after']>, TError = ListLabelsAdminWorkflowsUuidLabelsGetErrorType<HTTPValidationError>>({ uuid }: ListLabelsAdminWorkflowsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminWorkflowsUuidLabelsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, QueryKey, ListLabelsAdminWorkflowsUuidLabelsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminWorkflowsUuidLabelsGetQueryKey({ uuid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, QueryKey, ListLabelsAdminWorkflowsUuidLabelsGetParams['after']> = ({ signal, pageParam }) => listLabelsAdminWorkflowsUuidLabelsGet({ uuid },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, QueryKey, ListLabelsAdminWorkflowsUuidLabelsGetParams['after']> & { queryKey: QueryKey }
}

export type ListLabelsAdminWorkflowsUuidLabelsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>>
export type ListLabelsAdminWorkflowsUuidLabelsGetInfiniteQueryError = ListLabelsAdminWorkflowsUuidLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminWorkflowsUuidLabelsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, ListLabelsAdminWorkflowsUuidLabelsGetParams['after']>, TError = ListLabelsAdminWorkflowsUuidLabelsGetErrorType<HTTPValidationError>>(
 { uuid }: ListLabelsAdminWorkflowsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminWorkflowsUuidLabelsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, QueryKey, ListLabelsAdminWorkflowsUuidLabelsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminWorkflowsUuidLabelsGetInfiniteQueryOptions({ uuid },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminWorkflowsUuidLabelsGetQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError = ListLabelsAdminWorkflowsUuidLabelsGetErrorType<HTTPValidationError>>({ uuid }: ListLabelsAdminWorkflowsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminWorkflowsUuidLabelsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminWorkflowsUuidLabelsGetQueryKey({ uuid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>> = ({ signal }) => listLabelsAdminWorkflowsUuidLabelsGet({ uuid },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminWorkflowsUuidLabelsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>>
export type ListLabelsAdminWorkflowsUuidLabelsGetQueryError = ListLabelsAdminWorkflowsUuidLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminWorkflowsUuidLabelsGet = <TData = Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError = ListLabelsAdminWorkflowsUuidLabelsGetErrorType<HTTPValidationError>>(
 { uuid }: ListLabelsAdminWorkflowsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminWorkflowsUuidLabelsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminWorkflowsUuidLabelsGetQueryOptions({ uuid },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminWorkflowsUuidLabelsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError = ListLabelsAdminWorkflowsUuidLabelsGetErrorType<HTTPValidationError>>({ uuid }: ListLabelsAdminWorkflowsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminWorkflowsUuidLabelsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminWorkflowsUuidLabelsGetQueryKey({ uuid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>> = ({ signal }) => listLabelsAdminWorkflowsUuidLabelsGet({ uuid },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminWorkflowsUuidLabelsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>>
export type ListLabelsAdminWorkflowsUuidLabelsGetSuspenseQueryError = ListLabelsAdminWorkflowsUuidLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminWorkflowsUuidLabelsGetSuspense = <TData = Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError = ListLabelsAdminWorkflowsUuidLabelsGetErrorType<HTTPValidationError>>(
 { uuid }: ListLabelsAdminWorkflowsUuidLabelsGetPathParameters,
    params?: ListLabelsAdminWorkflowsUuidLabelsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminWorkflowsUuidLabelsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminWorkflowsUuidLabelsGetSuspenseQueryOptions({ uuid },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update
 */
export const updateAdminWorkflowsUuidLabelsPatch = (
    { uuid }: UpdateAdminWorkflowsUuidLabelsPatchPathParameters,
    mixInLabelPayload: MixInLabelPayload,
    params?: UpdateAdminWorkflowsUuidLabelsPatchParams,
 ) => {
      
      
      return updateAdminWorkflowsUuidLabelsPatchMutator<DashLabel[]>(
      {url: `/admin/workflows/${uuid}/labels`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: mixInLabelPayload,
        params
    },
      );
    }
  


export const getUpdateAdminWorkflowsUuidLabelsPatchMutationOptions = <TError = UpdateAdminWorkflowsUuidLabelsPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminWorkflowsUuidLabelsPatch>>, TError,{pathParams: UpdateAdminWorkflowsUuidLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminWorkflowsUuidLabelsPatchParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAdminWorkflowsUuidLabelsPatch>>, TError,{pathParams: UpdateAdminWorkflowsUuidLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminWorkflowsUuidLabelsPatchParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAdminWorkflowsUuidLabelsPatch>>, {pathParams: UpdateAdminWorkflowsUuidLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminWorkflowsUuidLabelsPatchParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  updateAdminWorkflowsUuidLabelsPatch(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAdminWorkflowsUuidLabelsPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateAdminWorkflowsUuidLabelsPatch>>>
    export type UpdateAdminWorkflowsUuidLabelsPatchMutationBody = MixInLabelPayload
    export type UpdateAdminWorkflowsUuidLabelsPatchMutationError = UpdateAdminWorkflowsUuidLabelsPatchErrorType<HTTPValidationError>

    /**
 * @summary Update
 */
export const useUpdateAdminWorkflowsUuidLabelsPatch = <TError = UpdateAdminWorkflowsUuidLabelsPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminWorkflowsUuidLabelsPatch>>, TError,{pathParams: UpdateAdminWorkflowsUuidLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminWorkflowsUuidLabelsPatchParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateAdminWorkflowsUuidLabelsPatch>>,
        TError,
        {pathParams: UpdateAdminWorkflowsUuidLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminWorkflowsUuidLabelsPatchParams},
        TContext
      > => {

      const mutationOptions = getUpdateAdminWorkflowsUuidLabelsPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Labels
 */
export const addLabelsAdminWorkflowsUuidLabelsPost = (
    { uuid }: AddLabelsAdminWorkflowsUuidLabelsPostPathParameters,
    mixInLabelPayload: MixInLabelPayload,
    params?: AddLabelsAdminWorkflowsUuidLabelsPostParams,
 ) => {
      
      
      return addLabelsAdminWorkflowsUuidLabelsPostMutator<DashLabel[]>(
      {url: `/admin/workflows/${uuid}/labels`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: mixInLabelPayload,
        params
    },
      );
    }
  


export const getAddLabelsAdminWorkflowsUuidLabelsPostMutationOptions = <TError = AddLabelsAdminWorkflowsUuidLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminWorkflowsUuidLabelsPost>>, TError,{pathParams: AddLabelsAdminWorkflowsUuidLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminWorkflowsUuidLabelsPostParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminWorkflowsUuidLabelsPost>>, TError,{pathParams: AddLabelsAdminWorkflowsUuidLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminWorkflowsUuidLabelsPostParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addLabelsAdminWorkflowsUuidLabelsPost>>, {pathParams: AddLabelsAdminWorkflowsUuidLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminWorkflowsUuidLabelsPostParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  addLabelsAdminWorkflowsUuidLabelsPost(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddLabelsAdminWorkflowsUuidLabelsPostMutationResult = NonNullable<Awaited<ReturnType<typeof addLabelsAdminWorkflowsUuidLabelsPost>>>
    export type AddLabelsAdminWorkflowsUuidLabelsPostMutationBody = MixInLabelPayload
    export type AddLabelsAdminWorkflowsUuidLabelsPostMutationError = AddLabelsAdminWorkflowsUuidLabelsPostErrorType<HTTPValidationError>

    /**
 * @summary Add Labels
 */
export const useAddLabelsAdminWorkflowsUuidLabelsPost = <TError = AddLabelsAdminWorkflowsUuidLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminWorkflowsUuidLabelsPost>>, TError,{pathParams: AddLabelsAdminWorkflowsUuidLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminWorkflowsUuidLabelsPostParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof addLabelsAdminWorkflowsUuidLabelsPost>>,
        TError,
        {pathParams: AddLabelsAdminWorkflowsUuidLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminWorkflowsUuidLabelsPostParams},
        TContext
      > => {

      const mutationOptions = getAddLabelsAdminWorkflowsUuidLabelsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Label
 */
export const deleteLabelAdminWorkflowsUuidLabelsLabelIdDelete = (
    { uuid, labelId }: DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeletePathParameters,
    params?: DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteParams,
 ) => {
      
      
      return deleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteMutator<unknown>(
      {url: `/admin/workflows/${uuid}/labels/${labelId}`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteMutationOptions = <TError = DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminWorkflowsUuidLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminWorkflowsUuidLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLabelAdminWorkflowsUuidLabelsLabelIdDelete>>, {pathParams: DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteParams}> = (props) => {
          const {pathParams,params} = props ?? {};

          return  deleteLabelAdminWorkflowsUuidLabelsLabelIdDelete(pathParams,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLabelAdminWorkflowsUuidLabelsLabelIdDelete>>>
    
    export type DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteMutationError = DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Label
 */
export const useDeleteLabelAdminWorkflowsUuidLabelsLabelIdDelete = <TError = DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminWorkflowsUuidLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteLabelAdminWorkflowsUuidLabelsLabelIdDelete>>,
        TError,
        {pathParams: DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteLabelAdminWorkflowsUuidLabelsLabelIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Workflow
 */
export const listWorkflowAdminWorkflowsGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listWorkflowAdminWorkflowsGetMutator<WorkflowSummary[]>(
      {url: `/admin/workflows`, method: 'GET', signal
    },
      );
    }
  

export const getListWorkflowAdminWorkflowsGetQueryKey = () => {
    return [`/admin/workflows`] as const;
    }

    
export const getListWorkflowAdminWorkflowsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>>, TError = ListWorkflowAdminWorkflowsGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListWorkflowAdminWorkflowsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>> = ({ signal }) => listWorkflowAdminWorkflowsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListWorkflowAdminWorkflowsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>>
export type ListWorkflowAdminWorkflowsGetInfiniteQueryError = ListWorkflowAdminWorkflowsGetErrorType<unknown>

/**
 * @summary List Workflow
 */
export const useListWorkflowAdminWorkflowsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>>, TError = ListWorkflowAdminWorkflowsGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListWorkflowAdminWorkflowsGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListWorkflowAdminWorkflowsGetQueryOptions = <TData = Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError = ListWorkflowAdminWorkflowsGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListWorkflowAdminWorkflowsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>> = ({ signal }) => listWorkflowAdminWorkflowsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListWorkflowAdminWorkflowsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>>
export type ListWorkflowAdminWorkflowsGetQueryError = ListWorkflowAdminWorkflowsGetErrorType<unknown>

/**
 * @summary List Workflow
 */
export const useListWorkflowAdminWorkflowsGet = <TData = Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError = ListWorkflowAdminWorkflowsGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListWorkflowAdminWorkflowsGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListWorkflowAdminWorkflowsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError = ListWorkflowAdminWorkflowsGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListWorkflowAdminWorkflowsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>> = ({ signal }) => listWorkflowAdminWorkflowsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListWorkflowAdminWorkflowsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>>
export type ListWorkflowAdminWorkflowsGetSuspenseQueryError = ListWorkflowAdminWorkflowsGetErrorType<unknown>

/**
 * @summary List Workflow
 */
export const useListWorkflowAdminWorkflowsGetSuspense = <TData = Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError = ListWorkflowAdminWorkflowsGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listWorkflowAdminWorkflowsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListWorkflowAdminWorkflowsGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Workflow
 */
export const getWorkflowAdminWorkflowsUuidGet = (
    { uuid }: GetWorkflowAdminWorkflowsUuidGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getWorkflowAdminWorkflowsUuidGetMutator<OrionDashWorkflowCommonWorkflowInfo>(
      {url: `/admin/workflows/${uuid}`, method: 'GET', signal
    },
      );
    }
  

export const getGetWorkflowAdminWorkflowsUuidGetQueryKey = ({ uuid }: GetWorkflowAdminWorkflowsUuidGetPathParameters,) => {
    return [`/admin/workflows/${uuid}`] as const;
    }

    
export const getGetWorkflowAdminWorkflowsUuidGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>>, TError = GetWorkflowAdminWorkflowsUuidGetErrorType<HTTPValidationError>>({ uuid }: GetWorkflowAdminWorkflowsUuidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowAdminWorkflowsUuidGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>> = ({ signal }) => getWorkflowAdminWorkflowsUuidGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowAdminWorkflowsUuidGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>>
export type GetWorkflowAdminWorkflowsUuidGetInfiniteQueryError = GetWorkflowAdminWorkflowsUuidGetErrorType<HTTPValidationError>

/**
 * @summary Get Workflow
 */
export const useGetWorkflowAdminWorkflowsUuidGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>>, TError = GetWorkflowAdminWorkflowsUuidGetErrorType<HTTPValidationError>>(
 { uuid }: GetWorkflowAdminWorkflowsUuidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWorkflowAdminWorkflowsUuidGetInfiniteQueryOptions({ uuid },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetWorkflowAdminWorkflowsUuidGetQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError = GetWorkflowAdminWorkflowsUuidGetErrorType<HTTPValidationError>>({ uuid }: GetWorkflowAdminWorkflowsUuidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowAdminWorkflowsUuidGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>> = ({ signal }) => getWorkflowAdminWorkflowsUuidGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowAdminWorkflowsUuidGetQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>>
export type GetWorkflowAdminWorkflowsUuidGetQueryError = GetWorkflowAdminWorkflowsUuidGetErrorType<HTTPValidationError>

/**
 * @summary Get Workflow
 */
export const useGetWorkflowAdminWorkflowsUuidGet = <TData = Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError = GetWorkflowAdminWorkflowsUuidGetErrorType<HTTPValidationError>>(
 { uuid }: GetWorkflowAdminWorkflowsUuidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWorkflowAdminWorkflowsUuidGetQueryOptions({ uuid },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetWorkflowAdminWorkflowsUuidGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError = GetWorkflowAdminWorkflowsUuidGetErrorType<HTTPValidationError>>({ uuid }: GetWorkflowAdminWorkflowsUuidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowAdminWorkflowsUuidGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>> = ({ signal }) => getWorkflowAdminWorkflowsUuidGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowAdminWorkflowsUuidGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>>
export type GetWorkflowAdminWorkflowsUuidGetSuspenseQueryError = GetWorkflowAdminWorkflowsUuidGetErrorType<HTTPValidationError>

/**
 * @summary Get Workflow
 */
export const useGetWorkflowAdminWorkflowsUuidGetSuspense = <TData = Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError = GetWorkflowAdminWorkflowsUuidGetErrorType<HTTPValidationError>>(
 { uuid }: GetWorkflowAdminWorkflowsUuidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getWorkflowAdminWorkflowsUuidGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWorkflowAdminWorkflowsUuidGetSuspenseQueryOptions({ uuid },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Save Workflow
 */
export const saveWorkflowAdminWorkflowsUuidPatch = (
    { uuid }: SaveWorkflowAdminWorkflowsUuidPatchPathParameters,
    workflowConfigPayload: WorkflowConfigPayload,
 ) => {
      
      
      return saveWorkflowAdminWorkflowsUuidPatchMutator<OrionDashWorkflowCommonWorkflowInfo>(
      {url: `/admin/workflows/${uuid}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: workflowConfigPayload
    },
      );
    }
  


export const getSaveWorkflowAdminWorkflowsUuidPatchMutationOptions = <TError = SaveWorkflowAdminWorkflowsUuidPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowAdminWorkflowsUuidPatch>>, TError,{pathParams: SaveWorkflowAdminWorkflowsUuidPatchPathParameters;data: WorkflowConfigPayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowAdminWorkflowsUuidPatch>>, TError,{pathParams: SaveWorkflowAdminWorkflowsUuidPatchPathParameters;data: WorkflowConfigPayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveWorkflowAdminWorkflowsUuidPatch>>, {pathParams: SaveWorkflowAdminWorkflowsUuidPatchPathParameters;data: WorkflowConfigPayload}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  saveWorkflowAdminWorkflowsUuidPatch(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveWorkflowAdminWorkflowsUuidPatchMutationResult = NonNullable<Awaited<ReturnType<typeof saveWorkflowAdminWorkflowsUuidPatch>>>
    export type SaveWorkflowAdminWorkflowsUuidPatchMutationBody = WorkflowConfigPayload
    export type SaveWorkflowAdminWorkflowsUuidPatchMutationError = SaveWorkflowAdminWorkflowsUuidPatchErrorType<HTTPValidationError>

    /**
 * @summary Save Workflow
 */
export const useSaveWorkflowAdminWorkflowsUuidPatch = <TError = SaveWorkflowAdminWorkflowsUuidPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowAdminWorkflowsUuidPatch>>, TError,{pathParams: SaveWorkflowAdminWorkflowsUuidPatchPathParameters;data: WorkflowConfigPayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof saveWorkflowAdminWorkflowsUuidPatch>>,
        TError,
        {pathParams: SaveWorkflowAdminWorkflowsUuidPatchPathParameters;data: WorkflowConfigPayload},
        TContext
      > => {

      const mutationOptions = getSaveWorkflowAdminWorkflowsUuidPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Workflow Api
 */
export const deleteWorkflowApiAdminWorkflowsUuidDelete = (
    { uuid }: DeleteWorkflowApiAdminWorkflowsUuidDeletePathParameters,
 ) => {
      
      
      return deleteWorkflowApiAdminWorkflowsUuidDeleteMutator<unknown>(
      {url: `/admin/workflows/${uuid}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteWorkflowApiAdminWorkflowsUuidDeleteMutationOptions = <TError = DeleteWorkflowApiAdminWorkflowsUuidDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowApiAdminWorkflowsUuidDelete>>, TError,{pathParams: DeleteWorkflowApiAdminWorkflowsUuidDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowApiAdminWorkflowsUuidDelete>>, TError,{pathParams: DeleteWorkflowApiAdminWorkflowsUuidDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWorkflowApiAdminWorkflowsUuidDelete>>, {pathParams: DeleteWorkflowApiAdminWorkflowsUuidDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  deleteWorkflowApiAdminWorkflowsUuidDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteWorkflowApiAdminWorkflowsUuidDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWorkflowApiAdminWorkflowsUuidDelete>>>
    
    export type DeleteWorkflowApiAdminWorkflowsUuidDeleteMutationError = DeleteWorkflowApiAdminWorkflowsUuidDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Workflow Api
 */
export const useDeleteWorkflowApiAdminWorkflowsUuidDelete = <TError = DeleteWorkflowApiAdminWorkflowsUuidDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowApiAdminWorkflowsUuidDelete>>, TError,{pathParams: DeleteWorkflowApiAdminWorkflowsUuidDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteWorkflowApiAdminWorkflowsUuidDelete>>,
        TError,
        {pathParams: DeleteWorkflowApiAdminWorkflowsUuidDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getDeleteWorkflowApiAdminWorkflowsUuidDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Workflow Api
 */
export const updateWorkflowApiAdminWorkflowsUuidPut = (
    { uuid }: UpdateWorkflowApiAdminWorkflowsUuidPutPathParameters,
    updateWorkflowConfigPayload: UpdateWorkflowConfigPayload,
 ) => {
      
      
      return updateWorkflowApiAdminWorkflowsUuidPutMutator<OrionDashWorkflowCommonWorkflowInfo>(
      {url: `/admin/workflows/${uuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateWorkflowConfigPayload
    },
      );
    }
  


export const getUpdateWorkflowApiAdminWorkflowsUuidPutMutationOptions = <TError = UpdateWorkflowApiAdminWorkflowsUuidPutErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowApiAdminWorkflowsUuidPut>>, TError,{pathParams: UpdateWorkflowApiAdminWorkflowsUuidPutPathParameters;data: UpdateWorkflowConfigPayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowApiAdminWorkflowsUuidPut>>, TError,{pathParams: UpdateWorkflowApiAdminWorkflowsUuidPutPathParameters;data: UpdateWorkflowConfigPayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWorkflowApiAdminWorkflowsUuidPut>>, {pathParams: UpdateWorkflowApiAdminWorkflowsUuidPutPathParameters;data: UpdateWorkflowConfigPayload}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  updateWorkflowApiAdminWorkflowsUuidPut(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateWorkflowApiAdminWorkflowsUuidPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateWorkflowApiAdminWorkflowsUuidPut>>>
    export type UpdateWorkflowApiAdminWorkflowsUuidPutMutationBody = UpdateWorkflowConfigPayload
    export type UpdateWorkflowApiAdminWorkflowsUuidPutMutationError = UpdateWorkflowApiAdminWorkflowsUuidPutErrorType<HTTPValidationError>

    /**
 * @summary Update Workflow Api
 */
export const useUpdateWorkflowApiAdminWorkflowsUuidPut = <TError = UpdateWorkflowApiAdminWorkflowsUuidPutErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowApiAdminWorkflowsUuidPut>>, TError,{pathParams: UpdateWorkflowApiAdminWorkflowsUuidPutPathParameters;data: UpdateWorkflowConfigPayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateWorkflowApiAdminWorkflowsUuidPut>>,
        TError,
        {pathParams: UpdateWorkflowApiAdminWorkflowsUuidPutPathParameters;data: UpdateWorkflowConfigPayload},
        TContext
      > => {

      const mutationOptions = getUpdateWorkflowApiAdminWorkflowsUuidPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create New Workflow
 */
export const createNewWorkflowAdminWorkflowsPost = (
    newWorkflowConfig: NewWorkflowConfig,
 ) => {
      
      
      return createNewWorkflowAdminWorkflowsPostMutator<OrionDashWorkflowCommonWorkflowInfo>(
      {url: `/admin/workflows/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newWorkflowConfig
    },
      );
    }
  


export const getCreateNewWorkflowAdminWorkflowsPostMutationOptions = <TError = CreateNewWorkflowAdminWorkflowsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNewWorkflowAdminWorkflowsPost>>, TError,{data: NewWorkflowConfig}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createNewWorkflowAdminWorkflowsPost>>, TError,{data: NewWorkflowConfig}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNewWorkflowAdminWorkflowsPost>>, {data: NewWorkflowConfig}> = (props) => {
          const {data} = props ?? {};

          return  createNewWorkflowAdminWorkflowsPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateNewWorkflowAdminWorkflowsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createNewWorkflowAdminWorkflowsPost>>>
    export type CreateNewWorkflowAdminWorkflowsPostMutationBody = NewWorkflowConfig
    export type CreateNewWorkflowAdminWorkflowsPostMutationError = CreateNewWorkflowAdminWorkflowsPostErrorType<HTTPValidationError>

    /**
 * @summary Create New Workflow
 */
export const useCreateNewWorkflowAdminWorkflowsPost = <TError = CreateNewWorkflowAdminWorkflowsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNewWorkflowAdminWorkflowsPost>>, TError,{data: NewWorkflowConfig}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createNewWorkflowAdminWorkflowsPost>>,
        TError,
        {data: NewWorkflowConfig},
        TContext
      > => {

      const mutationOptions = getCreateNewWorkflowAdminWorkflowsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Workflow Versions
 */
export const getWorkflowVersionsAdminWorkflowsUuidVersionsGet = (
    { uuid }: GetWorkflowVersionsAdminWorkflowsUuidVersionsGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getWorkflowVersionsAdminWorkflowsUuidVersionsGetMutator<OrionDashWorkflowCommonWorkflowInfo[]>(
      {url: `/admin/workflows/${uuid}/versions`, method: 'GET', signal
    },
      );
    }
  

export const getGetWorkflowVersionsAdminWorkflowsUuidVersionsGetQueryKey = ({ uuid }: GetWorkflowVersionsAdminWorkflowsUuidVersionsGetPathParameters,) => {
    return [`/admin/workflows/${uuid}/versions`] as const;
    }

    
export const getGetWorkflowVersionsAdminWorkflowsUuidVersionsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>>, TError = GetWorkflowVersionsAdminWorkflowsUuidVersionsGetErrorType<HTTPValidationError>>({ uuid }: GetWorkflowVersionsAdminWorkflowsUuidVersionsGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowVersionsAdminWorkflowsUuidVersionsGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>> = ({ signal }) => getWorkflowVersionsAdminWorkflowsUuidVersionsGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowVersionsAdminWorkflowsUuidVersionsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>>
export type GetWorkflowVersionsAdminWorkflowsUuidVersionsGetInfiniteQueryError = GetWorkflowVersionsAdminWorkflowsUuidVersionsGetErrorType<HTTPValidationError>

/**
 * @summary Get Workflow Versions
 */
export const useGetWorkflowVersionsAdminWorkflowsUuidVersionsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>>, TError = GetWorkflowVersionsAdminWorkflowsUuidVersionsGetErrorType<HTTPValidationError>>(
 { uuid }: GetWorkflowVersionsAdminWorkflowsUuidVersionsGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWorkflowVersionsAdminWorkflowsUuidVersionsGetInfiniteQueryOptions({ uuid },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetWorkflowVersionsAdminWorkflowsUuidVersionsGetQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError = GetWorkflowVersionsAdminWorkflowsUuidVersionsGetErrorType<HTTPValidationError>>({ uuid }: GetWorkflowVersionsAdminWorkflowsUuidVersionsGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowVersionsAdminWorkflowsUuidVersionsGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>> = ({ signal }) => getWorkflowVersionsAdminWorkflowsUuidVersionsGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowVersionsAdminWorkflowsUuidVersionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>>
export type GetWorkflowVersionsAdminWorkflowsUuidVersionsGetQueryError = GetWorkflowVersionsAdminWorkflowsUuidVersionsGetErrorType<HTTPValidationError>

/**
 * @summary Get Workflow Versions
 */
export const useGetWorkflowVersionsAdminWorkflowsUuidVersionsGet = <TData = Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError = GetWorkflowVersionsAdminWorkflowsUuidVersionsGetErrorType<HTTPValidationError>>(
 { uuid }: GetWorkflowVersionsAdminWorkflowsUuidVersionsGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWorkflowVersionsAdminWorkflowsUuidVersionsGetQueryOptions({ uuid },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetWorkflowVersionsAdminWorkflowsUuidVersionsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError = GetWorkflowVersionsAdminWorkflowsUuidVersionsGetErrorType<HTTPValidationError>>({ uuid }: GetWorkflowVersionsAdminWorkflowsUuidVersionsGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowVersionsAdminWorkflowsUuidVersionsGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>> = ({ signal }) => getWorkflowVersionsAdminWorkflowsUuidVersionsGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowVersionsAdminWorkflowsUuidVersionsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>>
export type GetWorkflowVersionsAdminWorkflowsUuidVersionsGetSuspenseQueryError = GetWorkflowVersionsAdminWorkflowsUuidVersionsGetErrorType<HTTPValidationError>

/**
 * @summary Get Workflow Versions
 */
export const useGetWorkflowVersionsAdminWorkflowsUuidVersionsGetSuspense = <TData = Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError = GetWorkflowVersionsAdminWorkflowsUuidVersionsGetErrorType<HTTPValidationError>>(
 { uuid }: GetWorkflowVersionsAdminWorkflowsUuidVersionsGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getWorkflowVersionsAdminWorkflowsUuidVersionsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWorkflowVersionsAdminWorkflowsUuidVersionsGetSuspenseQueryOptions({ uuid },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Versioned Workflow
 */
export const getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet = (
    { uuid, version }: GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetMutator<OrionDashWorkflowCommonWorkflowInfo>(
      {url: `/admin/workflows/${uuid}/versions/${version}`, method: 'GET', signal
    },
      );
    }
  

export const getGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetQueryKey = ({ uuid, version }: GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetPathParameters,) => {
    return [`/admin/workflows/${uuid}/versions/${version}`] as const;
    }

    
export const getGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>>, TError = GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetErrorType<HTTPValidationError>>({ uuid, version }: GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetQueryKey({ uuid, version });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>> = ({ signal }) => getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet({ uuid, version }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>>
export type GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetInfiniteQueryError = GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetErrorType<HTTPValidationError>

/**
 * @summary Get Versioned Workflow
 */
export const useGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>>, TError = GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetErrorType<HTTPValidationError>>(
 { uuid, version }: GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetInfiniteQueryOptions({ uuid, version },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetQueryOptions = <TData = Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError = GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetErrorType<HTTPValidationError>>({ uuid, version }: GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetQueryKey({ uuid, version });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>> = ({ signal }) => getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet({ uuid, version }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>>
export type GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetQueryError = GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetErrorType<HTTPValidationError>

/**
 * @summary Get Versioned Workflow
 */
export const useGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet = <TData = Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError = GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetErrorType<HTTPValidationError>>(
 { uuid, version }: GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetQueryOptions({ uuid, version },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError = GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetErrorType<HTTPValidationError>>({ uuid, version }: GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetQueryKey({ uuid, version });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>> = ({ signal }) => getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet({ uuid, version }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>>
export type GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetSuspenseQueryError = GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetErrorType<HTTPValidationError>

/**
 * @summary Get Versioned Workflow
 */
export const useGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetSuspense = <TData = Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError = GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetErrorType<HTTPValidationError>>(
 { uuid, version }: GetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getVersionedWorkflowAdminWorkflowsUuidVersionsVersionGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVersionedWorkflowAdminWorkflowsUuidVersionsVersionGetSuspenseQueryOptions({ uuid, version },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Delete Versioned Workflow
 */
export const deleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDelete = (
    { uuid, version }: DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeletePathParameters,
 ) => {
      
      
      return deleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeleteMutator<unknown>(
      {url: `/admin/workflows/${uuid}/versions/${version}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeleteMutationOptions = <TError = DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDelete>>, TError,{pathParams: DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDelete>>, TError,{pathParams: DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDelete>>, {pathParams: DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  deleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDelete>>>
    
    export type DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeleteMutationError = DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Versioned Workflow
 */
export const useDeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDelete = <TError = DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDelete>>, TError,{pathParams: DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDelete>>,
        TError,
        {pathParams: DeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getDeleteVersionedWorkflowAdminWorkflowsUuidVersionsVersionDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Deploy Workflow To Production
 */
export const deployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPost = (
    { uuid, version }: DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostPathParameters,
    workflowDeployPayload: WorkflowDeployPayload,
 ) => {
      
      
      return deployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostMutator<OrionDashWorkflowCommonWorkflowInfo>(
      {url: `/admin/workflows/${uuid}/versions/${version}/deploy`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowDeployPayload
    },
      );
    }
  


export const getDeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostMutationOptions = <TError = DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPost>>, TError,{pathParams: DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostPathParameters;data: WorkflowDeployPayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPost>>, TError,{pathParams: DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostPathParameters;data: WorkflowDeployPayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPost>>, {pathParams: DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostPathParameters;data: WorkflowDeployPayload}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  deployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPost(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostMutationResult = NonNullable<Awaited<ReturnType<typeof deployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPost>>>
    export type DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostMutationBody = WorkflowDeployPayload
    export type DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostMutationError = DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostErrorType<HTTPValidationError>

    /**
 * @summary Deploy Workflow To Production
 */
export const useDeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPost = <TError = DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPost>>, TError,{pathParams: DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostPathParameters;data: WorkflowDeployPayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPost>>,
        TError,
        {pathParams: DeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostPathParameters;data: WorkflowDeployPayload},
        TContext
      > => {

      const mutationOptions = getDeployWorkflowToProductionAdminWorkflowsUuidVersionsVersionDeployPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Labels
 */
export const listLabelsAdminEvaluationTemplatesIdLabelsGet = (
    { id }: ListLabelsAdminEvaluationTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminEvaluationTemplatesIdLabelsGetParams,
 signal?: AbortSignal
) => {
      
      
      return listLabelsAdminEvaluationTemplatesIdLabelsGetMutator<DashLabel[]>(
      {url: `/admin/evaluation/templates/${id}/labels`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListLabelsAdminEvaluationTemplatesIdLabelsGetQueryKey = ({ id }: ListLabelsAdminEvaluationTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminEvaluationTemplatesIdLabelsGetParams,) => {
    return [`/admin/evaluation/templates/${id}/labels`, ...(params ? [params]: [])] as const;
    }

    
export const getListLabelsAdminEvaluationTemplatesIdLabelsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, ListLabelsAdminEvaluationTemplatesIdLabelsGetParams['after']>, TError = ListLabelsAdminEvaluationTemplatesIdLabelsGetErrorType<HTTPValidationError>>({ id }: ListLabelsAdminEvaluationTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminEvaluationTemplatesIdLabelsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, QueryKey, ListLabelsAdminEvaluationTemplatesIdLabelsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminEvaluationTemplatesIdLabelsGetQueryKey({ id },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, QueryKey, ListLabelsAdminEvaluationTemplatesIdLabelsGetParams['after']> = ({ signal, pageParam }) => listLabelsAdminEvaluationTemplatesIdLabelsGet({ id },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, QueryKey, ListLabelsAdminEvaluationTemplatesIdLabelsGetParams['after']> & { queryKey: QueryKey }
}

export type ListLabelsAdminEvaluationTemplatesIdLabelsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>>
export type ListLabelsAdminEvaluationTemplatesIdLabelsGetInfiniteQueryError = ListLabelsAdminEvaluationTemplatesIdLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminEvaluationTemplatesIdLabelsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, ListLabelsAdminEvaluationTemplatesIdLabelsGetParams['after']>, TError = ListLabelsAdminEvaluationTemplatesIdLabelsGetErrorType<HTTPValidationError>>(
 { id }: ListLabelsAdminEvaluationTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminEvaluationTemplatesIdLabelsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, QueryKey, ListLabelsAdminEvaluationTemplatesIdLabelsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminEvaluationTemplatesIdLabelsGetInfiniteQueryOptions({ id },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminEvaluationTemplatesIdLabelsGetQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError = ListLabelsAdminEvaluationTemplatesIdLabelsGetErrorType<HTTPValidationError>>({ id }: ListLabelsAdminEvaluationTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminEvaluationTemplatesIdLabelsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminEvaluationTemplatesIdLabelsGetQueryKey({ id },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>> = ({ signal }) => listLabelsAdminEvaluationTemplatesIdLabelsGet({ id },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminEvaluationTemplatesIdLabelsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>>
export type ListLabelsAdminEvaluationTemplatesIdLabelsGetQueryError = ListLabelsAdminEvaluationTemplatesIdLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminEvaluationTemplatesIdLabelsGet = <TData = Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError = ListLabelsAdminEvaluationTemplatesIdLabelsGetErrorType<HTTPValidationError>>(
 { id }: ListLabelsAdminEvaluationTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminEvaluationTemplatesIdLabelsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminEvaluationTemplatesIdLabelsGetQueryOptions({ id },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminEvaluationTemplatesIdLabelsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError = ListLabelsAdminEvaluationTemplatesIdLabelsGetErrorType<HTTPValidationError>>({ id }: ListLabelsAdminEvaluationTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminEvaluationTemplatesIdLabelsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminEvaluationTemplatesIdLabelsGetQueryKey({ id },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>> = ({ signal }) => listLabelsAdminEvaluationTemplatesIdLabelsGet({ id },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminEvaluationTemplatesIdLabelsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>>
export type ListLabelsAdminEvaluationTemplatesIdLabelsGetSuspenseQueryError = ListLabelsAdminEvaluationTemplatesIdLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminEvaluationTemplatesIdLabelsGetSuspense = <TData = Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError = ListLabelsAdminEvaluationTemplatesIdLabelsGetErrorType<HTTPValidationError>>(
 { id }: ListLabelsAdminEvaluationTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminEvaluationTemplatesIdLabelsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminEvaluationTemplatesIdLabelsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminEvaluationTemplatesIdLabelsGetSuspenseQueryOptions({ id },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update
 */
export const updateAdminEvaluationTemplatesIdLabelsPatch = (
    { id }: UpdateAdminEvaluationTemplatesIdLabelsPatchPathParameters,
    mixInLabelPayload: MixInLabelPayload,
    params?: UpdateAdminEvaluationTemplatesIdLabelsPatchParams,
 ) => {
      
      
      return updateAdminEvaluationTemplatesIdLabelsPatchMutator<DashLabel[]>(
      {url: `/admin/evaluation/templates/${id}/labels`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: mixInLabelPayload,
        params
    },
      );
    }
  


export const getUpdateAdminEvaluationTemplatesIdLabelsPatchMutationOptions = <TError = UpdateAdminEvaluationTemplatesIdLabelsPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminEvaluationTemplatesIdLabelsPatch>>, TError,{pathParams: UpdateAdminEvaluationTemplatesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminEvaluationTemplatesIdLabelsPatchParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAdminEvaluationTemplatesIdLabelsPatch>>, TError,{pathParams: UpdateAdminEvaluationTemplatesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminEvaluationTemplatesIdLabelsPatchParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAdminEvaluationTemplatesIdLabelsPatch>>, {pathParams: UpdateAdminEvaluationTemplatesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminEvaluationTemplatesIdLabelsPatchParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  updateAdminEvaluationTemplatesIdLabelsPatch(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAdminEvaluationTemplatesIdLabelsPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateAdminEvaluationTemplatesIdLabelsPatch>>>
    export type UpdateAdminEvaluationTemplatesIdLabelsPatchMutationBody = MixInLabelPayload
    export type UpdateAdminEvaluationTemplatesIdLabelsPatchMutationError = UpdateAdminEvaluationTemplatesIdLabelsPatchErrorType<HTTPValidationError>

    /**
 * @summary Update
 */
export const useUpdateAdminEvaluationTemplatesIdLabelsPatch = <TError = UpdateAdminEvaluationTemplatesIdLabelsPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminEvaluationTemplatesIdLabelsPatch>>, TError,{pathParams: UpdateAdminEvaluationTemplatesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminEvaluationTemplatesIdLabelsPatchParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateAdminEvaluationTemplatesIdLabelsPatch>>,
        TError,
        {pathParams: UpdateAdminEvaluationTemplatesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminEvaluationTemplatesIdLabelsPatchParams},
        TContext
      > => {

      const mutationOptions = getUpdateAdminEvaluationTemplatesIdLabelsPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Labels
 */
export const addLabelsAdminEvaluationTemplatesIdLabelsPost = (
    { id }: AddLabelsAdminEvaluationTemplatesIdLabelsPostPathParameters,
    mixInLabelPayload: MixInLabelPayload,
    params?: AddLabelsAdminEvaluationTemplatesIdLabelsPostParams,
 ) => {
      
      
      return addLabelsAdminEvaluationTemplatesIdLabelsPostMutator<DashLabel[]>(
      {url: `/admin/evaluation/templates/${id}/labels`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: mixInLabelPayload,
        params
    },
      );
    }
  


export const getAddLabelsAdminEvaluationTemplatesIdLabelsPostMutationOptions = <TError = AddLabelsAdminEvaluationTemplatesIdLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminEvaluationTemplatesIdLabelsPost>>, TError,{pathParams: AddLabelsAdminEvaluationTemplatesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminEvaluationTemplatesIdLabelsPostParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminEvaluationTemplatesIdLabelsPost>>, TError,{pathParams: AddLabelsAdminEvaluationTemplatesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminEvaluationTemplatesIdLabelsPostParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addLabelsAdminEvaluationTemplatesIdLabelsPost>>, {pathParams: AddLabelsAdminEvaluationTemplatesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminEvaluationTemplatesIdLabelsPostParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  addLabelsAdminEvaluationTemplatesIdLabelsPost(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddLabelsAdminEvaluationTemplatesIdLabelsPostMutationResult = NonNullable<Awaited<ReturnType<typeof addLabelsAdminEvaluationTemplatesIdLabelsPost>>>
    export type AddLabelsAdminEvaluationTemplatesIdLabelsPostMutationBody = MixInLabelPayload
    export type AddLabelsAdminEvaluationTemplatesIdLabelsPostMutationError = AddLabelsAdminEvaluationTemplatesIdLabelsPostErrorType<HTTPValidationError>

    /**
 * @summary Add Labels
 */
export const useAddLabelsAdminEvaluationTemplatesIdLabelsPost = <TError = AddLabelsAdminEvaluationTemplatesIdLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminEvaluationTemplatesIdLabelsPost>>, TError,{pathParams: AddLabelsAdminEvaluationTemplatesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminEvaluationTemplatesIdLabelsPostParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof addLabelsAdminEvaluationTemplatesIdLabelsPost>>,
        TError,
        {pathParams: AddLabelsAdminEvaluationTemplatesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminEvaluationTemplatesIdLabelsPostParams},
        TContext
      > => {

      const mutationOptions = getAddLabelsAdminEvaluationTemplatesIdLabelsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Label
 */
export const deleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDelete = (
    { id, labelId }: DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeletePathParameters,
    params?: DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteParams,
 ) => {
      
      
      return deleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteMutator<unknown>(
      {url: `/admin/evaluation/templates/${id}/labels/${labelId}`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteMutationOptions = <TError = DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDelete>>, {pathParams: DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteParams}> = (props) => {
          const {pathParams,params} = props ?? {};

          return  deleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDelete(pathParams,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDelete>>>
    
    export type DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteMutationError = DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Label
 */
export const useDeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDelete = <TError = DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDelete>>,
        TError,
        {pathParams: DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteLabelAdminEvaluationTemplatesIdLabelsLabelIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Evaluation Templates
 */
export const listEvaluationTemplatesAdminEvaluationTemplatesGet = (
    params?: ListEvaluationTemplatesAdminEvaluationTemplatesGetParams,
 signal?: AbortSignal
) => {
      
      
      return listEvaluationTemplatesAdminEvaluationTemplatesGetMutator<EvaluationTemplateSummary[]>(
      {url: `/admin/evaluation/templates`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListEvaluationTemplatesAdminEvaluationTemplatesGetQueryKey = (params?: ListEvaluationTemplatesAdminEvaluationTemplatesGetParams,) => {
    return [`/admin/evaluation/templates`, ...(params ? [params]: [])] as const;
    }

    
export const getListEvaluationTemplatesAdminEvaluationTemplatesGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, ListEvaluationTemplatesAdminEvaluationTemplatesGetParams['after']>, TError = ListEvaluationTemplatesAdminEvaluationTemplatesGetErrorType<HTTPValidationError>>(params?: ListEvaluationTemplatesAdminEvaluationTemplatesGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError, TData, Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, QueryKey, ListEvaluationTemplatesAdminEvaluationTemplatesGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEvaluationTemplatesAdminEvaluationTemplatesGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, QueryKey, ListEvaluationTemplatesAdminEvaluationTemplatesGetParams['after']> = ({ signal, pageParam }) => listEvaluationTemplatesAdminEvaluationTemplatesGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError, TData, Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, QueryKey, ListEvaluationTemplatesAdminEvaluationTemplatesGetParams['after']> & { queryKey: QueryKey }
}

export type ListEvaluationTemplatesAdminEvaluationTemplatesGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>>
export type ListEvaluationTemplatesAdminEvaluationTemplatesGetInfiniteQueryError = ListEvaluationTemplatesAdminEvaluationTemplatesGetErrorType<HTTPValidationError>

/**
 * @summary List Evaluation Templates
 */
export const useListEvaluationTemplatesAdminEvaluationTemplatesGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, ListEvaluationTemplatesAdminEvaluationTemplatesGetParams['after']>, TError = ListEvaluationTemplatesAdminEvaluationTemplatesGetErrorType<HTTPValidationError>>(
 params?: ListEvaluationTemplatesAdminEvaluationTemplatesGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError, TData, Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, QueryKey, ListEvaluationTemplatesAdminEvaluationTemplatesGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEvaluationTemplatesAdminEvaluationTemplatesGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListEvaluationTemplatesAdminEvaluationTemplatesGetQueryOptions = <TData = Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError = ListEvaluationTemplatesAdminEvaluationTemplatesGetErrorType<HTTPValidationError>>(params?: ListEvaluationTemplatesAdminEvaluationTemplatesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEvaluationTemplatesAdminEvaluationTemplatesGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>> = ({ signal }) => listEvaluationTemplatesAdminEvaluationTemplatesGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListEvaluationTemplatesAdminEvaluationTemplatesGetQueryResult = NonNullable<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>>
export type ListEvaluationTemplatesAdminEvaluationTemplatesGetQueryError = ListEvaluationTemplatesAdminEvaluationTemplatesGetErrorType<HTTPValidationError>

/**
 * @summary List Evaluation Templates
 */
export const useListEvaluationTemplatesAdminEvaluationTemplatesGet = <TData = Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError = ListEvaluationTemplatesAdminEvaluationTemplatesGetErrorType<HTTPValidationError>>(
 params?: ListEvaluationTemplatesAdminEvaluationTemplatesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEvaluationTemplatesAdminEvaluationTemplatesGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListEvaluationTemplatesAdminEvaluationTemplatesGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError = ListEvaluationTemplatesAdminEvaluationTemplatesGetErrorType<HTTPValidationError>>(params?: ListEvaluationTemplatesAdminEvaluationTemplatesGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEvaluationTemplatesAdminEvaluationTemplatesGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>> = ({ signal }) => listEvaluationTemplatesAdminEvaluationTemplatesGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListEvaluationTemplatesAdminEvaluationTemplatesGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>>
export type ListEvaluationTemplatesAdminEvaluationTemplatesGetSuspenseQueryError = ListEvaluationTemplatesAdminEvaluationTemplatesGetErrorType<HTTPValidationError>

/**
 * @summary List Evaluation Templates
 */
export const useListEvaluationTemplatesAdminEvaluationTemplatesGetSuspense = <TData = Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError = ListEvaluationTemplatesAdminEvaluationTemplatesGetErrorType<HTTPValidationError>>(
 params?: ListEvaluationTemplatesAdminEvaluationTemplatesGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listEvaluationTemplatesAdminEvaluationTemplatesGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEvaluationTemplatesAdminEvaluationTemplatesGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Template
 */
export const createTemplateAdminEvaluationTemplatesPost = (
    newEvalTemplateInput: NewEvalTemplateInput,
 ) => {
      
      
      return createTemplateAdminEvaluationTemplatesPostMutator<EvalutaionTemplateData>(
      {url: `/admin/evaluation/templates`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newEvalTemplateInput
    },
      );
    }
  


export const getCreateTemplateAdminEvaluationTemplatesPostMutationOptions = <TError = CreateTemplateAdminEvaluationTemplatesPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTemplateAdminEvaluationTemplatesPost>>, TError,{data: NewEvalTemplateInput}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createTemplateAdminEvaluationTemplatesPost>>, TError,{data: NewEvalTemplateInput}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTemplateAdminEvaluationTemplatesPost>>, {data: NewEvalTemplateInput}> = (props) => {
          const {data} = props ?? {};

          return  createTemplateAdminEvaluationTemplatesPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTemplateAdminEvaluationTemplatesPostMutationResult = NonNullable<Awaited<ReturnType<typeof createTemplateAdminEvaluationTemplatesPost>>>
    export type CreateTemplateAdminEvaluationTemplatesPostMutationBody = NewEvalTemplateInput
    export type CreateTemplateAdminEvaluationTemplatesPostMutationError = CreateTemplateAdminEvaluationTemplatesPostErrorType<HTTPValidationError>

    /**
 * @summary Create Template
 */
export const useCreateTemplateAdminEvaluationTemplatesPost = <TError = CreateTemplateAdminEvaluationTemplatesPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTemplateAdminEvaluationTemplatesPost>>, TError,{data: NewEvalTemplateInput}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createTemplateAdminEvaluationTemplatesPost>>,
        TError,
        {data: NewEvalTemplateInput},
        TContext
      > => {

      const mutationOptions = getCreateTemplateAdminEvaluationTemplatesPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Evaluation Template
 */
export const getEvaluationTemplateAdminEvaluationTemplatesIdGet = (
    { id }: GetEvaluationTemplateAdminEvaluationTemplatesIdGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getEvaluationTemplateAdminEvaluationTemplatesIdGetMutator<EvalutaionTemplateData>(
      {url: `/admin/evaluation/templates/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetEvaluationTemplateAdminEvaluationTemplatesIdGetQueryKey = ({ id }: GetEvaluationTemplateAdminEvaluationTemplatesIdGetPathParameters,) => {
    return [`/admin/evaluation/templates/${id}`] as const;
    }

    
export const getGetEvaluationTemplateAdminEvaluationTemplatesIdGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>>, TError = GetEvaluationTemplateAdminEvaluationTemplatesIdGetErrorType<HTTPValidationError>>({ id }: GetEvaluationTemplateAdminEvaluationTemplatesIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEvaluationTemplateAdminEvaluationTemplatesIdGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>> = ({ signal }) => getEvaluationTemplateAdminEvaluationTemplatesIdGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEvaluationTemplateAdminEvaluationTemplatesIdGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>>
export type GetEvaluationTemplateAdminEvaluationTemplatesIdGetInfiniteQueryError = GetEvaluationTemplateAdminEvaluationTemplatesIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Evaluation Template
 */
export const useGetEvaluationTemplateAdminEvaluationTemplatesIdGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>>, TError = GetEvaluationTemplateAdminEvaluationTemplatesIdGetErrorType<HTTPValidationError>>(
 { id }: GetEvaluationTemplateAdminEvaluationTemplatesIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEvaluationTemplateAdminEvaluationTemplatesIdGetInfiniteQueryOptions({ id },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEvaluationTemplateAdminEvaluationTemplatesIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError = GetEvaluationTemplateAdminEvaluationTemplatesIdGetErrorType<HTTPValidationError>>({ id }: GetEvaluationTemplateAdminEvaluationTemplatesIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEvaluationTemplateAdminEvaluationTemplatesIdGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>> = ({ signal }) => getEvaluationTemplateAdminEvaluationTemplatesIdGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEvaluationTemplateAdminEvaluationTemplatesIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>>
export type GetEvaluationTemplateAdminEvaluationTemplatesIdGetQueryError = GetEvaluationTemplateAdminEvaluationTemplatesIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Evaluation Template
 */
export const useGetEvaluationTemplateAdminEvaluationTemplatesIdGet = <TData = Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError = GetEvaluationTemplateAdminEvaluationTemplatesIdGetErrorType<HTTPValidationError>>(
 { id }: GetEvaluationTemplateAdminEvaluationTemplatesIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEvaluationTemplateAdminEvaluationTemplatesIdGetQueryOptions({ id },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEvaluationTemplateAdminEvaluationTemplatesIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError = GetEvaluationTemplateAdminEvaluationTemplatesIdGetErrorType<HTTPValidationError>>({ id }: GetEvaluationTemplateAdminEvaluationTemplatesIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEvaluationTemplateAdminEvaluationTemplatesIdGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>> = ({ signal }) => getEvaluationTemplateAdminEvaluationTemplatesIdGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEvaluationTemplateAdminEvaluationTemplatesIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>>
export type GetEvaluationTemplateAdminEvaluationTemplatesIdGetSuspenseQueryError = GetEvaluationTemplateAdminEvaluationTemplatesIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Evaluation Template
 */
export const useGetEvaluationTemplateAdminEvaluationTemplatesIdGetSuspense = <TData = Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError = GetEvaluationTemplateAdminEvaluationTemplatesIdGetErrorType<HTTPValidationError>>(
 { id }: GetEvaluationTemplateAdminEvaluationTemplatesIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEvaluationTemplateAdminEvaluationTemplatesIdGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEvaluationTemplateAdminEvaluationTemplatesIdGetSuspenseQueryOptions({ id },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Template
 */
export const updateTemplateAdminEvaluationTemplatesIdPatch = (
    { id }: UpdateTemplateAdminEvaluationTemplatesIdPatchPathParameters,
    patchEvalTemplate: PatchEvalTemplate,
 ) => {
      
      
      return updateTemplateAdminEvaluationTemplatesIdPatchMutator<EvalutaionTemplateData>(
      {url: `/admin/evaluation/templates/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchEvalTemplate
    },
      );
    }
  


export const getUpdateTemplateAdminEvaluationTemplatesIdPatchMutationOptions = <TError = UpdateTemplateAdminEvaluationTemplatesIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTemplateAdminEvaluationTemplatesIdPatch>>, TError,{pathParams: UpdateTemplateAdminEvaluationTemplatesIdPatchPathParameters;data: PatchEvalTemplate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateTemplateAdminEvaluationTemplatesIdPatch>>, TError,{pathParams: UpdateTemplateAdminEvaluationTemplatesIdPatchPathParameters;data: PatchEvalTemplate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTemplateAdminEvaluationTemplatesIdPatch>>, {pathParams: UpdateTemplateAdminEvaluationTemplatesIdPatchPathParameters;data: PatchEvalTemplate}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  updateTemplateAdminEvaluationTemplatesIdPatch(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTemplateAdminEvaluationTemplatesIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateTemplateAdminEvaluationTemplatesIdPatch>>>
    export type UpdateTemplateAdminEvaluationTemplatesIdPatchMutationBody = PatchEvalTemplate
    export type UpdateTemplateAdminEvaluationTemplatesIdPatchMutationError = UpdateTemplateAdminEvaluationTemplatesIdPatchErrorType<HTTPValidationError>

    /**
 * @summary Update Template
 */
export const useUpdateTemplateAdminEvaluationTemplatesIdPatch = <TError = UpdateTemplateAdminEvaluationTemplatesIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTemplateAdminEvaluationTemplatesIdPatch>>, TError,{pathParams: UpdateTemplateAdminEvaluationTemplatesIdPatchPathParameters;data: PatchEvalTemplate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateTemplateAdminEvaluationTemplatesIdPatch>>,
        TError,
        {pathParams: UpdateTemplateAdminEvaluationTemplatesIdPatchPathParameters;data: PatchEvalTemplate},
        TContext
      > => {

      const mutationOptions = getUpdateTemplateAdminEvaluationTemplatesIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Template
 */
export const deleteTemplateAdminEvaluationTemplatesIdDelete = (
    { id }: DeleteTemplateAdminEvaluationTemplatesIdDeletePathParameters,
 ) => {
      
      
      return deleteTemplateAdminEvaluationTemplatesIdDeleteMutator<void>(
      {url: `/admin/evaluation/templates/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteTemplateAdminEvaluationTemplatesIdDeleteMutationOptions = <TError = DeleteTemplateAdminEvaluationTemplatesIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTemplateAdminEvaluationTemplatesIdDelete>>, TError,{pathParams: DeleteTemplateAdminEvaluationTemplatesIdDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteTemplateAdminEvaluationTemplatesIdDelete>>, TError,{pathParams: DeleteTemplateAdminEvaluationTemplatesIdDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTemplateAdminEvaluationTemplatesIdDelete>>, {pathParams: DeleteTemplateAdminEvaluationTemplatesIdDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  deleteTemplateAdminEvaluationTemplatesIdDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTemplateAdminEvaluationTemplatesIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTemplateAdminEvaluationTemplatesIdDelete>>>
    
    export type DeleteTemplateAdminEvaluationTemplatesIdDeleteMutationError = DeleteTemplateAdminEvaluationTemplatesIdDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Template
 */
export const useDeleteTemplateAdminEvaluationTemplatesIdDelete = <TError = DeleteTemplateAdminEvaluationTemplatesIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTemplateAdminEvaluationTemplatesIdDelete>>, TError,{pathParams: DeleteTemplateAdminEvaluationTemplatesIdDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTemplateAdminEvaluationTemplatesIdDelete>>,
        TError,
        {pathParams: DeleteTemplateAdminEvaluationTemplatesIdDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getDeleteTemplateAdminEvaluationTemplatesIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Recent Evaluation Tasks
 */
export const listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet = (
    { id }: ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetMutator<EvaluationTaskData[]>(
      {url: `/admin/evaluation/templates/${id}/tasks`, method: 'GET', signal
    },
      );
    }
  

export const getListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetQueryKey = ({ id }: ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetPathParameters,) => {
    return [`/admin/evaluation/templates/${id}/tasks`] as const;
    }

    
export const getListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>>, TError = ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetErrorType<HTTPValidationError>>({ id }: ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>> = ({ signal }) => listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>>
export type ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetInfiniteQueryError = ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetErrorType<HTTPValidationError>

/**
 * @summary List Recent Evaluation Tasks
 */
export const useListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>>, TError = ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetErrorType<HTTPValidationError>>(
 { id }: ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetInfiniteQueryOptions({ id },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetQueryOptions = <TData = Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError = ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetErrorType<HTTPValidationError>>({ id }: ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>> = ({ signal }) => listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetQueryResult = NonNullable<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>>
export type ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetQueryError = ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetErrorType<HTTPValidationError>

/**
 * @summary List Recent Evaluation Tasks
 */
export const useListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet = <TData = Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError = ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetErrorType<HTTPValidationError>>(
 { id }: ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetQueryOptions({ id },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError = ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetErrorType<HTTPValidationError>>({ id }: ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>> = ({ signal }) => listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>>
export type ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetSuspenseQueryError = ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetErrorType<HTTPValidationError>

/**
 * @summary List Recent Evaluation Tasks
 */
export const useListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetSuspense = <TData = Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError = ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetErrorType<HTTPValidationError>>(
 { id }: ListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListRecentEvaluationTasksAdminEvaluationTemplatesIdTasksGetSuspenseQueryOptions({ id },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Template Batch
 */
export const createTemplateBatchAdminEvaluationTemplatesBatchPost = (
    batchEvalTemplate: BatchEvalTemplate[],
 ) => {
      
      
      return createTemplateBatchAdminEvaluationTemplatesBatchPostMutator<EvalutaionTemplateData[]>(
      {url: `/admin/evaluation/templates/batch`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: batchEvalTemplate
    },
      );
    }
  


export const getCreateTemplateBatchAdminEvaluationTemplatesBatchPostMutationOptions = <TError = CreateTemplateBatchAdminEvaluationTemplatesBatchPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTemplateBatchAdminEvaluationTemplatesBatchPost>>, TError,{data: BatchEvalTemplate[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createTemplateBatchAdminEvaluationTemplatesBatchPost>>, TError,{data: BatchEvalTemplate[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTemplateBatchAdminEvaluationTemplatesBatchPost>>, {data: BatchEvalTemplate[]}> = (props) => {
          const {data} = props ?? {};

          return  createTemplateBatchAdminEvaluationTemplatesBatchPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTemplateBatchAdminEvaluationTemplatesBatchPostMutationResult = NonNullable<Awaited<ReturnType<typeof createTemplateBatchAdminEvaluationTemplatesBatchPost>>>
    export type CreateTemplateBatchAdminEvaluationTemplatesBatchPostMutationBody = BatchEvalTemplate[]
    export type CreateTemplateBatchAdminEvaluationTemplatesBatchPostMutationError = CreateTemplateBatchAdminEvaluationTemplatesBatchPostErrorType<HTTPValidationError>

    /**
 * @summary Create Template Batch
 */
export const useCreateTemplateBatchAdminEvaluationTemplatesBatchPost = <TError = CreateTemplateBatchAdminEvaluationTemplatesBatchPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTemplateBatchAdminEvaluationTemplatesBatchPost>>, TError,{data: BatchEvalTemplate[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createTemplateBatchAdminEvaluationTemplatesBatchPost>>,
        TError,
        {data: BatchEvalTemplate[]},
        TContext
      > => {

      const mutationOptions = getCreateTemplateBatchAdminEvaluationTemplatesBatchPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Complete Template
 */
export const completeTemplateAdminEvaluationTemplatesIdCompletionPost = (
    { id }: CompleteTemplateAdminEvaluationTemplatesIdCompletionPostPathParameters,
 ) => {
      
      
      return completeTemplateAdminEvaluationTemplatesIdCompletionPostMutator<unknown>(
      {url: `/admin/evaluation/templates/${id}/completion`, method: 'POST'
    },
      );
    }
  


export const getCompleteTemplateAdminEvaluationTemplatesIdCompletionPostMutationOptions = <TError = CompleteTemplateAdminEvaluationTemplatesIdCompletionPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof completeTemplateAdminEvaluationTemplatesIdCompletionPost>>, TError,{pathParams: CompleteTemplateAdminEvaluationTemplatesIdCompletionPostPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof completeTemplateAdminEvaluationTemplatesIdCompletionPost>>, TError,{pathParams: CompleteTemplateAdminEvaluationTemplatesIdCompletionPostPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof completeTemplateAdminEvaluationTemplatesIdCompletionPost>>, {pathParams: CompleteTemplateAdminEvaluationTemplatesIdCompletionPostPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  completeTemplateAdminEvaluationTemplatesIdCompletionPost(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompleteTemplateAdminEvaluationTemplatesIdCompletionPostMutationResult = NonNullable<Awaited<ReturnType<typeof completeTemplateAdminEvaluationTemplatesIdCompletionPost>>>
    
    export type CompleteTemplateAdminEvaluationTemplatesIdCompletionPostMutationError = CompleteTemplateAdminEvaluationTemplatesIdCompletionPostErrorType<HTTPValidationError>

    /**
 * @summary Complete Template
 */
export const useCompleteTemplateAdminEvaluationTemplatesIdCompletionPost = <TError = CompleteTemplateAdminEvaluationTemplatesIdCompletionPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof completeTemplateAdminEvaluationTemplatesIdCompletionPost>>, TError,{pathParams: CompleteTemplateAdminEvaluationTemplatesIdCompletionPostPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof completeTemplateAdminEvaluationTemplatesIdCompletionPost>>,
        TError,
        {pathParams: CompleteTemplateAdminEvaluationTemplatesIdCompletionPostPathParameters},
        TContext
      > => {

      const mutationOptions = getCompleteTemplateAdminEvaluationTemplatesIdCompletionPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Parser Template Case
 */
export const parserTemplateCaseAdminEvaluationTemplatesCaseParserPost = (
    parserCaseRequest: ParserCaseRequest,
 ) => {
      
      
      return parserTemplateCaseAdminEvaluationTemplatesCaseParserPostMutator<NewEvalTemplateOutput[]>(
      {url: `/admin/evaluation/templates/case_parser`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: parserCaseRequest
    },
      );
    }
  


export const getParserTemplateCaseAdminEvaluationTemplatesCaseParserPostMutationOptions = <TError = ParserTemplateCaseAdminEvaluationTemplatesCaseParserPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof parserTemplateCaseAdminEvaluationTemplatesCaseParserPost>>, TError,{data: ParserCaseRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof parserTemplateCaseAdminEvaluationTemplatesCaseParserPost>>, TError,{data: ParserCaseRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof parserTemplateCaseAdminEvaluationTemplatesCaseParserPost>>, {data: ParserCaseRequest}> = (props) => {
          const {data} = props ?? {};

          return  parserTemplateCaseAdminEvaluationTemplatesCaseParserPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ParserTemplateCaseAdminEvaluationTemplatesCaseParserPostMutationResult = NonNullable<Awaited<ReturnType<typeof parserTemplateCaseAdminEvaluationTemplatesCaseParserPost>>>
    export type ParserTemplateCaseAdminEvaluationTemplatesCaseParserPostMutationBody = ParserCaseRequest
    export type ParserTemplateCaseAdminEvaluationTemplatesCaseParserPostMutationError = ParserTemplateCaseAdminEvaluationTemplatesCaseParserPostErrorType<HTTPValidationError>

    /**
 * @summary Parser Template Case
 */
export const useParserTemplateCaseAdminEvaluationTemplatesCaseParserPost = <TError = ParserTemplateCaseAdminEvaluationTemplatesCaseParserPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof parserTemplateCaseAdminEvaluationTemplatesCaseParserPost>>, TError,{data: ParserCaseRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof parserTemplateCaseAdminEvaluationTemplatesCaseParserPost>>,
        TError,
        {data: ParserCaseRequest},
        TContext
      > => {

      const mutationOptions = getParserTemplateCaseAdminEvaluationTemplatesCaseParserPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Template Via Message Id
 */
export const createTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPost = (
    createTemplateViaMessageIDRequest: CreateTemplateViaMessageIDRequest,
 ) => {
      
      
      return createTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPostMutator<NewEvalTemplateOutput[]>(
      {url: `/admin/evaluation/templates/create_template_via_message_id`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createTemplateViaMessageIDRequest
    },
      );
    }
  


export const getCreateTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPostMutationOptions = <TError = CreateTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPost>>, TError,{data: CreateTemplateViaMessageIDRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPost>>, TError,{data: CreateTemplateViaMessageIDRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPost>>, {data: CreateTemplateViaMessageIDRequest}> = (props) => {
          const {data} = props ?? {};

          return  createTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPostMutationResult = NonNullable<Awaited<ReturnType<typeof createTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPost>>>
    export type CreateTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPostMutationBody = CreateTemplateViaMessageIDRequest
    export type CreateTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPostMutationError = CreateTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPostErrorType<HTTPValidationError>

    /**
 * @summary Create Template Via Message Id
 */
export const useCreateTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPost = <TError = CreateTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPost>>, TError,{data: CreateTemplateViaMessageIDRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPost>>,
        TError,
        {data: CreateTemplateViaMessageIDRequest},
        TContext
      > => {

      const mutationOptions = getCreateTemplateViaMessageIdAdminEvaluationTemplatesCreateTemplateViaMessageIdPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Template Via User Id
 */
export const createTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPost = (
    createTemplateViaUserIDRequest: CreateTemplateViaUserIDRequest,
 ) => {
      
      
      return createTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPostMutator<NewEvalTemplateOutput[]>(
      {url: `/admin/evaluation/templates/create_template_via_user_id`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createTemplateViaUserIDRequest
    },
      );
    }
  


export const getCreateTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPostMutationOptions = <TError = CreateTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPost>>, TError,{data: CreateTemplateViaUserIDRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPost>>, TError,{data: CreateTemplateViaUserIDRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPost>>, {data: CreateTemplateViaUserIDRequest}> = (props) => {
          const {data} = props ?? {};

          return  createTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPostMutationResult = NonNullable<Awaited<ReturnType<typeof createTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPost>>>
    export type CreateTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPostMutationBody = CreateTemplateViaUserIDRequest
    export type CreateTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPostMutationError = CreateTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPostErrorType<HTTPValidationError>

    /**
 * @summary Create Template Via User Id
 */
export const useCreateTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPost = <TError = CreateTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPost>>, TError,{data: CreateTemplateViaUserIDRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPost>>,
        TError,
        {data: CreateTemplateViaUserIDRequest},
        TContext
      > => {

      const mutationOptions = getCreateTemplateViaUserIdAdminEvaluationTemplatesCreateTemplateViaUserIdPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Evaluation Plans
 */
export const listEvaluationPlansAdminEvaluationPlansGet = (
    params?: ListEvaluationPlansAdminEvaluationPlansGetParams,
 signal?: AbortSignal
) => {
      
      
      return listEvaluationPlansAdminEvaluationPlansGetMutator<EvaluationPlanSummary[]>(
      {url: `/admin/evaluation/plans`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListEvaluationPlansAdminEvaluationPlansGetQueryKey = (params?: ListEvaluationPlansAdminEvaluationPlansGetParams,) => {
    return [`/admin/evaluation/plans`, ...(params ? [params]: [])] as const;
    }

    
export const getListEvaluationPlansAdminEvaluationPlansGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, ListEvaluationPlansAdminEvaluationPlansGetParams['after']>, TError = ListEvaluationPlansAdminEvaluationPlansGetErrorType<HTTPValidationError>>(params?: ListEvaluationPlansAdminEvaluationPlansGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError, TData, Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, QueryKey, ListEvaluationPlansAdminEvaluationPlansGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEvaluationPlansAdminEvaluationPlansGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, QueryKey, ListEvaluationPlansAdminEvaluationPlansGetParams['after']> = ({ signal, pageParam }) => listEvaluationPlansAdminEvaluationPlansGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError, TData, Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, QueryKey, ListEvaluationPlansAdminEvaluationPlansGetParams['after']> & { queryKey: QueryKey }
}

export type ListEvaluationPlansAdminEvaluationPlansGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>>
export type ListEvaluationPlansAdminEvaluationPlansGetInfiniteQueryError = ListEvaluationPlansAdminEvaluationPlansGetErrorType<HTTPValidationError>

/**
 * @summary List Evaluation Plans
 */
export const useListEvaluationPlansAdminEvaluationPlansGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, ListEvaluationPlansAdminEvaluationPlansGetParams['after']>, TError = ListEvaluationPlansAdminEvaluationPlansGetErrorType<HTTPValidationError>>(
 params?: ListEvaluationPlansAdminEvaluationPlansGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError, TData, Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, QueryKey, ListEvaluationPlansAdminEvaluationPlansGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEvaluationPlansAdminEvaluationPlansGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListEvaluationPlansAdminEvaluationPlansGetQueryOptions = <TData = Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError = ListEvaluationPlansAdminEvaluationPlansGetErrorType<HTTPValidationError>>(params?: ListEvaluationPlansAdminEvaluationPlansGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEvaluationPlansAdminEvaluationPlansGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>> = ({ signal }) => listEvaluationPlansAdminEvaluationPlansGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListEvaluationPlansAdminEvaluationPlansGetQueryResult = NonNullable<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>>
export type ListEvaluationPlansAdminEvaluationPlansGetQueryError = ListEvaluationPlansAdminEvaluationPlansGetErrorType<HTTPValidationError>

/**
 * @summary List Evaluation Plans
 */
export const useListEvaluationPlansAdminEvaluationPlansGet = <TData = Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError = ListEvaluationPlansAdminEvaluationPlansGetErrorType<HTTPValidationError>>(
 params?: ListEvaluationPlansAdminEvaluationPlansGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEvaluationPlansAdminEvaluationPlansGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListEvaluationPlansAdminEvaluationPlansGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError = ListEvaluationPlansAdminEvaluationPlansGetErrorType<HTTPValidationError>>(params?: ListEvaluationPlansAdminEvaluationPlansGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEvaluationPlansAdminEvaluationPlansGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>> = ({ signal }) => listEvaluationPlansAdminEvaluationPlansGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListEvaluationPlansAdminEvaluationPlansGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>>
export type ListEvaluationPlansAdminEvaluationPlansGetSuspenseQueryError = ListEvaluationPlansAdminEvaluationPlansGetErrorType<HTTPValidationError>

/**
 * @summary List Evaluation Plans
 */
export const useListEvaluationPlansAdminEvaluationPlansGetSuspense = <TData = Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError = ListEvaluationPlansAdminEvaluationPlansGetErrorType<HTTPValidationError>>(
 params?: ListEvaluationPlansAdminEvaluationPlansGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listEvaluationPlansAdminEvaluationPlansGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEvaluationPlansAdminEvaluationPlansGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Evaluation
 */
export const createEvaluationAdminEvaluationPlansPost = (
    createEvaluationRequest: CreateEvaluationRequest,
 ) => {
      
      
      return createEvaluationAdminEvaluationPlansPostMutator<EvaluationPlanData>(
      {url: `/admin/evaluation/plans`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createEvaluationRequest
    },
      );
    }
  


export const getCreateEvaluationAdminEvaluationPlansPostMutationOptions = <TError = CreateEvaluationAdminEvaluationPlansPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEvaluationAdminEvaluationPlansPost>>, TError,{data: CreateEvaluationRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createEvaluationAdminEvaluationPlansPost>>, TError,{data: CreateEvaluationRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEvaluationAdminEvaluationPlansPost>>, {data: CreateEvaluationRequest}> = (props) => {
          const {data} = props ?? {};

          return  createEvaluationAdminEvaluationPlansPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateEvaluationAdminEvaluationPlansPostMutationResult = NonNullable<Awaited<ReturnType<typeof createEvaluationAdminEvaluationPlansPost>>>
    export type CreateEvaluationAdminEvaluationPlansPostMutationBody = CreateEvaluationRequest
    export type CreateEvaluationAdminEvaluationPlansPostMutationError = CreateEvaluationAdminEvaluationPlansPostErrorType<HTTPValidationError>

    /**
 * @summary Create Evaluation
 */
export const useCreateEvaluationAdminEvaluationPlansPost = <TError = CreateEvaluationAdminEvaluationPlansPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEvaluationAdminEvaluationPlansPost>>, TError,{data: CreateEvaluationRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createEvaluationAdminEvaluationPlansPost>>,
        TError,
        {data: CreateEvaluationRequest},
        TContext
      > => {

      const mutationOptions = getCreateEvaluationAdminEvaluationPlansPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Evaluation Plan
 */
export const getEvaluationPlanAdminEvaluationPlansIdGet = (
    { id }: GetEvaluationPlanAdminEvaluationPlansIdGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getEvaluationPlanAdminEvaluationPlansIdGetMutator<EvaluationPlanData>(
      {url: `/admin/evaluation/plans/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetEvaluationPlanAdminEvaluationPlansIdGetQueryKey = ({ id }: GetEvaluationPlanAdminEvaluationPlansIdGetPathParameters,) => {
    return [`/admin/evaluation/plans/${id}`] as const;
    }

    
export const getGetEvaluationPlanAdminEvaluationPlansIdGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>>, TError = GetEvaluationPlanAdminEvaluationPlansIdGetErrorType<HTTPValidationError>>({ id }: GetEvaluationPlanAdminEvaluationPlansIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEvaluationPlanAdminEvaluationPlansIdGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>> = ({ signal }) => getEvaluationPlanAdminEvaluationPlansIdGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEvaluationPlanAdminEvaluationPlansIdGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>>
export type GetEvaluationPlanAdminEvaluationPlansIdGetInfiniteQueryError = GetEvaluationPlanAdminEvaluationPlansIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Evaluation Plan
 */
export const useGetEvaluationPlanAdminEvaluationPlansIdGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>>, TError = GetEvaluationPlanAdminEvaluationPlansIdGetErrorType<HTTPValidationError>>(
 { id }: GetEvaluationPlanAdminEvaluationPlansIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEvaluationPlanAdminEvaluationPlansIdGetInfiniteQueryOptions({ id },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEvaluationPlanAdminEvaluationPlansIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError = GetEvaluationPlanAdminEvaluationPlansIdGetErrorType<HTTPValidationError>>({ id }: GetEvaluationPlanAdminEvaluationPlansIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEvaluationPlanAdminEvaluationPlansIdGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>> = ({ signal }) => getEvaluationPlanAdminEvaluationPlansIdGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEvaluationPlanAdminEvaluationPlansIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>>
export type GetEvaluationPlanAdminEvaluationPlansIdGetQueryError = GetEvaluationPlanAdminEvaluationPlansIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Evaluation Plan
 */
export const useGetEvaluationPlanAdminEvaluationPlansIdGet = <TData = Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError = GetEvaluationPlanAdminEvaluationPlansIdGetErrorType<HTTPValidationError>>(
 { id }: GetEvaluationPlanAdminEvaluationPlansIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEvaluationPlanAdminEvaluationPlansIdGetQueryOptions({ id },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEvaluationPlanAdminEvaluationPlansIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError = GetEvaluationPlanAdminEvaluationPlansIdGetErrorType<HTTPValidationError>>({ id }: GetEvaluationPlanAdminEvaluationPlansIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEvaluationPlanAdminEvaluationPlansIdGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>> = ({ signal }) => getEvaluationPlanAdminEvaluationPlansIdGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEvaluationPlanAdminEvaluationPlansIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>>
export type GetEvaluationPlanAdminEvaluationPlansIdGetSuspenseQueryError = GetEvaluationPlanAdminEvaluationPlansIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Evaluation Plan
 */
export const useGetEvaluationPlanAdminEvaluationPlansIdGetSuspense = <TData = Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError = GetEvaluationPlanAdminEvaluationPlansIdGetErrorType<HTTPValidationError>>(
 { id }: GetEvaluationPlanAdminEvaluationPlansIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEvaluationPlanAdminEvaluationPlansIdGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEvaluationPlanAdminEvaluationPlansIdGetSuspenseQueryOptions({ id },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Start Evaluation
 */
export const startEvaluationAdminEvaluationPlansIdRunPost = (
    { id }: StartEvaluationAdminEvaluationPlansIdRunPostPathParameters,
 ) => {
      
      
      return startEvaluationAdminEvaluationPlansIdRunPostMutator<EvaluationPlanData>(
      {url: `/admin/evaluation/plans/${id}/run`, method: 'POST'
    },
      );
    }
  


export const getStartEvaluationAdminEvaluationPlansIdRunPostMutationOptions = <TError = StartEvaluationAdminEvaluationPlansIdRunPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startEvaluationAdminEvaluationPlansIdRunPost>>, TError,{pathParams: StartEvaluationAdminEvaluationPlansIdRunPostPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof startEvaluationAdminEvaluationPlansIdRunPost>>, TError,{pathParams: StartEvaluationAdminEvaluationPlansIdRunPostPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startEvaluationAdminEvaluationPlansIdRunPost>>, {pathParams: StartEvaluationAdminEvaluationPlansIdRunPostPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  startEvaluationAdminEvaluationPlansIdRunPost(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type StartEvaluationAdminEvaluationPlansIdRunPostMutationResult = NonNullable<Awaited<ReturnType<typeof startEvaluationAdminEvaluationPlansIdRunPost>>>
    
    export type StartEvaluationAdminEvaluationPlansIdRunPostMutationError = StartEvaluationAdminEvaluationPlansIdRunPostErrorType<HTTPValidationError>

    /**
 * @summary Start Evaluation
 */
export const useStartEvaluationAdminEvaluationPlansIdRunPost = <TError = StartEvaluationAdminEvaluationPlansIdRunPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startEvaluationAdminEvaluationPlansIdRunPost>>, TError,{pathParams: StartEvaluationAdminEvaluationPlansIdRunPostPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof startEvaluationAdminEvaluationPlansIdRunPost>>,
        TError,
        {pathParams: StartEvaluationAdminEvaluationPlansIdRunPostPathParameters},
        TContext
      > => {

      const mutationOptions = getStartEvaluationAdminEvaluationPlansIdRunPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 查询某个 agent 的执行记录
 * @summary Get Agent Evaluations
 */
export const getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet = (
    { planType, uuid, version }: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetPathParameters,
    params?: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams,
 signal?: AbortSignal
) => {
      
      
      return getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetMutator<EvaluationPlanSummary[]>(
      {url: `/admin/evaluation/plans/by_${planType}/${uuid}/${version}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetQueryKey = ({ planType, uuid, version }: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetPathParameters,
    params?: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams,) => {
    return [`/admin/evaluation/plans/by_${planType}/${uuid}/${version}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams['after']>, TError = GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetErrorType<HTTPValidationError>>({ planType, uuid, version }: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetPathParameters,
    params?: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError, TData, Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, QueryKey, GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetQueryKey({ planType, uuid, version },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, QueryKey, GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams['after']> = ({ signal, pageParam }) => getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet({ planType, uuid, version },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError, TData, Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, QueryKey, GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams['after']> & { queryKey: QueryKey }
}

export type GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>>
export type GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetInfiniteQueryError = GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetErrorType<HTTPValidationError>

/**
 * @summary Get Agent Evaluations
 */
export const useGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams['after']>, TError = GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetErrorType<HTTPValidationError>>(
 { planType, uuid, version }: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetPathParameters,
    params?: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError, TData, Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, QueryKey, GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetInfiniteQueryOptions({ planType, uuid, version },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetQueryOptions = <TData = Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError = GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetErrorType<HTTPValidationError>>({ planType, uuid, version }: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetPathParameters,
    params?: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetQueryKey({ planType, uuid, version },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>> = ({ signal }) => getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet({ planType, uuid, version },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>>
export type GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetQueryError = GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetErrorType<HTTPValidationError>

/**
 * @summary Get Agent Evaluations
 */
export const useGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet = <TData = Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError = GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetErrorType<HTTPValidationError>>(
 { planType, uuid, version }: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetPathParameters,
    params?: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetQueryOptions({ planType, uuid, version },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError = GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetErrorType<HTTPValidationError>>({ planType, uuid, version }: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetPathParameters,
    params?: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetQueryKey({ planType, uuid, version },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>> = ({ signal }) => getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet({ planType, uuid, version },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>>
export type GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetSuspenseQueryError = GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetErrorType<HTTPValidationError>

/**
 * @summary Get Agent Evaluations
 */
export const useGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetSuspense = <TData = Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError = GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetErrorType<HTTPValidationError>>(
 { planType, uuid, version }: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetPathParameters,
    params?: GetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentEvaluationsAdminEvaluationPlansByPlanTypeUuidVersionGetSuspenseQueryOptions({ planType, uuid, version },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List Evaluation Tasks
 */
export const listEvaluationTasksAdminEvaluationPlansIdTasksGet = (
    { id }: ListEvaluationTasksAdminEvaluationPlansIdTasksGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return listEvaluationTasksAdminEvaluationPlansIdTasksGetMutator<EvaluationTaskData[]>(
      {url: `/admin/evaluation/plans/${id}/tasks`, method: 'GET', signal
    },
      );
    }
  

export const getListEvaluationTasksAdminEvaluationPlansIdTasksGetQueryKey = ({ id }: ListEvaluationTasksAdminEvaluationPlansIdTasksGetPathParameters,) => {
    return [`/admin/evaluation/plans/${id}/tasks`] as const;
    }

    
export const getListEvaluationTasksAdminEvaluationPlansIdTasksGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>>, TError = ListEvaluationTasksAdminEvaluationPlansIdTasksGetErrorType<HTTPValidationError>>({ id }: ListEvaluationTasksAdminEvaluationPlansIdTasksGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEvaluationTasksAdminEvaluationPlansIdTasksGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>> = ({ signal }) => listEvaluationTasksAdminEvaluationPlansIdTasksGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListEvaluationTasksAdminEvaluationPlansIdTasksGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>>
export type ListEvaluationTasksAdminEvaluationPlansIdTasksGetInfiniteQueryError = ListEvaluationTasksAdminEvaluationPlansIdTasksGetErrorType<HTTPValidationError>

/**
 * @summary List Evaluation Tasks
 */
export const useListEvaluationTasksAdminEvaluationPlansIdTasksGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>>, TError = ListEvaluationTasksAdminEvaluationPlansIdTasksGetErrorType<HTTPValidationError>>(
 { id }: ListEvaluationTasksAdminEvaluationPlansIdTasksGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEvaluationTasksAdminEvaluationPlansIdTasksGetInfiniteQueryOptions({ id },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListEvaluationTasksAdminEvaluationPlansIdTasksGetQueryOptions = <TData = Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError = ListEvaluationTasksAdminEvaluationPlansIdTasksGetErrorType<HTTPValidationError>>({ id }: ListEvaluationTasksAdminEvaluationPlansIdTasksGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEvaluationTasksAdminEvaluationPlansIdTasksGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>> = ({ signal }) => listEvaluationTasksAdminEvaluationPlansIdTasksGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListEvaluationTasksAdminEvaluationPlansIdTasksGetQueryResult = NonNullable<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>>
export type ListEvaluationTasksAdminEvaluationPlansIdTasksGetQueryError = ListEvaluationTasksAdminEvaluationPlansIdTasksGetErrorType<HTTPValidationError>

/**
 * @summary List Evaluation Tasks
 */
export const useListEvaluationTasksAdminEvaluationPlansIdTasksGet = <TData = Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError = ListEvaluationTasksAdminEvaluationPlansIdTasksGetErrorType<HTTPValidationError>>(
 { id }: ListEvaluationTasksAdminEvaluationPlansIdTasksGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEvaluationTasksAdminEvaluationPlansIdTasksGetQueryOptions({ id },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListEvaluationTasksAdminEvaluationPlansIdTasksGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError = ListEvaluationTasksAdminEvaluationPlansIdTasksGetErrorType<HTTPValidationError>>({ id }: ListEvaluationTasksAdminEvaluationPlansIdTasksGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEvaluationTasksAdminEvaluationPlansIdTasksGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>> = ({ signal }) => listEvaluationTasksAdminEvaluationPlansIdTasksGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListEvaluationTasksAdminEvaluationPlansIdTasksGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>>
export type ListEvaluationTasksAdminEvaluationPlansIdTasksGetSuspenseQueryError = ListEvaluationTasksAdminEvaluationPlansIdTasksGetErrorType<HTTPValidationError>

/**
 * @summary List Evaluation Tasks
 */
export const useListEvaluationTasksAdminEvaluationPlansIdTasksGetSuspense = <TData = Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError = ListEvaluationTasksAdminEvaluationPlansIdTasksGetErrorType<HTTPValidationError>>(
 { id }: ListEvaluationTasksAdminEvaluationPlansIdTasksGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listEvaluationTasksAdminEvaluationPlansIdTasksGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEvaluationTasksAdminEvaluationPlansIdTasksGetSuspenseQueryOptions({ id },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Evaluation Via Message Id
 */
export const createEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPost = (
    { messageId }: CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostPathParameters,
 ) => {
      
      
      return createEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostMutator<EvaluationPlanData>(
      {url: `/admin/evaluation/plans/message/${messageId}`, method: 'POST'
    },
      );
    }
  


export const getCreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostMutationOptions = <TError = CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPost>>, TError,{pathParams: CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPost>>, TError,{pathParams: CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPost>>, {pathParams: CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  createEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPost(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostMutationResult = NonNullable<Awaited<ReturnType<typeof createEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPost>>>
    
    export type CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostMutationError = CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostErrorType<HTTPValidationError>

    /**
 * @summary Create Evaluation Via Message Id
 */
export const useCreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPost = <TError = CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPost>>, TError,{pathParams: CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPost>>,
        TError,
        {pathParams: CreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostPathParameters},
        TContext
      > => {

      const mutationOptions = getCreateEvaluationViaMessageIdAdminEvaluationPlansMessageMessageIdPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Batch Create Evaluation
 */
export const batchCreateEvaluationAdminEvaluationPlansBatchPost = (
    batchCreateEvaluationRequest: BatchCreateEvaluationRequest,
 ) => {
      
      
      return batchCreateEvaluationAdminEvaluationPlansBatchPostMutator<BatchCreateEvaluationResponse>(
      {url: `/admin/evaluation/plans/batch`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: batchCreateEvaluationRequest
    },
      );
    }
  


export const getBatchCreateEvaluationAdminEvaluationPlansBatchPostMutationOptions = <TError = BatchCreateEvaluationAdminEvaluationPlansBatchPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchCreateEvaluationAdminEvaluationPlansBatchPost>>, TError,{data: BatchCreateEvaluationRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof batchCreateEvaluationAdminEvaluationPlansBatchPost>>, TError,{data: BatchCreateEvaluationRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof batchCreateEvaluationAdminEvaluationPlansBatchPost>>, {data: BatchCreateEvaluationRequest}> = (props) => {
          const {data} = props ?? {};

          return  batchCreateEvaluationAdminEvaluationPlansBatchPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type BatchCreateEvaluationAdminEvaluationPlansBatchPostMutationResult = NonNullable<Awaited<ReturnType<typeof batchCreateEvaluationAdminEvaluationPlansBatchPost>>>
    export type BatchCreateEvaluationAdminEvaluationPlansBatchPostMutationBody = BatchCreateEvaluationRequest
    export type BatchCreateEvaluationAdminEvaluationPlansBatchPostMutationError = BatchCreateEvaluationAdminEvaluationPlansBatchPostErrorType<HTTPValidationError>

    /**
 * @summary Batch Create Evaluation
 */
export const useBatchCreateEvaluationAdminEvaluationPlansBatchPost = <TError = BatchCreateEvaluationAdminEvaluationPlansBatchPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchCreateEvaluationAdminEvaluationPlansBatchPost>>, TError,{data: BatchCreateEvaluationRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof batchCreateEvaluationAdminEvaluationPlansBatchPost>>,
        TError,
        {data: BatchCreateEvaluationRequest},
        TContext
      > => {

      const mutationOptions = getBatchCreateEvaluationAdminEvaluationPlansBatchPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Template Evaluation
 */
export const createTemplateEvaluationAdminEvaluationPlansTemplateBatchPost = (
    batchCreateTemplateEvaluationRequest: BatchCreateTemplateEvaluationRequest,
 ) => {
      
      
      return createTemplateEvaluationAdminEvaluationPlansTemplateBatchPostMutator<BatchCreateTemplateEvaluationResponse>(
      {url: `/admin/evaluation/plans/template/batch`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: batchCreateTemplateEvaluationRequest
    },
      );
    }
  


export const getCreateTemplateEvaluationAdminEvaluationPlansTemplateBatchPostMutationOptions = <TError = CreateTemplateEvaluationAdminEvaluationPlansTemplateBatchPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTemplateEvaluationAdminEvaluationPlansTemplateBatchPost>>, TError,{data: BatchCreateTemplateEvaluationRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createTemplateEvaluationAdminEvaluationPlansTemplateBatchPost>>, TError,{data: BatchCreateTemplateEvaluationRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTemplateEvaluationAdminEvaluationPlansTemplateBatchPost>>, {data: BatchCreateTemplateEvaluationRequest}> = (props) => {
          const {data} = props ?? {};

          return  createTemplateEvaluationAdminEvaluationPlansTemplateBatchPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTemplateEvaluationAdminEvaluationPlansTemplateBatchPostMutationResult = NonNullable<Awaited<ReturnType<typeof createTemplateEvaluationAdminEvaluationPlansTemplateBatchPost>>>
    export type CreateTemplateEvaluationAdminEvaluationPlansTemplateBatchPostMutationBody = BatchCreateTemplateEvaluationRequest
    export type CreateTemplateEvaluationAdminEvaluationPlansTemplateBatchPostMutationError = CreateTemplateEvaluationAdminEvaluationPlansTemplateBatchPostErrorType<HTTPValidationError>

    /**
 * @summary Create Template Evaluation
 */
export const useCreateTemplateEvaluationAdminEvaluationPlansTemplateBatchPost = <TError = CreateTemplateEvaluationAdminEvaluationPlansTemplateBatchPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTemplateEvaluationAdminEvaluationPlansTemplateBatchPost>>, TError,{data: BatchCreateTemplateEvaluationRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createTemplateEvaluationAdminEvaluationPlansTemplateBatchPost>>,
        TError,
        {data: BatchCreateTemplateEvaluationRequest},
        TContext
      > => {

      const mutationOptions = getCreateTemplateEvaluationAdminEvaluationPlansTemplateBatchPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Evaluation Task
 */
export const getEvaluationTaskAdminEvaluationTaskIdGet = (
    { id }: GetEvaluationTaskAdminEvaluationTaskIdGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getEvaluationTaskAdminEvaluationTaskIdGetMutator<EvaluationTaskData>(
      {url: `/admin/evaluation/task/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetEvaluationTaskAdminEvaluationTaskIdGetQueryKey = ({ id }: GetEvaluationTaskAdminEvaluationTaskIdGetPathParameters,) => {
    return [`/admin/evaluation/task/${id}`] as const;
    }

    
export const getGetEvaluationTaskAdminEvaluationTaskIdGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>>, TError = GetEvaluationTaskAdminEvaluationTaskIdGetErrorType<HTTPValidationError>>({ id }: GetEvaluationTaskAdminEvaluationTaskIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEvaluationTaskAdminEvaluationTaskIdGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>> = ({ signal }) => getEvaluationTaskAdminEvaluationTaskIdGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEvaluationTaskAdminEvaluationTaskIdGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>>
export type GetEvaluationTaskAdminEvaluationTaskIdGetInfiniteQueryError = GetEvaluationTaskAdminEvaluationTaskIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Evaluation Task
 */
export const useGetEvaluationTaskAdminEvaluationTaskIdGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>>, TError = GetEvaluationTaskAdminEvaluationTaskIdGetErrorType<HTTPValidationError>>(
 { id }: GetEvaluationTaskAdminEvaluationTaskIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEvaluationTaskAdminEvaluationTaskIdGetInfiniteQueryOptions({ id },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEvaluationTaskAdminEvaluationTaskIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError = GetEvaluationTaskAdminEvaluationTaskIdGetErrorType<HTTPValidationError>>({ id }: GetEvaluationTaskAdminEvaluationTaskIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEvaluationTaskAdminEvaluationTaskIdGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>> = ({ signal }) => getEvaluationTaskAdminEvaluationTaskIdGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEvaluationTaskAdminEvaluationTaskIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>>
export type GetEvaluationTaskAdminEvaluationTaskIdGetQueryError = GetEvaluationTaskAdminEvaluationTaskIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Evaluation Task
 */
export const useGetEvaluationTaskAdminEvaluationTaskIdGet = <TData = Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError = GetEvaluationTaskAdminEvaluationTaskIdGetErrorType<HTTPValidationError>>(
 { id }: GetEvaluationTaskAdminEvaluationTaskIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEvaluationTaskAdminEvaluationTaskIdGetQueryOptions({ id },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEvaluationTaskAdminEvaluationTaskIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError = GetEvaluationTaskAdminEvaluationTaskIdGetErrorType<HTTPValidationError>>({ id }: GetEvaluationTaskAdminEvaluationTaskIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEvaluationTaskAdminEvaluationTaskIdGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>> = ({ signal }) => getEvaluationTaskAdminEvaluationTaskIdGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEvaluationTaskAdminEvaluationTaskIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>>
export type GetEvaluationTaskAdminEvaluationTaskIdGetSuspenseQueryError = GetEvaluationTaskAdminEvaluationTaskIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Evaluation Task
 */
export const useGetEvaluationTaskAdminEvaluationTaskIdGetSuspense = <TData = Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError = GetEvaluationTaskAdminEvaluationTaskIdGetErrorType<HTTPValidationError>>(
 { id }: GetEvaluationTaskAdminEvaluationTaskIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEvaluationTaskAdminEvaluationTaskIdGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEvaluationTaskAdminEvaluationTaskIdGetSuspenseQueryOptions({ id },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Query Agent Traces
 */
export const queryAgentTracesAdminTraceByAgentUuidGet = (
    { uuid }: QueryAgentTracesAdminTraceByAgentUuidGetPathParameters,
    params?: QueryAgentTracesAdminTraceByAgentUuidGetParams,
 signal?: AbortSignal
) => {
      
      
      return queryAgentTracesAdminTraceByAgentUuidGetMutator<unknown>(
      {url: `/admin/trace/by_agent/${uuid}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQueryAgentTracesAdminTraceByAgentUuidGetQueryKey = ({ uuid }: QueryAgentTracesAdminTraceByAgentUuidGetPathParameters,
    params?: QueryAgentTracesAdminTraceByAgentUuidGetParams,) => {
    return [`/admin/trace/by_agent/${uuid}`, ...(params ? [params]: [])] as const;
    }

    
export const getQueryAgentTracesAdminTraceByAgentUuidGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, QueryAgentTracesAdminTraceByAgentUuidGetParams['after']>, TError = QueryAgentTracesAdminTraceByAgentUuidGetErrorType<HTTPValidationError>>({ uuid }: QueryAgentTracesAdminTraceByAgentUuidGetPathParameters,
    params?: QueryAgentTracesAdminTraceByAgentUuidGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError, TData, Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, QueryKey, QueryAgentTracesAdminTraceByAgentUuidGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryAgentTracesAdminTraceByAgentUuidGetQueryKey({ uuid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, QueryKey, QueryAgentTracesAdminTraceByAgentUuidGetParams['after']> = ({ signal, pageParam }) => queryAgentTracesAdminTraceByAgentUuidGet({ uuid },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError, TData, Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, QueryKey, QueryAgentTracesAdminTraceByAgentUuidGetParams['after']> & { queryKey: QueryKey }
}

export type QueryAgentTracesAdminTraceByAgentUuidGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>>
export type QueryAgentTracesAdminTraceByAgentUuidGetInfiniteQueryError = QueryAgentTracesAdminTraceByAgentUuidGetErrorType<HTTPValidationError>

/**
 * @summary Query Agent Traces
 */
export const useQueryAgentTracesAdminTraceByAgentUuidGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, QueryAgentTracesAdminTraceByAgentUuidGetParams['after']>, TError = QueryAgentTracesAdminTraceByAgentUuidGetErrorType<HTTPValidationError>>(
 { uuid }: QueryAgentTracesAdminTraceByAgentUuidGetPathParameters,
    params?: QueryAgentTracesAdminTraceByAgentUuidGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError, TData, Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, QueryKey, QueryAgentTracesAdminTraceByAgentUuidGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryAgentTracesAdminTraceByAgentUuidGetInfiniteQueryOptions({ uuid },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getQueryAgentTracesAdminTraceByAgentUuidGetQueryOptions = <TData = Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError = QueryAgentTracesAdminTraceByAgentUuidGetErrorType<HTTPValidationError>>({ uuid }: QueryAgentTracesAdminTraceByAgentUuidGetPathParameters,
    params?: QueryAgentTracesAdminTraceByAgentUuidGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryAgentTracesAdminTraceByAgentUuidGetQueryKey({ uuid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>> = ({ signal }) => queryAgentTracesAdminTraceByAgentUuidGet({ uuid },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryAgentTracesAdminTraceByAgentUuidGetQueryResult = NonNullable<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>>
export type QueryAgentTracesAdminTraceByAgentUuidGetQueryError = QueryAgentTracesAdminTraceByAgentUuidGetErrorType<HTTPValidationError>

/**
 * @summary Query Agent Traces
 */
export const useQueryAgentTracesAdminTraceByAgentUuidGet = <TData = Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError = QueryAgentTracesAdminTraceByAgentUuidGetErrorType<HTTPValidationError>>(
 { uuid }: QueryAgentTracesAdminTraceByAgentUuidGetPathParameters,
    params?: QueryAgentTracesAdminTraceByAgentUuidGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryAgentTracesAdminTraceByAgentUuidGetQueryOptions({ uuid },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getQueryAgentTracesAdminTraceByAgentUuidGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError = QueryAgentTracesAdminTraceByAgentUuidGetErrorType<HTTPValidationError>>({ uuid }: QueryAgentTracesAdminTraceByAgentUuidGetPathParameters,
    params?: QueryAgentTracesAdminTraceByAgentUuidGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryAgentTracesAdminTraceByAgentUuidGetQueryKey({ uuid },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>> = ({ signal }) => queryAgentTracesAdminTraceByAgentUuidGet({ uuid },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryAgentTracesAdminTraceByAgentUuidGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>>
export type QueryAgentTracesAdminTraceByAgentUuidGetSuspenseQueryError = QueryAgentTracesAdminTraceByAgentUuidGetErrorType<HTTPValidationError>

/**
 * @summary Query Agent Traces
 */
export const useQueryAgentTracesAdminTraceByAgentUuidGetSuspense = <TData = Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError = QueryAgentTracesAdminTraceByAgentUuidGetErrorType<HTTPValidationError>>(
 { uuid }: QueryAgentTracesAdminTraceByAgentUuidGetPathParameters,
    params?: QueryAgentTracesAdminTraceByAgentUuidGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof queryAgentTracesAdminTraceByAgentUuidGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryAgentTracesAdminTraceByAgentUuidGetSuspenseQueryOptions({ uuid },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Execution Context
 */
export const getExecutionContextAdminTraceTypeIdGet = (
    { type, id }: GetExecutionContextAdminTraceTypeIdGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getExecutionContextAdminTraceTypeIdGetMutator<GetExecutionContextResult>(
      {url: `/admin/trace/${type}/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetExecutionContextAdminTraceTypeIdGetQueryKey = ({ type, id }: GetExecutionContextAdminTraceTypeIdGetPathParameters,) => {
    return [`/admin/trace/${type}/${id}`] as const;
    }

    
export const getGetExecutionContextAdminTraceTypeIdGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>>, TError = GetExecutionContextAdminTraceTypeIdGetErrorType<HTTPValidationError>>({ type, id }: GetExecutionContextAdminTraceTypeIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExecutionContextAdminTraceTypeIdGetQueryKey({ type, id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>> = ({ signal }) => getExecutionContextAdminTraceTypeIdGet({ type, id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExecutionContextAdminTraceTypeIdGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>>
export type GetExecutionContextAdminTraceTypeIdGetInfiniteQueryError = GetExecutionContextAdminTraceTypeIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Execution Context
 */
export const useGetExecutionContextAdminTraceTypeIdGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>>, TError = GetExecutionContextAdminTraceTypeIdGetErrorType<HTTPValidationError>>(
 { type, id }: GetExecutionContextAdminTraceTypeIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExecutionContextAdminTraceTypeIdGetInfiniteQueryOptions({ type, id },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetExecutionContextAdminTraceTypeIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError = GetExecutionContextAdminTraceTypeIdGetErrorType<HTTPValidationError>>({ type, id }: GetExecutionContextAdminTraceTypeIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExecutionContextAdminTraceTypeIdGetQueryKey({ type, id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>> = ({ signal }) => getExecutionContextAdminTraceTypeIdGet({ type, id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExecutionContextAdminTraceTypeIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>>
export type GetExecutionContextAdminTraceTypeIdGetQueryError = GetExecutionContextAdminTraceTypeIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Execution Context
 */
export const useGetExecutionContextAdminTraceTypeIdGet = <TData = Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError = GetExecutionContextAdminTraceTypeIdGetErrorType<HTTPValidationError>>(
 { type, id }: GetExecutionContextAdminTraceTypeIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExecutionContextAdminTraceTypeIdGetQueryOptions({ type, id },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetExecutionContextAdminTraceTypeIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError = GetExecutionContextAdminTraceTypeIdGetErrorType<HTTPValidationError>>({ type, id }: GetExecutionContextAdminTraceTypeIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExecutionContextAdminTraceTypeIdGetQueryKey({ type, id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>> = ({ signal }) => getExecutionContextAdminTraceTypeIdGet({ type, id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExecutionContextAdminTraceTypeIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>>
export type GetExecutionContextAdminTraceTypeIdGetSuspenseQueryError = GetExecutionContextAdminTraceTypeIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Execution Context
 */
export const useGetExecutionContextAdminTraceTypeIdGetSuspense = <TData = Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError = GetExecutionContextAdminTraceTypeIdGetErrorType<HTTPValidationError>>(
 { type, id }: GetExecutionContextAdminTraceTypeIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getExecutionContextAdminTraceTypeIdGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExecutionContextAdminTraceTypeIdGetSuspenseQueryOptions({ type, id },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List Labels
 */
export const listLabelsAdminTemplatesIdLabelsGet = (
    { id }: ListLabelsAdminTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminTemplatesIdLabelsGetParams,
 signal?: AbortSignal
) => {
      
      
      return listLabelsAdminTemplatesIdLabelsGetMutator<DashLabel[]>(
      {url: `/admin/templates/${id}/labels`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListLabelsAdminTemplatesIdLabelsGetQueryKey = ({ id }: ListLabelsAdminTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminTemplatesIdLabelsGetParams,) => {
    return [`/admin/templates/${id}/labels`, ...(params ? [params]: [])] as const;
    }

    
export const getListLabelsAdminTemplatesIdLabelsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, ListLabelsAdminTemplatesIdLabelsGetParams['after']>, TError = ListLabelsAdminTemplatesIdLabelsGetErrorType<HTTPValidationError>>({ id }: ListLabelsAdminTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminTemplatesIdLabelsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, QueryKey, ListLabelsAdminTemplatesIdLabelsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminTemplatesIdLabelsGetQueryKey({ id },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, QueryKey, ListLabelsAdminTemplatesIdLabelsGetParams['after']> = ({ signal, pageParam }) => listLabelsAdminTemplatesIdLabelsGet({ id },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, QueryKey, ListLabelsAdminTemplatesIdLabelsGetParams['after']> & { queryKey: QueryKey }
}

export type ListLabelsAdminTemplatesIdLabelsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>>
export type ListLabelsAdminTemplatesIdLabelsGetInfiniteQueryError = ListLabelsAdminTemplatesIdLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminTemplatesIdLabelsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, ListLabelsAdminTemplatesIdLabelsGetParams['after']>, TError = ListLabelsAdminTemplatesIdLabelsGetErrorType<HTTPValidationError>>(
 { id }: ListLabelsAdminTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminTemplatesIdLabelsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, QueryKey, ListLabelsAdminTemplatesIdLabelsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminTemplatesIdLabelsGetInfiniteQueryOptions({ id },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminTemplatesIdLabelsGetQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError = ListLabelsAdminTemplatesIdLabelsGetErrorType<HTTPValidationError>>({ id }: ListLabelsAdminTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminTemplatesIdLabelsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminTemplatesIdLabelsGetQueryKey({ id },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>> = ({ signal }) => listLabelsAdminTemplatesIdLabelsGet({ id },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminTemplatesIdLabelsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>>
export type ListLabelsAdminTemplatesIdLabelsGetQueryError = ListLabelsAdminTemplatesIdLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminTemplatesIdLabelsGet = <TData = Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError = ListLabelsAdminTemplatesIdLabelsGetErrorType<HTTPValidationError>>(
 { id }: ListLabelsAdminTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminTemplatesIdLabelsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminTemplatesIdLabelsGetQueryOptions({ id },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminTemplatesIdLabelsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError = ListLabelsAdminTemplatesIdLabelsGetErrorType<HTTPValidationError>>({ id }: ListLabelsAdminTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminTemplatesIdLabelsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminTemplatesIdLabelsGetQueryKey({ id },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>> = ({ signal }) => listLabelsAdminTemplatesIdLabelsGet({ id },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminTemplatesIdLabelsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>>
export type ListLabelsAdminTemplatesIdLabelsGetSuspenseQueryError = ListLabelsAdminTemplatesIdLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminTemplatesIdLabelsGetSuspense = <TData = Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError = ListLabelsAdminTemplatesIdLabelsGetErrorType<HTTPValidationError>>(
 { id }: ListLabelsAdminTemplatesIdLabelsGetPathParameters,
    params?: ListLabelsAdminTemplatesIdLabelsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminTemplatesIdLabelsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminTemplatesIdLabelsGetSuspenseQueryOptions({ id },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update
 */
export const updateAdminTemplatesIdLabelsPatch = (
    { id }: UpdateAdminTemplatesIdLabelsPatchPathParameters,
    mixInLabelPayload: MixInLabelPayload,
    params?: UpdateAdminTemplatesIdLabelsPatchParams,
 ) => {
      
      
      return updateAdminTemplatesIdLabelsPatchMutator<DashLabel[]>(
      {url: `/admin/templates/${id}/labels`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: mixInLabelPayload,
        params
    },
      );
    }
  


export const getUpdateAdminTemplatesIdLabelsPatchMutationOptions = <TError = UpdateAdminTemplatesIdLabelsPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminTemplatesIdLabelsPatch>>, TError,{pathParams: UpdateAdminTemplatesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminTemplatesIdLabelsPatchParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAdminTemplatesIdLabelsPatch>>, TError,{pathParams: UpdateAdminTemplatesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminTemplatesIdLabelsPatchParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAdminTemplatesIdLabelsPatch>>, {pathParams: UpdateAdminTemplatesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminTemplatesIdLabelsPatchParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  updateAdminTemplatesIdLabelsPatch(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAdminTemplatesIdLabelsPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateAdminTemplatesIdLabelsPatch>>>
    export type UpdateAdminTemplatesIdLabelsPatchMutationBody = MixInLabelPayload
    export type UpdateAdminTemplatesIdLabelsPatchMutationError = UpdateAdminTemplatesIdLabelsPatchErrorType<HTTPValidationError>

    /**
 * @summary Update
 */
export const useUpdateAdminTemplatesIdLabelsPatch = <TError = UpdateAdminTemplatesIdLabelsPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminTemplatesIdLabelsPatch>>, TError,{pathParams: UpdateAdminTemplatesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminTemplatesIdLabelsPatchParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateAdminTemplatesIdLabelsPatch>>,
        TError,
        {pathParams: UpdateAdminTemplatesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminTemplatesIdLabelsPatchParams},
        TContext
      > => {

      const mutationOptions = getUpdateAdminTemplatesIdLabelsPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Labels
 */
export const addLabelsAdminTemplatesIdLabelsPost = (
    { id }: AddLabelsAdminTemplatesIdLabelsPostPathParameters,
    mixInLabelPayload: MixInLabelPayload,
    params?: AddLabelsAdminTemplatesIdLabelsPostParams,
 ) => {
      
      
      return addLabelsAdminTemplatesIdLabelsPostMutator<DashLabel[]>(
      {url: `/admin/templates/${id}/labels`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: mixInLabelPayload,
        params
    },
      );
    }
  


export const getAddLabelsAdminTemplatesIdLabelsPostMutationOptions = <TError = AddLabelsAdminTemplatesIdLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminTemplatesIdLabelsPost>>, TError,{pathParams: AddLabelsAdminTemplatesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminTemplatesIdLabelsPostParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminTemplatesIdLabelsPost>>, TError,{pathParams: AddLabelsAdminTemplatesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminTemplatesIdLabelsPostParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addLabelsAdminTemplatesIdLabelsPost>>, {pathParams: AddLabelsAdminTemplatesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminTemplatesIdLabelsPostParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  addLabelsAdminTemplatesIdLabelsPost(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddLabelsAdminTemplatesIdLabelsPostMutationResult = NonNullable<Awaited<ReturnType<typeof addLabelsAdminTemplatesIdLabelsPost>>>
    export type AddLabelsAdminTemplatesIdLabelsPostMutationBody = MixInLabelPayload
    export type AddLabelsAdminTemplatesIdLabelsPostMutationError = AddLabelsAdminTemplatesIdLabelsPostErrorType<HTTPValidationError>

    /**
 * @summary Add Labels
 */
export const useAddLabelsAdminTemplatesIdLabelsPost = <TError = AddLabelsAdminTemplatesIdLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminTemplatesIdLabelsPost>>, TError,{pathParams: AddLabelsAdminTemplatesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminTemplatesIdLabelsPostParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof addLabelsAdminTemplatesIdLabelsPost>>,
        TError,
        {pathParams: AddLabelsAdminTemplatesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminTemplatesIdLabelsPostParams},
        TContext
      > => {

      const mutationOptions = getAddLabelsAdminTemplatesIdLabelsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Label
 */
export const deleteLabelAdminTemplatesIdLabelsLabelIdDelete = (
    { id, labelId }: DeleteLabelAdminTemplatesIdLabelsLabelIdDeletePathParameters,
    params?: DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteParams,
 ) => {
      
      
      return deleteLabelAdminTemplatesIdLabelsLabelIdDeleteMutator<unknown>(
      {url: `/admin/templates/${id}/labels/${labelId}`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteLabelAdminTemplatesIdLabelsLabelIdDeleteMutationOptions = <TError = DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminTemplatesIdLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminTemplatesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminTemplatesIdLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminTemplatesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLabelAdminTemplatesIdLabelsLabelIdDelete>>, {pathParams: DeleteLabelAdminTemplatesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteParams}> = (props) => {
          const {pathParams,params} = props ?? {};

          return  deleteLabelAdminTemplatesIdLabelsLabelIdDelete(pathParams,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLabelAdminTemplatesIdLabelsLabelIdDelete>>>
    
    export type DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteMutationError = DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Label
 */
export const useDeleteLabelAdminTemplatesIdLabelsLabelIdDelete = <TError = DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminTemplatesIdLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminTemplatesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteLabelAdminTemplatesIdLabelsLabelIdDelete>>,
        TError,
        {pathParams: DeleteLabelAdminTemplatesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminTemplatesIdLabelsLabelIdDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteLabelAdminTemplatesIdLabelsLabelIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Templates Api
 */
export const queryTemplatesApiAdminTemplatesGet = (
    
 signal?: AbortSignal
) => {
      
      
      return queryTemplatesApiAdminTemplatesGetMutator<Template[]>(
      {url: `/admin/templates`, method: 'GET', signal
    },
      );
    }
  

export const getQueryTemplatesApiAdminTemplatesGetQueryKey = () => {
    return [`/admin/templates`] as const;
    }

    
export const getQueryTemplatesApiAdminTemplatesGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>>, TError = QueryTemplatesApiAdminTemplatesGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryTemplatesApiAdminTemplatesGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>> = ({ signal }) => queryTemplatesApiAdminTemplatesGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryTemplatesApiAdminTemplatesGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>>
export type QueryTemplatesApiAdminTemplatesGetInfiniteQueryError = QueryTemplatesApiAdminTemplatesGetErrorType<unknown>

/**
 * @summary Query Templates Api
 */
export const useQueryTemplatesApiAdminTemplatesGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>>, TError = QueryTemplatesApiAdminTemplatesGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryTemplatesApiAdminTemplatesGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getQueryTemplatesApiAdminTemplatesGetQueryOptions = <TData = Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError = QueryTemplatesApiAdminTemplatesGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryTemplatesApiAdminTemplatesGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>> = ({ signal }) => queryTemplatesApiAdminTemplatesGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryTemplatesApiAdminTemplatesGetQueryResult = NonNullable<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>>
export type QueryTemplatesApiAdminTemplatesGetQueryError = QueryTemplatesApiAdminTemplatesGetErrorType<unknown>

/**
 * @summary Query Templates Api
 */
export const useQueryTemplatesApiAdminTemplatesGet = <TData = Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError = QueryTemplatesApiAdminTemplatesGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryTemplatesApiAdminTemplatesGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getQueryTemplatesApiAdminTemplatesGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError = QueryTemplatesApiAdminTemplatesGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryTemplatesApiAdminTemplatesGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>> = ({ signal }) => queryTemplatesApiAdminTemplatesGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryTemplatesApiAdminTemplatesGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>>
export type QueryTemplatesApiAdminTemplatesGetSuspenseQueryError = QueryTemplatesApiAdminTemplatesGetErrorType<unknown>

/**
 * @summary Query Templates Api
 */
export const useQueryTemplatesApiAdminTemplatesGetSuspense = <TData = Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError = QueryTemplatesApiAdminTemplatesGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof queryTemplatesApiAdminTemplatesGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryTemplatesApiAdminTemplatesGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Query Template Keys Api
 */
export const queryTemplateKeysApiAdminTemplatesKeysGet = (
    params?: QueryTemplateKeysApiAdminTemplatesKeysGetParams,
 signal?: AbortSignal
) => {
      
      
      return queryTemplateKeysApiAdminTemplatesKeysGetMutator<TemplateInfo[]>(
      {url: `/admin/templates/keys`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getQueryTemplateKeysApiAdminTemplatesKeysGetQueryKey = (params?: QueryTemplateKeysApiAdminTemplatesKeysGetParams,) => {
    return [`/admin/templates/keys`, ...(params ? [params]: [])] as const;
    }

    
export const getQueryTemplateKeysApiAdminTemplatesKeysGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, QueryTemplateKeysApiAdminTemplatesKeysGetParams['after']>, TError = QueryTemplateKeysApiAdminTemplatesKeysGetErrorType<HTTPValidationError>>(params?: QueryTemplateKeysApiAdminTemplatesKeysGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError, TData, Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, QueryKey, QueryTemplateKeysApiAdminTemplatesKeysGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryTemplateKeysApiAdminTemplatesKeysGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, QueryKey, QueryTemplateKeysApiAdminTemplatesKeysGetParams['after']> = ({ signal, pageParam }) => queryTemplateKeysApiAdminTemplatesKeysGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError, TData, Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, QueryKey, QueryTemplateKeysApiAdminTemplatesKeysGetParams['after']> & { queryKey: QueryKey }
}

export type QueryTemplateKeysApiAdminTemplatesKeysGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>>
export type QueryTemplateKeysApiAdminTemplatesKeysGetInfiniteQueryError = QueryTemplateKeysApiAdminTemplatesKeysGetErrorType<HTTPValidationError>

/**
 * @summary Query Template Keys Api
 */
export const useQueryTemplateKeysApiAdminTemplatesKeysGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, QueryTemplateKeysApiAdminTemplatesKeysGetParams['after']>, TError = QueryTemplateKeysApiAdminTemplatesKeysGetErrorType<HTTPValidationError>>(
 params?: QueryTemplateKeysApiAdminTemplatesKeysGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError, TData, Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, QueryKey, QueryTemplateKeysApiAdminTemplatesKeysGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryTemplateKeysApiAdminTemplatesKeysGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getQueryTemplateKeysApiAdminTemplatesKeysGetQueryOptions = <TData = Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError = QueryTemplateKeysApiAdminTemplatesKeysGetErrorType<HTTPValidationError>>(params?: QueryTemplateKeysApiAdminTemplatesKeysGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryTemplateKeysApiAdminTemplatesKeysGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>> = ({ signal }) => queryTemplateKeysApiAdminTemplatesKeysGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryTemplateKeysApiAdminTemplatesKeysGetQueryResult = NonNullable<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>>
export type QueryTemplateKeysApiAdminTemplatesKeysGetQueryError = QueryTemplateKeysApiAdminTemplatesKeysGetErrorType<HTTPValidationError>

/**
 * @summary Query Template Keys Api
 */
export const useQueryTemplateKeysApiAdminTemplatesKeysGet = <TData = Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError = QueryTemplateKeysApiAdminTemplatesKeysGetErrorType<HTTPValidationError>>(
 params?: QueryTemplateKeysApiAdminTemplatesKeysGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryTemplateKeysApiAdminTemplatesKeysGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getQueryTemplateKeysApiAdminTemplatesKeysGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError = QueryTemplateKeysApiAdminTemplatesKeysGetErrorType<HTTPValidationError>>(params?: QueryTemplateKeysApiAdminTemplatesKeysGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryTemplateKeysApiAdminTemplatesKeysGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>> = ({ signal }) => queryTemplateKeysApiAdminTemplatesKeysGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryTemplateKeysApiAdminTemplatesKeysGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>>
export type QueryTemplateKeysApiAdminTemplatesKeysGetSuspenseQueryError = QueryTemplateKeysApiAdminTemplatesKeysGetErrorType<HTTPValidationError>

/**
 * @summary Query Template Keys Api
 */
export const useQueryTemplateKeysApiAdminTemplatesKeysGetSuspense = <TData = Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError = QueryTemplateKeysApiAdminTemplatesKeysGetErrorType<HTTPValidationError>>(
 params?: QueryTemplateKeysApiAdminTemplatesKeysGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof queryTemplateKeysApiAdminTemplatesKeysGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryTemplateKeysApiAdminTemplatesKeysGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Template Key Detail Api
 */
export const getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet = (
    { templateKey }: GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetMutator<TemplateKeyDetail>(
      {url: `/admin/templates/keys/${templateKey}/detail`, method: 'GET', signal
    },
      );
    }
  

export const getGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetQueryKey = ({ templateKey }: GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetPathParameters,) => {
    return [`/admin/templates/keys/${templateKey}/detail`] as const;
    }

    
export const getGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>>, TError = GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetErrorType<HTTPValidationError>>({ templateKey }: GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetQueryKey({ templateKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>> = ({ signal }) => getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet({ templateKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>>
export type GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetInfiniteQueryError = GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetErrorType<HTTPValidationError>

/**
 * @summary Get Template Key Detail Api
 */
export const useGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>>, TError = GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetErrorType<HTTPValidationError>>(
 { templateKey }: GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetInfiniteQueryOptions({ templateKey },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetQueryOptions = <TData = Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError = GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetErrorType<HTTPValidationError>>({ templateKey }: GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetQueryKey({ templateKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>> = ({ signal }) => getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet({ templateKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>>
export type GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetQueryError = GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetErrorType<HTTPValidationError>

/**
 * @summary Get Template Key Detail Api
 */
export const useGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet = <TData = Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError = GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetErrorType<HTTPValidationError>>(
 { templateKey }: GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetQueryOptions({ templateKey },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError = GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetErrorType<HTTPValidationError>>({ templateKey }: GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetQueryKey({ templateKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>> = ({ signal }) => getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet({ templateKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>>
export type GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetSuspenseQueryError = GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetErrorType<HTTPValidationError>

/**
 * @summary Get Template Key Detail Api
 */
export const useGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetSuspense = <TData = Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError = GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetErrorType<HTTPValidationError>>(
 { templateKey }: GetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTemplateKeyDetailApiAdminTemplatesKeysTemplateKeyDetailGetSuspenseQueryOptions({ templateKey },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Templates Api
 */
export const getTemplatesApiAdminTemplatesKeysTemplateKeyGet = (
    { templateKey }: GetTemplatesApiAdminTemplatesKeysTemplateKeyGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getTemplatesApiAdminTemplatesKeysTemplateKeyGetMutator<Template[]>(
      {url: `/admin/templates/keys/${templateKey}`, method: 'GET', signal
    },
      );
    }
  

export const getGetTemplatesApiAdminTemplatesKeysTemplateKeyGetQueryKey = ({ templateKey }: GetTemplatesApiAdminTemplatesKeysTemplateKeyGetPathParameters,) => {
    return [`/admin/templates/keys/${templateKey}`] as const;
    }

    
export const getGetTemplatesApiAdminTemplatesKeysTemplateKeyGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>>, TError = GetTemplatesApiAdminTemplatesKeysTemplateKeyGetErrorType<HTTPValidationError>>({ templateKey }: GetTemplatesApiAdminTemplatesKeysTemplateKeyGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTemplatesApiAdminTemplatesKeysTemplateKeyGetQueryKey({ templateKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>> = ({ signal }) => getTemplatesApiAdminTemplatesKeysTemplateKeyGet({ templateKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTemplatesApiAdminTemplatesKeysTemplateKeyGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>>
export type GetTemplatesApiAdminTemplatesKeysTemplateKeyGetInfiniteQueryError = GetTemplatesApiAdminTemplatesKeysTemplateKeyGetErrorType<HTTPValidationError>

/**
 * @summary Get Templates Api
 */
export const useGetTemplatesApiAdminTemplatesKeysTemplateKeyGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>>, TError = GetTemplatesApiAdminTemplatesKeysTemplateKeyGetErrorType<HTTPValidationError>>(
 { templateKey }: GetTemplatesApiAdminTemplatesKeysTemplateKeyGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTemplatesApiAdminTemplatesKeysTemplateKeyGetInfiniteQueryOptions({ templateKey },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTemplatesApiAdminTemplatesKeysTemplateKeyGetQueryOptions = <TData = Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError = GetTemplatesApiAdminTemplatesKeysTemplateKeyGetErrorType<HTTPValidationError>>({ templateKey }: GetTemplatesApiAdminTemplatesKeysTemplateKeyGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTemplatesApiAdminTemplatesKeysTemplateKeyGetQueryKey({ templateKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>> = ({ signal }) => getTemplatesApiAdminTemplatesKeysTemplateKeyGet({ templateKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTemplatesApiAdminTemplatesKeysTemplateKeyGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>>
export type GetTemplatesApiAdminTemplatesKeysTemplateKeyGetQueryError = GetTemplatesApiAdminTemplatesKeysTemplateKeyGetErrorType<HTTPValidationError>

/**
 * @summary Get Templates Api
 */
export const useGetTemplatesApiAdminTemplatesKeysTemplateKeyGet = <TData = Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError = GetTemplatesApiAdminTemplatesKeysTemplateKeyGetErrorType<HTTPValidationError>>(
 { templateKey }: GetTemplatesApiAdminTemplatesKeysTemplateKeyGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTemplatesApiAdminTemplatesKeysTemplateKeyGetQueryOptions({ templateKey },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTemplatesApiAdminTemplatesKeysTemplateKeyGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError = GetTemplatesApiAdminTemplatesKeysTemplateKeyGetErrorType<HTTPValidationError>>({ templateKey }: GetTemplatesApiAdminTemplatesKeysTemplateKeyGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTemplatesApiAdminTemplatesKeysTemplateKeyGetQueryKey({ templateKey });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>> = ({ signal }) => getTemplatesApiAdminTemplatesKeysTemplateKeyGet({ templateKey }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTemplatesApiAdminTemplatesKeysTemplateKeyGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>>
export type GetTemplatesApiAdminTemplatesKeysTemplateKeyGetSuspenseQueryError = GetTemplatesApiAdminTemplatesKeysTemplateKeyGetErrorType<HTTPValidationError>

/**
 * @summary Get Templates Api
 */
export const useGetTemplatesApiAdminTemplatesKeysTemplateKeyGetSuspense = <TData = Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError = GetTemplatesApiAdminTemplatesKeysTemplateKeyGetErrorType<HTTPValidationError>>(
 { templateKey }: GetTemplatesApiAdminTemplatesKeysTemplateKeyGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTemplatesApiAdminTemplatesKeysTemplateKeyGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTemplatesApiAdminTemplatesKeysTemplateKeyGetSuspenseQueryOptions({ templateKey },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Template Content Api
 */
export const getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet = (
    { templateKey }: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetPathParameters,
    params: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams,
 signal?: AbortSignal
) => {
      
      
      return getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetMutator<string>(
      {url: `/admin/templates/keys/${templateKey}/content`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetQueryKey = ({ templateKey }: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetPathParameters,
    params: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams,) => {
    return [`/admin/templates/keys/${templateKey}/content`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams['after']>, TError = GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetErrorType<HTTPValidationError>>({ templateKey }: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetPathParameters,
    params: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError, TData, Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, QueryKey, GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetQueryKey({ templateKey },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, QueryKey, GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams['after']> = ({ signal, pageParam }) => getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet({ templateKey },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError, TData, Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, QueryKey, GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams['after']> & { queryKey: QueryKey }
}

export type GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>>
export type GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetInfiniteQueryError = GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetErrorType<HTTPValidationError>

/**
 * @summary Get Template Content Api
 */
export const useGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams['after']>, TError = GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetErrorType<HTTPValidationError>>(
 { templateKey }: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetPathParameters,
    params: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError, TData, Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, QueryKey, GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetInfiniteQueryOptions({ templateKey },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetQueryOptions = <TData = Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError = GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetErrorType<HTTPValidationError>>({ templateKey }: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetPathParameters,
    params: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetQueryKey({ templateKey },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>> = ({ signal }) => getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet({ templateKey },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>>
export type GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetQueryError = GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetErrorType<HTTPValidationError>

/**
 * @summary Get Template Content Api
 */
export const useGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet = <TData = Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError = GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetErrorType<HTTPValidationError>>(
 { templateKey }: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetPathParameters,
    params: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetQueryOptions({ templateKey },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError = GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetErrorType<HTTPValidationError>>({ templateKey }: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetPathParameters,
    params: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetQueryKey({ templateKey },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>> = ({ signal }) => getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet({ templateKey },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>>
export type GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetSuspenseQueryError = GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetErrorType<HTTPValidationError>

/**
 * @summary Get Template Content Api
 */
export const useGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetSuspense = <TData = Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError = GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetErrorType<HTTPValidationError>>(
 { templateKey }: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetPathParameters,
    params: GetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTemplateContentApiAdminTemplatesKeysTemplateKeyContentGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTemplateContentApiAdminTemplatesKeysTemplateKeyContentGetSuspenseQueryOptions({ templateKey },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Template Api
 */
export const updateTemplateApiAdminTemplatesIdPatch = (
    { id }: UpdateTemplateApiAdminTemplatesIdPatchPathParameters,
    templatePayload: TemplatePayload,
 ) => {
      
      
      return updateTemplateApiAdminTemplatesIdPatchMutator<Template>(
      {url: `/admin/templates/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: templatePayload
    },
      );
    }
  


export const getUpdateTemplateApiAdminTemplatesIdPatchMutationOptions = <TError = UpdateTemplateApiAdminTemplatesIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTemplateApiAdminTemplatesIdPatch>>, TError,{pathParams: UpdateTemplateApiAdminTemplatesIdPatchPathParameters;data: TemplatePayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateTemplateApiAdminTemplatesIdPatch>>, TError,{pathParams: UpdateTemplateApiAdminTemplatesIdPatchPathParameters;data: TemplatePayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTemplateApiAdminTemplatesIdPatch>>, {pathParams: UpdateTemplateApiAdminTemplatesIdPatchPathParameters;data: TemplatePayload}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  updateTemplateApiAdminTemplatesIdPatch(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTemplateApiAdminTemplatesIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateTemplateApiAdminTemplatesIdPatch>>>
    export type UpdateTemplateApiAdminTemplatesIdPatchMutationBody = TemplatePayload
    export type UpdateTemplateApiAdminTemplatesIdPatchMutationError = UpdateTemplateApiAdminTemplatesIdPatchErrorType<HTTPValidationError>

    /**
 * @summary Update Template Api
 */
export const useUpdateTemplateApiAdminTemplatesIdPatch = <TError = UpdateTemplateApiAdminTemplatesIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTemplateApiAdminTemplatesIdPatch>>, TError,{pathParams: UpdateTemplateApiAdminTemplatesIdPatchPathParameters;data: TemplatePayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateTemplateApiAdminTemplatesIdPatch>>,
        TError,
        {pathParams: UpdateTemplateApiAdminTemplatesIdPatchPathParameters;data: TemplatePayload},
        TContext
      > => {

      const mutationOptions = getUpdateTemplateApiAdminTemplatesIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Template Api
 */
export const deleteTemplateApiAdminTemplatesIdDelete = (
    { id }: DeleteTemplateApiAdminTemplatesIdDeletePathParameters,
 ) => {
      
      
      return deleteTemplateApiAdminTemplatesIdDeleteMutator<unknown>(
      {url: `/admin/templates/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteTemplateApiAdminTemplatesIdDeleteMutationOptions = <TError = DeleteTemplateApiAdminTemplatesIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTemplateApiAdminTemplatesIdDelete>>, TError,{pathParams: DeleteTemplateApiAdminTemplatesIdDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteTemplateApiAdminTemplatesIdDelete>>, TError,{pathParams: DeleteTemplateApiAdminTemplatesIdDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTemplateApiAdminTemplatesIdDelete>>, {pathParams: DeleteTemplateApiAdminTemplatesIdDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  deleteTemplateApiAdminTemplatesIdDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTemplateApiAdminTemplatesIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTemplateApiAdminTemplatesIdDelete>>>
    
    export type DeleteTemplateApiAdminTemplatesIdDeleteMutationError = DeleteTemplateApiAdminTemplatesIdDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Template Api
 */
export const useDeleteTemplateApiAdminTemplatesIdDelete = <TError = DeleteTemplateApiAdminTemplatesIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTemplateApiAdminTemplatesIdDelete>>, TError,{pathParams: DeleteTemplateApiAdminTemplatesIdDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTemplateApiAdminTemplatesIdDelete>>,
        TError,
        {pathParams: DeleteTemplateApiAdminTemplatesIdDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getDeleteTemplateApiAdminTemplatesIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Template Api
 */
export const addTemplateApiAdminTemplatesPost = (
    templatePayload: TemplatePayload,
 ) => {
      
      
      return addTemplateApiAdminTemplatesPostMutator<Template>(
      {url: `/admin/templates/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: templatePayload
    },
      );
    }
  


export const getAddTemplateApiAdminTemplatesPostMutationOptions = <TError = AddTemplateApiAdminTemplatesPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTemplateApiAdminTemplatesPost>>, TError,{data: TemplatePayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addTemplateApiAdminTemplatesPost>>, TError,{data: TemplatePayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addTemplateApiAdminTemplatesPost>>, {data: TemplatePayload}> = (props) => {
          const {data} = props ?? {};

          return  addTemplateApiAdminTemplatesPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddTemplateApiAdminTemplatesPostMutationResult = NonNullable<Awaited<ReturnType<typeof addTemplateApiAdminTemplatesPost>>>
    export type AddTemplateApiAdminTemplatesPostMutationBody = TemplatePayload
    export type AddTemplateApiAdminTemplatesPostMutationError = AddTemplateApiAdminTemplatesPostErrorType<HTTPValidationError>

    /**
 * @summary Add Template Api
 */
export const useAddTemplateApiAdminTemplatesPost = <TError = AddTemplateApiAdminTemplatesPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTemplateApiAdminTemplatesPost>>, TError,{data: TemplatePayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof addTemplateApiAdminTemplatesPost>>,
        TError,
        {data: TemplatePayload},
        TContext
      > => {

      const mutationOptions = getAddTemplateApiAdminTemplatesPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Draft Template Api
 */
export const addDraftTemplateApiAdminTemplatesDraftPost = (
    templatePayload: TemplatePayload,
 ) => {
      
      
      return addDraftTemplateApiAdminTemplatesDraftPostMutator<Template>(
      {url: `/admin/templates/draft`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: templatePayload
    },
      );
    }
  


export const getAddDraftTemplateApiAdminTemplatesDraftPostMutationOptions = <TError = AddDraftTemplateApiAdminTemplatesDraftPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addDraftTemplateApiAdminTemplatesDraftPost>>, TError,{data: TemplatePayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addDraftTemplateApiAdminTemplatesDraftPost>>, TError,{data: TemplatePayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addDraftTemplateApiAdminTemplatesDraftPost>>, {data: TemplatePayload}> = (props) => {
          const {data} = props ?? {};

          return  addDraftTemplateApiAdminTemplatesDraftPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddDraftTemplateApiAdminTemplatesDraftPostMutationResult = NonNullable<Awaited<ReturnType<typeof addDraftTemplateApiAdminTemplatesDraftPost>>>
    export type AddDraftTemplateApiAdminTemplatesDraftPostMutationBody = TemplatePayload
    export type AddDraftTemplateApiAdminTemplatesDraftPostMutationError = AddDraftTemplateApiAdminTemplatesDraftPostErrorType<HTTPValidationError>

    /**
 * @summary Add Draft Template Api
 */
export const useAddDraftTemplateApiAdminTemplatesDraftPost = <TError = AddDraftTemplateApiAdminTemplatesDraftPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addDraftTemplateApiAdminTemplatesDraftPost>>, TError,{data: TemplatePayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof addDraftTemplateApiAdminTemplatesDraftPost>>,
        TError,
        {data: TemplatePayload},
        TContext
      > => {

      const mutationOptions = getAddDraftTemplateApiAdminTemplatesDraftPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get History Templates Api
 */
export const getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet = (
    { templateKey }: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetPathParameters,
    params?: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams,
 signal?: AbortSignal
) => {
      
      
      return getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetMutator<TemplateHistory[]>(
      {url: `/admin/templates/draft/${templateKey}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetQueryKey = ({ templateKey }: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetPathParameters,
    params?: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams,) => {
    return [`/admin/templates/draft/${templateKey}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams['after']>, TError = GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetErrorType<HTTPValidationError>>({ templateKey }: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetPathParameters,
    params?: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError, TData, Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, QueryKey, GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetQueryKey({ templateKey },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, QueryKey, GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams['after']> = ({ signal, pageParam }) => getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet({ templateKey },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError, TData, Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, QueryKey, GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams['after']> & { queryKey: QueryKey }
}

export type GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>>
export type GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetInfiniteQueryError = GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetErrorType<HTTPValidationError>

/**
 * @summary Get History Templates Api
 */
export const useGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams['after']>, TError = GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetErrorType<HTTPValidationError>>(
 { templateKey }: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetPathParameters,
    params?: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError, TData, Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, QueryKey, GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetInfiniteQueryOptions({ templateKey },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetQueryOptions = <TData = Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError = GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetErrorType<HTTPValidationError>>({ templateKey }: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetPathParameters,
    params?: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetQueryKey({ templateKey },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>> = ({ signal }) => getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet({ templateKey },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetQueryResult = NonNullable<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>>
export type GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetQueryError = GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetErrorType<HTTPValidationError>

/**
 * @summary Get History Templates Api
 */
export const useGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet = <TData = Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError = GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetErrorType<HTTPValidationError>>(
 { templateKey }: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetPathParameters,
    params?: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetQueryOptions({ templateKey },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError = GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetErrorType<HTTPValidationError>>({ templateKey }: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetPathParameters,
    params?: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetQueryKey({ templateKey },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>> = ({ signal }) => getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet({ templateKey },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>>
export type GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetSuspenseQueryError = GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetErrorType<HTTPValidationError>

/**
 * @summary Get History Templates Api
 */
export const useGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetSuspense = <TData = Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError = GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetErrorType<HTTPValidationError>>(
 { templateKey }: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetPathParameters,
    params?: GetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHistoryTemplatesApiAdminTemplatesDraftTemplateKeyGetSuspenseQueryOptions({ templateKey },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Deploy Template Api
 */
export const deployTemplateApiAdminTemplatesDraftIdPost = (
    { id }: DeployTemplateApiAdminTemplatesDraftIdPostPathParameters,
 ) => {
      
      
      return deployTemplateApiAdminTemplatesDraftIdPostMutator<Template>(
      {url: `/admin/templates/draft/${id}`, method: 'POST'
    },
      );
    }
  


export const getDeployTemplateApiAdminTemplatesDraftIdPostMutationOptions = <TError = DeployTemplateApiAdminTemplatesDraftIdPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deployTemplateApiAdminTemplatesDraftIdPost>>, TError,{pathParams: DeployTemplateApiAdminTemplatesDraftIdPostPathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deployTemplateApiAdminTemplatesDraftIdPost>>, TError,{pathParams: DeployTemplateApiAdminTemplatesDraftIdPostPathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deployTemplateApiAdminTemplatesDraftIdPost>>, {pathParams: DeployTemplateApiAdminTemplatesDraftIdPostPathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  deployTemplateApiAdminTemplatesDraftIdPost(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeployTemplateApiAdminTemplatesDraftIdPostMutationResult = NonNullable<Awaited<ReturnType<typeof deployTemplateApiAdminTemplatesDraftIdPost>>>
    
    export type DeployTemplateApiAdminTemplatesDraftIdPostMutationError = DeployTemplateApiAdminTemplatesDraftIdPostErrorType<HTTPValidationError>

    /**
 * @summary Deploy Template Api
 */
export const useDeployTemplateApiAdminTemplatesDraftIdPost = <TError = DeployTemplateApiAdminTemplatesDraftIdPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deployTemplateApiAdminTemplatesDraftIdPost>>, TError,{pathParams: DeployTemplateApiAdminTemplatesDraftIdPostPathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deployTemplateApiAdminTemplatesDraftIdPost>>,
        TError,
        {pathParams: DeployTemplateApiAdminTemplatesDraftIdPostPathParameters},
        TContext
      > => {

      const mutationOptions = getDeployTemplateApiAdminTemplatesDraftIdPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Batch Deploy Template Api
 */
export const batchDeployTemplateApiAdminTemplatesDeployPost = (
    batchDeployPayload: BatchDeployPayload,
 ) => {
      
      
      return batchDeployTemplateApiAdminTemplatesDeployPostMutator<Template[]>(
      {url: `/admin/templates/deploy`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: batchDeployPayload
    },
      );
    }
  


export const getBatchDeployTemplateApiAdminTemplatesDeployPostMutationOptions = <TError = BatchDeployTemplateApiAdminTemplatesDeployPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchDeployTemplateApiAdminTemplatesDeployPost>>, TError,{data: BatchDeployPayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof batchDeployTemplateApiAdminTemplatesDeployPost>>, TError,{data: BatchDeployPayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof batchDeployTemplateApiAdminTemplatesDeployPost>>, {data: BatchDeployPayload}> = (props) => {
          const {data} = props ?? {};

          return  batchDeployTemplateApiAdminTemplatesDeployPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type BatchDeployTemplateApiAdminTemplatesDeployPostMutationResult = NonNullable<Awaited<ReturnType<typeof batchDeployTemplateApiAdminTemplatesDeployPost>>>
    export type BatchDeployTemplateApiAdminTemplatesDeployPostMutationBody = BatchDeployPayload
    export type BatchDeployTemplateApiAdminTemplatesDeployPostMutationError = BatchDeployTemplateApiAdminTemplatesDeployPostErrorType<HTTPValidationError>

    /**
 * @summary Batch Deploy Template Api
 */
export const useBatchDeployTemplateApiAdminTemplatesDeployPost = <TError = BatchDeployTemplateApiAdminTemplatesDeployPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchDeployTemplateApiAdminTemplatesDeployPost>>, TError,{data: BatchDeployPayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof batchDeployTemplateApiAdminTemplatesDeployPost>>,
        TError,
        {data: BatchDeployPayload},
        TContext
      > => {

      const mutationOptions = getBatchDeployTemplateApiAdminTemplatesDeployPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Translate Template Api
 */
export const translateTemplateApiAdminTemplatesTranslatePost = (
    translateTemplatePayload: TranslateTemplatePayload,
 ) => {
      
      
      return translateTemplateApiAdminTemplatesTranslatePostMutator<string>(
      {url: `/admin/templates/translate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: translateTemplatePayload
    },
      );
    }
  


export const getTranslateTemplateApiAdminTemplatesTranslatePostMutationOptions = <TError = TranslateTemplateApiAdminTemplatesTranslatePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof translateTemplateApiAdminTemplatesTranslatePost>>, TError,{data: TranslateTemplatePayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof translateTemplateApiAdminTemplatesTranslatePost>>, TError,{data: TranslateTemplatePayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof translateTemplateApiAdminTemplatesTranslatePost>>, {data: TranslateTemplatePayload}> = (props) => {
          const {data} = props ?? {};

          return  translateTemplateApiAdminTemplatesTranslatePost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TranslateTemplateApiAdminTemplatesTranslatePostMutationResult = NonNullable<Awaited<ReturnType<typeof translateTemplateApiAdminTemplatesTranslatePost>>>
    export type TranslateTemplateApiAdminTemplatesTranslatePostMutationBody = TranslateTemplatePayload
    export type TranslateTemplateApiAdminTemplatesTranslatePostMutationError = TranslateTemplateApiAdminTemplatesTranslatePostErrorType<HTTPValidationError>

    /**
 * @summary Translate Template Api
 */
export const useTranslateTemplateApiAdminTemplatesTranslatePost = <TError = TranslateTemplateApiAdminTemplatesTranslatePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof translateTemplateApiAdminTemplatesTranslatePost>>, TError,{data: TranslateTemplatePayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof translateTemplateApiAdminTemplatesTranslatePost>>,
        TError,
        {data: TranslateTemplatePayload},
        TContext
      > => {

      const mutationOptions = getTranslateTemplateApiAdminTemplatesTranslatePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Show Translate Template Result Api
 */
export const showTranslateTemplateResultApiAdminTemplatesTranslateGet = (
    params?: ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams,
 signal?: AbortSignal
) => {
      
      
      return showTranslateTemplateResultApiAdminTemplatesTranslateGetMutator<DraftTemplateResult[]>(
      {url: `/admin/templates/translate`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getShowTranslateTemplateResultApiAdminTemplatesTranslateGetQueryKey = (params?: ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams,) => {
    return [`/admin/templates/translate`, ...(params ? [params]: [])] as const;
    }

    
export const getShowTranslateTemplateResultApiAdminTemplatesTranslateGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams['after']>, TError = ShowTranslateTemplateResultApiAdminTemplatesTranslateGetErrorType<HTTPValidationError>>(params?: ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError, TData, Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, QueryKey, ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShowTranslateTemplateResultApiAdminTemplatesTranslateGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, QueryKey, ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams['after']> = ({ signal, pageParam }) => showTranslateTemplateResultApiAdminTemplatesTranslateGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError, TData, Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, QueryKey, ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams['after']> & { queryKey: QueryKey }
}

export type ShowTranslateTemplateResultApiAdminTemplatesTranslateGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>>
export type ShowTranslateTemplateResultApiAdminTemplatesTranslateGetInfiniteQueryError = ShowTranslateTemplateResultApiAdminTemplatesTranslateGetErrorType<HTTPValidationError>

/**
 * @summary Show Translate Template Result Api
 */
export const useShowTranslateTemplateResultApiAdminTemplatesTranslateGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams['after']>, TError = ShowTranslateTemplateResultApiAdminTemplatesTranslateGetErrorType<HTTPValidationError>>(
 params?: ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError, TData, Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, QueryKey, ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getShowTranslateTemplateResultApiAdminTemplatesTranslateGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getShowTranslateTemplateResultApiAdminTemplatesTranslateGetQueryOptions = <TData = Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError = ShowTranslateTemplateResultApiAdminTemplatesTranslateGetErrorType<HTTPValidationError>>(params?: ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShowTranslateTemplateResultApiAdminTemplatesTranslateGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>> = ({ signal }) => showTranslateTemplateResultApiAdminTemplatesTranslateGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ShowTranslateTemplateResultApiAdminTemplatesTranslateGetQueryResult = NonNullable<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>>
export type ShowTranslateTemplateResultApiAdminTemplatesTranslateGetQueryError = ShowTranslateTemplateResultApiAdminTemplatesTranslateGetErrorType<HTTPValidationError>

/**
 * @summary Show Translate Template Result Api
 */
export const useShowTranslateTemplateResultApiAdminTemplatesTranslateGet = <TData = Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError = ShowTranslateTemplateResultApiAdminTemplatesTranslateGetErrorType<HTTPValidationError>>(
 params?: ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getShowTranslateTemplateResultApiAdminTemplatesTranslateGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getShowTranslateTemplateResultApiAdminTemplatesTranslateGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError = ShowTranslateTemplateResultApiAdminTemplatesTranslateGetErrorType<HTTPValidationError>>(params?: ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShowTranslateTemplateResultApiAdminTemplatesTranslateGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>> = ({ signal }) => showTranslateTemplateResultApiAdminTemplatesTranslateGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ShowTranslateTemplateResultApiAdminTemplatesTranslateGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>>
export type ShowTranslateTemplateResultApiAdminTemplatesTranslateGetSuspenseQueryError = ShowTranslateTemplateResultApiAdminTemplatesTranslateGetErrorType<HTTPValidationError>

/**
 * @summary Show Translate Template Result Api
 */
export const useShowTranslateTemplateResultApiAdminTemplatesTranslateGetSuspense = <TData = Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError = ShowTranslateTemplateResultApiAdminTemplatesTranslateGetErrorType<HTTPValidationError>>(
 params?: ShowTranslateTemplateResultApiAdminTemplatesTranslateGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultApiAdminTemplatesTranslateGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getShowTranslateTemplateResultApiAdminTemplatesTranslateGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Show Translate Template Result Detail Api
 */
export const showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet = (
    params?: ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams,
 signal?: AbortSignal
) => {
      
      
      return showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetMutator<DraftTemplateResultDetail>(
      {url: `/admin/templates/translate_result`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetQueryKey = (params?: ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams,) => {
    return [`/admin/templates/translate_result`, ...(params ? [params]: [])] as const;
    }

    
export const getShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams['after']>, TError = ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetErrorType<HTTPValidationError>>(params?: ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError, TData, Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, QueryKey, ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, QueryKey, ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams['after']> = ({ signal, pageParam }) => showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError, TData, Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, QueryKey, ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams['after']> & { queryKey: QueryKey }
}

export type ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>>
export type ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetInfiniteQueryError = ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetErrorType<HTTPValidationError>

/**
 * @summary Show Translate Template Result Detail Api
 */
export const useShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams['after']>, TError = ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetErrorType<HTTPValidationError>>(
 params?: ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError, TData, Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, QueryKey, ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetQueryOptions = <TData = Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError = ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetErrorType<HTTPValidationError>>(params?: ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>> = ({ signal }) => showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetQueryResult = NonNullable<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>>
export type ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetQueryError = ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetErrorType<HTTPValidationError>

/**
 * @summary Show Translate Template Result Detail Api
 */
export const useShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet = <TData = Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError = ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetErrorType<HTTPValidationError>>(
 params?: ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError = ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetErrorType<HTTPValidationError>>(params?: ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>> = ({ signal }) => showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>>
export type ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetSuspenseQueryError = ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetErrorType<HTTPValidationError>

/**
 * @summary Show Translate Template Result Detail Api
 */
export const useShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetSuspense = <TData = Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError = ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetErrorType<HTTPValidationError>>(
 params?: ShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof showTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getShowTranslateTemplateResultDetailApiAdminTemplatesTranslateResultGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List Labels
 */
export const listLabelsAdminLabelsGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listLabelsAdminLabelsGetMutator<DashLabel[]>(
      {url: `/admin/labels`, method: 'GET', signal
    },
      );
    }
  

export const getListLabelsAdminLabelsGetQueryKey = () => {
    return [`/admin/labels`] as const;
    }

    
export const getListLabelsAdminLabelsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>>, TError = ListLabelsAdminLabelsGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminLabelsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>> = ({ signal }) => listLabelsAdminLabelsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminLabelsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>>
export type ListLabelsAdminLabelsGetInfiniteQueryError = ListLabelsAdminLabelsGetErrorType<unknown>

/**
 * @summary List Labels
 */
export const useListLabelsAdminLabelsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>>, TError = ListLabelsAdminLabelsGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminLabelsGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminLabelsGetQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError = ListLabelsAdminLabelsGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminLabelsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>> = ({ signal }) => listLabelsAdminLabelsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminLabelsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>>
export type ListLabelsAdminLabelsGetQueryError = ListLabelsAdminLabelsGetErrorType<unknown>

/**
 * @summary List Labels
 */
export const useListLabelsAdminLabelsGet = <TData = Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError = ListLabelsAdminLabelsGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminLabelsGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminLabelsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError = ListLabelsAdminLabelsGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminLabelsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>> = ({ signal }) => listLabelsAdminLabelsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminLabelsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>>
export type ListLabelsAdminLabelsGetSuspenseQueryError = ListLabelsAdminLabelsGetErrorType<unknown>

/**
 * @summary List Labels
 */
export const useListLabelsAdminLabelsGetSuspense = <TData = Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError = ListLabelsAdminLabelsGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminLabelsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminLabelsGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Template Api
 */
export const updateTemplateApiAdminLabelsIdPatch = (
    { id }: UpdateTemplateApiAdminLabelsIdPatchPathParameters,
    dashLabelPatchData: DashLabelPatchData,
 ) => {
      
      
      return updateTemplateApiAdminLabelsIdPatchMutator<DashLabel>(
      {url: `/admin/labels/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: dashLabelPatchData
    },
      );
    }
  


export const getUpdateTemplateApiAdminLabelsIdPatchMutationOptions = <TError = UpdateTemplateApiAdminLabelsIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTemplateApiAdminLabelsIdPatch>>, TError,{pathParams: UpdateTemplateApiAdminLabelsIdPatchPathParameters;data: DashLabelPatchData}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateTemplateApiAdminLabelsIdPatch>>, TError,{pathParams: UpdateTemplateApiAdminLabelsIdPatchPathParameters;data: DashLabelPatchData}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTemplateApiAdminLabelsIdPatch>>, {pathParams: UpdateTemplateApiAdminLabelsIdPatchPathParameters;data: DashLabelPatchData}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  updateTemplateApiAdminLabelsIdPatch(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTemplateApiAdminLabelsIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateTemplateApiAdminLabelsIdPatch>>>
    export type UpdateTemplateApiAdminLabelsIdPatchMutationBody = DashLabelPatchData
    export type UpdateTemplateApiAdminLabelsIdPatchMutationError = UpdateTemplateApiAdminLabelsIdPatchErrorType<HTTPValidationError>

    /**
 * @summary Update Template Api
 */
export const useUpdateTemplateApiAdminLabelsIdPatch = <TError = UpdateTemplateApiAdminLabelsIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTemplateApiAdminLabelsIdPatch>>, TError,{pathParams: UpdateTemplateApiAdminLabelsIdPatchPathParameters;data: DashLabelPatchData}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateTemplateApiAdminLabelsIdPatch>>,
        TError,
        {pathParams: UpdateTemplateApiAdminLabelsIdPatchPathParameters;data: DashLabelPatchData},
        TContext
      > => {

      const mutationOptions = getUpdateTemplateApiAdminLabelsIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Template Api
 */
export const deleteTemplateApiAdminLabelsIdDelete = (
    { id }: DeleteTemplateApiAdminLabelsIdDeletePathParameters,
 ) => {
      
      
      return deleteTemplateApiAdminLabelsIdDeleteMutator<DashLabel[]>(
      {url: `/admin/labels/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteTemplateApiAdminLabelsIdDeleteMutationOptions = <TError = DeleteTemplateApiAdminLabelsIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTemplateApiAdminLabelsIdDelete>>, TError,{pathParams: DeleteTemplateApiAdminLabelsIdDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteTemplateApiAdminLabelsIdDelete>>, TError,{pathParams: DeleteTemplateApiAdminLabelsIdDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTemplateApiAdminLabelsIdDelete>>, {pathParams: DeleteTemplateApiAdminLabelsIdDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  deleteTemplateApiAdminLabelsIdDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTemplateApiAdminLabelsIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTemplateApiAdminLabelsIdDelete>>>
    
    export type DeleteTemplateApiAdminLabelsIdDeleteMutationError = DeleteTemplateApiAdminLabelsIdDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Template Api
 */
export const useDeleteTemplateApiAdminLabelsIdDelete = <TError = DeleteTemplateApiAdminLabelsIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTemplateApiAdminLabelsIdDelete>>, TError,{pathParams: DeleteTemplateApiAdminLabelsIdDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTemplateApiAdminLabelsIdDelete>>,
        TError,
        {pathParams: DeleteTemplateApiAdminLabelsIdDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getDeleteTemplateApiAdminLabelsIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Label
 */
export const createLabelAdminLabelsPost = (
    dashLabelPostData: DashLabelPostData,
 ) => {
      
      
      return createLabelAdminLabelsPostMutator<DashLabel>(
      {url: `/admin/labels/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dashLabelPostData
    },
      );
    }
  


export const getCreateLabelAdminLabelsPostMutationOptions = <TError = CreateLabelAdminLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLabelAdminLabelsPost>>, TError,{data: DashLabelPostData}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createLabelAdminLabelsPost>>, TError,{data: DashLabelPostData}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createLabelAdminLabelsPost>>, {data: DashLabelPostData}> = (props) => {
          const {data} = props ?? {};

          return  createLabelAdminLabelsPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateLabelAdminLabelsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createLabelAdminLabelsPost>>>
    export type CreateLabelAdminLabelsPostMutationBody = DashLabelPostData
    export type CreateLabelAdminLabelsPostMutationError = CreateLabelAdminLabelsPostErrorType<HTTPValidationError>

    /**
 * @summary Create Label
 */
export const useCreateLabelAdminLabelsPost = <TError = CreateLabelAdminLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLabelAdminLabelsPost>>, TError,{data: DashLabelPostData}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createLabelAdminLabelsPost>>,
        TError,
        {data: DashLabelPostData},
        TContext
      > => {

      const mutationOptions = getCreateLabelAdminLabelsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Current Dash User Info
 */
export const getCurrentDashUserInfoAdminUserGet = (
    
 signal?: AbortSignal
) => {
      
      
      return getCurrentDashUserInfoAdminUserGetMutator<DashUserInfo>(
      {url: `/admin/user`, method: 'GET', signal
    },
      );
    }
  

export const getGetCurrentDashUserInfoAdminUserGetQueryKey = () => {
    return [`/admin/user`] as const;
    }

    
export const getGetCurrentDashUserInfoAdminUserGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>>, TError = GetCurrentDashUserInfoAdminUserGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentDashUserInfoAdminUserGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>> = ({ signal }) => getCurrentDashUserInfoAdminUserGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCurrentDashUserInfoAdminUserGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>>
export type GetCurrentDashUserInfoAdminUserGetInfiniteQueryError = GetCurrentDashUserInfoAdminUserGetErrorType<unknown>

/**
 * @summary Get Current Dash User Info
 */
export const useGetCurrentDashUserInfoAdminUserGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>>, TError = GetCurrentDashUserInfoAdminUserGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCurrentDashUserInfoAdminUserGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCurrentDashUserInfoAdminUserGetQueryOptions = <TData = Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError = GetCurrentDashUserInfoAdminUserGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentDashUserInfoAdminUserGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>> = ({ signal }) => getCurrentDashUserInfoAdminUserGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCurrentDashUserInfoAdminUserGetQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>>
export type GetCurrentDashUserInfoAdminUserGetQueryError = GetCurrentDashUserInfoAdminUserGetErrorType<unknown>

/**
 * @summary Get Current Dash User Info
 */
export const useGetCurrentDashUserInfoAdminUserGet = <TData = Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError = GetCurrentDashUserInfoAdminUserGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCurrentDashUserInfoAdminUserGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCurrentDashUserInfoAdminUserGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError = GetCurrentDashUserInfoAdminUserGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentDashUserInfoAdminUserGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>> = ({ signal }) => getCurrentDashUserInfoAdminUserGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCurrentDashUserInfoAdminUserGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>>
export type GetCurrentDashUserInfoAdminUserGetSuspenseQueryError = GetCurrentDashUserInfoAdminUserGetErrorType<unknown>

/**
 * @summary Get Current Dash User Info
 */
export const useGetCurrentDashUserInfoAdminUserGetSuspense = <TData = Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError = GetCurrentDashUserInfoAdminUserGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCurrentDashUserInfoAdminUserGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCurrentDashUserInfoAdminUserGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Execel
 */
export const getExecelAdminFinetuneGetExcelPost = (
    orionDashFinetuneAppGetExcelPayload: OrionDashFinetuneAppGetExcelPayload,
 ) => {
      
      
      return getExecelAdminFinetuneGetExcelPostMutator<unknown>(
      {url: `/admin/finetune/get_excel`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: orionDashFinetuneAppGetExcelPayload
    },
      );
    }
  


export const getGetExecelAdminFinetuneGetExcelPostMutationOptions = <TError = GetExecelAdminFinetuneGetExcelPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getExecelAdminFinetuneGetExcelPost>>, TError,{data: OrionDashFinetuneAppGetExcelPayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof getExecelAdminFinetuneGetExcelPost>>, TError,{data: OrionDashFinetuneAppGetExcelPayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getExecelAdminFinetuneGetExcelPost>>, {data: OrionDashFinetuneAppGetExcelPayload}> = (props) => {
          const {data} = props ?? {};

          return  getExecelAdminFinetuneGetExcelPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetExecelAdminFinetuneGetExcelPostMutationResult = NonNullable<Awaited<ReturnType<typeof getExecelAdminFinetuneGetExcelPost>>>
    export type GetExecelAdminFinetuneGetExcelPostMutationBody = OrionDashFinetuneAppGetExcelPayload
    export type GetExecelAdminFinetuneGetExcelPostMutationError = GetExecelAdminFinetuneGetExcelPostErrorType<HTTPValidationError>

    /**
 * @summary Get Execel
 */
export const useGetExecelAdminFinetuneGetExcelPost = <TError = GetExecelAdminFinetuneGetExcelPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getExecelAdminFinetuneGetExcelPost>>, TError,{data: OrionDashFinetuneAppGetExcelPayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof getExecelAdminFinetuneGetExcelPost>>,
        TError,
        {data: OrionDashFinetuneAppGetExcelPayload},
        TContext
      > => {

      const mutationOptions = getGetExecelAdminFinetuneGetExcelPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get File
 */
export const getFileAdminFinetuneGetOpenaiFileFileIdGet = (
    { fileId }: GetFileAdminFinetuneGetOpenaiFileFileIdGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getFileAdminFinetuneGetOpenaiFileFileIdGetMutator<unknown>(
      {url: `/admin/finetune/get_openai_file/${fileId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetFileAdminFinetuneGetOpenaiFileFileIdGetQueryKey = ({ fileId }: GetFileAdminFinetuneGetOpenaiFileFileIdGetPathParameters,) => {
    return [`/admin/finetune/get_openai_file/${fileId}`] as const;
    }

    
export const getGetFileAdminFinetuneGetOpenaiFileFileIdGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>>, TError = GetFileAdminFinetuneGetOpenaiFileFileIdGetErrorType<HTTPValidationError>>({ fileId }: GetFileAdminFinetuneGetOpenaiFileFileIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFileAdminFinetuneGetOpenaiFileFileIdGetQueryKey({ fileId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>> = ({ signal }) => getFileAdminFinetuneGetOpenaiFileFileIdGet({ fileId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFileAdminFinetuneGetOpenaiFileFileIdGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>>
export type GetFileAdminFinetuneGetOpenaiFileFileIdGetInfiniteQueryError = GetFileAdminFinetuneGetOpenaiFileFileIdGetErrorType<HTTPValidationError>

/**
 * @summary Get File
 */
export const useGetFileAdminFinetuneGetOpenaiFileFileIdGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>>, TError = GetFileAdminFinetuneGetOpenaiFileFileIdGetErrorType<HTTPValidationError>>(
 { fileId }: GetFileAdminFinetuneGetOpenaiFileFileIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFileAdminFinetuneGetOpenaiFileFileIdGetInfiniteQueryOptions({ fileId },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFileAdminFinetuneGetOpenaiFileFileIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError = GetFileAdminFinetuneGetOpenaiFileFileIdGetErrorType<HTTPValidationError>>({ fileId }: GetFileAdminFinetuneGetOpenaiFileFileIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFileAdminFinetuneGetOpenaiFileFileIdGetQueryKey({ fileId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>> = ({ signal }) => getFileAdminFinetuneGetOpenaiFileFileIdGet({ fileId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFileAdminFinetuneGetOpenaiFileFileIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>>
export type GetFileAdminFinetuneGetOpenaiFileFileIdGetQueryError = GetFileAdminFinetuneGetOpenaiFileFileIdGetErrorType<HTTPValidationError>

/**
 * @summary Get File
 */
export const useGetFileAdminFinetuneGetOpenaiFileFileIdGet = <TData = Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError = GetFileAdminFinetuneGetOpenaiFileFileIdGetErrorType<HTTPValidationError>>(
 { fileId }: GetFileAdminFinetuneGetOpenaiFileFileIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFileAdminFinetuneGetOpenaiFileFileIdGetQueryOptions({ fileId },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetFileAdminFinetuneGetOpenaiFileFileIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError = GetFileAdminFinetuneGetOpenaiFileFileIdGetErrorType<HTTPValidationError>>({ fileId }: GetFileAdminFinetuneGetOpenaiFileFileIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFileAdminFinetuneGetOpenaiFileFileIdGetQueryKey({ fileId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>> = ({ signal }) => getFileAdminFinetuneGetOpenaiFileFileIdGet({ fileId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFileAdminFinetuneGetOpenaiFileFileIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>>
export type GetFileAdminFinetuneGetOpenaiFileFileIdGetSuspenseQueryError = GetFileAdminFinetuneGetOpenaiFileFileIdGetErrorType<HTTPValidationError>

/**
 * @summary Get File
 */
export const useGetFileAdminFinetuneGetOpenaiFileFileIdGetSuspense = <TData = Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError = GetFileAdminFinetuneGetOpenaiFileFileIdGetErrorType<HTTPValidationError>>(
 { fileId }: GetFileAdminFinetuneGetOpenaiFileFileIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFileAdminFinetuneGetOpenaiFileFileIdGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFileAdminFinetuneGetOpenaiFileFileIdGetSuspenseQueryOptions({ fileId },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Upload Excel
 */
export const uploadExcelAdminFinetuneUploadExcelPost = (
    bodyUploadExcelAdminFinetuneUploadExcelPost: BodyUploadExcelAdminFinetuneUploadExcelPost,
 ) => {
      
      const formData = new FormData();
formData.append('file', bodyUploadExcelAdminFinetuneUploadExcelPost.file)

      return uploadExcelAdminFinetuneUploadExcelPostMutator<unknown>(
      {url: `/admin/finetune/upload_excel`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getUploadExcelAdminFinetuneUploadExcelPostMutationOptions = <TError = UploadExcelAdminFinetuneUploadExcelPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadExcelAdminFinetuneUploadExcelPost>>, TError,{data: BodyUploadExcelAdminFinetuneUploadExcelPost}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof uploadExcelAdminFinetuneUploadExcelPost>>, TError,{data: BodyUploadExcelAdminFinetuneUploadExcelPost}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadExcelAdminFinetuneUploadExcelPost>>, {data: BodyUploadExcelAdminFinetuneUploadExcelPost}> = (props) => {
          const {data} = props ?? {};

          return  uploadExcelAdminFinetuneUploadExcelPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadExcelAdminFinetuneUploadExcelPostMutationResult = NonNullable<Awaited<ReturnType<typeof uploadExcelAdminFinetuneUploadExcelPost>>>
    export type UploadExcelAdminFinetuneUploadExcelPostMutationBody = BodyUploadExcelAdminFinetuneUploadExcelPost
    export type UploadExcelAdminFinetuneUploadExcelPostMutationError = UploadExcelAdminFinetuneUploadExcelPostErrorType<HTTPValidationError>

    /**
 * @summary Upload Excel
 */
export const useUploadExcelAdminFinetuneUploadExcelPost = <TError = UploadExcelAdminFinetuneUploadExcelPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadExcelAdminFinetuneUploadExcelPost>>, TError,{data: BodyUploadExcelAdminFinetuneUploadExcelPost}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof uploadExcelAdminFinetuneUploadExcelPost>>,
        TError,
        {data: BodyUploadExcelAdminFinetuneUploadExcelPost},
        TContext
      > => {

      const mutationOptions = getUploadExcelAdminFinetuneUploadExcelPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Finetune Job
 */
export const listFinetuneJobAdminFinetuneTuneJobGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listFinetuneJobAdminFinetuneTuneJobGetMutator<FineTuningJob[]>(
      {url: `/admin/finetune/tune_job`, method: 'GET', signal
    },
      );
    }
  

export const getListFinetuneJobAdminFinetuneTuneJobGetQueryKey = () => {
    return [`/admin/finetune/tune_job`] as const;
    }

    
export const getListFinetuneJobAdminFinetuneTuneJobGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>>, TError = ListFinetuneJobAdminFinetuneTuneJobGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFinetuneJobAdminFinetuneTuneJobGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>> = ({ signal }) => listFinetuneJobAdminFinetuneTuneJobGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFinetuneJobAdminFinetuneTuneJobGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>>
export type ListFinetuneJobAdminFinetuneTuneJobGetInfiniteQueryError = ListFinetuneJobAdminFinetuneTuneJobGetErrorType<unknown>

/**
 * @summary List Finetune Job
 */
export const useListFinetuneJobAdminFinetuneTuneJobGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>>, TError = ListFinetuneJobAdminFinetuneTuneJobGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFinetuneJobAdminFinetuneTuneJobGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListFinetuneJobAdminFinetuneTuneJobGetQueryOptions = <TData = Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError = ListFinetuneJobAdminFinetuneTuneJobGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFinetuneJobAdminFinetuneTuneJobGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>> = ({ signal }) => listFinetuneJobAdminFinetuneTuneJobGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFinetuneJobAdminFinetuneTuneJobGetQueryResult = NonNullable<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>>
export type ListFinetuneJobAdminFinetuneTuneJobGetQueryError = ListFinetuneJobAdminFinetuneTuneJobGetErrorType<unknown>

/**
 * @summary List Finetune Job
 */
export const useListFinetuneJobAdminFinetuneTuneJobGet = <TData = Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError = ListFinetuneJobAdminFinetuneTuneJobGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFinetuneJobAdminFinetuneTuneJobGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListFinetuneJobAdminFinetuneTuneJobGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError = ListFinetuneJobAdminFinetuneTuneJobGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFinetuneJobAdminFinetuneTuneJobGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>> = ({ signal }) => listFinetuneJobAdminFinetuneTuneJobGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFinetuneJobAdminFinetuneTuneJobGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>>
export type ListFinetuneJobAdminFinetuneTuneJobGetSuspenseQueryError = ListFinetuneJobAdminFinetuneTuneJobGetErrorType<unknown>

/**
 * @summary List Finetune Job
 */
export const useListFinetuneJobAdminFinetuneTuneJobGetSuspense = <TData = Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError = ListFinetuneJobAdminFinetuneTuneJobGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFinetuneJobAdminFinetuneTuneJobGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFinetuneJobAdminFinetuneTuneJobGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Finetune Job
 */
export const createFinetuneJobAdminFinetuneTuneJobPost = (
    createTuneJob: CreateTuneJob,
 ) => {
      
      
      return createFinetuneJobAdminFinetuneTuneJobPostMutator<FineTuningJob>(
      {url: `/admin/finetune/tune_job`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createTuneJob
    },
      );
    }
  


export const getCreateFinetuneJobAdminFinetuneTuneJobPostMutationOptions = <TError = CreateFinetuneJobAdminFinetuneTuneJobPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFinetuneJobAdminFinetuneTuneJobPost>>, TError,{data: CreateTuneJob}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createFinetuneJobAdminFinetuneTuneJobPost>>, TError,{data: CreateTuneJob}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFinetuneJobAdminFinetuneTuneJobPost>>, {data: CreateTuneJob}> = (props) => {
          const {data} = props ?? {};

          return  createFinetuneJobAdminFinetuneTuneJobPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateFinetuneJobAdminFinetuneTuneJobPostMutationResult = NonNullable<Awaited<ReturnType<typeof createFinetuneJobAdminFinetuneTuneJobPost>>>
    export type CreateFinetuneJobAdminFinetuneTuneJobPostMutationBody = CreateTuneJob
    export type CreateFinetuneJobAdminFinetuneTuneJobPostMutationError = CreateFinetuneJobAdminFinetuneTuneJobPostErrorType<HTTPValidationError>

    /**
 * @summary Create Finetune Job
 */
export const useCreateFinetuneJobAdminFinetuneTuneJobPost = <TError = CreateFinetuneJobAdminFinetuneTuneJobPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFinetuneJobAdminFinetuneTuneJobPost>>, TError,{data: CreateTuneJob}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createFinetuneJobAdminFinetuneTuneJobPost>>,
        TError,
        {data: CreateTuneJob},
        TContext
      > => {

      const mutationOptions = getCreateFinetuneJobAdminFinetuneTuneJobPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Finetune Models
 */
export const listFinetuneModelsAdminFinetuneModelGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listFinetuneModelsAdminFinetuneModelGetMutator<FineTuneModelData[]>(
      {url: `/admin/finetune/model`, method: 'GET', signal
    },
      );
    }
  

export const getListFinetuneModelsAdminFinetuneModelGetQueryKey = () => {
    return [`/admin/finetune/model`] as const;
    }

    
export const getListFinetuneModelsAdminFinetuneModelGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>>, TError = ListFinetuneModelsAdminFinetuneModelGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFinetuneModelsAdminFinetuneModelGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>> = ({ signal }) => listFinetuneModelsAdminFinetuneModelGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFinetuneModelsAdminFinetuneModelGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>>
export type ListFinetuneModelsAdminFinetuneModelGetInfiniteQueryError = ListFinetuneModelsAdminFinetuneModelGetErrorType<unknown>

/**
 * @summary List Finetune Models
 */
export const useListFinetuneModelsAdminFinetuneModelGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>>, TError = ListFinetuneModelsAdminFinetuneModelGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFinetuneModelsAdminFinetuneModelGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListFinetuneModelsAdminFinetuneModelGetQueryOptions = <TData = Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError = ListFinetuneModelsAdminFinetuneModelGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFinetuneModelsAdminFinetuneModelGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>> = ({ signal }) => listFinetuneModelsAdminFinetuneModelGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFinetuneModelsAdminFinetuneModelGetQueryResult = NonNullable<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>>
export type ListFinetuneModelsAdminFinetuneModelGetQueryError = ListFinetuneModelsAdminFinetuneModelGetErrorType<unknown>

/**
 * @summary List Finetune Models
 */
export const useListFinetuneModelsAdminFinetuneModelGet = <TData = Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError = ListFinetuneModelsAdminFinetuneModelGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFinetuneModelsAdminFinetuneModelGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListFinetuneModelsAdminFinetuneModelGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError = ListFinetuneModelsAdminFinetuneModelGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFinetuneModelsAdminFinetuneModelGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>> = ({ signal }) => listFinetuneModelsAdminFinetuneModelGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFinetuneModelsAdminFinetuneModelGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>>
export type ListFinetuneModelsAdminFinetuneModelGetSuspenseQueryError = ListFinetuneModelsAdminFinetuneModelGetErrorType<unknown>

/**
 * @summary List Finetune Models
 */
export const useListFinetuneModelsAdminFinetuneModelGetSuspense = <TData = Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError = ListFinetuneModelsAdminFinetuneModelGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFinetuneModelsAdminFinetuneModelGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFinetuneModelsAdminFinetuneModelGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List Finetune Files
 */
export const listFinetuneFilesAdminFinetuneTuneFileGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listFinetuneFilesAdminFinetuneTuneFileGetMutator<FileObject[]>(
      {url: `/admin/finetune/tune_file`, method: 'GET', signal
    },
      );
    }
  

export const getListFinetuneFilesAdminFinetuneTuneFileGetQueryKey = () => {
    return [`/admin/finetune/tune_file`] as const;
    }

    
export const getListFinetuneFilesAdminFinetuneTuneFileGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>>, TError = ListFinetuneFilesAdminFinetuneTuneFileGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFinetuneFilesAdminFinetuneTuneFileGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>> = ({ signal }) => listFinetuneFilesAdminFinetuneTuneFileGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFinetuneFilesAdminFinetuneTuneFileGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>>
export type ListFinetuneFilesAdminFinetuneTuneFileGetInfiniteQueryError = ListFinetuneFilesAdminFinetuneTuneFileGetErrorType<unknown>

/**
 * @summary List Finetune Files
 */
export const useListFinetuneFilesAdminFinetuneTuneFileGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>>, TError = ListFinetuneFilesAdminFinetuneTuneFileGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFinetuneFilesAdminFinetuneTuneFileGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListFinetuneFilesAdminFinetuneTuneFileGetQueryOptions = <TData = Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError = ListFinetuneFilesAdminFinetuneTuneFileGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFinetuneFilesAdminFinetuneTuneFileGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>> = ({ signal }) => listFinetuneFilesAdminFinetuneTuneFileGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFinetuneFilesAdminFinetuneTuneFileGetQueryResult = NonNullable<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>>
export type ListFinetuneFilesAdminFinetuneTuneFileGetQueryError = ListFinetuneFilesAdminFinetuneTuneFileGetErrorType<unknown>

/**
 * @summary List Finetune Files
 */
export const useListFinetuneFilesAdminFinetuneTuneFileGet = <TData = Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError = ListFinetuneFilesAdminFinetuneTuneFileGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFinetuneFilesAdminFinetuneTuneFileGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListFinetuneFilesAdminFinetuneTuneFileGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError = ListFinetuneFilesAdminFinetuneTuneFileGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFinetuneFilesAdminFinetuneTuneFileGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>> = ({ signal }) => listFinetuneFilesAdminFinetuneTuneFileGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFinetuneFilesAdminFinetuneTuneFileGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>>
export type ListFinetuneFilesAdminFinetuneTuneFileGetSuspenseQueryError = ListFinetuneFilesAdminFinetuneTuneFileGetErrorType<unknown>

/**
 * @summary List Finetune Files
 */
export const useListFinetuneFilesAdminFinetuneTuneFileGetSuspense = <TData = Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError = ListFinetuneFilesAdminFinetuneTuneFileGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listFinetuneFilesAdminFinetuneTuneFileGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFinetuneFilesAdminFinetuneTuneFileGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Enable Tune Model
 */
export const enableTuneModelAdminFinetuneEnablePost = (
    enableTuneModel: EnableTuneModel,
 ) => {
      
      
      return enableTuneModelAdminFinetuneEnablePostMutator<FineTuneModelData>(
      {url: `/admin/finetune/enable`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: enableTuneModel
    },
      );
    }
  


export const getEnableTuneModelAdminFinetuneEnablePostMutationOptions = <TError = EnableTuneModelAdminFinetuneEnablePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof enableTuneModelAdminFinetuneEnablePost>>, TError,{data: EnableTuneModel}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof enableTuneModelAdminFinetuneEnablePost>>, TError,{data: EnableTuneModel}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof enableTuneModelAdminFinetuneEnablePost>>, {data: EnableTuneModel}> = (props) => {
          const {data} = props ?? {};

          return  enableTuneModelAdminFinetuneEnablePost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EnableTuneModelAdminFinetuneEnablePostMutationResult = NonNullable<Awaited<ReturnType<typeof enableTuneModelAdminFinetuneEnablePost>>>
    export type EnableTuneModelAdminFinetuneEnablePostMutationBody = EnableTuneModel
    export type EnableTuneModelAdminFinetuneEnablePostMutationError = EnableTuneModelAdminFinetuneEnablePostErrorType<HTTPValidationError>

    /**
 * @summary Enable Tune Model
 */
export const useEnableTuneModelAdminFinetuneEnablePost = <TError = EnableTuneModelAdminFinetuneEnablePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof enableTuneModelAdminFinetuneEnablePost>>, TError,{data: EnableTuneModel}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof enableTuneModelAdminFinetuneEnablePost>>,
        TError,
        {data: EnableTuneModel},
        TContext
      > => {

      const mutationOptions = getEnableTuneModelAdminFinetuneEnablePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get User Profile Execel
 */
export const getUserProfileExecelAdminChoreGetUserProfileExecelPost = (
    orionDashChoreAppGetExcelPayload: OrionDashChoreAppGetExcelPayload,
 ) => {
      
      
      return getUserProfileExecelAdminChoreGetUserProfileExecelPostMutator<unknown>(
      {url: `/admin/chore/get_user_profile_execel`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: orionDashChoreAppGetExcelPayload
    },
      );
    }
  


export const getGetUserProfileExecelAdminChoreGetUserProfileExecelPostMutationOptions = <TError = GetUserProfileExecelAdminChoreGetUserProfileExecelPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getUserProfileExecelAdminChoreGetUserProfileExecelPost>>, TError,{data: OrionDashChoreAppGetExcelPayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof getUserProfileExecelAdminChoreGetUserProfileExecelPost>>, TError,{data: OrionDashChoreAppGetExcelPayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getUserProfileExecelAdminChoreGetUserProfileExecelPost>>, {data: OrionDashChoreAppGetExcelPayload}> = (props) => {
          const {data} = props ?? {};

          return  getUserProfileExecelAdminChoreGetUserProfileExecelPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetUserProfileExecelAdminChoreGetUserProfileExecelPostMutationResult = NonNullable<Awaited<ReturnType<typeof getUserProfileExecelAdminChoreGetUserProfileExecelPost>>>
    export type GetUserProfileExecelAdminChoreGetUserProfileExecelPostMutationBody = OrionDashChoreAppGetExcelPayload
    export type GetUserProfileExecelAdminChoreGetUserProfileExecelPostMutationError = GetUserProfileExecelAdminChoreGetUserProfileExecelPostErrorType<HTTPValidationError>

    /**
 * @summary Get User Profile Execel
 */
export const useGetUserProfileExecelAdminChoreGetUserProfileExecelPost = <TError = GetUserProfileExecelAdminChoreGetUserProfileExecelPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getUserProfileExecelAdminChoreGetUserProfileExecelPost>>, TError,{data: OrionDashChoreAppGetExcelPayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof getUserProfileExecelAdminChoreGetUserProfileExecelPost>>,
        TError,
        {data: OrionDashChoreAppGetExcelPayload},
        TContext
      > => {

      const mutationOptions = getGetUserProfileExecelAdminChoreGetUserProfileExecelPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Labels
 */
export const listLabelsAdminAnnotationIdLabelsGet = (
    { id }: ListLabelsAdminAnnotationIdLabelsGetPathParameters,
    params?: ListLabelsAdminAnnotationIdLabelsGetParams,
 signal?: AbortSignal
) => {
      
      
      return listLabelsAdminAnnotationIdLabelsGetMutator<DashLabel[]>(
      {url: `/admin/annotation/${id}/labels`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListLabelsAdminAnnotationIdLabelsGetQueryKey = ({ id }: ListLabelsAdminAnnotationIdLabelsGetPathParameters,
    params?: ListLabelsAdminAnnotationIdLabelsGetParams,) => {
    return [`/admin/annotation/${id}/labels`, ...(params ? [params]: [])] as const;
    }

    
export const getListLabelsAdminAnnotationIdLabelsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, ListLabelsAdminAnnotationIdLabelsGetParams['after']>, TError = ListLabelsAdminAnnotationIdLabelsGetErrorType<HTTPValidationError>>({ id }: ListLabelsAdminAnnotationIdLabelsGetPathParameters,
    params?: ListLabelsAdminAnnotationIdLabelsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, QueryKey, ListLabelsAdminAnnotationIdLabelsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminAnnotationIdLabelsGetQueryKey({ id },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, QueryKey, ListLabelsAdminAnnotationIdLabelsGetParams['after']> = ({ signal, pageParam }) => listLabelsAdminAnnotationIdLabelsGet({ id },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, QueryKey, ListLabelsAdminAnnotationIdLabelsGetParams['after']> & { queryKey: QueryKey }
}

export type ListLabelsAdminAnnotationIdLabelsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>>
export type ListLabelsAdminAnnotationIdLabelsGetInfiniteQueryError = ListLabelsAdminAnnotationIdLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminAnnotationIdLabelsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, ListLabelsAdminAnnotationIdLabelsGetParams['after']>, TError = ListLabelsAdminAnnotationIdLabelsGetErrorType<HTTPValidationError>>(
 { id }: ListLabelsAdminAnnotationIdLabelsGetPathParameters,
    params?: ListLabelsAdminAnnotationIdLabelsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, QueryKey, ListLabelsAdminAnnotationIdLabelsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminAnnotationIdLabelsGetInfiniteQueryOptions({ id },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminAnnotationIdLabelsGetQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError = ListLabelsAdminAnnotationIdLabelsGetErrorType<HTTPValidationError>>({ id }: ListLabelsAdminAnnotationIdLabelsGetPathParameters,
    params?: ListLabelsAdminAnnotationIdLabelsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminAnnotationIdLabelsGetQueryKey({ id },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>> = ({ signal }) => listLabelsAdminAnnotationIdLabelsGet({ id },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminAnnotationIdLabelsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>>
export type ListLabelsAdminAnnotationIdLabelsGetQueryError = ListLabelsAdminAnnotationIdLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminAnnotationIdLabelsGet = <TData = Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError = ListLabelsAdminAnnotationIdLabelsGetErrorType<HTTPValidationError>>(
 { id }: ListLabelsAdminAnnotationIdLabelsGetPathParameters,
    params?: ListLabelsAdminAnnotationIdLabelsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminAnnotationIdLabelsGetQueryOptions({ id },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminAnnotationIdLabelsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError = ListLabelsAdminAnnotationIdLabelsGetErrorType<HTTPValidationError>>({ id }: ListLabelsAdminAnnotationIdLabelsGetPathParameters,
    params?: ListLabelsAdminAnnotationIdLabelsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminAnnotationIdLabelsGetQueryKey({ id },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>> = ({ signal }) => listLabelsAdminAnnotationIdLabelsGet({ id },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminAnnotationIdLabelsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>>
export type ListLabelsAdminAnnotationIdLabelsGetSuspenseQueryError = ListLabelsAdminAnnotationIdLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminAnnotationIdLabelsGetSuspense = <TData = Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError = ListLabelsAdminAnnotationIdLabelsGetErrorType<HTTPValidationError>>(
 { id }: ListLabelsAdminAnnotationIdLabelsGetPathParameters,
    params?: ListLabelsAdminAnnotationIdLabelsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminAnnotationIdLabelsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminAnnotationIdLabelsGetSuspenseQueryOptions({ id },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update
 */
export const updateAdminAnnotationIdLabelsPatch = (
    { id }: UpdateAdminAnnotationIdLabelsPatchPathParameters,
    mixInLabelPayload: MixInLabelPayload,
    params?: UpdateAdminAnnotationIdLabelsPatchParams,
 ) => {
      
      
      return updateAdminAnnotationIdLabelsPatchMutator<DashLabel[]>(
      {url: `/admin/annotation/${id}/labels`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: mixInLabelPayload,
        params
    },
      );
    }
  


export const getUpdateAdminAnnotationIdLabelsPatchMutationOptions = <TError = UpdateAdminAnnotationIdLabelsPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminAnnotationIdLabelsPatch>>, TError,{pathParams: UpdateAdminAnnotationIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminAnnotationIdLabelsPatchParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAdminAnnotationIdLabelsPatch>>, TError,{pathParams: UpdateAdminAnnotationIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminAnnotationIdLabelsPatchParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAdminAnnotationIdLabelsPatch>>, {pathParams: UpdateAdminAnnotationIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminAnnotationIdLabelsPatchParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  updateAdminAnnotationIdLabelsPatch(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAdminAnnotationIdLabelsPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateAdminAnnotationIdLabelsPatch>>>
    export type UpdateAdminAnnotationIdLabelsPatchMutationBody = MixInLabelPayload
    export type UpdateAdminAnnotationIdLabelsPatchMutationError = UpdateAdminAnnotationIdLabelsPatchErrorType<HTTPValidationError>

    /**
 * @summary Update
 */
export const useUpdateAdminAnnotationIdLabelsPatch = <TError = UpdateAdminAnnotationIdLabelsPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminAnnotationIdLabelsPatch>>, TError,{pathParams: UpdateAdminAnnotationIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminAnnotationIdLabelsPatchParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateAdminAnnotationIdLabelsPatch>>,
        TError,
        {pathParams: UpdateAdminAnnotationIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminAnnotationIdLabelsPatchParams},
        TContext
      > => {

      const mutationOptions = getUpdateAdminAnnotationIdLabelsPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Labels
 */
export const addLabelsAdminAnnotationIdLabelsPost = (
    { id }: AddLabelsAdminAnnotationIdLabelsPostPathParameters,
    mixInLabelPayload: MixInLabelPayload,
    params?: AddLabelsAdminAnnotationIdLabelsPostParams,
 ) => {
      
      
      return addLabelsAdminAnnotationIdLabelsPostMutator<DashLabel[]>(
      {url: `/admin/annotation/${id}/labels`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: mixInLabelPayload,
        params
    },
      );
    }
  


export const getAddLabelsAdminAnnotationIdLabelsPostMutationOptions = <TError = AddLabelsAdminAnnotationIdLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminAnnotationIdLabelsPost>>, TError,{pathParams: AddLabelsAdminAnnotationIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminAnnotationIdLabelsPostParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminAnnotationIdLabelsPost>>, TError,{pathParams: AddLabelsAdminAnnotationIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminAnnotationIdLabelsPostParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addLabelsAdminAnnotationIdLabelsPost>>, {pathParams: AddLabelsAdminAnnotationIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminAnnotationIdLabelsPostParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  addLabelsAdminAnnotationIdLabelsPost(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddLabelsAdminAnnotationIdLabelsPostMutationResult = NonNullable<Awaited<ReturnType<typeof addLabelsAdminAnnotationIdLabelsPost>>>
    export type AddLabelsAdminAnnotationIdLabelsPostMutationBody = MixInLabelPayload
    export type AddLabelsAdminAnnotationIdLabelsPostMutationError = AddLabelsAdminAnnotationIdLabelsPostErrorType<HTTPValidationError>

    /**
 * @summary Add Labels
 */
export const useAddLabelsAdminAnnotationIdLabelsPost = <TError = AddLabelsAdminAnnotationIdLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminAnnotationIdLabelsPost>>, TError,{pathParams: AddLabelsAdminAnnotationIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminAnnotationIdLabelsPostParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof addLabelsAdminAnnotationIdLabelsPost>>,
        TError,
        {pathParams: AddLabelsAdminAnnotationIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminAnnotationIdLabelsPostParams},
        TContext
      > => {

      const mutationOptions = getAddLabelsAdminAnnotationIdLabelsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Label
 */
export const deleteLabelAdminAnnotationIdLabelsLabelIdDelete = (
    { id, labelId }: DeleteLabelAdminAnnotationIdLabelsLabelIdDeletePathParameters,
    params?: DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteParams,
 ) => {
      
      
      return deleteLabelAdminAnnotationIdLabelsLabelIdDeleteMutator<unknown>(
      {url: `/admin/annotation/${id}/labels/${labelId}`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteLabelAdminAnnotationIdLabelsLabelIdDeleteMutationOptions = <TError = DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminAnnotationIdLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminAnnotationIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminAnnotationIdLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminAnnotationIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLabelAdminAnnotationIdLabelsLabelIdDelete>>, {pathParams: DeleteLabelAdminAnnotationIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteParams}> = (props) => {
          const {pathParams,params} = props ?? {};

          return  deleteLabelAdminAnnotationIdLabelsLabelIdDelete(pathParams,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLabelAdminAnnotationIdLabelsLabelIdDelete>>>
    
    export type DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteMutationError = DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Label
 */
export const useDeleteLabelAdminAnnotationIdLabelsLabelIdDelete = <TError = DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminAnnotationIdLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminAnnotationIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteLabelAdminAnnotationIdLabelsLabelIdDelete>>,
        TError,
        {pathParams: DeleteLabelAdminAnnotationIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminAnnotationIdLabelsLabelIdDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteLabelAdminAnnotationIdLabelsLabelIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Annotations
 */
export const listAnnotationsAdminAnnotationGet = (
    params?: ListAnnotationsAdminAnnotationGetParams,
 signal?: AbortSignal
) => {
      
      
      return listAnnotationsAdminAnnotationGetMutator<AnnotationData[]>(
      {url: `/admin/annotation`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListAnnotationsAdminAnnotationGetQueryKey = (params?: ListAnnotationsAdminAnnotationGetParams,) => {
    return [`/admin/annotation`, ...(params ? [params]: [])] as const;
    }

    
export const getListAnnotationsAdminAnnotationGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, ListAnnotationsAdminAnnotationGetParams['after']>, TError = ListAnnotationsAdminAnnotationGetErrorType<HTTPValidationError>>(params?: ListAnnotationsAdminAnnotationGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError, TData, Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, QueryKey, ListAnnotationsAdminAnnotationGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAnnotationsAdminAnnotationGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, QueryKey, ListAnnotationsAdminAnnotationGetParams['after']> = ({ signal, pageParam }) => listAnnotationsAdminAnnotationGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError, TData, Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, QueryKey, ListAnnotationsAdminAnnotationGetParams['after']> & { queryKey: QueryKey }
}

export type ListAnnotationsAdminAnnotationGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>>
export type ListAnnotationsAdminAnnotationGetInfiniteQueryError = ListAnnotationsAdminAnnotationGetErrorType<HTTPValidationError>

/**
 * @summary List Annotations
 */
export const useListAnnotationsAdminAnnotationGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, ListAnnotationsAdminAnnotationGetParams['after']>, TError = ListAnnotationsAdminAnnotationGetErrorType<HTTPValidationError>>(
 params?: ListAnnotationsAdminAnnotationGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError, TData, Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, QueryKey, ListAnnotationsAdminAnnotationGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAnnotationsAdminAnnotationGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListAnnotationsAdminAnnotationGetQueryOptions = <TData = Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError = ListAnnotationsAdminAnnotationGetErrorType<HTTPValidationError>>(params?: ListAnnotationsAdminAnnotationGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAnnotationsAdminAnnotationGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>> = ({ signal }) => listAnnotationsAdminAnnotationGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListAnnotationsAdminAnnotationGetQueryResult = NonNullable<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>>
export type ListAnnotationsAdminAnnotationGetQueryError = ListAnnotationsAdminAnnotationGetErrorType<HTTPValidationError>

/**
 * @summary List Annotations
 */
export const useListAnnotationsAdminAnnotationGet = <TData = Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError = ListAnnotationsAdminAnnotationGetErrorType<HTTPValidationError>>(
 params?: ListAnnotationsAdminAnnotationGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAnnotationsAdminAnnotationGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListAnnotationsAdminAnnotationGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError = ListAnnotationsAdminAnnotationGetErrorType<HTTPValidationError>>(params?: ListAnnotationsAdminAnnotationGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAnnotationsAdminAnnotationGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>> = ({ signal }) => listAnnotationsAdminAnnotationGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListAnnotationsAdminAnnotationGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>>
export type ListAnnotationsAdminAnnotationGetSuspenseQueryError = ListAnnotationsAdminAnnotationGetErrorType<HTTPValidationError>

/**
 * @summary List Annotations
 */
export const useListAnnotationsAdminAnnotationGetSuspense = <TData = Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError = ListAnnotationsAdminAnnotationGetErrorType<HTTPValidationError>>(
 params?: ListAnnotationsAdminAnnotationGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listAnnotationsAdminAnnotationGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAnnotationsAdminAnnotationGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create
 */
export const createAdminAnnotationPost = (
    annotationCreate: AnnotationCreate,
 ) => {
      
      
      return createAdminAnnotationPostMutator<AnnotationData>(
      {url: `/admin/annotation`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: annotationCreate
    },
      );
    }
  


export const getCreateAdminAnnotationPostMutationOptions = <TError = CreateAdminAnnotationPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAdminAnnotationPost>>, TError,{data: AnnotationCreate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createAdminAnnotationPost>>, TError,{data: AnnotationCreate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAdminAnnotationPost>>, {data: AnnotationCreate}> = (props) => {
          const {data} = props ?? {};

          return  createAdminAnnotationPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateAdminAnnotationPostMutationResult = NonNullable<Awaited<ReturnType<typeof createAdminAnnotationPost>>>
    export type CreateAdminAnnotationPostMutationBody = AnnotationCreate
    export type CreateAdminAnnotationPostMutationError = CreateAdminAnnotationPostErrorType<HTTPValidationError>

    /**
 * @summary Create
 */
export const useCreateAdminAnnotationPost = <TError = CreateAdminAnnotationPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAdminAnnotationPost>>, TError,{data: AnnotationCreate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createAdminAnnotationPost>>,
        TError,
        {data: AnnotationCreate},
        TContext
      > => {

      const mutationOptions = getCreateAdminAnnotationPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get
 */
export const getAdminAnnotationIdGet = (
    { id }: GetAdminAnnotationIdGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getAdminAnnotationIdGetMutator<AnnotationData>(
      {url: `/admin/annotation/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetAdminAnnotationIdGetQueryKey = ({ id }: GetAdminAnnotationIdGetPathParameters,) => {
    return [`/admin/annotation/${id}`] as const;
    }

    
export const getGetAdminAnnotationIdGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>>, TError = GetAdminAnnotationIdGetErrorType<HTTPValidationError>>({ id }: GetAdminAnnotationIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminAnnotationIdGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>> = ({ signal }) => getAdminAnnotationIdGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminAnnotationIdGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>>
export type GetAdminAnnotationIdGetInfiniteQueryError = GetAdminAnnotationIdGetErrorType<HTTPValidationError>

/**
 * @summary Get
 */
export const useGetAdminAnnotationIdGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>>, TError = GetAdminAnnotationIdGetErrorType<HTTPValidationError>>(
 { id }: GetAdminAnnotationIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminAnnotationIdGetInfiniteQueryOptions({ id },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminAnnotationIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError = GetAdminAnnotationIdGetErrorType<HTTPValidationError>>({ id }: GetAdminAnnotationIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminAnnotationIdGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>> = ({ signal }) => getAdminAnnotationIdGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminAnnotationIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>>
export type GetAdminAnnotationIdGetQueryError = GetAdminAnnotationIdGetErrorType<HTTPValidationError>

/**
 * @summary Get
 */
export const useGetAdminAnnotationIdGet = <TData = Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError = GetAdminAnnotationIdGetErrorType<HTTPValidationError>>(
 { id }: GetAdminAnnotationIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminAnnotationIdGetQueryOptions({ id },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminAnnotationIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError = GetAdminAnnotationIdGetErrorType<HTTPValidationError>>({ id }: GetAdminAnnotationIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminAnnotationIdGetQueryKey({ id });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>> = ({ signal }) => getAdminAnnotationIdGet({ id }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminAnnotationIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>>
export type GetAdminAnnotationIdGetSuspenseQueryError = GetAdminAnnotationIdGetErrorType<HTTPValidationError>

/**
 * @summary Get
 */
export const useGetAdminAnnotationIdGetSuspense = <TData = Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError = GetAdminAnnotationIdGetErrorType<HTTPValidationError>>(
 { id }: GetAdminAnnotationIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAdminAnnotationIdGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminAnnotationIdGetSuspenseQueryOptions({ id },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Remove
 */
export const removeAdminAnnotationIdDelete = (
    { id }: RemoveAdminAnnotationIdDeletePathParameters,
 ) => {
      
      
      return removeAdminAnnotationIdDeleteMutator<unknown>(
      {url: `/admin/annotation/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getRemoveAdminAnnotationIdDeleteMutationOptions = <TError = RemoveAdminAnnotationIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeAdminAnnotationIdDelete>>, TError,{pathParams: RemoveAdminAnnotationIdDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof removeAdminAnnotationIdDelete>>, TError,{pathParams: RemoveAdminAnnotationIdDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeAdminAnnotationIdDelete>>, {pathParams: RemoveAdminAnnotationIdDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  removeAdminAnnotationIdDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveAdminAnnotationIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof removeAdminAnnotationIdDelete>>>
    
    export type RemoveAdminAnnotationIdDeleteMutationError = RemoveAdminAnnotationIdDeleteErrorType<HTTPValidationError>

    /**
 * @summary Remove
 */
export const useRemoveAdminAnnotationIdDelete = <TError = RemoveAdminAnnotationIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeAdminAnnotationIdDelete>>, TError,{pathParams: RemoveAdminAnnotationIdDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof removeAdminAnnotationIdDelete>>,
        TError,
        {pathParams: RemoveAdminAnnotationIdDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getRemoveAdminAnnotationIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Users
 */
export const getUsersAdminUsersGet = (
    params?: GetUsersAdminUsersGetParams,
 signal?: AbortSignal
) => {
      
      
      return getUsersAdminUsersGetMutator<BizUserInfo[]>(
      {url: `/admin/users`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetUsersAdminUsersGetQueryKey = (params?: GetUsersAdminUsersGetParams,) => {
    return [`/admin/users`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersAdminUsersGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersAdminUsersGet>>, GetUsersAdminUsersGetParams['after']>, TError = GetUsersAdminUsersGetErrorType<HTTPValidationError>>(params?: GetUsersAdminUsersGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError, TData, Awaited<ReturnType<typeof getUsersAdminUsersGet>>, QueryKey, GetUsersAdminUsersGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersAdminUsersGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersAdminUsersGet>>, QueryKey, GetUsersAdminUsersGetParams['after']> = ({ signal, pageParam }) => getUsersAdminUsersGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError, TData, Awaited<ReturnType<typeof getUsersAdminUsersGet>>, QueryKey, GetUsersAdminUsersGetParams['after']> & { queryKey: QueryKey }
}

export type GetUsersAdminUsersGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersAdminUsersGet>>>
export type GetUsersAdminUsersGetInfiniteQueryError = GetUsersAdminUsersGetErrorType<HTTPValidationError>

/**
 * @summary Get Users
 */
export const useGetUsersAdminUsersGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersAdminUsersGet>>, GetUsersAdminUsersGetParams['after']>, TError = GetUsersAdminUsersGetErrorType<HTTPValidationError>>(
 params?: GetUsersAdminUsersGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError, TData, Awaited<ReturnType<typeof getUsersAdminUsersGet>>, QueryKey, GetUsersAdminUsersGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersAdminUsersGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersAdminUsersGetQueryOptions = <TData = Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError = GetUsersAdminUsersGetErrorType<HTTPValidationError>>(params?: GetUsersAdminUsersGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersAdminUsersGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersAdminUsersGet>>> = ({ signal }) => getUsersAdminUsersGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersAdminUsersGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersAdminUsersGet>>>
export type GetUsersAdminUsersGetQueryError = GetUsersAdminUsersGetErrorType<HTTPValidationError>

/**
 * @summary Get Users
 */
export const useGetUsersAdminUsersGet = <TData = Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError = GetUsersAdminUsersGetErrorType<HTTPValidationError>>(
 params?: GetUsersAdminUsersGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersAdminUsersGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersAdminUsersGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError = GetUsersAdminUsersGetErrorType<HTTPValidationError>>(params?: GetUsersAdminUsersGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersAdminUsersGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersAdminUsersGet>>> = ({ signal }) => getUsersAdminUsersGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersAdminUsersGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersAdminUsersGet>>>
export type GetUsersAdminUsersGetSuspenseQueryError = GetUsersAdminUsersGetErrorType<HTTPValidationError>

/**
 * @summary Get Users
 */
export const useGetUsersAdminUsersGetSuspense = <TData = Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError = GetUsersAdminUsersGetErrorType<HTTPValidationError>>(
 params?: GetUsersAdminUsersGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUsersAdminUsersGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersAdminUsersGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get User
 */
export const getUserAdminUsersUserIdGet = (
    { userId }: GetUserAdminUsersUserIdGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getUserAdminUsersUserIdGetMutator<BizUserInfo>(
      {url: `/admin/users/${userId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserAdminUsersUserIdGetQueryKey = ({ userId }: GetUserAdminUsersUserIdGetPathParameters,) => {
    return [`/admin/users/${userId}`] as const;
    }

    
export const getGetUserAdminUsersUserIdGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>>, TError = GetUserAdminUsersUserIdGetErrorType<HTTPValidationError>>({ userId }: GetUserAdminUsersUserIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserAdminUsersUserIdGetQueryKey({ userId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>> = ({ signal }) => getUserAdminUsersUserIdGet({ userId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserAdminUsersUserIdGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>>
export type GetUserAdminUsersUserIdGetInfiniteQueryError = GetUserAdminUsersUserIdGetErrorType<HTTPValidationError>

/**
 * @summary Get User
 */
export const useGetUserAdminUsersUserIdGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>>, TError = GetUserAdminUsersUserIdGetErrorType<HTTPValidationError>>(
 { userId }: GetUserAdminUsersUserIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserAdminUsersUserIdGetInfiniteQueryOptions({ userId },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserAdminUsersUserIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError = GetUserAdminUsersUserIdGetErrorType<HTTPValidationError>>({ userId }: GetUserAdminUsersUserIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserAdminUsersUserIdGetQueryKey({ userId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>> = ({ signal }) => getUserAdminUsersUserIdGet({ userId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserAdminUsersUserIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>>
export type GetUserAdminUsersUserIdGetQueryError = GetUserAdminUsersUserIdGetErrorType<HTTPValidationError>

/**
 * @summary Get User
 */
export const useGetUserAdminUsersUserIdGet = <TData = Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError = GetUserAdminUsersUserIdGetErrorType<HTTPValidationError>>(
 { userId }: GetUserAdminUsersUserIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserAdminUsersUserIdGetQueryOptions({ userId },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserAdminUsersUserIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError = GetUserAdminUsersUserIdGetErrorType<HTTPValidationError>>({ userId }: GetUserAdminUsersUserIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserAdminUsersUserIdGetQueryKey({ userId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>> = ({ signal }) => getUserAdminUsersUserIdGet({ userId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserAdminUsersUserIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>>
export type GetUserAdminUsersUserIdGetSuspenseQueryError = GetUserAdminUsersUserIdGetErrorType<HTTPValidationError>

/**
 * @summary Get User
 */
export const useGetUserAdminUsersUserIdGetSuspense = <TData = Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError = GetUserAdminUsersUserIdGetErrorType<HTTPValidationError>>(
 { userId }: GetUserAdminUsersUserIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserAdminUsersUserIdGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserAdminUsersUserIdGetSuspenseQueryOptions({ userId },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update User Action
 */
export const updateUserActionAdminUsersUserIdPatch = (
    { userId }: UpdateUserActionAdminUsersUserIdPatchPathParameters,
    updateUserRequest: UpdateUserRequest,
 ) => {
      
      
      return updateUserActionAdminUsersUserIdPatchMutator<UserInfoOutput>(
      {url: `/admin/users/${userId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateUserRequest
    },
      );
    }
  


export const getUpdateUserActionAdminUsersUserIdPatchMutationOptions = <TError = UpdateUserActionAdminUsersUserIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserActionAdminUsersUserIdPatch>>, TError,{pathParams: UpdateUserActionAdminUsersUserIdPatchPathParameters;data: UpdateUserRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateUserActionAdminUsersUserIdPatch>>, TError,{pathParams: UpdateUserActionAdminUsersUserIdPatchPathParameters;data: UpdateUserRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserActionAdminUsersUserIdPatch>>, {pathParams: UpdateUserActionAdminUsersUserIdPatchPathParameters;data: UpdateUserRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  updateUserActionAdminUsersUserIdPatch(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUserActionAdminUsersUserIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserActionAdminUsersUserIdPatch>>>
    export type UpdateUserActionAdminUsersUserIdPatchMutationBody = UpdateUserRequest
    export type UpdateUserActionAdminUsersUserIdPatchMutationError = UpdateUserActionAdminUsersUserIdPatchErrorType<HTTPValidationError>

    /**
 * @summary Update User Action
 */
export const useUpdateUserActionAdminUsersUserIdPatch = <TError = UpdateUserActionAdminUsersUserIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserActionAdminUsersUserIdPatch>>, TError,{pathParams: UpdateUserActionAdminUsersUserIdPatchPathParameters;data: UpdateUserRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateUserActionAdminUsersUserIdPatch>>,
        TError,
        {pathParams: UpdateUserActionAdminUsersUserIdPatchPathParameters;data: UpdateUserRequest},
        TContext
      > => {

      const mutationOptions = getUpdateUserActionAdminUsersUserIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get User Calendar Events
 */
export const getUserCalendarEventsAdminUsersUserIdCalendarEventsGet = (
    { userId }: GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getUserCalendarEventsAdminUsersUserIdCalendarEventsGetMutator<UserCalendarDataResponse>(
      {url: `/admin/users/${userId}/calendar/events`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserCalendarEventsAdminUsersUserIdCalendarEventsGetQueryKey = ({ userId }: GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetPathParameters,) => {
    return [`/admin/users/${userId}/calendar/events`] as const;
    }

    
export const getGetUserCalendarEventsAdminUsersUserIdCalendarEventsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>>, TError = GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetErrorType<HTTPValidationError>>({ userId }: GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserCalendarEventsAdminUsersUserIdCalendarEventsGetQueryKey({ userId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>> = ({ signal }) => getUserCalendarEventsAdminUsersUserIdCalendarEventsGet({ userId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>>
export type GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetInfiniteQueryError = GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetErrorType<HTTPValidationError>

/**
 * @summary Get User Calendar Events
 */
export const useGetUserCalendarEventsAdminUsersUserIdCalendarEventsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>>, TError = GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetErrorType<HTTPValidationError>>(
 { userId }: GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserCalendarEventsAdminUsersUserIdCalendarEventsGetInfiniteQueryOptions({ userId },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserCalendarEventsAdminUsersUserIdCalendarEventsGetQueryOptions = <TData = Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError = GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetErrorType<HTTPValidationError>>({ userId }: GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserCalendarEventsAdminUsersUserIdCalendarEventsGetQueryKey({ userId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>> = ({ signal }) => getUserCalendarEventsAdminUsersUserIdCalendarEventsGet({ userId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>>
export type GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetQueryError = GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetErrorType<HTTPValidationError>

/**
 * @summary Get User Calendar Events
 */
export const useGetUserCalendarEventsAdminUsersUserIdCalendarEventsGet = <TData = Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError = GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetErrorType<HTTPValidationError>>(
 { userId }: GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserCalendarEventsAdminUsersUserIdCalendarEventsGetQueryOptions({ userId },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserCalendarEventsAdminUsersUserIdCalendarEventsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError = GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetErrorType<HTTPValidationError>>({ userId }: GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserCalendarEventsAdminUsersUserIdCalendarEventsGetQueryKey({ userId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>> = ({ signal }) => getUserCalendarEventsAdminUsersUserIdCalendarEventsGet({ userId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>>
export type GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetSuspenseQueryError = GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetErrorType<HTTPValidationError>

/**
 * @summary Get User Calendar Events
 */
export const useGetUserCalendarEventsAdminUsersUserIdCalendarEventsGetSuspense = <TData = Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError = GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetErrorType<HTTPValidationError>>(
 { userId }: GetUserCalendarEventsAdminUsersUserIdCalendarEventsGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventsAdminUsersUserIdCalendarEventsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserCalendarEventsAdminUsersUserIdCalendarEventsGetSuspenseQueryOptions({ userId },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get User Calendar Event By Uuid
 */
export const getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet = (
    { userId, eventUuid }: GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetMutator<UserCalendarDataResponse>(
      {url: `/admin/users/${userId}/calendar/events/${eventUuid}`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetQueryKey = ({ userId, eventUuid }: GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetPathParameters,) => {
    return [`/admin/users/${userId}/calendar/events/${eventUuid}`] as const;
    }

    
export const getGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>>, TError = GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetErrorType<HTTPValidationError>>({ userId, eventUuid }: GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetQueryKey({ userId, eventUuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>> = ({ signal }) => getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet({ userId, eventUuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>>
export type GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetInfiniteQueryError = GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetErrorType<HTTPValidationError>

/**
 * @summary Get User Calendar Event By Uuid
 */
export const useGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>>, TError = GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetErrorType<HTTPValidationError>>(
 { userId, eventUuid }: GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetInfiniteQueryOptions({ userId, eventUuid },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetQueryOptions = <TData = Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError = GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetErrorType<HTTPValidationError>>({ userId, eventUuid }: GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetQueryKey({ userId, eventUuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>> = ({ signal }) => getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet({ userId, eventUuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>>
export type GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetQueryError = GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetErrorType<HTTPValidationError>

/**
 * @summary Get User Calendar Event By Uuid
 */
export const useGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet = <TData = Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError = GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetErrorType<HTTPValidationError>>(
 { userId, eventUuid }: GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetQueryOptions({ userId, eventUuid },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError = GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetErrorType<HTTPValidationError>>({ userId, eventUuid }: GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetQueryKey({ userId, eventUuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>> = ({ signal }) => getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet({ userId, eventUuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>>
export type GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetSuspenseQueryError = GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetErrorType<HTTPValidationError>

/**
 * @summary Get User Calendar Event By Uuid
 */
export const useGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetSuspense = <TData = Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError = GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetErrorType<HTTPValidationError>>(
 { userId, eventUuid }: GetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserCalendarEventByUuidAdminUsersUserIdCalendarEventsEventUuidGetSuspenseQueryOptions({ userId, eventUuid },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get User Notes
 */
export const getUserNotesAdminUsersUserIdNotesGet = (
    { userId }: GetUserNotesAdminUsersUserIdNotesGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getUserNotesAdminUsersUserIdNotesGetMutator<UserNoteData[]>(
      {url: `/admin/users/${userId}/notes`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserNotesAdminUsersUserIdNotesGetQueryKey = ({ userId }: GetUserNotesAdminUsersUserIdNotesGetPathParameters,) => {
    return [`/admin/users/${userId}/notes`] as const;
    }

    
export const getGetUserNotesAdminUsersUserIdNotesGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>>, TError = GetUserNotesAdminUsersUserIdNotesGetErrorType<HTTPValidationError>>({ userId }: GetUserNotesAdminUsersUserIdNotesGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserNotesAdminUsersUserIdNotesGetQueryKey({ userId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>> = ({ signal }) => getUserNotesAdminUsersUserIdNotesGet({ userId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserNotesAdminUsersUserIdNotesGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>>
export type GetUserNotesAdminUsersUserIdNotesGetInfiniteQueryError = GetUserNotesAdminUsersUserIdNotesGetErrorType<HTTPValidationError>

/**
 * @summary Get User Notes
 */
export const useGetUserNotesAdminUsersUserIdNotesGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>>, TError = GetUserNotesAdminUsersUserIdNotesGetErrorType<HTTPValidationError>>(
 { userId }: GetUserNotesAdminUsersUserIdNotesGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserNotesAdminUsersUserIdNotesGetInfiniteQueryOptions({ userId },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserNotesAdminUsersUserIdNotesGetQueryOptions = <TData = Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError = GetUserNotesAdminUsersUserIdNotesGetErrorType<HTTPValidationError>>({ userId }: GetUserNotesAdminUsersUserIdNotesGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserNotesAdminUsersUserIdNotesGetQueryKey({ userId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>> = ({ signal }) => getUserNotesAdminUsersUserIdNotesGet({ userId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserNotesAdminUsersUserIdNotesGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>>
export type GetUserNotesAdminUsersUserIdNotesGetQueryError = GetUserNotesAdminUsersUserIdNotesGetErrorType<HTTPValidationError>

/**
 * @summary Get User Notes
 */
export const useGetUserNotesAdminUsersUserIdNotesGet = <TData = Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError = GetUserNotesAdminUsersUserIdNotesGetErrorType<HTTPValidationError>>(
 { userId }: GetUserNotesAdminUsersUserIdNotesGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserNotesAdminUsersUserIdNotesGetQueryOptions({ userId },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserNotesAdminUsersUserIdNotesGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError = GetUserNotesAdminUsersUserIdNotesGetErrorType<HTTPValidationError>>({ userId }: GetUserNotesAdminUsersUserIdNotesGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserNotesAdminUsersUserIdNotesGetQueryKey({ userId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>> = ({ signal }) => getUserNotesAdminUsersUserIdNotesGet({ userId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserNotesAdminUsersUserIdNotesGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>>
export type GetUserNotesAdminUsersUserIdNotesGetSuspenseQueryError = GetUserNotesAdminUsersUserIdNotesGetErrorType<HTTPValidationError>

/**
 * @summary Get User Notes
 */
export const useGetUserNotesAdminUsersUserIdNotesGetSuspense = <TData = Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError = GetUserNotesAdminUsersUserIdNotesGetErrorType<HTTPValidationError>>(
 { userId }: GetUserNotesAdminUsersUserIdNotesGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserNotesAdminUsersUserIdNotesGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserNotesAdminUsersUserIdNotesGetSuspenseQueryOptions({ userId },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create User Note
 */
export const createUserNoteAdminUsersUserIdNotesPost = (
    { userId }: CreateUserNoteAdminUsersUserIdNotesPostPathParameters,
    userNoteRequest: UserNoteRequest,
 ) => {
      
      
      return createUserNoteAdminUsersUserIdNotesPostMutator<UserNoteData>(
      {url: `/admin/users/${userId}/notes`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userNoteRequest
    },
      );
    }
  


export const getCreateUserNoteAdminUsersUserIdNotesPostMutationOptions = <TError = CreateUserNoteAdminUsersUserIdNotesPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUserNoteAdminUsersUserIdNotesPost>>, TError,{pathParams: CreateUserNoteAdminUsersUserIdNotesPostPathParameters;data: UserNoteRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createUserNoteAdminUsersUserIdNotesPost>>, TError,{pathParams: CreateUserNoteAdminUsersUserIdNotesPostPathParameters;data: UserNoteRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUserNoteAdminUsersUserIdNotesPost>>, {pathParams: CreateUserNoteAdminUsersUserIdNotesPostPathParameters;data: UserNoteRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  createUserNoteAdminUsersUserIdNotesPost(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateUserNoteAdminUsersUserIdNotesPostMutationResult = NonNullable<Awaited<ReturnType<typeof createUserNoteAdminUsersUserIdNotesPost>>>
    export type CreateUserNoteAdminUsersUserIdNotesPostMutationBody = UserNoteRequest
    export type CreateUserNoteAdminUsersUserIdNotesPostMutationError = CreateUserNoteAdminUsersUserIdNotesPostErrorType<HTTPValidationError>

    /**
 * @summary Create User Note
 */
export const useCreateUserNoteAdminUsersUserIdNotesPost = <TError = CreateUserNoteAdminUsersUserIdNotesPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUserNoteAdminUsersUserIdNotesPost>>, TError,{pathParams: CreateUserNoteAdminUsersUserIdNotesPostPathParameters;data: UserNoteRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createUserNoteAdminUsersUserIdNotesPost>>,
        TError,
        {pathParams: CreateUserNoteAdminUsersUserIdNotesPostPathParameters;data: UserNoteRequest},
        TContext
      > => {

      const mutationOptions = getCreateUserNoteAdminUsersUserIdNotesPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update User Note
 */
export const updateUserNoteAdminUsersUserIdNotesNoteIdPatch = (
    { userId, noteId }: UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchPathParameters,
    userNoteRequest: UserNoteRequest,
 ) => {
      
      
      return updateUserNoteAdminUsersUserIdNotesNoteIdPatchMutator<UserNoteData>(
      {url: `/admin/users/${userId}/notes/${noteId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: userNoteRequest
    },
      );
    }
  


export const getUpdateUserNoteAdminUsersUserIdNotesNoteIdPatchMutationOptions = <TError = UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserNoteAdminUsersUserIdNotesNoteIdPatch>>, TError,{pathParams: UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchPathParameters;data: UserNoteRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateUserNoteAdminUsersUserIdNotesNoteIdPatch>>, TError,{pathParams: UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchPathParameters;data: UserNoteRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserNoteAdminUsersUserIdNotesNoteIdPatch>>, {pathParams: UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchPathParameters;data: UserNoteRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  updateUserNoteAdminUsersUserIdNotesNoteIdPatch(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserNoteAdminUsersUserIdNotesNoteIdPatch>>>
    export type UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchMutationBody = UserNoteRequest
    export type UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchMutationError = UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchErrorType<HTTPValidationError>

    /**
 * @summary Update User Note
 */
export const useUpdateUserNoteAdminUsersUserIdNotesNoteIdPatch = <TError = UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserNoteAdminUsersUserIdNotesNoteIdPatch>>, TError,{pathParams: UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchPathParameters;data: UserNoteRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateUserNoteAdminUsersUserIdNotesNoteIdPatch>>,
        TError,
        {pathParams: UpdateUserNoteAdminUsersUserIdNotesNoteIdPatchPathParameters;data: UserNoteRequest},
        TContext
      > => {

      const mutationOptions = getUpdateUserNoteAdminUsersUserIdNotesNoteIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete User Note
 */
export const deleteUserNoteAdminUsersUserIdNotesNoteIdDelete = (
    { userId, noteId }: DeleteUserNoteAdminUsersUserIdNotesNoteIdDeletePathParameters,
 ) => {
      
      
      return deleteUserNoteAdminUsersUserIdNotesNoteIdDeleteMutator<unknown>(
      {url: `/admin/users/${userId}/notes/${noteId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteUserNoteAdminUsersUserIdNotesNoteIdDeleteMutationOptions = <TError = DeleteUserNoteAdminUsersUserIdNotesNoteIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserNoteAdminUsersUserIdNotesNoteIdDelete>>, TError,{pathParams: DeleteUserNoteAdminUsersUserIdNotesNoteIdDeletePathParameters}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteUserNoteAdminUsersUserIdNotesNoteIdDelete>>, TError,{pathParams: DeleteUserNoteAdminUsersUserIdNotesNoteIdDeletePathParameters}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserNoteAdminUsersUserIdNotesNoteIdDelete>>, {pathParams: DeleteUserNoteAdminUsersUserIdNotesNoteIdDeletePathParameters}> = (props) => {
          const {pathParams} = props ?? {};

          return  deleteUserNoteAdminUsersUserIdNotesNoteIdDelete(pathParams,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteUserNoteAdminUsersUserIdNotesNoteIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserNoteAdminUsersUserIdNotesNoteIdDelete>>>
    
    export type DeleteUserNoteAdminUsersUserIdNotesNoteIdDeleteMutationError = DeleteUserNoteAdminUsersUserIdNotesNoteIdDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete User Note
 */
export const useDeleteUserNoteAdminUsersUserIdNotesNoteIdDelete = <TError = DeleteUserNoteAdminUsersUserIdNotesNoteIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserNoteAdminUsersUserIdNotesNoteIdDelete>>, TError,{pathParams: DeleteUserNoteAdminUsersUserIdNotesNoteIdDeletePathParameters}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteUserNoteAdminUsersUserIdNotesNoteIdDelete>>,
        TError,
        {pathParams: DeleteUserNoteAdminUsersUserIdNotesNoteIdDeletePathParameters},
        TContext
      > => {

      const mutationOptions = getDeleteUserNoteAdminUsersUserIdNotesNoteIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Chat History By User
 */
export const getChatHistoryByUserAdminUsersUserIdChatHistoryGet = (
    { userId }: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams,
 signal?: AbortSignal
) => {
      
      
      return getChatHistoryByUserAdminUsersUserIdChatHistoryGetMutator<BizMessage[]>(
      {url: `/admin/users/${userId}/chat/history`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetChatHistoryByUserAdminUsersUserIdChatHistoryGetQueryKey = ({ userId }: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams,) => {
    return [`/admin/users/${userId}/chat/history`, ...(params ? [params]: [])] as const;
    }

    
export const getGetChatHistoryByUserAdminUsersUserIdChatHistoryGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams['after']>, TError = GetChatHistoryByUserAdminUsersUserIdChatHistoryGetErrorType<HTTPValidationError>>({ userId }: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError, TData, Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, QueryKey, GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatHistoryByUserAdminUsersUserIdChatHistoryGetQueryKey({ userId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, QueryKey, GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams['after']> = ({ signal, pageParam }) => getChatHistoryByUserAdminUsersUserIdChatHistoryGet({ userId },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError, TData, Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, QueryKey, GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams['after']> & { queryKey: QueryKey }
}

export type GetChatHistoryByUserAdminUsersUserIdChatHistoryGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>>
export type GetChatHistoryByUserAdminUsersUserIdChatHistoryGetInfiniteQueryError = GetChatHistoryByUserAdminUsersUserIdChatHistoryGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat History By User
 */
export const useGetChatHistoryByUserAdminUsersUserIdChatHistoryGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams['after']>, TError = GetChatHistoryByUserAdminUsersUserIdChatHistoryGetErrorType<HTTPValidationError>>(
 { userId }: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError, TData, Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, QueryKey, GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatHistoryByUserAdminUsersUserIdChatHistoryGetInfiniteQueryOptions({ userId },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatHistoryByUserAdminUsersUserIdChatHistoryGetQueryOptions = <TData = Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError = GetChatHistoryByUserAdminUsersUserIdChatHistoryGetErrorType<HTTPValidationError>>({ userId }: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatHistoryByUserAdminUsersUserIdChatHistoryGetQueryKey({ userId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>> = ({ signal }) => getChatHistoryByUserAdminUsersUserIdChatHistoryGet({ userId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatHistoryByUserAdminUsersUserIdChatHistoryGetQueryResult = NonNullable<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>>
export type GetChatHistoryByUserAdminUsersUserIdChatHistoryGetQueryError = GetChatHistoryByUserAdminUsersUserIdChatHistoryGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat History By User
 */
export const useGetChatHistoryByUserAdminUsersUserIdChatHistoryGet = <TData = Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError = GetChatHistoryByUserAdminUsersUserIdChatHistoryGetErrorType<HTTPValidationError>>(
 { userId }: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatHistoryByUserAdminUsersUserIdChatHistoryGetQueryOptions({ userId },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatHistoryByUserAdminUsersUserIdChatHistoryGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError = GetChatHistoryByUserAdminUsersUserIdChatHistoryGetErrorType<HTTPValidationError>>({ userId }: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatHistoryByUserAdminUsersUserIdChatHistoryGetQueryKey({ userId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>> = ({ signal }) => getChatHistoryByUserAdminUsersUserIdChatHistoryGet({ userId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatHistoryByUserAdminUsersUserIdChatHistoryGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>>
export type GetChatHistoryByUserAdminUsersUserIdChatHistoryGetSuspenseQueryError = GetChatHistoryByUserAdminUsersUserIdChatHistoryGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat History By User
 */
export const useGetChatHistoryByUserAdminUsersUserIdChatHistoryGetSuspense = <TData = Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError = GetChatHistoryByUserAdminUsersUserIdChatHistoryGetErrorType<HTTPValidationError>>(
 { userId }: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByUserAdminUsersUserIdChatHistoryGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByUserAdminUsersUserIdChatHistoryGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatHistoryByUserAdminUsersUserIdChatHistoryGetSuspenseQueryOptions({ userId },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Chat Stats By User
 */
export const getChatStatsByUserAdminUsersUserIdChatStatsGet = (
    { userId }: GetChatStatsByUserAdminUsersUserIdChatStatsGetPathParameters,
    params: GetChatStatsByUserAdminUsersUserIdChatStatsGetParams,
 signal?: AbortSignal
) => {
      
      
      return getChatStatsByUserAdminUsersUserIdChatStatsGetMutator<SessionsDateRangeStats>(
      {url: `/admin/users/${userId}/chat/stats`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetChatStatsByUserAdminUsersUserIdChatStatsGetQueryKey = ({ userId }: GetChatStatsByUserAdminUsersUserIdChatStatsGetPathParameters,
    params: GetChatStatsByUserAdminUsersUserIdChatStatsGetParams,) => {
    return [`/admin/users/${userId}/chat/stats`, ...(params ? [params]: [])] as const;
    }

    
export const getGetChatStatsByUserAdminUsersUserIdChatStatsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, GetChatStatsByUserAdminUsersUserIdChatStatsGetParams['after']>, TError = GetChatStatsByUserAdminUsersUserIdChatStatsGetErrorType<HTTPValidationError>>({ userId }: GetChatStatsByUserAdminUsersUserIdChatStatsGetPathParameters,
    params: GetChatStatsByUserAdminUsersUserIdChatStatsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError, TData, Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, QueryKey, GetChatStatsByUserAdminUsersUserIdChatStatsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatStatsByUserAdminUsersUserIdChatStatsGetQueryKey({ userId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, QueryKey, GetChatStatsByUserAdminUsersUserIdChatStatsGetParams['after']> = ({ signal, pageParam }) => getChatStatsByUserAdminUsersUserIdChatStatsGet({ userId },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError, TData, Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, QueryKey, GetChatStatsByUserAdminUsersUserIdChatStatsGetParams['after']> & { queryKey: QueryKey }
}

export type GetChatStatsByUserAdminUsersUserIdChatStatsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>>
export type GetChatStatsByUserAdminUsersUserIdChatStatsGetInfiniteQueryError = GetChatStatsByUserAdminUsersUserIdChatStatsGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat Stats By User
 */
export const useGetChatStatsByUserAdminUsersUserIdChatStatsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, GetChatStatsByUserAdminUsersUserIdChatStatsGetParams['after']>, TError = GetChatStatsByUserAdminUsersUserIdChatStatsGetErrorType<HTTPValidationError>>(
 { userId }: GetChatStatsByUserAdminUsersUserIdChatStatsGetPathParameters,
    params: GetChatStatsByUserAdminUsersUserIdChatStatsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError, TData, Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, QueryKey, GetChatStatsByUserAdminUsersUserIdChatStatsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatStatsByUserAdminUsersUserIdChatStatsGetInfiniteQueryOptions({ userId },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatStatsByUserAdminUsersUserIdChatStatsGetQueryOptions = <TData = Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError = GetChatStatsByUserAdminUsersUserIdChatStatsGetErrorType<HTTPValidationError>>({ userId }: GetChatStatsByUserAdminUsersUserIdChatStatsGetPathParameters,
    params: GetChatStatsByUserAdminUsersUserIdChatStatsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatStatsByUserAdminUsersUserIdChatStatsGetQueryKey({ userId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>> = ({ signal }) => getChatStatsByUserAdminUsersUserIdChatStatsGet({ userId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatStatsByUserAdminUsersUserIdChatStatsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>>
export type GetChatStatsByUserAdminUsersUserIdChatStatsGetQueryError = GetChatStatsByUserAdminUsersUserIdChatStatsGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat Stats By User
 */
export const useGetChatStatsByUserAdminUsersUserIdChatStatsGet = <TData = Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError = GetChatStatsByUserAdminUsersUserIdChatStatsGetErrorType<HTTPValidationError>>(
 { userId }: GetChatStatsByUserAdminUsersUserIdChatStatsGetPathParameters,
    params: GetChatStatsByUserAdminUsersUserIdChatStatsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatStatsByUserAdminUsersUserIdChatStatsGetQueryOptions({ userId },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatStatsByUserAdminUsersUserIdChatStatsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError = GetChatStatsByUserAdminUsersUserIdChatStatsGetErrorType<HTTPValidationError>>({ userId }: GetChatStatsByUserAdminUsersUserIdChatStatsGetPathParameters,
    params: GetChatStatsByUserAdminUsersUserIdChatStatsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatStatsByUserAdminUsersUserIdChatStatsGetQueryKey({ userId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>> = ({ signal }) => getChatStatsByUserAdminUsersUserIdChatStatsGet({ userId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatStatsByUserAdminUsersUserIdChatStatsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>>
export type GetChatStatsByUserAdminUsersUserIdChatStatsGetSuspenseQueryError = GetChatStatsByUserAdminUsersUserIdChatStatsGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat Stats By User
 */
export const useGetChatStatsByUserAdminUsersUserIdChatStatsGetSuspense = <TData = Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError = GetChatStatsByUserAdminUsersUserIdChatStatsGetErrorType<HTTPValidationError>>(
 { userId }: GetChatStatsByUserAdminUsersUserIdChatStatsGetPathParameters,
    params: GetChatStatsByUserAdminUsersUserIdChatStatsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatStatsByUserAdminUsersUserIdChatStatsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatStatsByUserAdminUsersUserIdChatStatsGetSuspenseQueryOptions({ userId },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get User Events
 */
export const getUserEventsAdminUsersUserIdEventsGet = (
    { userId }: GetUserEventsAdminUsersUserIdEventsGetPathParameters,
    params?: GetUserEventsAdminUsersUserIdEventsGetParams,
 signal?: AbortSignal
) => {
      
      
      return getUserEventsAdminUsersUserIdEventsGetMutator<UserEventInfo[]>(
      {url: `/admin/users/${userId}/events`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetUserEventsAdminUsersUserIdEventsGetQueryKey = ({ userId }: GetUserEventsAdminUsersUserIdEventsGetPathParameters,
    params?: GetUserEventsAdminUsersUserIdEventsGetParams,) => {
    return [`/admin/users/${userId}/events`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserEventsAdminUsersUserIdEventsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, GetUserEventsAdminUsersUserIdEventsGetParams['after']>, TError = GetUserEventsAdminUsersUserIdEventsGetErrorType<HTTPValidationError>>({ userId }: GetUserEventsAdminUsersUserIdEventsGetPathParameters,
    params?: GetUserEventsAdminUsersUserIdEventsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError, TData, Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, QueryKey, GetUserEventsAdminUsersUserIdEventsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserEventsAdminUsersUserIdEventsGetQueryKey({ userId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, QueryKey, GetUserEventsAdminUsersUserIdEventsGetParams['after']> = ({ signal, pageParam }) => getUserEventsAdminUsersUserIdEventsGet({ userId },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError, TData, Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, QueryKey, GetUserEventsAdminUsersUserIdEventsGetParams['after']> & { queryKey: QueryKey }
}

export type GetUserEventsAdminUsersUserIdEventsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>>
export type GetUserEventsAdminUsersUserIdEventsGetInfiniteQueryError = GetUserEventsAdminUsersUserIdEventsGetErrorType<HTTPValidationError>

/**
 * @summary Get User Events
 */
export const useGetUserEventsAdminUsersUserIdEventsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, GetUserEventsAdminUsersUserIdEventsGetParams['after']>, TError = GetUserEventsAdminUsersUserIdEventsGetErrorType<HTTPValidationError>>(
 { userId }: GetUserEventsAdminUsersUserIdEventsGetPathParameters,
    params?: GetUserEventsAdminUsersUserIdEventsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError, TData, Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, QueryKey, GetUserEventsAdminUsersUserIdEventsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserEventsAdminUsersUserIdEventsGetInfiniteQueryOptions({ userId },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserEventsAdminUsersUserIdEventsGetQueryOptions = <TData = Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError = GetUserEventsAdminUsersUserIdEventsGetErrorType<HTTPValidationError>>({ userId }: GetUserEventsAdminUsersUserIdEventsGetPathParameters,
    params?: GetUserEventsAdminUsersUserIdEventsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserEventsAdminUsersUserIdEventsGetQueryKey({ userId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>> = ({ signal }) => getUserEventsAdminUsersUserIdEventsGet({ userId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserEventsAdminUsersUserIdEventsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>>
export type GetUserEventsAdminUsersUserIdEventsGetQueryError = GetUserEventsAdminUsersUserIdEventsGetErrorType<HTTPValidationError>

/**
 * @summary Get User Events
 */
export const useGetUserEventsAdminUsersUserIdEventsGet = <TData = Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError = GetUserEventsAdminUsersUserIdEventsGetErrorType<HTTPValidationError>>(
 { userId }: GetUserEventsAdminUsersUserIdEventsGetPathParameters,
    params?: GetUserEventsAdminUsersUserIdEventsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserEventsAdminUsersUserIdEventsGetQueryOptions({ userId },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserEventsAdminUsersUserIdEventsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError = GetUserEventsAdminUsersUserIdEventsGetErrorType<HTTPValidationError>>({ userId }: GetUserEventsAdminUsersUserIdEventsGetPathParameters,
    params?: GetUserEventsAdminUsersUserIdEventsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserEventsAdminUsersUserIdEventsGetQueryKey({ userId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>> = ({ signal }) => getUserEventsAdminUsersUserIdEventsGet({ userId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserEventsAdminUsersUserIdEventsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>>
export type GetUserEventsAdminUsersUserIdEventsGetSuspenseQueryError = GetUserEventsAdminUsersUserIdEventsGetErrorType<HTTPValidationError>

/**
 * @summary Get User Events
 */
export const useGetUserEventsAdminUsersUserIdEventsGetSuspense = <TData = Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError = GetUserEventsAdminUsersUserIdEventsGetErrorType<HTTPValidationError>>(
 { userId }: GetUserEventsAdminUsersUserIdEventsGetPathParameters,
    params?: GetUserEventsAdminUsersUserIdEventsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserEventsAdminUsersUserIdEventsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserEventsAdminUsersUserIdEventsGetSuspenseQueryOptions({ userId },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Cancel Chat
 */
export const cancelChatAdminChatCancelPost = (
    
 ) => {
      
      
      return cancelChatAdminChatCancelPostMutator<MessageResponse>(
      {url: `/admin/chat/cancel`, method: 'POST'
    },
      );
    }
  


export const getCancelChatAdminChatCancelPostMutationOptions = <TError = CancelChatAdminChatCancelPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelChatAdminChatCancelPost>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof cancelChatAdminChatCancelPost>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelChatAdminChatCancelPost>>, void> = () => {
          

          return  cancelChatAdminChatCancelPost()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CancelChatAdminChatCancelPostMutationResult = NonNullable<Awaited<ReturnType<typeof cancelChatAdminChatCancelPost>>>
    
    export type CancelChatAdminChatCancelPostMutationError = CancelChatAdminChatCancelPostErrorType<unknown>

    /**
 * @summary Cancel Chat
 */
export const useCancelChatAdminChatCancelPost = <TError = CancelChatAdminChatCancelPostErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelChatAdminChatCancelPost>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof cancelChatAdminChatCancelPost>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getCancelChatAdminChatCancelPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Chat With Bot
 */
export const chatWithBotAdminChatPost = (
    chatRequest: ChatRequest,
 ) => {
      
      
      return chatWithBotAdminChatPostMutator<MessageResponse>(
      {url: `/admin/chat`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: chatRequest
    },
      );
    }
  


export const getChatWithBotAdminChatPostMutationOptions = <TError = ChatWithBotAdminChatPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof chatWithBotAdminChatPost>>, TError,{data: ChatRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof chatWithBotAdminChatPost>>, TError,{data: ChatRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof chatWithBotAdminChatPost>>, {data: ChatRequest}> = (props) => {
          const {data} = props ?? {};

          return  chatWithBotAdminChatPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChatWithBotAdminChatPostMutationResult = NonNullable<Awaited<ReturnType<typeof chatWithBotAdminChatPost>>>
    export type ChatWithBotAdminChatPostMutationBody = ChatRequest
    export type ChatWithBotAdminChatPostMutationError = ChatWithBotAdminChatPostErrorType<HTTPValidationError>

    /**
 * @summary Chat With Bot
 */
export const useChatWithBotAdminChatPost = <TError = ChatWithBotAdminChatPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof chatWithBotAdminChatPost>>, TError,{data: ChatRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof chatWithBotAdminChatPost>>,
        TError,
        {data: ChatRequest},
        TContext
      > => {

      const mutationOptions = getChatWithBotAdminChatPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Chat Messages
 */
export const deleteChatMessagesAdminChatHistoryDelete = (
    
 ) => {
      
      
      return deleteChatMessagesAdminChatHistoryDeleteMutator<unknown>(
      {url: `/admin/chat/history`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteChatMessagesAdminChatHistoryDeleteMutationOptions = <TError = DeleteChatMessagesAdminChatHistoryDeleteErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteChatMessagesAdminChatHistoryDelete>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteChatMessagesAdminChatHistoryDelete>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteChatMessagesAdminChatHistoryDelete>>, void> = () => {
          

          return  deleteChatMessagesAdminChatHistoryDelete()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteChatMessagesAdminChatHistoryDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteChatMessagesAdminChatHistoryDelete>>>
    
    export type DeleteChatMessagesAdminChatHistoryDeleteMutationError = DeleteChatMessagesAdminChatHistoryDeleteErrorType<unknown>

    /**
 * @summary Delete Chat Messages
 */
export const useDeleteChatMessagesAdminChatHistoryDelete = <TError = DeleteChatMessagesAdminChatHistoryDeleteErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteChatMessagesAdminChatHistoryDelete>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteChatMessagesAdminChatHistoryDelete>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDeleteChatMessagesAdminChatHistoryDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Chat History
 */
export const getChatHistoryAdminChatHistoryGet = (
    params?: GetChatHistoryAdminChatHistoryGetParams,
 signal?: AbortSignal
) => {
      
      
      return getChatHistoryAdminChatHistoryGetMutator<ChatHistoryResponse>(
      {url: `/admin/chat/history`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetChatHistoryAdminChatHistoryGetQueryKey = (params?: GetChatHistoryAdminChatHistoryGetParams,) => {
    return [`/admin/chat/history`, ...(params ? [params]: [])] as const;
    }

    
export const getGetChatHistoryAdminChatHistoryGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, GetChatHistoryAdminChatHistoryGetParams['after']>, TError = GetChatHistoryAdminChatHistoryGetErrorType<HTTPValidationError>>(params?: GetChatHistoryAdminChatHistoryGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError, TData, Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, QueryKey, GetChatHistoryAdminChatHistoryGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatHistoryAdminChatHistoryGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, QueryKey, GetChatHistoryAdminChatHistoryGetParams['after']> = ({ signal, pageParam }) => getChatHistoryAdminChatHistoryGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError, TData, Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, QueryKey, GetChatHistoryAdminChatHistoryGetParams['after']> & { queryKey: QueryKey }
}

export type GetChatHistoryAdminChatHistoryGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>>
export type GetChatHistoryAdminChatHistoryGetInfiniteQueryError = GetChatHistoryAdminChatHistoryGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat History
 */
export const useGetChatHistoryAdminChatHistoryGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, GetChatHistoryAdminChatHistoryGetParams['after']>, TError = GetChatHistoryAdminChatHistoryGetErrorType<HTTPValidationError>>(
 params?: GetChatHistoryAdminChatHistoryGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError, TData, Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, QueryKey, GetChatHistoryAdminChatHistoryGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatHistoryAdminChatHistoryGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatHistoryAdminChatHistoryGetQueryOptions = <TData = Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError = GetChatHistoryAdminChatHistoryGetErrorType<HTTPValidationError>>(params?: GetChatHistoryAdminChatHistoryGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatHistoryAdminChatHistoryGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>> = ({ signal }) => getChatHistoryAdminChatHistoryGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatHistoryAdminChatHistoryGetQueryResult = NonNullable<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>>
export type GetChatHistoryAdminChatHistoryGetQueryError = GetChatHistoryAdminChatHistoryGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat History
 */
export const useGetChatHistoryAdminChatHistoryGet = <TData = Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError = GetChatHistoryAdminChatHistoryGetErrorType<HTTPValidationError>>(
 params?: GetChatHistoryAdminChatHistoryGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatHistoryAdminChatHistoryGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatHistoryAdminChatHistoryGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError = GetChatHistoryAdminChatHistoryGetErrorType<HTTPValidationError>>(params?: GetChatHistoryAdminChatHistoryGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatHistoryAdminChatHistoryGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>> = ({ signal }) => getChatHistoryAdminChatHistoryGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatHistoryAdminChatHistoryGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>>
export type GetChatHistoryAdminChatHistoryGetSuspenseQueryError = GetChatHistoryAdminChatHistoryGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat History
 */
export const useGetChatHistoryAdminChatHistoryGetSuspense = <TData = Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError = GetChatHistoryAdminChatHistoryGetErrorType<HTTPValidationError>>(
 params?: GetChatHistoryAdminChatHistoryGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatHistoryAdminChatHistoryGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatHistoryAdminChatHistoryGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Dry Run Agent
 */
export const dryRunAgentAdminAgentsRunPost = (
    experimentRequest: ExperimentRequest,
 ) => {
      
      
      return dryRunAgentAdminAgentsRunPostMutator<AgentRunTaskResult>(
      {url: `/admin/agents/run`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: experimentRequest
    },
      );
    }
  


export const getDryRunAgentAdminAgentsRunPostMutationOptions = <TError = DryRunAgentAdminAgentsRunPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dryRunAgentAdminAgentsRunPost>>, TError,{data: ExperimentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof dryRunAgentAdminAgentsRunPost>>, TError,{data: ExperimentRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dryRunAgentAdminAgentsRunPost>>, {data: ExperimentRequest}> = (props) => {
          const {data} = props ?? {};

          return  dryRunAgentAdminAgentsRunPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DryRunAgentAdminAgentsRunPostMutationResult = NonNullable<Awaited<ReturnType<typeof dryRunAgentAdminAgentsRunPost>>>
    export type DryRunAgentAdminAgentsRunPostMutationBody = ExperimentRequest
    export type DryRunAgentAdminAgentsRunPostMutationError = DryRunAgentAdminAgentsRunPostErrorType<HTTPValidationError>

    /**
 * @summary Dry Run Agent
 */
export const useDryRunAgentAdminAgentsRunPost = <TError = DryRunAgentAdminAgentsRunPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dryRunAgentAdminAgentsRunPost>>, TError,{data: ExperimentRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof dryRunAgentAdminAgentsRunPost>>,
        TError,
        {data: ExperimentRequest},
        TContext
      > => {

      const mutationOptions = getDryRunAgentAdminAgentsRunPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dump Full Workflow Config
 */
export const dumpFullWorkflowConfigAdminWorkflowsDumpGet = (
    workflowFullRequest: WorkflowFullRequest,
 signal?: AbortSignal
) => {
      
      
      return dumpFullWorkflowConfigAdminWorkflowsDumpGetMutator<unknown>(
      {url: `/admin/workflows/dump`, method: 'GET',
      headers: {'Content-Type': 'application/json', }, signal
    },
      );
    }
  

export const getDumpFullWorkflowConfigAdminWorkflowsDumpGetQueryKey = (workflowFullRequest: WorkflowFullRequest,) => {
    return [`/admin/workflows/dump`, workflowFullRequest] as const;
    }

    
export const getDumpFullWorkflowConfigAdminWorkflowsDumpGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>>, TError = DumpFullWorkflowConfigAdminWorkflowsDumpGetErrorType<HTTPValidationError>>(workflowFullRequest: WorkflowFullRequest, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDumpFullWorkflowConfigAdminWorkflowsDumpGetQueryKey(workflowFullRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>> = ({ signal }) => dumpFullWorkflowConfigAdminWorkflowsDumpGet(workflowFullRequest, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError, TData> & { queryKey: QueryKey }
}

export type DumpFullWorkflowConfigAdminWorkflowsDumpGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>>
export type DumpFullWorkflowConfigAdminWorkflowsDumpGetInfiniteQueryError = DumpFullWorkflowConfigAdminWorkflowsDumpGetErrorType<HTTPValidationError>

/**
 * @summary Dump Full Workflow Config
 */
export const useDumpFullWorkflowConfigAdminWorkflowsDumpGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>>, TError = DumpFullWorkflowConfigAdminWorkflowsDumpGetErrorType<HTTPValidationError>>(
 workflowFullRequest: WorkflowFullRequest, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDumpFullWorkflowConfigAdminWorkflowsDumpGetInfiniteQueryOptions(workflowFullRequest,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDumpFullWorkflowConfigAdminWorkflowsDumpGetQueryOptions = <TData = Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError = DumpFullWorkflowConfigAdminWorkflowsDumpGetErrorType<HTTPValidationError>>(workflowFullRequest: WorkflowFullRequest, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDumpFullWorkflowConfigAdminWorkflowsDumpGetQueryKey(workflowFullRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>> = ({ signal }) => dumpFullWorkflowConfigAdminWorkflowsDumpGet(workflowFullRequest, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError, TData> & { queryKey: QueryKey }
}

export type DumpFullWorkflowConfigAdminWorkflowsDumpGetQueryResult = NonNullable<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>>
export type DumpFullWorkflowConfigAdminWorkflowsDumpGetQueryError = DumpFullWorkflowConfigAdminWorkflowsDumpGetErrorType<HTTPValidationError>

/**
 * @summary Dump Full Workflow Config
 */
export const useDumpFullWorkflowConfigAdminWorkflowsDumpGet = <TData = Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError = DumpFullWorkflowConfigAdminWorkflowsDumpGetErrorType<HTTPValidationError>>(
 workflowFullRequest: WorkflowFullRequest, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDumpFullWorkflowConfigAdminWorkflowsDumpGetQueryOptions(workflowFullRequest,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDumpFullWorkflowConfigAdminWorkflowsDumpGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError = DumpFullWorkflowConfigAdminWorkflowsDumpGetErrorType<HTTPValidationError>>(workflowFullRequest: WorkflowFullRequest, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDumpFullWorkflowConfigAdminWorkflowsDumpGetQueryKey(workflowFullRequest);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>> = ({ signal }) => dumpFullWorkflowConfigAdminWorkflowsDumpGet(workflowFullRequest, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError, TData> & { queryKey: QueryKey }
}

export type DumpFullWorkflowConfigAdminWorkflowsDumpGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>>
export type DumpFullWorkflowConfigAdminWorkflowsDumpGetSuspenseQueryError = DumpFullWorkflowConfigAdminWorkflowsDumpGetErrorType<HTTPValidationError>

/**
 * @summary Dump Full Workflow Config
 */
export const useDumpFullWorkflowConfigAdminWorkflowsDumpGetSuspense = <TData = Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError = DumpFullWorkflowConfigAdminWorkflowsDumpGetErrorType<HTTPValidationError>>(
 workflowFullRequest: WorkflowFullRequest, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof dumpFullWorkflowConfigAdminWorkflowsDumpGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDumpFullWorkflowConfigAdminWorkflowsDumpGetSuspenseQueryOptions(workflowFullRequest,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Dry Run Workflow
 */
export const dryRunWorkflowAdminWorkflowsRunPost = (
    workflowFlowDebugRequest: WorkflowFlowDebugRequest,
 ) => {
      
      
      return dryRunWorkflowAdminWorkflowsRunPostMutator<WorkflowDebugResult>(
      {url: `/admin/workflows/run`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowFlowDebugRequest
    },
      );
    }
  


export const getDryRunWorkflowAdminWorkflowsRunPostMutationOptions = <TError = DryRunWorkflowAdminWorkflowsRunPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dryRunWorkflowAdminWorkflowsRunPost>>, TError,{data: WorkflowFlowDebugRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof dryRunWorkflowAdminWorkflowsRunPost>>, TError,{data: WorkflowFlowDebugRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dryRunWorkflowAdminWorkflowsRunPost>>, {data: WorkflowFlowDebugRequest}> = (props) => {
          const {data} = props ?? {};

          return  dryRunWorkflowAdminWorkflowsRunPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DryRunWorkflowAdminWorkflowsRunPostMutationResult = NonNullable<Awaited<ReturnType<typeof dryRunWorkflowAdminWorkflowsRunPost>>>
    export type DryRunWorkflowAdminWorkflowsRunPostMutationBody = WorkflowFlowDebugRequest
    export type DryRunWorkflowAdminWorkflowsRunPostMutationError = DryRunWorkflowAdminWorkflowsRunPostErrorType<HTTPValidationError>

    /**
 * @summary Dry Run Workflow
 */
export const useDryRunWorkflowAdminWorkflowsRunPost = <TError = DryRunWorkflowAdminWorkflowsRunPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dryRunWorkflowAdminWorkflowsRunPost>>, TError,{data: WorkflowFlowDebugRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof dryRunWorkflowAdminWorkflowsRunPost>>,
        TError,
        {data: WorkflowFlowDebugRequest},
        TContext
      > => {

      const mutationOptions = getDryRunWorkflowAdminWorkflowsRunPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Labels
 */
export const listLabelsAdminMessagesIdLabelsGet = (
    { id }: ListLabelsAdminMessagesIdLabelsGetPathParameters,
    params?: ListLabelsAdminMessagesIdLabelsGetParams,
 signal?: AbortSignal
) => {
      
      
      return listLabelsAdminMessagesIdLabelsGetMutator<DashLabel[]>(
      {url: `/admin/messages/${id}/labels`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListLabelsAdminMessagesIdLabelsGetQueryKey = ({ id }: ListLabelsAdminMessagesIdLabelsGetPathParameters,
    params?: ListLabelsAdminMessagesIdLabelsGetParams,) => {
    return [`/admin/messages/${id}/labels`, ...(params ? [params]: [])] as const;
    }

    
export const getListLabelsAdminMessagesIdLabelsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, ListLabelsAdminMessagesIdLabelsGetParams['after']>, TError = ListLabelsAdminMessagesIdLabelsGetErrorType<HTTPValidationError>>({ id }: ListLabelsAdminMessagesIdLabelsGetPathParameters,
    params?: ListLabelsAdminMessagesIdLabelsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, QueryKey, ListLabelsAdminMessagesIdLabelsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminMessagesIdLabelsGetQueryKey({ id },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, QueryKey, ListLabelsAdminMessagesIdLabelsGetParams['after']> = ({ signal, pageParam }) => listLabelsAdminMessagesIdLabelsGet({ id },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, QueryKey, ListLabelsAdminMessagesIdLabelsGetParams['after']> & { queryKey: QueryKey }
}

export type ListLabelsAdminMessagesIdLabelsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>>
export type ListLabelsAdminMessagesIdLabelsGetInfiniteQueryError = ListLabelsAdminMessagesIdLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminMessagesIdLabelsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, ListLabelsAdminMessagesIdLabelsGetParams['after']>, TError = ListLabelsAdminMessagesIdLabelsGetErrorType<HTTPValidationError>>(
 { id }: ListLabelsAdminMessagesIdLabelsGetPathParameters,
    params?: ListLabelsAdminMessagesIdLabelsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError, TData, Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, QueryKey, ListLabelsAdminMessagesIdLabelsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminMessagesIdLabelsGetInfiniteQueryOptions({ id },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminMessagesIdLabelsGetQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError = ListLabelsAdminMessagesIdLabelsGetErrorType<HTTPValidationError>>({ id }: ListLabelsAdminMessagesIdLabelsGetPathParameters,
    params?: ListLabelsAdminMessagesIdLabelsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminMessagesIdLabelsGetQueryKey({ id },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>> = ({ signal }) => listLabelsAdminMessagesIdLabelsGet({ id },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminMessagesIdLabelsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>>
export type ListLabelsAdminMessagesIdLabelsGetQueryError = ListLabelsAdminMessagesIdLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminMessagesIdLabelsGet = <TData = Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError = ListLabelsAdminMessagesIdLabelsGetErrorType<HTTPValidationError>>(
 { id }: ListLabelsAdminMessagesIdLabelsGetPathParameters,
    params?: ListLabelsAdminMessagesIdLabelsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminMessagesIdLabelsGetQueryOptions({ id },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListLabelsAdminMessagesIdLabelsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError = ListLabelsAdminMessagesIdLabelsGetErrorType<HTTPValidationError>>({ id }: ListLabelsAdminMessagesIdLabelsGetPathParameters,
    params?: ListLabelsAdminMessagesIdLabelsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLabelsAdminMessagesIdLabelsGetQueryKey({ id },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>> = ({ signal }) => listLabelsAdminMessagesIdLabelsGet({ id },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListLabelsAdminMessagesIdLabelsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>>
export type ListLabelsAdminMessagesIdLabelsGetSuspenseQueryError = ListLabelsAdminMessagesIdLabelsGetErrorType<HTTPValidationError>

/**
 * @summary List Labels
 */
export const useListLabelsAdminMessagesIdLabelsGetSuspense = <TData = Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError = ListLabelsAdminMessagesIdLabelsGetErrorType<HTTPValidationError>>(
 { id }: ListLabelsAdminMessagesIdLabelsGetPathParameters,
    params?: ListLabelsAdminMessagesIdLabelsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listLabelsAdminMessagesIdLabelsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListLabelsAdminMessagesIdLabelsGetSuspenseQueryOptions({ id },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update
 */
export const updateAdminMessagesIdLabelsPatch = (
    { id }: UpdateAdminMessagesIdLabelsPatchPathParameters,
    mixInLabelPayload: MixInLabelPayload,
    params?: UpdateAdminMessagesIdLabelsPatchParams,
 ) => {
      
      
      return updateAdminMessagesIdLabelsPatchMutator<DashLabel[]>(
      {url: `/admin/messages/${id}/labels`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: mixInLabelPayload,
        params
    },
      );
    }
  


export const getUpdateAdminMessagesIdLabelsPatchMutationOptions = <TError = UpdateAdminMessagesIdLabelsPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminMessagesIdLabelsPatch>>, TError,{pathParams: UpdateAdminMessagesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminMessagesIdLabelsPatchParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAdminMessagesIdLabelsPatch>>, TError,{pathParams: UpdateAdminMessagesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminMessagesIdLabelsPatchParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAdminMessagesIdLabelsPatch>>, {pathParams: UpdateAdminMessagesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminMessagesIdLabelsPatchParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  updateAdminMessagesIdLabelsPatch(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAdminMessagesIdLabelsPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateAdminMessagesIdLabelsPatch>>>
    export type UpdateAdminMessagesIdLabelsPatchMutationBody = MixInLabelPayload
    export type UpdateAdminMessagesIdLabelsPatchMutationError = UpdateAdminMessagesIdLabelsPatchErrorType<HTTPValidationError>

    /**
 * @summary Update
 */
export const useUpdateAdminMessagesIdLabelsPatch = <TError = UpdateAdminMessagesIdLabelsPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminMessagesIdLabelsPatch>>, TError,{pathParams: UpdateAdminMessagesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminMessagesIdLabelsPatchParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateAdminMessagesIdLabelsPatch>>,
        TError,
        {pathParams: UpdateAdminMessagesIdLabelsPatchPathParameters;data: MixInLabelPayload;params?: UpdateAdminMessagesIdLabelsPatchParams},
        TContext
      > => {

      const mutationOptions = getUpdateAdminMessagesIdLabelsPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Labels
 */
export const addLabelsAdminMessagesIdLabelsPost = (
    { id }: AddLabelsAdminMessagesIdLabelsPostPathParameters,
    mixInLabelPayload: MixInLabelPayload,
    params?: AddLabelsAdminMessagesIdLabelsPostParams,
 ) => {
      
      
      return addLabelsAdminMessagesIdLabelsPostMutator<DashLabel[]>(
      {url: `/admin/messages/${id}/labels`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: mixInLabelPayload,
        params
    },
      );
    }
  


export const getAddLabelsAdminMessagesIdLabelsPostMutationOptions = <TError = AddLabelsAdminMessagesIdLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminMessagesIdLabelsPost>>, TError,{pathParams: AddLabelsAdminMessagesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminMessagesIdLabelsPostParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminMessagesIdLabelsPost>>, TError,{pathParams: AddLabelsAdminMessagesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminMessagesIdLabelsPostParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addLabelsAdminMessagesIdLabelsPost>>, {pathParams: AddLabelsAdminMessagesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminMessagesIdLabelsPostParams}> = (props) => {
          const {pathParams,data,params} = props ?? {};

          return  addLabelsAdminMessagesIdLabelsPost(pathParams,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddLabelsAdminMessagesIdLabelsPostMutationResult = NonNullable<Awaited<ReturnType<typeof addLabelsAdminMessagesIdLabelsPost>>>
    export type AddLabelsAdminMessagesIdLabelsPostMutationBody = MixInLabelPayload
    export type AddLabelsAdminMessagesIdLabelsPostMutationError = AddLabelsAdminMessagesIdLabelsPostErrorType<HTTPValidationError>

    /**
 * @summary Add Labels
 */
export const useAddLabelsAdminMessagesIdLabelsPost = <TError = AddLabelsAdminMessagesIdLabelsPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelsAdminMessagesIdLabelsPost>>, TError,{pathParams: AddLabelsAdminMessagesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminMessagesIdLabelsPostParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof addLabelsAdminMessagesIdLabelsPost>>,
        TError,
        {pathParams: AddLabelsAdminMessagesIdLabelsPostPathParameters;data: MixInLabelPayload;params?: AddLabelsAdminMessagesIdLabelsPostParams},
        TContext
      > => {

      const mutationOptions = getAddLabelsAdminMessagesIdLabelsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Label
 */
export const deleteLabelAdminMessagesIdLabelsLabelIdDelete = (
    { id, labelId }: DeleteLabelAdminMessagesIdLabelsLabelIdDeletePathParameters,
    params?: DeleteLabelAdminMessagesIdLabelsLabelIdDeleteParams,
 ) => {
      
      
      return deleteLabelAdminMessagesIdLabelsLabelIdDeleteMutator<unknown>(
      {url: `/admin/messages/${id}/labels/${labelId}`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteLabelAdminMessagesIdLabelsLabelIdDeleteMutationOptions = <TError = DeleteLabelAdminMessagesIdLabelsLabelIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminMessagesIdLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminMessagesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminMessagesIdLabelsLabelIdDeleteParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminMessagesIdLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminMessagesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminMessagesIdLabelsLabelIdDeleteParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLabelAdminMessagesIdLabelsLabelIdDelete>>, {pathParams: DeleteLabelAdminMessagesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminMessagesIdLabelsLabelIdDeleteParams}> = (props) => {
          const {pathParams,params} = props ?? {};

          return  deleteLabelAdminMessagesIdLabelsLabelIdDelete(pathParams,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteLabelAdminMessagesIdLabelsLabelIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLabelAdminMessagesIdLabelsLabelIdDelete>>>
    
    export type DeleteLabelAdminMessagesIdLabelsLabelIdDeleteMutationError = DeleteLabelAdminMessagesIdLabelsLabelIdDeleteErrorType<HTTPValidationError>

    /**
 * @summary Delete Label
 */
export const useDeleteLabelAdminMessagesIdLabelsLabelIdDelete = <TError = DeleteLabelAdminMessagesIdLabelsLabelIdDeleteErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLabelAdminMessagesIdLabelsLabelIdDelete>>, TError,{pathParams: DeleteLabelAdminMessagesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminMessagesIdLabelsLabelIdDeleteParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteLabelAdminMessagesIdLabelsLabelIdDelete>>,
        TError,
        {pathParams: DeleteLabelAdminMessagesIdLabelsLabelIdDeletePathParameters;params?: DeleteLabelAdminMessagesIdLabelsLabelIdDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteLabelAdminMessagesIdLabelsLabelIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Chat History By Message
 */
export const getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet = (
    { messageId }: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams,
 signal?: AbortSignal
) => {
      
      
      return getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetMutator<BizMessage[]>(
      {url: `/admin/messages/${messageId}/chat/history`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetQueryKey = ({ messageId }: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams,) => {
    return [`/admin/messages/${messageId}/chat/history`, ...(params ? [params]: [])] as const;
    }

    
export const getGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams['after']>, TError = GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetErrorType<HTTPValidationError>>({ messageId }: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError, TData, Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, QueryKey, GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetQueryKey({ messageId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, QueryKey, GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams['after']> = ({ signal, pageParam }) => getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet({ messageId },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError, TData, Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, QueryKey, GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams['after']> & { queryKey: QueryKey }
}

export type GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>>
export type GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetInfiniteQueryError = GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat History By Message
 */
export const useGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams['after']>, TError = GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetErrorType<HTTPValidationError>>(
 { messageId }: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError, TData, Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, QueryKey, GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetInfiniteQueryOptions({ messageId },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetQueryOptions = <TData = Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError = GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetErrorType<HTTPValidationError>>({ messageId }: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetQueryKey({ messageId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>> = ({ signal }) => getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet({ messageId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetQueryResult = NonNullable<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>>
export type GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetQueryError = GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat History By Message
 */
export const useGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet = <TData = Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError = GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetErrorType<HTTPValidationError>>(
 { messageId }: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetQueryOptions({ messageId },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError = GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetErrorType<HTTPValidationError>>({ messageId }: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetQueryKey({ messageId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>> = ({ signal }) => getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet({ messageId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>>
export type GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetSuspenseQueryError = GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat History By Message
 */
export const useGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetSuspense = <TData = Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError = GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetErrorType<HTTPValidationError>>(
 { messageId }: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByMessageAdminMessagesMessageIdChatHistoryGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatHistoryByMessageAdminMessagesMessageIdChatHistoryGetSuspenseQueryOptions({ messageId },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Chat Groups
 */
export const getChatGroupsAdminGroupsGet = (
    params?: GetChatGroupsAdminGroupsGetParams,
 signal?: AbortSignal
) => {
      
      
      return getChatGroupsAdminGroupsGetMutator<BizUsrGroupInfo[]>(
      {url: `/admin/groups`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetChatGroupsAdminGroupsGetQueryKey = (params?: GetChatGroupsAdminGroupsGetParams,) => {
    return [`/admin/groups`, ...(params ? [params]: [])] as const;
    }

    
export const getGetChatGroupsAdminGroupsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, GetChatGroupsAdminGroupsGetParams['after']>, TError = GetChatGroupsAdminGroupsGetErrorType<HTTPValidationError>>(params?: GetChatGroupsAdminGroupsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError, TData, Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, QueryKey, GetChatGroupsAdminGroupsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatGroupsAdminGroupsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, QueryKey, GetChatGroupsAdminGroupsGetParams['after']> = ({ signal, pageParam }) => getChatGroupsAdminGroupsGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError, TData, Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, QueryKey, GetChatGroupsAdminGroupsGetParams['after']> & { queryKey: QueryKey }
}

export type GetChatGroupsAdminGroupsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>>
export type GetChatGroupsAdminGroupsGetInfiniteQueryError = GetChatGroupsAdminGroupsGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat Groups
 */
export const useGetChatGroupsAdminGroupsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, GetChatGroupsAdminGroupsGetParams['after']>, TError = GetChatGroupsAdminGroupsGetErrorType<HTTPValidationError>>(
 params?: GetChatGroupsAdminGroupsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError, TData, Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, QueryKey, GetChatGroupsAdminGroupsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatGroupsAdminGroupsGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatGroupsAdminGroupsGetQueryOptions = <TData = Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError = GetChatGroupsAdminGroupsGetErrorType<HTTPValidationError>>(params?: GetChatGroupsAdminGroupsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatGroupsAdminGroupsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>> = ({ signal }) => getChatGroupsAdminGroupsGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatGroupsAdminGroupsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>>
export type GetChatGroupsAdminGroupsGetQueryError = GetChatGroupsAdminGroupsGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat Groups
 */
export const useGetChatGroupsAdminGroupsGet = <TData = Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError = GetChatGroupsAdminGroupsGetErrorType<HTTPValidationError>>(
 params?: GetChatGroupsAdminGroupsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatGroupsAdminGroupsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatGroupsAdminGroupsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError = GetChatGroupsAdminGroupsGetErrorType<HTTPValidationError>>(params?: GetChatGroupsAdminGroupsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatGroupsAdminGroupsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>> = ({ signal }) => getChatGroupsAdminGroupsGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatGroupsAdminGroupsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>>
export type GetChatGroupsAdminGroupsGetSuspenseQueryError = GetChatGroupsAdminGroupsGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat Groups
 */
export const useGetChatGroupsAdminGroupsGetSuspense = <TData = Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError = GetChatGroupsAdminGroupsGetErrorType<HTTPValidationError>>(
 params?: GetChatGroupsAdminGroupsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatGroupsAdminGroupsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatGroupsAdminGroupsGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Chat History By Group
 */
export const getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet = (
    { groupId }: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams,
 signal?: AbortSignal
) => {
      
      
      return getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetMutator<BizMessage[]>(
      {url: `/admin/groups/${groupId}/chat/history`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetQueryKey = ({ groupId }: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams,) => {
    return [`/admin/groups/${groupId}/chat/history`, ...(params ? [params]: [])] as const;
    }

    
export const getGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams['after']>, TError = GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetErrorType<HTTPValidationError>>({ groupId }: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError, TData, Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, QueryKey, GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetQueryKey({ groupId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, QueryKey, GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams['after']> = ({ signal, pageParam }) => getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet({ groupId },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError, TData, Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, QueryKey, GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams['after']> & { queryKey: QueryKey }
}

export type GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>>
export type GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetInfiniteQueryError = GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat History By Group
 */
export const useGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams['after']>, TError = GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetErrorType<HTTPValidationError>>(
 { groupId }: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError, TData, Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, QueryKey, GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetInfiniteQueryOptions({ groupId },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetQueryOptions = <TData = Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError = GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetErrorType<HTTPValidationError>>({ groupId }: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetQueryKey({ groupId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>> = ({ signal }) => getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet({ groupId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetQueryResult = NonNullable<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>>
export type GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetQueryError = GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat History By Group
 */
export const useGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet = <TData = Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError = GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetErrorType<HTTPValidationError>>(
 { groupId }: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetQueryOptions({ groupId },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError = GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetErrorType<HTTPValidationError>>({ groupId }: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetQueryKey({ groupId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>> = ({ signal }) => getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet({ groupId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>>
export type GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetSuspenseQueryError = GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat History By Group
 */
export const useGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetSuspense = <TData = Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError = GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetErrorType<HTTPValidationError>>(
 { groupId }: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetPathParameters,
    params?: GetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatHistoryByGroupAdminGroupsGroupIdChatHistoryGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatHistoryByGroupAdminGroupsGroupIdChatHistoryGetSuspenseQueryOptions({ groupId },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Chat Stats By Group
 */
export const getChatStatsByGroupAdminGroupsGroupIdChatStatsGet = (
    { groupId }: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetPathParameters,
    params: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams,
 signal?: AbortSignal
) => {
      
      
      return getChatStatsByGroupAdminGroupsGroupIdChatStatsGetMutator<SessionsDateRangeStats>(
      {url: `/admin/groups/${groupId}/chat/stats`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetChatStatsByGroupAdminGroupsGroupIdChatStatsGetQueryKey = ({ groupId }: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetPathParameters,
    params: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams,) => {
    return [`/admin/groups/${groupId}/chat/stats`, ...(params ? [params]: [])] as const;
    }

    
export const getGetChatStatsByGroupAdminGroupsGroupIdChatStatsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams['after']>, TError = GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetErrorType<HTTPValidationError>>({ groupId }: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetPathParameters,
    params: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError, TData, Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, QueryKey, GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatStatsByGroupAdminGroupsGroupIdChatStatsGetQueryKey({ groupId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, QueryKey, GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams['after']> = ({ signal, pageParam }) => getChatStatsByGroupAdminGroupsGroupIdChatStatsGet({ groupId },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError, TData, Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, QueryKey, GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams['after']> & { queryKey: QueryKey }
}

export type GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>>
export type GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetInfiniteQueryError = GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat Stats By Group
 */
export const useGetChatStatsByGroupAdminGroupsGroupIdChatStatsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams['after']>, TError = GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetErrorType<HTTPValidationError>>(
 { groupId }: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetPathParameters,
    params: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError, TData, Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, QueryKey, GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatStatsByGroupAdminGroupsGroupIdChatStatsGetInfiniteQueryOptions({ groupId },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatStatsByGroupAdminGroupsGroupIdChatStatsGetQueryOptions = <TData = Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError = GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetErrorType<HTTPValidationError>>({ groupId }: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetPathParameters,
    params: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatStatsByGroupAdminGroupsGroupIdChatStatsGetQueryKey({ groupId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>> = ({ signal }) => getChatStatsByGroupAdminGroupsGroupIdChatStatsGet({ groupId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>>
export type GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetQueryError = GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat Stats By Group
 */
export const useGetChatStatsByGroupAdminGroupsGroupIdChatStatsGet = <TData = Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError = GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetErrorType<HTTPValidationError>>(
 { groupId }: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetPathParameters,
    params: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatStatsByGroupAdminGroupsGroupIdChatStatsGetQueryOptions({ groupId },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChatStatsByGroupAdminGroupsGroupIdChatStatsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError = GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetErrorType<HTTPValidationError>>({ groupId }: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetPathParameters,
    params: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChatStatsByGroupAdminGroupsGroupIdChatStatsGetQueryKey({ groupId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>> = ({ signal }) => getChatStatsByGroupAdminGroupsGroupIdChatStatsGet({ groupId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>>
export type GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetSuspenseQueryError = GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetErrorType<HTTPValidationError>

/**
 * @summary Get Chat Stats By Group
 */
export const useGetChatStatsByGroupAdminGroupsGroupIdChatStatsGetSuspense = <TData = Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError = GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetErrorType<HTTPValidationError>>(
 { groupId }: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetPathParameters,
    params: GetChatStatsByGroupAdminGroupsGroupIdChatStatsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getChatStatsByGroupAdminGroupsGroupIdChatStatsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChatStatsByGroupAdminGroupsGroupIdChatStatsGetSuspenseQueryOptions({ groupId },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Group Events
 */
export const getGroupEventsAdminGroupsGroupIdEventsGet = (
    { groupId }: GetGroupEventsAdminGroupsGroupIdEventsGetPathParameters,
    params?: GetGroupEventsAdminGroupsGroupIdEventsGetParams,
 signal?: AbortSignal
) => {
      
      
      return getGroupEventsAdminGroupsGroupIdEventsGetMutator<UserEventInfo[]>(
      {url: `/admin/groups/${groupId}/events`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetGroupEventsAdminGroupsGroupIdEventsGetQueryKey = ({ groupId }: GetGroupEventsAdminGroupsGroupIdEventsGetPathParameters,
    params?: GetGroupEventsAdminGroupsGroupIdEventsGetParams,) => {
    return [`/admin/groups/${groupId}/events`, ...(params ? [params]: [])] as const;
    }

    
export const getGetGroupEventsAdminGroupsGroupIdEventsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, GetGroupEventsAdminGroupsGroupIdEventsGetParams['after']>, TError = GetGroupEventsAdminGroupsGroupIdEventsGetErrorType<HTTPValidationError>>({ groupId }: GetGroupEventsAdminGroupsGroupIdEventsGetPathParameters,
    params?: GetGroupEventsAdminGroupsGroupIdEventsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError, TData, Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, QueryKey, GetGroupEventsAdminGroupsGroupIdEventsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupEventsAdminGroupsGroupIdEventsGetQueryKey({ groupId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, QueryKey, GetGroupEventsAdminGroupsGroupIdEventsGetParams['after']> = ({ signal, pageParam }) => getGroupEventsAdminGroupsGroupIdEventsGet({ groupId },{...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError, TData, Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, QueryKey, GetGroupEventsAdminGroupsGroupIdEventsGetParams['after']> & { queryKey: QueryKey }
}

export type GetGroupEventsAdminGroupsGroupIdEventsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>>
export type GetGroupEventsAdminGroupsGroupIdEventsGetInfiniteQueryError = GetGroupEventsAdminGroupsGroupIdEventsGetErrorType<HTTPValidationError>

/**
 * @summary Get Group Events
 */
export const useGetGroupEventsAdminGroupsGroupIdEventsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, GetGroupEventsAdminGroupsGroupIdEventsGetParams['after']>, TError = GetGroupEventsAdminGroupsGroupIdEventsGetErrorType<HTTPValidationError>>(
 { groupId }: GetGroupEventsAdminGroupsGroupIdEventsGetPathParameters,
    params?: GetGroupEventsAdminGroupsGroupIdEventsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError, TData, Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, QueryKey, GetGroupEventsAdminGroupsGroupIdEventsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetGroupEventsAdminGroupsGroupIdEventsGetInfiniteQueryOptions({ groupId },params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetGroupEventsAdminGroupsGroupIdEventsGetQueryOptions = <TData = Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError = GetGroupEventsAdminGroupsGroupIdEventsGetErrorType<HTTPValidationError>>({ groupId }: GetGroupEventsAdminGroupsGroupIdEventsGetPathParameters,
    params?: GetGroupEventsAdminGroupsGroupIdEventsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupEventsAdminGroupsGroupIdEventsGetQueryKey({ groupId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>> = ({ signal }) => getGroupEventsAdminGroupsGroupIdEventsGet({ groupId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGroupEventsAdminGroupsGroupIdEventsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>>
export type GetGroupEventsAdminGroupsGroupIdEventsGetQueryError = GetGroupEventsAdminGroupsGroupIdEventsGetErrorType<HTTPValidationError>

/**
 * @summary Get Group Events
 */
export const useGetGroupEventsAdminGroupsGroupIdEventsGet = <TData = Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError = GetGroupEventsAdminGroupsGroupIdEventsGetErrorType<HTTPValidationError>>(
 { groupId }: GetGroupEventsAdminGroupsGroupIdEventsGetPathParameters,
    params?: GetGroupEventsAdminGroupsGroupIdEventsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetGroupEventsAdminGroupsGroupIdEventsGetQueryOptions({ groupId },params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetGroupEventsAdminGroupsGroupIdEventsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError = GetGroupEventsAdminGroupsGroupIdEventsGetErrorType<HTTPValidationError>>({ groupId }: GetGroupEventsAdminGroupsGroupIdEventsGetPathParameters,
    params?: GetGroupEventsAdminGroupsGroupIdEventsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupEventsAdminGroupsGroupIdEventsGetQueryKey({ groupId },params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>> = ({ signal }) => getGroupEventsAdminGroupsGroupIdEventsGet({ groupId },params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGroupEventsAdminGroupsGroupIdEventsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>>
export type GetGroupEventsAdminGroupsGroupIdEventsGetSuspenseQueryError = GetGroupEventsAdminGroupsGroupIdEventsGetErrorType<HTTPValidationError>

/**
 * @summary Get Group Events
 */
export const useGetGroupEventsAdminGroupsGroupIdEventsGetSuspense = <TData = Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError = GetGroupEventsAdminGroupsGroupIdEventsGetErrorType<HTTPValidationError>>(
 { groupId }: GetGroupEventsAdminGroupsGroupIdEventsGetPathParameters,
    params?: GetGroupEventsAdminGroupsGroupIdEventsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getGroupEventsAdminGroupsGroupIdEventsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetGroupEventsAdminGroupsGroupIdEventsGetSuspenseQueryOptions({ groupId },params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Sessions
 */
export const getSessionsAdminSessionsGet = (
    params?: GetSessionsAdminSessionsGetParams,
 signal?: AbortSignal
) => {
      
      
      return getSessionsAdminSessionsGetMutator<SessionOutput[]>(
      {url: `/admin/sessions`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetSessionsAdminSessionsGetQueryKey = (params?: GetSessionsAdminSessionsGetParams,) => {
    return [`/admin/sessions`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSessionsAdminSessionsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, GetSessionsAdminSessionsGetParams['after']>, TError = GetSessionsAdminSessionsGetErrorType<HTTPValidationError>>(params?: GetSessionsAdminSessionsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError, TData, Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, QueryKey, GetSessionsAdminSessionsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSessionsAdminSessionsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, QueryKey, GetSessionsAdminSessionsGetParams['after']> = ({ signal, pageParam }) => getSessionsAdminSessionsGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError, TData, Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, QueryKey, GetSessionsAdminSessionsGetParams['after']> & { queryKey: QueryKey }
}

export type GetSessionsAdminSessionsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>>
export type GetSessionsAdminSessionsGetInfiniteQueryError = GetSessionsAdminSessionsGetErrorType<HTTPValidationError>

/**
 * @summary Get Sessions
 */
export const useGetSessionsAdminSessionsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, GetSessionsAdminSessionsGetParams['after']>, TError = GetSessionsAdminSessionsGetErrorType<HTTPValidationError>>(
 params?: GetSessionsAdminSessionsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError, TData, Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, QueryKey, GetSessionsAdminSessionsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSessionsAdminSessionsGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSessionsAdminSessionsGetQueryOptions = <TData = Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError = GetSessionsAdminSessionsGetErrorType<HTTPValidationError>>(params?: GetSessionsAdminSessionsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSessionsAdminSessionsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>> = ({ signal }) => getSessionsAdminSessionsGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSessionsAdminSessionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>>
export type GetSessionsAdminSessionsGetQueryError = GetSessionsAdminSessionsGetErrorType<HTTPValidationError>

/**
 * @summary Get Sessions
 */
export const useGetSessionsAdminSessionsGet = <TData = Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError = GetSessionsAdminSessionsGetErrorType<HTTPValidationError>>(
 params?: GetSessionsAdminSessionsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSessionsAdminSessionsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSessionsAdminSessionsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError = GetSessionsAdminSessionsGetErrorType<HTTPValidationError>>(params?: GetSessionsAdminSessionsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSessionsAdminSessionsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>> = ({ signal }) => getSessionsAdminSessionsGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSessionsAdminSessionsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>>
export type GetSessionsAdminSessionsGetSuspenseQueryError = GetSessionsAdminSessionsGetErrorType<HTTPValidationError>

/**
 * @summary Get Sessions
 */
export const useGetSessionsAdminSessionsGetSuspense = <TData = Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError = GetSessionsAdminSessionsGetErrorType<HTTPValidationError>>(
 params?: GetSessionsAdminSessionsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSessionsAdminSessionsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSessionsAdminSessionsGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Sessions By User Id
 */
export const getSessionsByUserIdAdminSessionsUserIdGet = (
    { userId }: GetSessionsByUserIdAdminSessionsUserIdGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return getSessionsByUserIdAdminSessionsUserIdGetMutator<SessionOutput[]>(
      {url: `/admin/sessions/${userId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetSessionsByUserIdAdminSessionsUserIdGetQueryKey = ({ userId }: GetSessionsByUserIdAdminSessionsUserIdGetPathParameters,) => {
    return [`/admin/sessions/${userId}`] as const;
    }

    
export const getGetSessionsByUserIdAdminSessionsUserIdGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>>, TError = GetSessionsByUserIdAdminSessionsUserIdGetErrorType<HTTPValidationError>>({ userId }: GetSessionsByUserIdAdminSessionsUserIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSessionsByUserIdAdminSessionsUserIdGetQueryKey({ userId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>> = ({ signal }) => getSessionsByUserIdAdminSessionsUserIdGet({ userId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSessionsByUserIdAdminSessionsUserIdGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>>
export type GetSessionsByUserIdAdminSessionsUserIdGetInfiniteQueryError = GetSessionsByUserIdAdminSessionsUserIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Sessions By User Id
 */
export const useGetSessionsByUserIdAdminSessionsUserIdGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>>, TError = GetSessionsByUserIdAdminSessionsUserIdGetErrorType<HTTPValidationError>>(
 { userId }: GetSessionsByUserIdAdminSessionsUserIdGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSessionsByUserIdAdminSessionsUserIdGetInfiniteQueryOptions({ userId },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSessionsByUserIdAdminSessionsUserIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError = GetSessionsByUserIdAdminSessionsUserIdGetErrorType<HTTPValidationError>>({ userId }: GetSessionsByUserIdAdminSessionsUserIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSessionsByUserIdAdminSessionsUserIdGetQueryKey({ userId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>> = ({ signal }) => getSessionsByUserIdAdminSessionsUserIdGet({ userId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSessionsByUserIdAdminSessionsUserIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>>
export type GetSessionsByUserIdAdminSessionsUserIdGetQueryError = GetSessionsByUserIdAdminSessionsUserIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Sessions By User Id
 */
export const useGetSessionsByUserIdAdminSessionsUserIdGet = <TData = Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError = GetSessionsByUserIdAdminSessionsUserIdGetErrorType<HTTPValidationError>>(
 { userId }: GetSessionsByUserIdAdminSessionsUserIdGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSessionsByUserIdAdminSessionsUserIdGetQueryOptions({ userId },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSessionsByUserIdAdminSessionsUserIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError = GetSessionsByUserIdAdminSessionsUserIdGetErrorType<HTTPValidationError>>({ userId }: GetSessionsByUserIdAdminSessionsUserIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSessionsByUserIdAdminSessionsUserIdGetQueryKey({ userId });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>> = ({ signal }) => getSessionsByUserIdAdminSessionsUserIdGet({ userId }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSessionsByUserIdAdminSessionsUserIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>>
export type GetSessionsByUserIdAdminSessionsUserIdGetSuspenseQueryError = GetSessionsByUserIdAdminSessionsUserIdGetErrorType<HTTPValidationError>

/**
 * @summary Get Sessions By User Id
 */
export const useGetSessionsByUserIdAdminSessionsUserIdGetSuspense = <TData = Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError = GetSessionsByUserIdAdminSessionsUserIdGetErrorType<HTTPValidationError>>(
 { userId }: GetSessionsByUserIdAdminSessionsUserIdGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSessionsByUserIdAdminSessionsUserIdGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSessionsByUserIdAdminSessionsUserIdGetSuspenseQueryOptions({ userId },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Bots
 */
export const getBotsAdminBotsGet = (
    params?: GetBotsAdminBotsGetParams,
 signal?: AbortSignal
) => {
      
      
      return getBotsAdminBotsGetMutator<BotInfoOutput[]>(
      {url: `/admin/bots`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetBotsAdminBotsGetQueryKey = (params?: GetBotsAdminBotsGetParams,) => {
    return [`/admin/bots`, ...(params ? [params]: [])] as const;
    }

    
export const getGetBotsAdminBotsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getBotsAdminBotsGet>>, GetBotsAdminBotsGetParams['after']>, TError = GetBotsAdminBotsGetErrorType<HTTPValidationError>>(params?: GetBotsAdminBotsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError, TData, Awaited<ReturnType<typeof getBotsAdminBotsGet>>, QueryKey, GetBotsAdminBotsGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBotsAdminBotsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBotsAdminBotsGet>>, QueryKey, GetBotsAdminBotsGetParams['after']> = ({ signal, pageParam }) => getBotsAdminBotsGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError, TData, Awaited<ReturnType<typeof getBotsAdminBotsGet>>, QueryKey, GetBotsAdminBotsGetParams['after']> & { queryKey: QueryKey }
}

export type GetBotsAdminBotsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getBotsAdminBotsGet>>>
export type GetBotsAdminBotsGetInfiniteQueryError = GetBotsAdminBotsGetErrorType<HTTPValidationError>

/**
 * @summary Get Bots
 */
export const useGetBotsAdminBotsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getBotsAdminBotsGet>>, GetBotsAdminBotsGetParams['after']>, TError = GetBotsAdminBotsGetErrorType<HTTPValidationError>>(
 params?: GetBotsAdminBotsGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError, TData, Awaited<ReturnType<typeof getBotsAdminBotsGet>>, QueryKey, GetBotsAdminBotsGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBotsAdminBotsGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetBotsAdminBotsGetQueryOptions = <TData = Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError = GetBotsAdminBotsGetErrorType<HTTPValidationError>>(params?: GetBotsAdminBotsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBotsAdminBotsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBotsAdminBotsGet>>> = ({ signal }) => getBotsAdminBotsGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetBotsAdminBotsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getBotsAdminBotsGet>>>
export type GetBotsAdminBotsGetQueryError = GetBotsAdminBotsGetErrorType<HTTPValidationError>

/**
 * @summary Get Bots
 */
export const useGetBotsAdminBotsGet = <TData = Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError = GetBotsAdminBotsGetErrorType<HTTPValidationError>>(
 params?: GetBotsAdminBotsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBotsAdminBotsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetBotsAdminBotsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError = GetBotsAdminBotsGetErrorType<HTTPValidationError>>(params?: GetBotsAdminBotsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBotsAdminBotsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBotsAdminBotsGet>>> = ({ signal }) => getBotsAdminBotsGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetBotsAdminBotsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getBotsAdminBotsGet>>>
export type GetBotsAdminBotsGetSuspenseQueryError = GetBotsAdminBotsGetErrorType<HTTPValidationError>

/**
 * @summary Get Bots
 */
export const useGetBotsAdminBotsGetSuspense = <TData = Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError = GetBotsAdminBotsGetErrorType<HTTPValidationError>>(
 params?: GetBotsAdminBotsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getBotsAdminBotsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBotsAdminBotsGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List Contact Way Action
 */
export const listContactWayActionAdminWechatContactWaysGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listContactWayActionAdminWechatContactWaysGetMutator<DashWechatContactWay[]>(
      {url: `/admin/wechat/contact_ways`, method: 'GET', signal
    },
      );
    }
  

export const getListContactWayActionAdminWechatContactWaysGetQueryKey = () => {
    return [`/admin/wechat/contact_ways`] as const;
    }

    
export const getListContactWayActionAdminWechatContactWaysGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>>, TError = ListContactWayActionAdminWechatContactWaysGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListContactWayActionAdminWechatContactWaysGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>> = ({ signal }) => listContactWayActionAdminWechatContactWaysGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListContactWayActionAdminWechatContactWaysGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>>
export type ListContactWayActionAdminWechatContactWaysGetInfiniteQueryError = ListContactWayActionAdminWechatContactWaysGetErrorType<unknown>

/**
 * @summary List Contact Way Action
 */
export const useListContactWayActionAdminWechatContactWaysGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>>, TError = ListContactWayActionAdminWechatContactWaysGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListContactWayActionAdminWechatContactWaysGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListContactWayActionAdminWechatContactWaysGetQueryOptions = <TData = Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError = ListContactWayActionAdminWechatContactWaysGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListContactWayActionAdminWechatContactWaysGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>> = ({ signal }) => listContactWayActionAdminWechatContactWaysGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListContactWayActionAdminWechatContactWaysGetQueryResult = NonNullable<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>>
export type ListContactWayActionAdminWechatContactWaysGetQueryError = ListContactWayActionAdminWechatContactWaysGetErrorType<unknown>

/**
 * @summary List Contact Way Action
 */
export const useListContactWayActionAdminWechatContactWaysGet = <TData = Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError = ListContactWayActionAdminWechatContactWaysGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListContactWayActionAdminWechatContactWaysGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListContactWayActionAdminWechatContactWaysGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError = ListContactWayActionAdminWechatContactWaysGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListContactWayActionAdminWechatContactWaysGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>> = ({ signal }) => listContactWayActionAdminWechatContactWaysGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListContactWayActionAdminWechatContactWaysGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>>
export type ListContactWayActionAdminWechatContactWaysGetSuspenseQueryError = ListContactWayActionAdminWechatContactWaysGetErrorType<unknown>

/**
 * @summary List Contact Way Action
 */
export const useListContactWayActionAdminWechatContactWaysGetSuspense = <TData = Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError = ListContactWayActionAdminWechatContactWaysGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listContactWayActionAdminWechatContactWaysGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListContactWayActionAdminWechatContactWaysGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Contact Way Action
 */
export const createContactWayActionAdminWechatContactWaysPost = (
    contactWayCreateRequest: ContactWayCreateRequest,
 ) => {
      
      
      return createContactWayActionAdminWechatContactWaysPostMutator<DashWechatContactWay>(
      {url: `/admin/wechat/contact_ways`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: contactWayCreateRequest
    },
      );
    }
  


export const getCreateContactWayActionAdminWechatContactWaysPostMutationOptions = <TError = CreateContactWayActionAdminWechatContactWaysPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContactWayActionAdminWechatContactWaysPost>>, TError,{data: ContactWayCreateRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createContactWayActionAdminWechatContactWaysPost>>, TError,{data: ContactWayCreateRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createContactWayActionAdminWechatContactWaysPost>>, {data: ContactWayCreateRequest}> = (props) => {
          const {data} = props ?? {};

          return  createContactWayActionAdminWechatContactWaysPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateContactWayActionAdminWechatContactWaysPostMutationResult = NonNullable<Awaited<ReturnType<typeof createContactWayActionAdminWechatContactWaysPost>>>
    export type CreateContactWayActionAdminWechatContactWaysPostMutationBody = ContactWayCreateRequest
    export type CreateContactWayActionAdminWechatContactWaysPostMutationError = CreateContactWayActionAdminWechatContactWaysPostErrorType<HTTPValidationError>

    /**
 * @summary Create Contact Way Action
 */
export const useCreateContactWayActionAdminWechatContactWaysPost = <TError = CreateContactWayActionAdminWechatContactWaysPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContactWayActionAdminWechatContactWaysPost>>, TError,{data: ContactWayCreateRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createContactWayActionAdminWechatContactWaysPost>>,
        TError,
        {data: ContactWayCreateRequest},
        TContext
      > => {

      const mutationOptions = getCreateContactWayActionAdminWechatContactWaysPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Patch Contact Way Action
 */
export const patchContactWayActionAdminWechatContactWaysWayIdPatch = (
    { wayId }: PatchContactWayActionAdminWechatContactWaysWayIdPatchPathParameters,
    contactWayPatchRequest: ContactWayPatchRequest,
 ) => {
      
      
      return patchContactWayActionAdminWechatContactWaysWayIdPatchMutator<DashWechatContactWay>(
      {url: `/admin/wechat/contact_ways/${wayId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: contactWayPatchRequest
    },
      );
    }
  


export const getPatchContactWayActionAdminWechatContactWaysWayIdPatchMutationOptions = <TError = PatchContactWayActionAdminWechatContactWaysWayIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchContactWayActionAdminWechatContactWaysWayIdPatch>>, TError,{pathParams: PatchContactWayActionAdminWechatContactWaysWayIdPatchPathParameters;data: ContactWayPatchRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patchContactWayActionAdminWechatContactWaysWayIdPatch>>, TError,{pathParams: PatchContactWayActionAdminWechatContactWaysWayIdPatchPathParameters;data: ContactWayPatchRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchContactWayActionAdminWechatContactWaysWayIdPatch>>, {pathParams: PatchContactWayActionAdminWechatContactWaysWayIdPatchPathParameters;data: ContactWayPatchRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  patchContactWayActionAdminWechatContactWaysWayIdPatch(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchContactWayActionAdminWechatContactWaysWayIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof patchContactWayActionAdminWechatContactWaysWayIdPatch>>>
    export type PatchContactWayActionAdminWechatContactWaysWayIdPatchMutationBody = ContactWayPatchRequest
    export type PatchContactWayActionAdminWechatContactWaysWayIdPatchMutationError = PatchContactWayActionAdminWechatContactWaysWayIdPatchErrorType<HTTPValidationError>

    /**
 * @summary Patch Contact Way Action
 */
export const usePatchContactWayActionAdminWechatContactWaysWayIdPatch = <TError = PatchContactWayActionAdminWechatContactWaysWayIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchContactWayActionAdminWechatContactWaysWayIdPatch>>, TError,{pathParams: PatchContactWayActionAdminWechatContactWaysWayIdPatchPathParameters;data: ContactWayPatchRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patchContactWayActionAdminWechatContactWaysWayIdPatch>>,
        TError,
        {pathParams: PatchContactWayActionAdminWechatContactWaysWayIdPatchPathParameters;data: ContactWayPatchRequest},
        TContext
      > => {

      const mutationOptions = getPatchContactWayActionAdminWechatContactWaysWayIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List All
 */
export const listAllAdminUserTrafficGet = (
    
 signal?: AbortSignal
) => {
      
      
      return listAllAdminUserTrafficGetMutator<UserTrafficData[]>(
      {url: `/admin/user-traffic`, method: 'GET', signal
    },
      );
    }
  

export const getListAllAdminUserTrafficGetQueryKey = () => {
    return [`/admin/user-traffic`] as const;
    }

    
export const getListAllAdminUserTrafficGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>>, TError = ListAllAdminUserTrafficGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAllAdminUserTrafficGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>> = ({ signal }) => listAllAdminUserTrafficGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListAllAdminUserTrafficGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>>
export type ListAllAdminUserTrafficGetInfiniteQueryError = ListAllAdminUserTrafficGetErrorType<unknown>

/**
 * @summary List All
 */
export const useListAllAdminUserTrafficGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>>, TError = ListAllAdminUserTrafficGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAllAdminUserTrafficGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListAllAdminUserTrafficGetQueryOptions = <TData = Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError = ListAllAdminUserTrafficGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAllAdminUserTrafficGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>> = ({ signal }) => listAllAdminUserTrafficGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListAllAdminUserTrafficGetQueryResult = NonNullable<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>>
export type ListAllAdminUserTrafficGetQueryError = ListAllAdminUserTrafficGetErrorType<unknown>

/**
 * @summary List All
 */
export const useListAllAdminUserTrafficGet = <TData = Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError = ListAllAdminUserTrafficGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAllAdminUserTrafficGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListAllAdminUserTrafficGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError = ListAllAdminUserTrafficGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAllAdminUserTrafficGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>> = ({ signal }) => listAllAdminUserTrafficGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ListAllAdminUserTrafficGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>>
export type ListAllAdminUserTrafficGetSuspenseQueryError = ListAllAdminUserTrafficGetErrorType<unknown>

/**
 * @summary List All
 */
export const useListAllAdminUserTrafficGetSuspense = <TData = Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError = ListAllAdminUserTrafficGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listAllAdminUserTrafficGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAllAdminUserTrafficGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create
 */
export const createAdminUserTrafficPost = (
    userTrafficPayload: UserTrafficPayload,
 ) => {
      
      
      return createAdminUserTrafficPostMutator<UserTrafficData>(
      {url: `/admin/user-traffic`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userTrafficPayload
    },
      );
    }
  


export const getCreateAdminUserTrafficPostMutationOptions = <TError = CreateAdminUserTrafficPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAdminUserTrafficPost>>, TError,{data: UserTrafficPayload}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createAdminUserTrafficPost>>, TError,{data: UserTrafficPayload}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAdminUserTrafficPost>>, {data: UserTrafficPayload}> = (props) => {
          const {data} = props ?? {};

          return  createAdminUserTrafficPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateAdminUserTrafficPostMutationResult = NonNullable<Awaited<ReturnType<typeof createAdminUserTrafficPost>>>
    export type CreateAdminUserTrafficPostMutationBody = UserTrafficPayload
    export type CreateAdminUserTrafficPostMutationError = CreateAdminUserTrafficPostErrorType<HTTPValidationError>

    /**
 * @summary Create
 */
export const useCreateAdminUserTrafficPost = <TError = CreateAdminUserTrafficPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAdminUserTrafficPost>>, TError,{data: UserTrafficPayload}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createAdminUserTrafficPost>>,
        TError,
        {data: UserTrafficPayload},
        TContext
      > => {

      const mutationOptions = getCreateAdminUserTrafficPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update
 */
export const updateAdminUserTrafficIdPatch = (
    { id }: UpdateAdminUserTrafficIdPatchPathParameters,
    userTrafficUpdate: UserTrafficUpdate,
 ) => {
      
      
      return updateAdminUserTrafficIdPatchMutator<UserTrafficData>(
      {url: `/admin/user-traffic/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: userTrafficUpdate
    },
      );
    }
  


export const getUpdateAdminUserTrafficIdPatchMutationOptions = <TError = UpdateAdminUserTrafficIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminUserTrafficIdPatch>>, TError,{pathParams: UpdateAdminUserTrafficIdPatchPathParameters;data: UserTrafficUpdate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAdminUserTrafficIdPatch>>, TError,{pathParams: UpdateAdminUserTrafficIdPatchPathParameters;data: UserTrafficUpdate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAdminUserTrafficIdPatch>>, {pathParams: UpdateAdminUserTrafficIdPatchPathParameters;data: UserTrafficUpdate}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  updateAdminUserTrafficIdPatch(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAdminUserTrafficIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateAdminUserTrafficIdPatch>>>
    export type UpdateAdminUserTrafficIdPatchMutationBody = UserTrafficUpdate
    export type UpdateAdminUserTrafficIdPatchMutationError = UpdateAdminUserTrafficIdPatchErrorType<HTTPValidationError>

    /**
 * @summary Update
 */
export const useUpdateAdminUserTrafficIdPatch = <TError = UpdateAdminUserTrafficIdPatchErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminUserTrafficIdPatch>>, TError,{pathParams: UpdateAdminUserTrafficIdPatchPathParameters;data: UserTrafficUpdate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateAdminUserTrafficIdPatch>>,
        TError,
        {pathParams: UpdateAdminUserTrafficIdPatchPathParameters;data: UserTrafficUpdate},
        TContext
      > => {

      const mutationOptions = getUpdateAdminUserTrafficIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Dashboard Url
 */
export const getDashboardUrlAdminMetabaseDashboardPost = (
    dashboardRequest: DashboardRequest,
 ) => {
      
      
      return getDashboardUrlAdminMetabaseDashboardPostMutator<DashboardData>(
      {url: `/admin/metabase/dashboard`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dashboardRequest
    },
      );
    }
  


export const getGetDashboardUrlAdminMetabaseDashboardPostMutationOptions = <TError = GetDashboardUrlAdminMetabaseDashboardPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDashboardUrlAdminMetabaseDashboardPost>>, TError,{data: DashboardRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof getDashboardUrlAdminMetabaseDashboardPost>>, TError,{data: DashboardRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getDashboardUrlAdminMetabaseDashboardPost>>, {data: DashboardRequest}> = (props) => {
          const {data} = props ?? {};

          return  getDashboardUrlAdminMetabaseDashboardPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetDashboardUrlAdminMetabaseDashboardPostMutationResult = NonNullable<Awaited<ReturnType<typeof getDashboardUrlAdminMetabaseDashboardPost>>>
    export type GetDashboardUrlAdminMetabaseDashboardPostMutationBody = DashboardRequest
    export type GetDashboardUrlAdminMetabaseDashboardPostMutationError = GetDashboardUrlAdminMetabaseDashboardPostErrorType<HTTPValidationError>

    /**
 * @summary Get Dashboard Url
 */
export const useGetDashboardUrlAdminMetabaseDashboardPost = <TError = GetDashboardUrlAdminMetabaseDashboardPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDashboardUrlAdminMetabaseDashboardPost>>, TError,{data: DashboardRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof getDashboardUrlAdminMetabaseDashboardPost>>,
        TError,
        {data: DashboardRequest},
        TContext
      > => {

      const mutationOptions = getGetDashboardUrlAdminMetabaseDashboardPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Mock Data
 */
export const getMockDataAdminCalendarMockDataGet = (
    
 signal?: AbortSignal
) => {
      
      
      return getMockDataAdminCalendarMockDataGetMutator<MockDataResponse>(
      {url: `/admin/calendar/mock_data`, method: 'GET', signal
    },
      );
    }
  

export const getGetMockDataAdminCalendarMockDataGetQueryKey = () => {
    return [`/admin/calendar/mock_data`] as const;
    }

    
export const getGetMockDataAdminCalendarMockDataGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>>, TError = GetMockDataAdminCalendarMockDataGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMockDataAdminCalendarMockDataGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>> = ({ signal }) => getMockDataAdminCalendarMockDataGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMockDataAdminCalendarMockDataGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>>
export type GetMockDataAdminCalendarMockDataGetInfiniteQueryError = GetMockDataAdminCalendarMockDataGetErrorType<unknown>

/**
 * @summary Get Mock Data
 */
export const useGetMockDataAdminCalendarMockDataGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>>, TError = GetMockDataAdminCalendarMockDataGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMockDataAdminCalendarMockDataGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetMockDataAdminCalendarMockDataGetQueryOptions = <TData = Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError = GetMockDataAdminCalendarMockDataGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMockDataAdminCalendarMockDataGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>> = ({ signal }) => getMockDataAdminCalendarMockDataGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMockDataAdminCalendarMockDataGetQueryResult = NonNullable<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>>
export type GetMockDataAdminCalendarMockDataGetQueryError = GetMockDataAdminCalendarMockDataGetErrorType<unknown>

/**
 * @summary Get Mock Data
 */
export const useGetMockDataAdminCalendarMockDataGet = <TData = Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError = GetMockDataAdminCalendarMockDataGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMockDataAdminCalendarMockDataGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetMockDataAdminCalendarMockDataGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError = GetMockDataAdminCalendarMockDataGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMockDataAdminCalendarMockDataGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>> = ({ signal }) => getMockDataAdminCalendarMockDataGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMockDataAdminCalendarMockDataGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>>
export type GetMockDataAdminCalendarMockDataGetSuspenseQueryError = GetMockDataAdminCalendarMockDataGetErrorType<unknown>

/**
 * @summary Get Mock Data
 */
export const useGetMockDataAdminCalendarMockDataGetSuspense = <TData = Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError = GetMockDataAdminCalendarMockDataGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMockDataAdminCalendarMockDataGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMockDataAdminCalendarMockDataGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Execute Workflow
 */
export const executeWorkflowApiWorkflowsWorkflowUuidPost = (
    { workflowUuid }: ExecuteWorkflowApiWorkflowsWorkflowUuidPostPathParameters,
    workflowApiRequest: WorkflowApiRequest,
 ) => {
      
      
      return executeWorkflowApiWorkflowsWorkflowUuidPostMutator<WorkflowApiResponse>(
      {url: `/api/workflows/${workflowUuid}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowApiRequest
    },
      );
    }
  


export const getExecuteWorkflowApiWorkflowsWorkflowUuidPostMutationOptions = <TError = ExecuteWorkflowApiWorkflowsWorkflowUuidPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof executeWorkflowApiWorkflowsWorkflowUuidPost>>, TError,{pathParams: ExecuteWorkflowApiWorkflowsWorkflowUuidPostPathParameters;data: WorkflowApiRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof executeWorkflowApiWorkflowsWorkflowUuidPost>>, TError,{pathParams: ExecuteWorkflowApiWorkflowsWorkflowUuidPostPathParameters;data: WorkflowApiRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof executeWorkflowApiWorkflowsWorkflowUuidPost>>, {pathParams: ExecuteWorkflowApiWorkflowsWorkflowUuidPostPathParameters;data: WorkflowApiRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  executeWorkflowApiWorkflowsWorkflowUuidPost(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExecuteWorkflowApiWorkflowsWorkflowUuidPostMutationResult = NonNullable<Awaited<ReturnType<typeof executeWorkflowApiWorkflowsWorkflowUuidPost>>>
    export type ExecuteWorkflowApiWorkflowsWorkflowUuidPostMutationBody = WorkflowApiRequest
    export type ExecuteWorkflowApiWorkflowsWorkflowUuidPostMutationError = ExecuteWorkflowApiWorkflowsWorkflowUuidPostErrorType<HTTPValidationError>

    /**
 * @summary Execute Workflow
 */
export const useExecuteWorkflowApiWorkflowsWorkflowUuidPost = <TError = ExecuteWorkflowApiWorkflowsWorkflowUuidPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof executeWorkflowApiWorkflowsWorkflowUuidPost>>, TError,{pathParams: ExecuteWorkflowApiWorkflowsWorkflowUuidPostPathParameters;data: WorkflowApiRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof executeWorkflowApiWorkflowsWorkflowUuidPost>>,
        TError,
        {pathParams: ExecuteWorkflowApiWorkflowsWorkflowUuidPostPathParameters;data: WorkflowApiRequest},
        TContext
      > => {

      const mutationOptions = getExecuteWorkflowApiWorkflowsWorkflowUuidPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Execute Agent
 */
export const executeAgentApiAgentsAgentIdPost = (
    { agentId }: ExecuteAgentApiAgentsAgentIdPostPathParameters,
    agentApiRequest: AgentApiRequest,
 ) => {
      
      
      return executeAgentApiAgentsAgentIdPostMutator<AgentRunResult>(
      {url: `/api/agents/${agentId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: agentApiRequest
    },
      );
    }
  


export const getExecuteAgentApiAgentsAgentIdPostMutationOptions = <TError = ExecuteAgentApiAgentsAgentIdPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof executeAgentApiAgentsAgentIdPost>>, TError,{pathParams: ExecuteAgentApiAgentsAgentIdPostPathParameters;data: AgentApiRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof executeAgentApiAgentsAgentIdPost>>, TError,{pathParams: ExecuteAgentApiAgentsAgentIdPostPathParameters;data: AgentApiRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof executeAgentApiAgentsAgentIdPost>>, {pathParams: ExecuteAgentApiAgentsAgentIdPostPathParameters;data: AgentApiRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  executeAgentApiAgentsAgentIdPost(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExecuteAgentApiAgentsAgentIdPostMutationResult = NonNullable<Awaited<ReturnType<typeof executeAgentApiAgentsAgentIdPost>>>
    export type ExecuteAgentApiAgentsAgentIdPostMutationBody = AgentApiRequest
    export type ExecuteAgentApiAgentsAgentIdPostMutationError = ExecuteAgentApiAgentsAgentIdPostErrorType<HTTPValidationError>

    /**
 * @summary Execute Agent
 */
export const useExecuteAgentApiAgentsAgentIdPost = <TError = ExecuteAgentApiAgentsAgentIdPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof executeAgentApiAgentsAgentIdPost>>, TError,{pathParams: ExecuteAgentApiAgentsAgentIdPostPathParameters;data: AgentApiRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof executeAgentApiAgentsAgentIdPost>>,
        TError,
        {pathParams: ExecuteAgentApiAgentsAgentIdPostPathParameters;data: AgentApiRequest},
        TContext
      > => {

      const mutationOptions = getExecuteAgentApiAgentsAgentIdPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Run
 */
export const runAdminPlaygroundRunPost = (
    playgroundRunRequest: PlaygroundRunRequest,
 ) => {
      
      
      return runAdminPlaygroundRunPostMutator<RunAdminPlaygroundRunPost200>(
      {url: `/admin/playground/run`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: playgroundRunRequest
    },
      );
    }
  


export const getRunAdminPlaygroundRunPostMutationOptions = <TError = RunAdminPlaygroundRunPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runAdminPlaygroundRunPost>>, TError,{data: PlaygroundRunRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof runAdminPlaygroundRunPost>>, TError,{data: PlaygroundRunRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof runAdminPlaygroundRunPost>>, {data: PlaygroundRunRequest}> = (props) => {
          const {data} = props ?? {};

          return  runAdminPlaygroundRunPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RunAdminPlaygroundRunPostMutationResult = NonNullable<Awaited<ReturnType<typeof runAdminPlaygroundRunPost>>>
    export type RunAdminPlaygroundRunPostMutationBody = PlaygroundRunRequest
    export type RunAdminPlaygroundRunPostMutationError = RunAdminPlaygroundRunPostErrorType<HTTPValidationError>

    /**
 * @summary Run
 */
export const useRunAdminPlaygroundRunPost = <TError = RunAdminPlaygroundRunPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runAdminPlaygroundRunPost>>, TError,{data: PlaygroundRunRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof runAdminPlaygroundRunPost>>,
        TError,
        {data: PlaygroundRunRequest},
        TContext
      > => {

      const mutationOptions = getRunAdminPlaygroundRunPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Execute Tool
 */
export const executeToolAdminPlaygroundExecuteToolPost = (
    playgroundRunToolRequest: PlaygroundRunToolRequest,
 ) => {
      
      
      return executeToolAdminPlaygroundExecuteToolPostMutator<ToolResult>(
      {url: `/admin/playground/execute/tool`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: playgroundRunToolRequest
    },
      );
    }
  


export const getExecuteToolAdminPlaygroundExecuteToolPostMutationOptions = <TError = ExecuteToolAdminPlaygroundExecuteToolPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof executeToolAdminPlaygroundExecuteToolPost>>, TError,{data: PlaygroundRunToolRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof executeToolAdminPlaygroundExecuteToolPost>>, TError,{data: PlaygroundRunToolRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof executeToolAdminPlaygroundExecuteToolPost>>, {data: PlaygroundRunToolRequest}> = (props) => {
          const {data} = props ?? {};

          return  executeToolAdminPlaygroundExecuteToolPost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExecuteToolAdminPlaygroundExecuteToolPostMutationResult = NonNullable<Awaited<ReturnType<typeof executeToolAdminPlaygroundExecuteToolPost>>>
    export type ExecuteToolAdminPlaygroundExecuteToolPostMutationBody = PlaygroundRunToolRequest
    export type ExecuteToolAdminPlaygroundExecuteToolPostMutationError = ExecuteToolAdminPlaygroundExecuteToolPostErrorType<HTTPValidationError>

    /**
 * @summary Execute Tool
 */
export const useExecuteToolAdminPlaygroundExecuteToolPost = <TError = ExecuteToolAdminPlaygroundExecuteToolPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof executeToolAdminPlaygroundExecuteToolPost>>, TError,{data: PlaygroundRunToolRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof executeToolAdminPlaygroundExecuteToolPost>>,
        TError,
        {data: PlaygroundRunToolRequest},
        TContext
      > => {

      const mutationOptions = getExecuteToolAdminPlaygroundExecuteToolPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Share
 */
export const shareAdminPlaygroundSharePost = (
    playgroundShareRequest: PlaygroundShareRequest,
 ) => {
      
      
      return shareAdminPlaygroundSharePostMutator<PlaygroundShareInfo>(
      {url: `/admin/playground/share`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: playgroundShareRequest
    },
      );
    }
  


export const getShareAdminPlaygroundSharePostMutationOptions = <TError = ShareAdminPlaygroundSharePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof shareAdminPlaygroundSharePost>>, TError,{data: PlaygroundShareRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof shareAdminPlaygroundSharePost>>, TError,{data: PlaygroundShareRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof shareAdminPlaygroundSharePost>>, {data: PlaygroundShareRequest}> = (props) => {
          const {data} = props ?? {};

          return  shareAdminPlaygroundSharePost(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ShareAdminPlaygroundSharePostMutationResult = NonNullable<Awaited<ReturnType<typeof shareAdminPlaygroundSharePost>>>
    export type ShareAdminPlaygroundSharePostMutationBody = PlaygroundShareRequest
    export type ShareAdminPlaygroundSharePostMutationError = ShareAdminPlaygroundSharePostErrorType<HTTPValidationError>

    /**
 * @summary Share
 */
export const useShareAdminPlaygroundSharePost = <TError = ShareAdminPlaygroundSharePostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof shareAdminPlaygroundSharePost>>, TError,{data: PlaygroundShareRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof shareAdminPlaygroundSharePost>>,
        TError,
        {data: PlaygroundShareRequest},
        TContext
      > => {

      const mutationOptions = getShareAdminPlaygroundSharePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update
 */
export const updateAdminPlaygroundUpdateUuidPost = (
    { uuid }: UpdateAdminPlaygroundUpdateUuidPostPathParameters,
    playgroundShareRequest: PlaygroundShareRequest,
 ) => {
      
      
      return updateAdminPlaygroundUpdateUuidPostMutator<PlaygroundShareInfo>(
      {url: `/admin/playground/update/${uuid}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: playgroundShareRequest
    },
      );
    }
  


export const getUpdateAdminPlaygroundUpdateUuidPostMutationOptions = <TError = UpdateAdminPlaygroundUpdateUuidPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminPlaygroundUpdateUuidPost>>, TError,{pathParams: UpdateAdminPlaygroundUpdateUuidPostPathParameters;data: PlaygroundShareRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAdminPlaygroundUpdateUuidPost>>, TError,{pathParams: UpdateAdminPlaygroundUpdateUuidPostPathParameters;data: PlaygroundShareRequest}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAdminPlaygroundUpdateUuidPost>>, {pathParams: UpdateAdminPlaygroundUpdateUuidPostPathParameters;data: PlaygroundShareRequest}> = (props) => {
          const {pathParams,data} = props ?? {};

          return  updateAdminPlaygroundUpdateUuidPost(pathParams,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAdminPlaygroundUpdateUuidPostMutationResult = NonNullable<Awaited<ReturnType<typeof updateAdminPlaygroundUpdateUuidPost>>>
    export type UpdateAdminPlaygroundUpdateUuidPostMutationBody = PlaygroundShareRequest
    export type UpdateAdminPlaygroundUpdateUuidPostMutationError = UpdateAdminPlaygroundUpdateUuidPostErrorType<HTTPValidationError>

    /**
 * @summary Update
 */
export const useUpdateAdminPlaygroundUpdateUuidPost = <TError = UpdateAdminPlaygroundUpdateUuidPostErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminPlaygroundUpdateUuidPost>>, TError,{pathParams: UpdateAdminPlaygroundUpdateUuidPostPathParameters;data: PlaygroundShareRequest}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateAdminPlaygroundUpdateUuidPost>>,
        TError,
        {pathParams: UpdateAdminPlaygroundUpdateUuidPostPathParameters;data: PlaygroundShareRequest},
        TContext
      > => {

      const mutationOptions = getUpdateAdminPlaygroundUpdateUuidPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Presets
 */
export const presetsAdminPlaygroundPresetsGet = (
    
 signal?: AbortSignal
) => {
      
      
      return presetsAdminPlaygroundPresetsGetMutator<PlaygroundShareInfo[]>(
      {url: `/admin/playground/presets`, method: 'GET', signal
    },
      );
    }
  

export const getPresetsAdminPlaygroundPresetsGetQueryKey = () => {
    return [`/admin/playground/presets`] as const;
    }

    
export const getPresetsAdminPlaygroundPresetsGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>>, TError = PresetsAdminPlaygroundPresetsGetErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPresetsAdminPlaygroundPresetsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>> = ({ signal }) => presetsAdminPlaygroundPresetsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PresetsAdminPlaygroundPresetsGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>>
export type PresetsAdminPlaygroundPresetsGetInfiniteQueryError = PresetsAdminPlaygroundPresetsGetErrorType<unknown>

/**
 * @summary Presets
 */
export const usePresetsAdminPlaygroundPresetsGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>>, TError = PresetsAdminPlaygroundPresetsGetErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPresetsAdminPlaygroundPresetsGetInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPresetsAdminPlaygroundPresetsGetQueryOptions = <TData = Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError = PresetsAdminPlaygroundPresetsGetErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPresetsAdminPlaygroundPresetsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>> = ({ signal }) => presetsAdminPlaygroundPresetsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PresetsAdminPlaygroundPresetsGetQueryResult = NonNullable<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>>
export type PresetsAdminPlaygroundPresetsGetQueryError = PresetsAdminPlaygroundPresetsGetErrorType<unknown>

/**
 * @summary Presets
 */
export const usePresetsAdminPlaygroundPresetsGet = <TData = Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError = PresetsAdminPlaygroundPresetsGetErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPresetsAdminPlaygroundPresetsGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPresetsAdminPlaygroundPresetsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError = PresetsAdminPlaygroundPresetsGetErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPresetsAdminPlaygroundPresetsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>> = ({ signal }) => presetsAdminPlaygroundPresetsGet(signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PresetsAdminPlaygroundPresetsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>>
export type PresetsAdminPlaygroundPresetsGetSuspenseQueryError = PresetsAdminPlaygroundPresetsGetErrorType<unknown>

/**
 * @summary Presets
 */
export const usePresetsAdminPlaygroundPresetsGetSuspense = <TData = Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError = PresetsAdminPlaygroundPresetsGetErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof presetsAdminPlaygroundPresetsGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPresetsAdminPlaygroundPresetsGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Preset
 */
export const presetAdminPlaygroundPresetsPresetUuidGet = (
    { uuid }: PresetAdminPlaygroundPresetsPresetUuidGetPathParameters,
 signal?: AbortSignal
) => {
      
      
      return presetAdminPlaygroundPresetsPresetUuidGetMutator<PlaygroundShareInfo>(
      {url: `/admin/playground/presets/preset-${uuid}`, method: 'GET', signal
    },
      );
    }
  

export const getPresetAdminPlaygroundPresetsPresetUuidGetQueryKey = ({ uuid }: PresetAdminPlaygroundPresetsPresetUuidGetPathParameters,) => {
    return [`/admin/playground/presets/preset-${uuid}`] as const;
    }

    
export const getPresetAdminPlaygroundPresetsPresetUuidGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>>, TError = PresetAdminPlaygroundPresetsPresetUuidGetErrorType<HTTPValidationError>>({ uuid }: PresetAdminPlaygroundPresetsPresetUuidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPresetAdminPlaygroundPresetsPresetUuidGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>> = ({ signal }) => presetAdminPlaygroundPresetsPresetUuidGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PresetAdminPlaygroundPresetsPresetUuidGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>>
export type PresetAdminPlaygroundPresetsPresetUuidGetInfiniteQueryError = PresetAdminPlaygroundPresetsPresetUuidGetErrorType<HTTPValidationError>

/**
 * @summary Preset
 */
export const usePresetAdminPlaygroundPresetsPresetUuidGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>>, TError = PresetAdminPlaygroundPresetsPresetUuidGetErrorType<HTTPValidationError>>(
 { uuid }: PresetAdminPlaygroundPresetsPresetUuidGetPathParameters, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError, TData>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPresetAdminPlaygroundPresetsPresetUuidGetInfiniteQueryOptions({ uuid },options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPresetAdminPlaygroundPresetsPresetUuidGetQueryOptions = <TData = Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError = PresetAdminPlaygroundPresetsPresetUuidGetErrorType<HTTPValidationError>>({ uuid }: PresetAdminPlaygroundPresetsPresetUuidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPresetAdminPlaygroundPresetsPresetUuidGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>> = ({ signal }) => presetAdminPlaygroundPresetsPresetUuidGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PresetAdminPlaygroundPresetsPresetUuidGetQueryResult = NonNullable<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>>
export type PresetAdminPlaygroundPresetsPresetUuidGetQueryError = PresetAdminPlaygroundPresetsPresetUuidGetErrorType<HTTPValidationError>

/**
 * @summary Preset
 */
export const usePresetAdminPlaygroundPresetsPresetUuidGet = <TData = Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError = PresetAdminPlaygroundPresetsPresetUuidGetErrorType<HTTPValidationError>>(
 { uuid }: PresetAdminPlaygroundPresetsPresetUuidGetPathParameters, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPresetAdminPlaygroundPresetsPresetUuidGetQueryOptions({ uuid },options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPresetAdminPlaygroundPresetsPresetUuidGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError = PresetAdminPlaygroundPresetsPresetUuidGetErrorType<HTTPValidationError>>({ uuid }: PresetAdminPlaygroundPresetsPresetUuidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPresetAdminPlaygroundPresetsPresetUuidGetQueryKey({ uuid });

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>> = ({ signal }) => presetAdminPlaygroundPresetsPresetUuidGet({ uuid }, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PresetAdminPlaygroundPresetsPresetUuidGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>>
export type PresetAdminPlaygroundPresetsPresetUuidGetSuspenseQueryError = PresetAdminPlaygroundPresetsPresetUuidGetErrorType<HTTPValidationError>

/**
 * @summary Preset
 */
export const usePresetAdminPlaygroundPresetsPresetUuidGetSuspense = <TData = Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError = PresetAdminPlaygroundPresetsPresetUuidGetErrorType<HTTPValidationError>>(
 { uuid }: PresetAdminPlaygroundPresetsPresetUuidGetPathParameters, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof presetAdminPlaygroundPresetsPresetUuidGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPresetAdminPlaygroundPresetsPresetUuidGetSuspenseQueryOptions({ uuid },options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Ping
 */
export const pingGet = (
    params?: PingGetParams,
 signal?: AbortSignal
) => {
      
      
      return pingGetMutator<unknown>(
      {url: `/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getPingGetQueryKey = (params?: PingGetParams,) => {
    return [`/`, ...(params ? [params]: [])] as const;
    }

    
export const getPingGetInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof pingGet>>, PingGetParams['after']>, TError = PingGetErrorType<HTTPValidationError>>(params?: PingGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof pingGet>>, TError, TData, Awaited<ReturnType<typeof pingGet>>, QueryKey, PingGetParams['after']>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPingGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pingGet>>, QueryKey, PingGetParams['after']> = ({ signal, pageParam }) => pingGet({...params, after: pageParam || params?.['after']}, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof pingGet>>, TError, TData, Awaited<ReturnType<typeof pingGet>>, QueryKey, PingGetParams['after']> & { queryKey: QueryKey }
}

export type PingGetInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof pingGet>>>
export type PingGetInfiniteQueryError = PingGetErrorType<HTTPValidationError>

/**
 * @summary Ping
 */
export const usePingGetInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof pingGet>>, PingGetParams['after']>, TError = PingGetErrorType<HTTPValidationError>>(
 params?: PingGetParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof pingGet>>, TError, TData, Awaited<ReturnType<typeof pingGet>>, QueryKey, PingGetParams['after']>>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPingGetInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPingGetQueryOptions = <TData = Awaited<ReturnType<typeof pingGet>>, TError = PingGetErrorType<HTTPValidationError>>(params?: PingGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pingGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPingGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pingGet>>> = ({ signal }) => pingGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pingGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PingGetQueryResult = NonNullable<Awaited<ReturnType<typeof pingGet>>>
export type PingGetQueryError = PingGetErrorType<HTTPValidationError>

/**
 * @summary Ping
 */
export const usePingGet = <TData = Awaited<ReturnType<typeof pingGet>>, TError = PingGetErrorType<HTTPValidationError>>(
 params?: PingGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pingGet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPingGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getPingGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof pingGet>>, TError = PingGetErrorType<HTTPValidationError>>(params?: PingGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof pingGet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPingGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pingGet>>> = ({ signal }) => pingGet(params, signal);

      

      

   return  { queryKey, queryFn,   enabled: true,  ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof pingGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PingGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof pingGet>>>
export type PingGetSuspenseQueryError = PingGetErrorType<HTTPValidationError>

/**
 * @summary Ping
 */
export const usePingGetSuspense = <TData = Awaited<ReturnType<typeof pingGet>>, TError = PingGetErrorType<HTTPValidationError>>(
 params?: PingGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof pingGet>>, TError, TData>>, }

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPingGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




