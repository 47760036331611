import { useEffect, useState } from "react";

export interface AlertInfo {
  title?: string;
  message: string;
  actions: AlertAction[];
}

export interface AlertAction {
  title: string;
  style?: "cancel" | "destructive";
}

export interface Notification {
  name: string;
}

interface JSBridge {
  getUserToken(): Promise<string>;
  closePage(): Promise<void>;
  vibrate(): Promise<void>;
  showLoading(): Promise<void>;
  hideLoading(): Promise<void>;
  alert(info: AlertInfo): Promise<number>;
  toast(message: string): Promise<void>;
}

async function waitForEvent(eventName: string): Promise<void> {
  return new Promise((resolve) => {
    document.addEventListener(eventName, () => {
      resolve();
    });
  });
}

export const bridgeRef = {
  current: null,
} as { current: JSBridge | null };

export async function getJSBridge(): Promise<JSBridge | null> {
  if (bridgeRef.current) {
    return bridgeRef.current;
  }

  if (typeof window === "undefined") {
    return null;
  }

  if (!("WebViewJavascriptBridge" in window)) {
    await waitForEvent("WebViewJavascriptBridgeReady");
  }

  if (!("OABridge" in window)) {
    throw new Error("OABridge not found");
  }

  bridgeRef.current = createJSBridgeProxy(window.OABridge);
  return bridgeRef.current;
}

function createJSBridgeProxy(oaBridge: any): JSBridge {
  return {
    getUserToken: async () => {
      return new Promise((resolve) => {
        oaBridge.getUserToken(null, resolve);
      });
    },

    closePage: async () => {
      return new Promise((resolve) => {
        oaBridge.closePage(null, resolve);
      });
    },

    vibrate: async () => {
      return new Promise((resolve) => {
        oaBridge.vibrate(null, resolve);
      });
    },
    alert: async (info: AlertInfo) => {
      return new Promise((resolve) => {
        oaBridge.alert(JSON.stringify(info), resolve);
      });
    },

    showLoading: async () => {
      return new Promise((resolve) => {
        oaBridge.showLoading(null, resolve);
      });
    },

    hideLoading: async () => {
      return new Promise((resolve) => {
        oaBridge.hideLoading(null, resolve);
      });
    },

    toast: async (message: string) => {
      return new Promise((resolve) => {
        oaBridge.toast(message, resolve);
      });
    },
  };
}

export function useJSBridge() {
  const [jsBridge, setJSBridge] = useState<JSBridge | null>(null);

  useEffect(() => {
    (async () => {
      const bridge = await getJSBridge();
      if (bridge) {
        setJSBridge(bridge);
      }
    })();
  }, []);

  return jsBridge;
}
