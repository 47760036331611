import { LoaderCircle } from "lucide-react";
import { useEffect } from "react";

import { useJSBridge } from "@/jsbridge";

export default function PageLoading() {
  const bridge = useJSBridge();

  useEffect(() => {
    if (bridge) {
      bridge.showLoading();
      return () => {
        bridge.hideLoading();
      };
    }
  }, [bridge]);

  return (
    <div className="flex h-full min-h-[100dvh] w-full flex-col items-center justify-center gap-2 text-center">
      <LoaderCircle className="animate-spin" />
      <p>Loading</p>
    </div>
  );
}
